// video-block.js

class VideoBlock {

    async uploadVideo(file) {
        // 在这里实现视频上传逻辑，返回视频的URL
        // 例如，您可以使用Fetch API将文件上传到服务器
        const formData = new FormData();
        formData.append('file', file);

        const response = await fetch('/api/utils/upload', {
            method: 'POST',
            body: formData,
        });
        console.log(response)
        if (response.ok) {
            const data = await response.json();
            return data.data.file;
        } else {
            // 处理上传失败的情况
            console.error('视频上传失败');
            return '';
        }
    }



    static get toolbox() {
        return {
            title: '视频',
            icon: '<svg t="1693551700876" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2312" width="200" height="200"><path d="M768.355 416a256 256 0 1 0-512 0 192 192 0 1 0 0 384v64a256 256 0 0 1-58.88-505.216 320.128 320.128 0 0 1 629.76 0A256.128 256.128 0 0 1 768.355 864v-64a192 192 0 0 0 0-384z m-512 384h128v64h-128v-64z m384 0h128v64h-128v-64z" fill="#4d4d4d" p-id="2313"></path><path d="M539.043 589.184V922.24a32.448 32.448 0 0 1-32 32.192 32.448 32.448 0 0 1-32-32.192V589.184l-36.096 36.096a32.192 32.192 0 0 1-45.056-0.192 31.616 31.616 0 0 1-0.192-45.056l90.88-90.88A31.36 31.36 0 0 1 507.107 480a30.08 30.08 0 0 1 22.4 9.088l90.88 90.944a32.192 32.192 0 0 1-0.192 45.056 31.616 31.616 0 0 1-45.056 0.192l-36.096-36.096z" fill="#4d4d4d" p-id="2314"></path></svg>',
        };
    }

    constructor({
        data
    }) {
        this.data = data;
    }

    render() {
        const container = document.createElement('div');

        container.style.width = '100%';
        container.style.height = '100%';
        container.style.border = '1px solid #ccc';
        container.style.display = 'flex';
        container.style.alignItems = 'center';
        container.style.backgroundColor = '#fff';
        container.style.justifyContent = 'center';

        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = 'video/*'; // 限制文件类型为视频文件
        fileInput.controls = 'controls*'; // 限制文件类型为视频文件
        fileInput.style.opacity = 0; // 使文件输入框透明
        fileInput.style.position = 'absolute';
        fileInput.style.left = '0';
        fileInput.style.top = '0';
        fileInput.style.width = '100%';
        fileInput.style.height = '100%';
        const icon = document.createElement('div');
        icon.innerHTML = '<svg t="1693551700876" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2312" width="40" height="40"><path d="M768.355 416a256 256 0 1 0-512 0 192 192 0 1 0 0 384v64a256 256 0 0 1-58.88-505.216 320.128 320.128 0 0 1 629.76 0A256.128 256.128 0 0 1 768.355 864v-64a192 192 0 0 0 0-384z m-512 384h128v64h-128v-64z m384 0h128v64h-128v-64z" fill="#4d4d4d" p-id="2313"></path><path d="M539.043 589.184V922.24a32.448 32.448 0 0 1-32 32.192 32.448 32.448 0 0 1-32-32.192V589.184l-36.096 36.096a32.192 32.192 0 0 1-45.056-0.192 31.616 31.616 0 0 1-0.192-45.056l90.88-90.88A31.36 31.36 0 0 1 507.107 480a30.08 30.08 0 0 1 22.4 9.088l90.88 90.944a32.192 32.192 0 0 1-0.192 45.056 31.616 31.616 0 0 1-45.056 0.192l-36.096-36.096z" fill="#4d4d4d" p-id="2314"></path></svg>';
        icon.style.cursor = 'pointer';
        // icon.style.width = '24px';
        // icon.style.height = '24px';

        // 将文件上传输入框添加到容器
        container.appendChild(fileInput);
        // 将图标添加到容器
        container.appendChild(icon);

        // 添加点击事件处理程序来触发文件选择
        icon.addEventListener('click', () => {
            fileInput.click();
        });









        // const fileInput = document.createElement('input');
        // fileInput.type = 'file';
        // fileInput.accept = 'video/*'; // 限制文件类型为视频文件
        fileInput.addEventListener('change', async (event) => {
            const file = event.target.files[0];

            if (file) {
                // 进行视频上传和预览
                const videoUrl = await this.uploadVideo(file);

                // 更新插件的数据
                this.data.src = videoUrl;

                // 在预览区域显示上传的视频
                const videoPreview = document.createElement('video');
                videoPreview.src = videoUrl;
                videoPreview.controls = true;
                container.appendChild(videoPreview);
                icon.style.display = 'none';
            }
        });

        container.appendChild(fileInput);

         // 自动触发文件上传对话框，打开选择视频文件的功能
        setTimeout(() => {
            fileInput.click();
        }, 0);

        // 可以在此处添加其他UI元素

        return container;
    }

    save() {
        // Return the saved data for the video block
        return {
            src: this.data.src || '',
            // You can include other attributes as needed
        };
    }
}

export default VideoBlock;