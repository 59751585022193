// EmbedTool.js
class EmbedTool {

    static get toolbox() {
        return {
            title: '嵌入',
            icon: '<svg t="1698248742177" class="icon" viewBox="0 0 1211 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1448" width="200" height="200"><path d="M717.034962 1023.997037a47.40727 47.40727 0 0 1-33.185089-80.592359l427.850614-427.850614a15.407363 15.407363 0 0 0 4.740727-10.666636 11.851818 11.851818 0 0 0-3.555545-8.296272L699.257236 81.777541a47.768751 47.768751 0 0 1 67.55536-67.55536l413.628433 413.628433a105.481176 105.481176 0 0 1 30.814725 77.036814 110.221903 110.221903 0 0 1-33.185089 77.036814L750.220051 1010.960038a47.40727 47.40727 0 0 1-33.185089 13.036999z" fill="" p-id="1449"></path><path d="M1142.515213 559.405789H47.40727a47.40727 47.40727 0 1 1 0-94.814541h1095.107943a47.40727 47.40727 0 1 1 0 94.814541z" fill="" p-id="1450"></path></svg>',
        };
    }
    constructor({ data, api }) {
      this.data = data || {};
      this.api = api;
    }
  
    render() {
      this.embedContainer = document.createElement('div');
      this.embedContainer.classList.add('embed-container');
     
  
      const inputContainer = document.createElement('div');
      inputContainer.classList.add('url-input-container');
      inputContainer.style.display = 'flex';
        
      const input = document.createElement('input');
      input.setAttribute('type', 'text');
      input.setAttribute('placeholder', 'Enter URL');
      input.classList.add('url-input');
      input.style.width = '80%';
      input.style.height = '50px';
      input.style.border = '1px solid #ccc';
      input.style.display = 'flex';
      input.style.alignItems = 'center';
      input.style.backgroundColor = '#fff';
      input.style.justifyContent = 'center';
      input.style.paddingLeft = '20px';
  
      const embedButton = document.createElement('button');
      embedButton.textContent = '嵌入';
      embedButton.classList.add('embed-button');
      embedButton.style.width = '20%';
      embedButton.style.height = '50px';
      
      embedButton.addEventListener('click', () => {
        this.data.url = input.value;
        console.log(this.data)
        this.api.blocks.insert('customEmbed', this.data);
        this.api.blocks.delete(this.api.blocks.getCurrentBlockIndex());


        const embeddedContent = document.createElement('iframe');
        const videoUrl = this.getVideoEmbedURL(this.data.url); 
        embeddedContent.src = videoUrl;
        embeddedContent.frameborder = '0';
        embeddedContent.allowfullscreen = true;
        embeddedContent.style.width = '650px';
        embeddedContent.style.height = '500px';
  
        // embeddedContent.appendChild(videoElement);
        this.embedContainer.appendChild(embeddedContent);
        
        console.log(this.embedContainer)
        inputContainer.style.display = 'none';

        
      });
  
      inputContainer.appendChild(input);
      inputContainer.appendChild(embedButton);
      this.embedContainer.appendChild(inputContainer);
  
      if (this.data.url) {
        // 如果已经有 URL，则呈现嵌入的内容
        const embeddedContent = document.createElement('iframe');
        const videoUrl = this.getVideoEmbedURL(this.data.url); 
        embeddedContent.src = videoUrl;
        embeddedContent.frameborder = '0';
        embeddedContent.allowfullscreen = true;
  
        // embeddedContent.appendChild(videoElement);
        this.embedContainer.appendChild(embeddedContent);
        console.log(this.embedContainer)
        inputContainer.style.display = 'none';
        // embedButton.style.display = 'none';
      }
  
      return this.embedContainer;
    }


    getVideoEmbedURL(url) {
        let videoUrl = '';
        if (url.includes('youtube.com')) {
          // YouTube 视频URL，处理为嵌入链接
          const videoId = this.getYouTubeVideoId(url);
          if (videoId) {
            videoUrl = `https://www.youtube.com/embed/${videoId}`;
          }
        } else if (url.includes('bilibili.com')) {
          // Bilibili 视频URL，处理为嵌入链接
          const videoId = this.getBilibiliVideoId(url);
          if (videoId) {
            videoUrl = `https://player.bilibili.com/player.html?aid=${videoId}`;
          }
        } else if (url.includes('v.youku.com')) {
          // 优酷视频URL，处理为嵌入链接
          const videoId = this.getYoukuVideoId(url);
          if (videoId) {
            videoUrl = `https://player.youku.com/embed/${videoId}`;
          }
        } else if (url.includes('v.qq.com')) {
          // 腾讯视频URL，处理为嵌入链接
          const videoId = this.getQQVideoId(url);
          if (videoId) {
            videoUrl = `https://v.qq.com/iframe/player.html?vid=${videoId}`;
          }
        }

        return videoUrl;
    }

    getYouTubeVideoId(url) {
        // 从 YouTube URL 中提取视频ID
        const match = url.match(/[?&]v=([a-zA-Z0-9_-]+)/);
        return match ? match[1] : null;
      }
    
      getBilibiliVideoId(url) {
        // 从 Bilibili URL 中提取视频ID
        const match = url.match(/\/video\/av(\d+)/);
        return match ? match[1] : null;
      }
    
      getYoukuVideoId(url) {
        // 从优酷 URL 中提取视频ID
        const match = url.match(/id_(\w+)/);
        return match ? match[1] : null;
      }
    
      getQQVideoId(url) {
        // 从腾讯视频URL中提取视频ID
        const match = url.match(/\/([a-zA-Z0-9]+)\.html/);
        return match ? match[1] : null;
      }

    // getYouTubeEmbedURL(url) {
    //     // 处理 YouTube URL，将其转换为嵌入链接
    //     const videoId = this.getYouTubeVideoId(url);
    //     if (videoId) {
    //       return `https://www.youtube.com/embed/${videoId}`;
    //     } else {
    //       // 如果未能解析视频ID，返回原始URL
    //       return url;
    //     }
    //   }
    
    //   getYouTubeVideoId(url) {
    //     // 从 YouTube URL 中提取视频ID
    //     const match = url.match(/[?&]v=([a-zA-Z0-9_-]+)/);
    //     return match ? match[1] : null;
    //   }
  
    save() {
      return {
        url: this.data.url,
      };
    }
  
    validate(savedData) {
      if (!savedData.url) {
        return false;
      }
      return true;
    }
  }
  
  export default EmbedTool;
  