<template>
    <div class="index">
        <!-- <headerbar></headerbar> -->
        <div class="index_nei">
            <!-- <div class="banner">
                <img src="../../img/huodong_banner.jpg" alt="">
            </div> -->
            <div class="person">




                <div class="jobTop">
                    <div class="jobTop_left">
                        <div class="left_nav">
                            <img v-if="userinfo.avatar != null" :src="userinfo.avatar" alt="">
                            <img v-else src="../../img/tx.jpg">
                            <p>{{ userinfo.name }}</p>
                        </div>
                        <div class="miaoshu">
                            <p>{{ userinfo.intro }}</p>
                        </div>

                        <!-- <div class="anniu">
                            <div class="anniu2" v-if="detail2.is_following" @click="anniuBtn(detail2.id)">
                                <img src="../../img/duihao.png" alt="">
                                <span>已关注</span>
                            </div>
                            <div class="anniu1" v-else @click="anniuBtn(detail2.id)">
                                <img src="../../img/email_1.png" alt="">
                                <p>关注</p>
                            </div>
                        </div> -->

                    </div>
                    <div class="jobTop_right">
                        <img src="../../img/ge_detail.png" alt="">
                    </div>
                </div>








                <!-- <div class="personInfo">
                    <div class="tx">
                        <img src="@/img/tx.jpg" alt="" />
                    </div>
                    <div class="detail">
                        <p>{{ userinfo.name }}</p>
                        <span @click="xiugai">修改密码</span>
                    </div>
                </div> -->

                <div class="person_head">
                    <router-link class="nav" to="/MyFavorite"
                        :class="{ active: $route.path === '/FavoriteArticle' || $route.path === '/FavoriteProject' || $route.path === '/FavoriteJob' }"
                        active-class="active">
                        <p>我的收藏</p>
                    </router-link>
                    <router-link class="nav" to="/myArticle" active-class="active">
                        <p>我的文章</p>
                    </router-link>
                    <router-link class="nav" to="/myProject" active-class="active">
                        <p>我的项目</p>
                    </router-link>
                    <!-- <router-link class="nav" to="/myCase" active-class="active">
                        <p>我的案例</p>
                    </router-link> -->
                    <router-link class="nav" to="/myVideo" active-class="active">
                        <p>我的视频</p>
                    </router-link>
                    <router-link class="nav" to="/myPoster" active-class="active">
                        <p>我的海报</p>
                    </router-link>
                    <router-link class="nav" to="/myJianli"
                        :class="{ active: $route.path === '/myJianli' || $route.path === '/editJianli' }">
                        <p>我的简历</p>
                    </router-link>
                    <!-- <router-link class="nav" to="/mySetting" active-class="active">
                        <p>账号设置</p>
                    </router-link> -->
                    <router-link class="nav" to="/follow" active-class="active">
                        <p>我的关注</p>
                    </router-link>
                    <router-link class="nav" to="/fans" active-class="active">
                        <p>我的粉丝</p>
                    </router-link>


                </div>

                <div class="person_con">
                    <router-view></router-view>
                </div>

            </div>
        </div>
        <footerbar></footerbar>
    </div>
</template>

<script>
// import headerbar from '../../components/headerbar.vue';
import { eventBus } from '../../App.vue';
import footerbar from '../../components/footerbar.vue';
export default {
    name: 'person',
    data() {
        return {
            pageTitle: '个人中心收藏的文章',
            userinfo: '',
            token: window.localStorage.getItem('token'),
        }
    },
    created() {
        document.title = this.pageTitle;

    },
    components: {
        // headerbar,
        footerbar
    },
    mounted() {
        this.setActivePage()
        console.log(this.token)
        if (this.token != null) {
            this.getinfo()
        }
    },
    methods: {

        setActivePage() {
            // 触发事件，传递当前页面的名称
            eventBus.$emit('updateActivePage', '');
        },
        chooseBtn(e) {
            console.log(e)
            this.choose = e
        },
        chooseBtn1(e) {
            this.choose1 = e
        },
        todetail() {

        },
        //获取用户信息
        getinfo() {
            this.$axios.get('/api/user/info', {
                headers: {
                    'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
                }
            }).then((result) => {
                console.log(result)
                if (result.data.code == 200) {
                    this.userinfo = result.data.data
                } else {
                    this.$message.error(result.data.message);
                }
            }).catch((err) => {
                console.log(err)
                window.localStorage.clear()
                // this.$message.error(err.response.data.message);
                // if (err.response.data.code == 403) {
                //     setTimeout(() => {
                //         this.$router.push({
                //             name: 'login'
                //         })
                //     }, 1500);

                // }
            });
        },

        //修改密码
        xiugai() {
            this.$router.push({
                name: 'changepwd',
                // query: {
                //     type: e
                // }
            })
        },


    },
}
</script>

<style scoped lang="scss">
@import "../person/person.scss"
</style>