<template>
    <div class="index">
        <!-- <headerbar></headerbar> -->
        <div class="index_nei">
            <div class="liebiao_top">
                <div class="top_left">
                    <p>搜索结果({{ allList.length }})</p>
                </div>
                <div class="top_right">
                    <div class="right1">
                        <el-select v-model="value" placeholder="请选择" @change="shaixuan">
                            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="right1">
                        <el-select v-model="value1" placeholder="行业" @change="shaixuan1">
                            <el-option v-for="item in options1" :key="item.id" :label="item.name" :value="item.name">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="right1">
                        <el-select v-model="value2" placeholder="全国" @change="shaixuan2">
                            <el-option v-for="item in options2" :key="item.code" :label="item.name" :value="item.code">
                            </el-option>
                        </el-select>
                    </div>
                    <!-- <div class="right1">
                        <el-select v-model="value3" placeholder="颜色">
                            <el-option v-for="item in options3" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </div> -->
                </div>
            </div>
            <div class="liebiao_con">
                <div class="allList" v-if="allList.length > 0">
                    <div class="list1" v-for="(item, index) in allList" :key="index" @click="todetail(item.id)">
                        <div class="img" v-if="item.cover != null">
                            <img :src="item.cover" alt="">
                        </div>
                        <div class="des">
                            <div class="title">
                                <p>{{ item.title }}</p>
                            </div>
                            <div class="xq">
                                <div class="xq1">
                                    <!-- <img :src="item.author.tx" alt=""> -->
                                    <p>{{ item.author }}</p>
                                </div>
                                <div class="xq2">
                                    <div class="sc">
                                        <img src="../../img/shoucang.png" alt="">
                                        <p>{{ item.collect_count }}</p>
                                    </div>
                                    <div class="sc">
                                        <img src="../../img/dianzan.png" alt="">
                                        <p>{{ item.support_count }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="kong" v-else>
                    <img src="../../img/kong.png" alt="">
                    <p>暂时没有结果信息</p>
                </div>
            </div>
        </div>
        <footerbar></footerbar>
    </div>
</template>

<script>
// import headerbar from '../../components/headerbar.vue';
import { eventBus } from '../../App.vue';
import footerbar from '../../components/footerbar.vue';
export default {
    name: 'searchResult',
    data() {
        return {
            pageTitle: '搜索',
            keywords: '',
            options: [{
                value: '1',
                label: '精选'
            }, {
                value: '2',
                label: '热门'
            }, {
                value: '3',
                label: '热点筛选'
            }],
            value: '',
            options1: [{
                value: '选项1',
                label: '标签1'
            }, {
                value: '选项2',
                label: '标签2'
            }, {
                value: '选项3',
                label: '标签3'
            }, {
                value: '选项4',
                label: '标签4'
            }, {
                value: '选项5',
                label: '标签5'
            }],
            value1: '',
            options2: [{
                value: '选项1',
                label: '行业1'
            }, {
                value: '选项2',
                label: '行业2'
            }, {
                value: '选项3',
                label: '行业3'
            }, {
                value: '选项44',
                label: '行业'
            }, {
                value: '选项5',
                label: '行业5'
            }],
            value2: '',
            options3: [{
                value: '选项1',
                label: '颜色1'
            }, {
                value: '选项2',
                label: '颜色2'
            }, {
                value: '选项3',
                label: '颜色3'
            }, {
                value: '选项44',
                label: '颜色4'
            }, {
                value: '选项5',
                label: '颜色5'
            }],
            value3: '',
            allList: [
                {
                    img: require('@/img/cz1.jpg'),
                    tag: '文章',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
                    date: '2023-02-16',
                    dianzan: '125',
                    shoucang: '7895',
                    isdianzan: false,
                    author: {
                        name: '用户123',
                        tx: require('@/img/tx.jpg'),
                    },
                },


                {
                    img: require('@/img/cz2.jpg'),
                    tag: '文章',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
                    date: '2023-02-16',
                    dianzan: '125',
                    shoucang: '7895',
                    isdianzan: false,
                    author: {
                        name: '用户123',
                        tx: require('@/img/tx.jpg'),
                    },
                },

                {
                    img: require('@/img/cz3.jpg'),
                    tag: '文章',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
                    date: '2023-02-16',
                    dianzan: '125',
                    isdianzan: false,
                    shoucang: '7895',
                    author: {
                        name: '用户123',
                        tx: require('@/img/tx.jpg'),
                    },
                },

                {
                    img: require('@/img/cz4.jpg'),
                    tag: '文章',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
                    date: '2023-02-16',
                    dianzan: '125',
                    shoucang: '7895',
                    isdianzan: false,
                    author: {
                        name: '用户123',
                        tx: require('@/img/tx.jpg'),
                    },
                },

                {
                    img: require('@/img/cz5.jpg'),
                    tag: '文章',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
                    date: '2023-02-16',
                    dianzan: '125',
                    shoucang: '7895',
                    isdianzan: false,
                    author: {
                        name: '用户123',
                        tx: require('@/img/tx.jpg'),
                    },
                },


                {
                    img: require('@/img/cz6.jpg'),
                    tag: '文章',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
                    date: '2023-02-16',
                    dianzan: '125',
                    shoucang: '7895',
                    isdianzan: false,
                    author: {
                        name: '用户123',
                        tx: require('@/img/tx.jpg'),
                    },
                },
                {
                    img: require('@/img/cz1.jpg'),
                    tag: '文章',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
                    date: '2023-02-16',
                    dianzan: '125',
                    shoucang: '7895',
                    isdianzan: false,
                    author: {
                        name: '用户123',
                        tx: require('@/img/tx.jpg'),
                    },
                },


                {
                    img: require('@/img/cz2.jpg'),
                    tag: '文章',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
                    date: '2023-02-16',
                    dianzan: '125',
                    shoucang: '7895',
                    isdianzan: false,
                    author: {
                        name: '用户123',
                        tx: require('@/img/tx.jpg'),
                    },
                },

                {
                    img: require('@/img/cz3.jpg'),
                    tag: '文章',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
                    date: '2023-02-16',
                    dianzan: '125',
                    isdianzan: false,
                    shoucang: '7895',
                    author: {
                        name: '用户123',
                        tx: require('@/img/tx.jpg'),
                    },
                },

                {
                    img: require('@/img/cz4.jpg'),
                    tag: '文章',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
                    date: '2023-02-16',
                    dianzan: '125',
                    shoucang: '7895',
                    isdianzan: false,
                    author: {
                        name: '用户123',
                        tx: require('@/img/tx.jpg'),
                    },
                },

                {
                    img: require('@/img/cz5.jpg'),
                    tag: '文章',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
                    date: '2023-02-16',
                    dianzan: '125',
                    shoucang: '7895',
                    isdianzan: false,
                    author: {
                        name: '用户123',
                        tx: require('@/img/tx.jpg'),
                    },
                },


                {
                    img: require('@/img/cz6.jpg'),
                    tag: '文章',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
                    date: '2023-02-16',
                    dianzan: '125',
                    shoucang: '7895',
                    isdianzan: false,
                    author: {
                        name: '用户123',
                        tx: require('@/img/tx.jpg'),
                    },
                },



            ],
            tiaojian: '1',
            tiaojian1: '',
            tiaojian2: '',
            // tiaojian2: 'created_at.desc',
        }
    },
    created() {
        document.title = this.pageTitle;
    },
    components: {
        // headerbar,
        footerbar,
    },
    mounted() {
        console.log(this.$route.query.keywords) // 输出 123
        this.keywords = this.$route.query.keywords;
        this.search()
        this.gethangye()
        this.getprovince()
        this.setActivePage()
    },
    methods: {
        setActivePage() {
            // 触发事件，传递当前页面的名称
            eventBus.$emit('updateActivePage', '');
        },
        shaixuan(e){
            this.tiaojian = e;
            this.search()
        },
        shaixuan1(e){
            this.tiaojian1 = e;
            this.search()
        },
        shaixuan2(e){
            this.tiaojian2 = e;
            this.search()
        },
        //获取地区
        getprovince() {
            this.$axios.get('/api/pc/area', {}).then((result) => {
                console.log(result)
                if (result.data.code == 200) {
                    this.options2 = result.data.data
                }
            }).catch((err) => {
                console.log(err)
            })
        },
        //获取行业列表
        gethangye() {
            this.$axios.get('/api/pc/industry', {}).then((result) => {
                console.log(result)
                if (result.data.code == 200) {
                    this.options1 = result.data.data
                } else {
                    this.$message.error(result.data.message);
                }
            }).catch((err) => {
                console.log(err)
                this.$message.error(err.response.data.message);
            });
        },
        search() {
            this.$axios.get('/api/__yao/table/pc.article/search', {
                params: {
                    "order": "created_at.desc",
                    "where.categories.match": this.tiaojian,
                    "where.industry.match": this.tiaojian1,
                    "where.title.match": this.keywords,
                }
            }).then((result) => {
                console.log(result)
                if (result.data.code == 200) {
                    this.allList = result.data.data
                } else {
                    this.$message.error(result.data.message);
                }
            }).catch((err) => {
                console.log(err)
            });
        },
        todetail(e) {
            // this.$router.push('/articleDetail')
            this.$router.push({
                name: 'articleDetail',
                query: {
                    id: e
                }
            })
        },
    }
}
</script>

<style scoped lang="scss">
@import '../searchResult/searchResult.scss'
</style>

<style>
.right1 .el-input__inner {
    border: 1px solid #212121;
    height: 35px;
}
</style>