<template>
    <div class="index">
        <!-- <headerbar :selected-nav="selectedNav"></headerbar> -->
        <div class="index_nei">
            <div class="wz_left">
                <div class="content">
                    <div class="title">
                        <h2>{{ detail.title }}</h2>
                        <div class="des">
                            <div class="des1">
                                <p>{{ detail.published_at | datefmt('YYYY-MM-DD HH:mm') }}</p>
                                <i>·</i>
                                <p>{{ detail.origin }}</p>
                            </div>
                            <div class="des1">
                                <p>{{ detail.support_count }}点赞</p>
                                <i>·</i>
                                <p>{{ detail.read_count }}阅读</p>
                                <i>·</i>
                                <p>{{ detail.collect_count }}收藏</p>
                                <i>·</i>
                                <p>{{ detail.transfer_count }}转发</p>
                            </div>
                        </div>
                    </div>
                    <div class="con">
                        <p v-if="detail.content != null">{{ detail.content[0].data.text }}</p>
                        <img v-if="detail.cover != null" :src="detail.cover[0]" alt="">
                    </div>
                    <div class="dibu">
                        <div class="dibu1">
                            <p>未经授权严禁转载，授权事宜请联系作者本人，侵权必究。</p>
                        </div>
                        <div class="dibu2">
                            <div class="dibu2_1" @click="dzBtn(detail.id)">
                                <div class="img">
                                    <img src="../../img/tuijian.png" >
                                    <!-- <img src="../../img/tuijian_a.png" alt="" v-else> -->
                                </div>
                                <p>{{ detail.support_count }}</p>
                            </div>
                            <div class="dibu2_1"  @click="scBtn(detail.id)">
                                <div class="img">
                                    <img src="../../img/sc.png" alt="">
                                </div>
                                <p>{{ detail.collect_count }}</p>
                            </div>
                            <div class="dibu2_1">
                                <div class="img">
                                    <img src="../../img/zhuanfa.png" alt="">
                                </div>
                                <p>{{ detail.transfer_count }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pinlun">
                    <div class="pinglun_shuru">
                        <h2>发表评论</h2>
                        <div class="shuru">
                            <!-- <div class="tx">
                                <img :src="myXinxi.pic" alt="">
                            </div> -->
                            <div class="kuang" style="width:100%">
                                <el-input type="textarea" placeholder="说两句吧" v-model="textarea" maxlength="2000"
                                    show-word-limit resize="none">
                                </el-input>
                                <div class="anniu" @click="pinglunBtn(detail.id)">
                                    <p>发布</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="pl_list" v-if="pinglunList.length >0">
                        <h3>全部评论({{ pinglunList.length }})</h3>
                        <div class="allList">
                            <div class="list1" v-for="(item, index) in pinglunList" :key="index">
                                <div class="list1_img">
                                    <img :src="item.user.avatar" alt="">
                                </div>
                                <div class="list_xq">
                                    <div class="xq_left">
                                        <div class="left_top">
                                            <p>{{ item.user.name }}</p>
                                            <!-- <span>{{ item.time }}</span> -->
                                        </div>
                                        <div class="left_bot">
                                            <p>{{ item.content }}</p>
                                            <div class="button" @click="huifu()">回复</div>
                                        </div>
                                    </div>
                                    <div class="xq_right" :class="{ active: item.isdian === true }">
                                        <img src="../../img/tuijian_a.png" alt="" v-if="item.isdian">
                                        <img src="../../img/tuijian.png" alt="" v-else>
                                        <p>{{ item.support_count }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="wz_right">
                <div class="right1">
                    <h3>发布者</h3>
                    <div class="right1_con">
                        <div class="con1">
                            <div class="con1_left">
                                <img :src="detail.user.avatar" alt="">
                                <div class="detail">
                                    <p>{{ detail.user.name }} </p>
                                    <span>{{ detail.user.intro }}</span>
                                    <span>{{ detail.user.position }}</span>
                                </div>
                            </div>
                            <div class="con1_right">
                                <!-- <div class="anniu" v-if="zuozhe.isguanzhu">
                                    <span>+</span>
                                    <p>关注</p>
                                </div> -->
                                <!-- <div class="anniuyi" v-else>
                                    <img src="../../img/duihao.png" alt="">
                                    <p>已关注</p>
                                </div> -->
                            </div>
                        </div>
                        <div class="con2">
                            <div class="con2_1">
                                <p>{{ detail.user.fans }}</p>
                                <span>粉丝</span>
                            </div>
                            <div class="con2_1">
                                <p>{{ detail.user.article_count }}</p>
                                <span>文章</span>
                            </div>
                            <div class="con2_1">
                                <p>{{ detail.user.case_count }}</p>
                                <span>案例</span>
                            </div>
                            <div class="con2_1">
                                <p>{{ detail.user.project_count }}</p>
                                <span>项目</span>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- <div class="right1">
                    <h3>相关企业</h3>
                    <div class="right1_con">
                        <div class="con1">
                            <div class="con1_left">
                                <img :src="xgqy.pic" alt="">
                                <div class="detail">
                                    <p>{{ xgqy.name }} </p>
                                    <span>{{ xgqy.address }}</span>
                                    <span>{{ xgqy.gsname }}</span>
                                </div>
                            </div>
                            <div class="con1_right">
                                <div class="anniu" v-if="xgqy.isguanzhu">
                                    <span>+</span>
                                    <p>关注</p>
                                </div>
                                <div class="anniuyi" v-else>
                                    <img src="../../img/duihao.png" alt="">
                                    <p>已关注</p>
                                </div>
                            </div>
                        </div>
                        <div class="con2">
                            <div class="con2_1">
                                <p>{{ xgqy.fensi }}</p>
                                <span>粉丝</span>
                            </div>
                            <div class="con2_1">
                                <p>{{ xgqy.wenzang }}</p>
                                <span>文章</span>
                            </div>
                            <div class="con2_1">
                                <p>{{ xgqy.anli }}</p>
                                <span>案例</span>
                            </div>
                            <div class="con2_1">
                                <p>{{ xgqy.xiangmu }}</p>
                                <span>项目</span>
                            </div>
                        </div>
                    </div>
                </div> -->

                <div class="right2">
                    <h3>热门文章</h3>
                    <div class="list1" v-for="(item,index) in xgList" :key="index" @click="todetail(item.id)">
                        <img :src="item.cover[0]" alt="">
                        <p>{{ item.title }}</p>
                    </div>
                </div>
            </div>
        </div>
        <footerbar></footerbar>
    </div>
</template>

<script>
// import headerbar from '../../components/headerbar.vue';
import { eventBus } from '../../App.vue';
import footerbar from '../../components/footerbar.vue';
export default {
    name: "articleDetail",
    data() {
        return {
            id:'',
            pageTitle: '海报详情',
            selectedNav: '发现',
            textarea: '',
            detail: {},
            xgList: [
                {
                    pic: require('@/img/cz1.jpg'),
                    title: '全方位為客戶提供專業的品牌諮詢市場策略以...',
                },
                {
                    pic: require('@/img/cz2.jpg'),
                    title: '全方位為客戶提供專業的品牌諮詢市場策略以...',
                },
                {
                    pic: require('@/img/cz3.jpg'),
                    title: '全方位為客戶提供專業的品牌諮詢市場策略以...',
                },
                {
                    pic: require('@/img/cz4.jpg'),
                    title: '全方位為客戶提供專業的品牌諮詢市場策略以...',
                },
                {
                    pic: require('@/img/cz5.jpg'),
                    title: '全方位為客戶提供專業的品牌諮詢市場策略以...',
                },
            ],
            zuozhe: {
                name: 'Mearry',
                tx: require('../../img/shejishi.jpg'),
                isguanzhu: false,
                tag: '创意/设计',
                shenfen: '前时趣CMO,UDEE执行主编',
                fensi: '45893',
                wenzang: '74',
                anli: '88',
                xiangmu: '56'
            },
            xgqy: {
                    name: 'UGEE',
                    pic: require('../../img/pinpai1.jpg'),
                    address: '上海市·静安区',
                    gsname: '上海UGEE品牌策划有限公司',
                    fensi: '45893',
                    wenzang: '74',
                    anli: '88',
                    xiangmu: '56',
                    isguanzhu: true,
                },
            myXinxi: {
                name: 'LGG',
                pic: require('../../img/shejishi.jpg'),
            },
            pinglunList: [
                {
                    author: {
                        name: '年少有为',
                        tx: require('../../img/shejishi1.jpg'),
                    },
                    time: '5小时前',
                    dianzan: '892',
                    isdian: true,
                    des: '结合文化与商业，引领着中国的品牌设计。2013年正式更名为靳刘高设计，印证三代人的同心协力。',
                },
                {
                    author: {
                        name: '年少有为',
                        tx: require('../../img/shejishi2.jpg'),
                    },
                    time: '5小时前',
                    dianzan: '892',
                    isdian: false,
                    des: '结合文化与商业，引领着中国的品牌设计。2013年正式更名为靳刘高设计，印证三代人的同心协力。',
                },
                {
                    author: {
                        name: '年少有为',
                        tx: require('../../img/shejishi1.jpg'),
                    },
                    time: '5小时前',
                    dianzan: '892',
                    isdian: true,
                    des: '结合文化与商业，引领着中国的品牌设计。2013年正式更名为靳刘高设计，印证三代人的同心协力。',
                },
                {
                    author: {
                        name: '年少有为',
                        tx: require('../../img/shejishi3.jpg'),
                    },
                    time: '5小时前',
                    dianzan: '892',
                    isdian: true,
                    des: '结合文化与商业，引领着中国的品牌设计。2013年正式更名为靳刘高设计，印证三代人的同心协力。',
                },
            ],
        }
    },
    created() {
        document.title = this.pageTitle;
    },
    components: {
        // headerbar,
        footerbar,
    },
    mounted() {
        console.log(this.$route.query.id) // 输出 123
        this.id = this.$route.query.id
        this.getDetail();
        this.gethot()
        this.setActivePage()
    },
    methods:{
        setActivePage() {
            // 触发事件，传递当前页面的名称
            eventBus.$emit('updateActivePage', '发现');
        },
        //获取详情
        getDetail(){
            this.$axios.get('api/pc/article/detail/' + this.id,{}).then((result) => {
                console.log(result)
                if(result.data.code == 200){
                    this.detail = result.data.data;
                    this.pinglunList = result.data.data.comments
                }else{
                    this.$message.error(result.data.message);
                }
            }).catch((err) => {
                console.log(err)
                this.$message.error(err.response.data.message);
            });
        },

       //进入文章详情
       todetail(e) {
            // this.id = e;
            // this.getDetail()
            this.$router.push({
                name: 'articleDetail',
                query: {
                    id: e
                }
            })
        },

         // 获取热门文章
         gethot() {
            this.$axios.get('/api/pc/article/hot', {}).then((result) => {
                console.log(result)
                if (result.data.code == 200) {
                    this.xgList = result.data.data.hot_article
                } else {
                    this.$message.error(result.data.message);
                }
            }).catch((err) => {
                console.log(err)
                this.$message.error(err.response.data.message);
            });
        },
        //收藏
        scBtn(e) {
            this.$axios.post('/api/user/collect/article', {
                id: e,
                type: 1,
            }, {
                headers: {
                    'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
                }
            }).then((result) => {
                console.log(result)
                if (result.data.code == 200) {
                    this.$message({
                        message: '收藏成功！',
                        type: 'success'
                    });
                } else {
                    this.$message.error(result.data.message);
                }
            }).catch((err) => {
                console.log(err)
                this.$message.error(err.response.data.message);
                if (err.response.data.code == 403) {
                    setTimeout(() => {
                        this.$router.push({
                            name: 'login'
                        })
                    }, 1500);
                }
            });
        },


        //点赞
        dzBtn(e) {
            this.$axios.post('/api/user/support/content', {
                id: e,
                type: 1,
            }, {
                headers: {
                    'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
                }
            }).then((result) => {
                console.log(result)
                if (result.data.code == 200) {
                    this.$message({
                        message: '点赞成功！',
                        type: 'success'
                    });
                } else {
                    this.$message.error(result.data.message);
                }
            }).catch((err) => {
                console.log(err)
                this.$message.error(err.response.data.message);
                if (err.response.data.code == 403) {
                    setTimeout(() => {
                        this.$router.push({
                            name: 'login'
                        })
                    }, 1500);
                }
            });
        },

        pinglunBtn(e){
            this.$axios.post('/api/user/comment/save',{
                type: 1,
                ext_id: e,
                content:this.textarea,
                comment_id:'',
            },{
                headers: {
                    'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
                } 
            }).then((result) => {
                console.log(result)
                if(result.data.code == 200){
                    this.$message({
                        message: result.data.message,
                        type: 'success'
                    });
                    this.textarea = ""
                }else{
                    this.$message.error(result.data.message);
                }
            }).catch((err) => {
                console.log(err)
                this.$message.error(err.response.data.message);
            });
        },

        huifu(){

        },





    },

}
</script>

<style scoped lang="scss">
@import "../articleDetail/articleDetail.scss"
</style>

<style>
.kuang .el-textarea__inner {
    height: 160px;
}
</style>