<template>
    <div class="index">
        <div class="liebiao_con" v-if="allList.length > 0">
            <div class="allList">
                <div class="list1" v-for="(item, index) in allList" :key="index" @click="todetail(item.id)">
                    <div class="img">
                        <img :src="item.cover" alt="">
                    </div>
                    <div class="des">
                        <div class="title">
                            <p>{{ item.title }}</p>
                        </div>
                        <div class="xq">
                            <div class="xq1">
                                <!-- <img :src="item.author.tx" alt=""> -->
                                <p>{{ item.author }}</p>
                            </div>
                            <div class="xq2">
                                <div class="sc">
                                    <img src="../../img/shoucang.png" alt="">
                                    <p>{{ item.collect_count }}</p>
                                </div>
                                <div class="sc">
                                    <img src="../../img/dianzan.png" alt="">
                                    <p>{{ item.support_count }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="kong">
            <!-- <div class="kong1">
                <i class="el-icon-warning-outline"></i>
                <p>您还有没收藏项目</p>
            </div> -->
            <div class="anniu" @click="fabu">
                <p>发布我的案例</p>
            </div>
        </div>
    </div>
</template>

<script>
import { eventBus } from '../../App.vue';
export default {
    name: 'FavoriteArticle',

    data() {
        return {
            pageTitle: '我的案例',
            allList: [
                


                {
                    img: require('@/img/cz6.jpg'),
                    tag: '文章',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
                    date: '2023-02-16',
                    dianzan: '125',
                    shoucang: '7895',
                    isdianzan: false,
                    author: {
                        name: '用户123',
                        tx: require('@/img/tx.jpg'),
                    },
                },
                {
                    img: require('@/img/cz1.jpg'),
                    tag: '文章',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
                    date: '2023-02-16',
                    dianzan: '125',
                    shoucang: '7895',
                    isdianzan: false,
                    author: {
                        name: '用户123',
                        tx: require('@/img/tx.jpg'),
                    },
                },


                {
                    img: require('@/img/cz2.jpg'),
                    tag: '文章',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
                    date: '2023-02-16',
                    dianzan: '125',
                    shoucang: '7895',
                    isdianzan: false,
                    author: {
                        name: '用户123',
                        tx: require('@/img/tx.jpg'),
                    },
                },

                {
                    img: require('@/img/cz3.jpg'),
                    tag: '文章',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
                    date: '2023-02-16',
                    dianzan: '125',
                    isdianzan: false,
                    shoucang: '7895',
                    author: {
                        name: '用户123',
                        tx: require('@/img/tx.jpg'),
                    },
                },

                {
                    img: require('@/img/cz4.jpg'),
                    tag: '文章',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
                    date: '2023-02-16',
                    dianzan: '125',
                    shoucang: '7895',
                    isdianzan: false,
                    author: {
                        name: '用户123',
                        tx: require('@/img/tx.jpg'),
                    },
                },

                {
                    img: require('@/img/cz5.jpg'),
                    tag: '文章',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
                    date: '2023-02-16',
                    dianzan: '125',
                    shoucang: '7895',
                    isdianzan: false,
                    author: {
                        name: '用户123',
                        tx: require('@/img/tx.jpg'),
                    },
                },


                {
                    img: require('@/img/cz6.jpg'),
                    tag: '文章',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
                    date: '2023-02-16',
                    dianzan: '125',
                    shoucang: '7895',
                    isdianzan: false,
                    author: {
                        name: '用户123',
                        tx: require('@/img/tx.jpg'),
                    },
                },



            ],
        };
    },
    created() {
        document.title = this.pageTitle;
    },
    mounted() {
        this.getList()
    },

    methods: {

        fabu(){
            this.$router.push({
                name:'fabu',
                query:{
                    type:'case'
                }
            })
        },


        getList() {
            this.$axios.get('/api/__yao/table/personal.article/search', {
                headers: {
                    'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
                }
            },{
                params:{
                    "where.type.eq":'case'
                }
            }).then((result) => {
                console.log(result)
                if (result.data.code == 200) {
                    this.allList = result.data.data
                }else{
                    this.$message.error(result.data.message);
                }
            }).catch((err) => {
                console.log(err)
                this.$message.error(err.response.data.message);
                if (err.response.data.code == 403) {
                    setTimeout(() => {
                        eventBus.$emit('clearToken', '');
                        this.$router.push({
                            name: 'login'
                        })
                    }, 1500);
                }
            });
        },
        todetail(e) {
            this.$router.push({
                name: 'caseDetail',
                query: {
                    id: e
                }
            })
        },
    },
};
</script>

<style lang="scss" scoped>
.index {
    margin-top: 30px;
    width: 100%;
    height: auto;

    .liebiao_con {
        width: 100%;
        height: auto;

        .allList {
            width: 100%;
            height: auto;
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            .list1 {
                margin-bottom: 30px;
                margin-right: 2%;
                width: 32%;
                height: 300px;
                cursor: pointer;

                &:nth-child(3n) {
                    margin-right: 0;
                }

                &:hover {
                    .img {
                        .xuanze {
                            opacity: 1;
                        }
                    }
                }

                .img {
                    position: relative;
                    width: 100%;
                    height: 200px;
                    border-radius: 10px;
                    overflow: hidden;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }

                    .tag {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 60px;
                        height: 30px;
                        border-radius: 10px 0 10px 0;
                        background: rgba($color: #000000, $alpha: 0.6);
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;

                        p {
                            color: #fff;
                            font-size: 14px;
                        }
                    }

                    .xuanze {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        z-index: 99;
                        opacity: 0;
                        width: 100%;
                        height: auto;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        box-sizing: border-box;
                        padding: 10px 20px;
                        background: linear-gradient(to bottom, transparent, #00000f);
                        transition: all 0.35s;

                        .ms {
                            width: 80%;
                            height: auto;

                            p {
                                font-size: 14px;
                                color: #fff;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                width: 100%;
                            }
                        }

                        .choose {
                            width: 20%;
                            height: 35px;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;

                            .choose1 {
                                width: 35px;
                                height: 35px;
                                cursor: pointer;

                                img {
                                    width: 100%;
                                    height: auto;
                                }
                            }
                        }
                    }
                }

                .des {
                    margin-top: 20px;
                    width: 100%;
                    height: auto;

                    .title {
                        margin-bottom: 10px;
                        width: 100%;
                        height: auto;

                        p {
                            font-size: 15px;
                            color: #212121;
                        }
                    }

                    .xq {
                        width: 100%;
                        height: auto;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        .xq1 {
                            display: flex;
                            align-items: center;

                            img {
                                margin-right: 10px;
                                width: 26px;
                                height: 26px;
                                border-radius: 7px;
                            }

                            p {
                                font-size: 14px;
                                color: #666;
                            }
                        }

                        .xq2 {
                            display: flex;
                            align-items: center;

                            .sc {
                                display: flex;
                                align-items: center;
                                margin-right: 15px;

                                img {
                                    margin-right: 5px;
                                    width: 26px;
                                    height: 26px;
                                }

                                p {
                                    font-size: 15px;
                                    color: #666;
                                }
                            }
                        }
                    }
                }
            }
        }

        .chengyuan {
            .list1 {
                height: 330px;
                border-radius: 10px;
                box-sizing: border-box;
                padding: 20px;
                border: 1px solid #ccc;
                border-radius: 10px;

                &:hover {
                    background-color: #fafafa;
                }

                .cy_top {
                    margin-bottom: 20px;
                    width: 100%;
                    height: auto;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .cy_1 {
                        width: auto;
                        display: flex;
                        align-items: center;

                        img {
                            margin-right: 10px;
                            width: 50px;
                            height: 50px;
                        }

                        .detail {
                            display: flex;
                            flex-direction: column;

                            p {
                                font-size: 16px;
                                color: #212121;
                            }

                            span {
                                font-size: 14px;
                                color: #999;
                            }
                        }
                    }

                    .cy_2 {
                        width: 100px;
                        height: 35px;

                        .guanzhu {
                            width: 100%;
                            height: 100%;
                            border: 1px solid #212121;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 8px;

                            img {
                                margin-right: 10px;
                                width: auto;
                            }

                            p {
                                font-size: 14px;
                                color: #212121;
                            }
                        }

                        .weiguanzhu {
                            border: 1px solid #ff6600;
                            width: 100%;
                            height: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 8px;

                            i {
                                margin-right: 10px;
                                font-size: 20px;
                                color: #ff6600;
                            }

                            p {
                                font-size: 14px;
                                color: #ff6600;
                            }
                        }
                    }
                }

                .cy_bot {
                    width: 100%;
                    height: auto;
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;

                    .bot1 {
                        margin-bottom: 20px;
                        margin-right: 4%;
                        width: 48%;
                        height: 95px;

                        &:nth-child(2n) {
                            margin-right: 0;
                        }

                        img {
                            width: 100%;
                            border-radius: 10px;
                        }
                    }
                }
            }
        }

        .guanyu {
            width: 100%;
            height: auto;
            display: flex;
            align-items: baseline;
            justify-content: space-between;

            .guanyu_left {
                width: 75%;
                height: auto;
                display: flex;
                flex-direction: column;

                .jianjie {
                    width: 100%;
                    height: auto;
                    margin-bottom: 40px;

                    h2 {
                        margin-bottom: 25px;
                        font-size: 24px;
                        color: #212121;
                        font-weight: bold;
                    }

                    .neirong {
                        margin-bottom: 45px;
                        width: 100%;
                        height: auto;

                        p {
                            font-size: 15px;
                            color: #212121;
                            line-height: 33px;
                        }
                    }


                }

                .shanchang {
                    width: 100%;
                    height: auto;
                    margin-bottom: 40px;

                    h2 {
                        margin-bottom: 25px;
                        font-size: 24px;
                        color: #212121;
                        font-weight: bold;
                    }

                    .sc {
                        width: 100%;
                        height: auto;
                        display: flex;
                        align-items: center;
                        flex-wrap: wrap;

                        .tags {
                            width: auto;
                            padding: 0 15px;
                            margin-right: 15px;
                            height: 35px;
                            background-color: #f5f5f5;
                            border-radius: 8px;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;

                            p {
                                font-size: 15px;
                                color: #212121;
                            }
                        }
                    }
                }

                .pinpai {
                    width: 100%;
                    height: auto;
                    display: flex;
                    flex-wrap: wrap;

                    .pp {
                        width: 100px;
                        height: 100px;
                        border-radius: 8px;
                        overflow: hidden;
                        margin-right: 20px;
                        margin-bottom: 20px;

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }

            }

            .guanyu_right {
                width: 23%;
                height: auto;

                .xinxi {
                    margin-bottom: 40px;
                    width: 100%;
                    height: 145px;
                    border: 1px solid #ccc;
                    border-radius: 10px;
                    background-color: #fafafa;
                    box-sizing: border-box;
                    padding: 35px;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;

                    .xx1 {
                        margin-bottom: 20px;
                        width: 45%;
                        height: auto;
                        display: flex;
                        align-items: center;

                        img {
                            width: auto;
                            margin-right: 10px;
                        }

                        p {
                            font-size: 16px;
                            color: #212121;
                        }
                    }
                }

                .chengyuan {
                    width: 100%;
                    height: auto;

                    .chengyuan1 {
                        display: flex;
                        flex-wrap: wrap;

                        .cc {
                            margin-bottom: 15px;
                            width: 23%;
                            height: 90px;
                            margin-right: 2.6%;

                            &:nth-child(4n) {
                                margin-right: 0;
                            }

                            img {
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }

                    .more {
                        margin-top: 10px;
                        width: auto;

                        a {
                            color: #ff6600;
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }

    .kong {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .kong1 {
            width: 100%;
            height: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 20px;

            i {
                margin-right: 15px;
                font-size: 22px;
                color: #ff6600;
            }

            p {
                font-size: 16px;
                color: #ff6600;
                font-weight: bold;
            }
        }

        .anniu {
            width: 200px;
            height: 35px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background-color: #ff6600;
            border-radius: 5px;

            p {
                color: #fff;
                font-size: 15px;
            }
        }
    }
}
</style>