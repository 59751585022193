<template>
  <div class="index">
    <!-- <headerbar :selected-nav="selectedNav"></headerbar> -->
    <div class="index">
      <div class="jobTop">
        <div class="jobTop_left">
          <div class="left_nav">
            <img v-if="detail.logo != null" :src="detail.logo" alt="">
            <p>{{ detail.name }}</p>
          </div>
          <div class="miaoshu">
            <p>{{ detail.intro }}</p>
          </div>

          <div class="anniu">
            <!-- <div class="anniu2">
              <img src="../../img/duihao.png" alt="">
              <span>已关注</span>
            </div> -->
            <div class="anniu1" @click="jiaru(detail.id)">
              <img src="../../img/email_1.png" alt="">
              <p>申请加入</p>
            </div>
          </div>

        </div>
        <div class="jobTop_right">
          <img src="../../img/chuangzaozhe.png" alt="">
        </div>
      </div>
      <div class="huodong">
        <div class="liebiao_top">
          <div class="top_left">
            <div class="choose" :class="{ active: choose === 'guanyu' }" @click="chooseBtn('guanyu')">
              <p>关于</p>
            </div>
            <div class="choose" :class="{ active: choose === 'zuopin' }" @click="chooseBtn('zuopin')">
              <p>文章{{ allList.length }}</p>
            </div>
            <div class="choose" :class="{ active: choose === 'case' }" @click="chooseBtn('case')">
              <p>案例{{ caseList.length }}</p>
            </div>
            <div class="choose" :class="{ active: choose === 'neirong' }" @click="chooseBtn('neirong')">
              <p>项目{{ projectList.length }}</p>
            </div>
            <div class="choose" :class="{ active: choose === 'chengyuan' }" @click="chooseBtn('chengyuan')">
              <p>成员{{ chengyuanList.length }}</p>
            </div>
            <div class="choose" :class="{ active: choose === 'zhaopin' }" @click="chooseBtn('zhaopin')">
              <p>招聘{{ gongzuoList.length }}</p>
            </div>

          </div>
        </div>
        <div class="liebiao_con">
          <div class="allList" v-if="choose == 'zuopin'">
            <div class="list1" v-for="(item, index) in allList" :key="index" @click="todetail(item.id)">
              <div class="img">
                <img :src="item.cover[0]" alt="">
                <div class="tag">
                  <p>文章</p>
                </div>
                <div class="xuanze">
                  <div class="ms">
                    <p>{{ item.title }}</p>
                  </div>
                  <div class="choose">
                    <div class="choose1">
                      <img src="../../img/shoucang.png" alt="" @click.stop="scBtn(item.id)">
                    </div>
                    <div class="choose1" @click.stop="dzBtn(item.id)">
                      <img src="../../img/dianzan.png" alt="" v-if="item.isdianzan == true">
                      <img src="../../img/dianzan.png" alt="" v-else>
                    </div>
                  </div>
                </div>
              </div>
              <div class="des">
                <div class="title">
                  <p>{{ item.title }}</p>
                </div>
                <div class="xq">
                  <div class="xq1">
                    <!-- <img :src="item.author.tx" alt=""> -->
                    <p>{{ item.author }}</p>
                  </div>
                  <div class="xq2">
                    <div class="sc">
                      <img src="../../img/shoucang.png" alt="">
                      <p>{{ item.collect_count }}</p>
                    </div>
                    <div class="sc">
                      <img src="../../img/dianzan.png" alt="">
                      <p>{{ item.support_count }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="allList chengyuan" v-if="choose == 'chengyuan'">
            <div class="list1" v-for="(item, index) in chengyuanList" :key="index" @click="niurentodetail(item.id)">
              <div class="cy_top">
                <div class="cy_1">
                  <img :src="item.avatar" alt="">
                  <div class="detail">
                    <p>{{ item.name }}</p>
                    <span>{{ item.position }}</span>
                  </div>
                </div>
                <div class="cy_2">
                  <div class="guanzhu" v-if="item.each_fans" @click.stop="qxguanzhu(item.id)">
                    <img src="../../img/duihao.png" alt="">
                    <p>已关注</p>
                  </div>

                  <div class="weiguanzhu" v-if="!item.each_fans" @click.stop="addguanzhu(item.id)">
                    <i> + </i>
                    <p>关注</p>
                  </div>
                </div>
              </div>
              <!-- <div class="cy_bot">
                <div class="bot1" v-for="(tt, index) in item.list" :key="index">
                  <img :src="tt.pic" alt="">
                </div>
              </div> -->
            </div>
          </div>
          <div class="guanyu" v-if="choose == 'guanyu'">
            <div class="guanyu_left">
              <div class="jianjie">
                <h2>简介</h2>
                <div class="neirong">
                  <p>{{ detail.intro }}</p>
                </div>

              </div>
              <div class="shanchang">
                <h2>行业</h2>
                <div class="sc">
                  <div class="tags" v-for="(item, index) in detail.industry" :key="index">
                    <p>{{ item }}</p>
                  </div>
                </div>
              </div>
              <!-- <div class="pinpai">
                  <div class="pp" v-for="(item, index) in pinpaiList" :key="index">
                    <img :src="item.img" alt="">
                  </div>
                </div> -->
            </div>
            <div class="guanyu_right">
              <div class="xinxi">
                <div class="xx1">
                  <img src="../../img/location1.jpg" alt="">
                  <p>{{ detail.address }}</p>
                </div>

                <div class="xx1">
                  <img src="../../img/chuangli.jpg" alt="">
                  <p>{{ detail.born }}</p>
                </div>

                <div class="xx1">
                  <img src="../../img/chuangli.jpg" alt="">
                  <p>{{ detail.name }}</p>
                </div>

                <div class="xx1">
                  <img src="../../img/huiyuan.jpg" alt="">
                  <p>{{ detail.phone }}</p>
                </div>
              </div>
              <!-- <div class="chengyuan">
                <div class="chengyuan1">
                  <div class="cc" v-for="(item, index) in cyList" :key="index">
                    <img :src="item.pic" alt="">
                  </div>
                </div>
                <div class="more">
                  <router-link to="">查看所有46名成员</router-link>
                </div>
              </div> -->
            </div>
          </div>
          <div class="jobList" v-if="choose == 'zhaopin'">
            <div class="list1" v-for="(item, index) in gongzuoList" :key="index">
              <div class="list1_left">
                <!-- <div class="img">
                  <img :src="item.pic" alt="">
                </div> -->
                <div class="des">
                  <p>{{ item.title }}</p>
                  <div class="tag">
                    <!-- <span v-for="(tag, index) in item.tags" :key="index">{{ tag.name }}</span> -->
                    <span>{{ item.slug }}</span>
                  </div>
                </div>
              </div>
              <div class="list1_right">
                <p>{{ item.salary_low }}-{{ item.salary_up }}</p>
                <span>{{ item.published_at | datefmt('YYYY-MM-DD HH:mm') }}</span>
              </div>
              <div class="choose">
                <div class="choose1" @click="toJobDetail(item.id)">
                  <img src="../../img/chakan.png" alt="">
                  <p>查看职位</p>
                </div>
                <!-- <div class="choose1">
                  <img src="../../img/shenqing.png" alt="">
                  <p>立即申请</p>
                </div> -->
                <div style="clear: both;"></div>
              </div>
            </div>
          </div>
          <div class="allList" v-if="choose == 'case'">
            <div class="list1" v-for="(item, index) in caseList" :key="index" @click="todetail1(item.id)">
              <div class="img">
                <img :src="item.cover[0]" alt="">
                <div class="tag">
                  <p>项目</p>
                </div>
                <div class="xuanze">
                  <div class="ms">
                    <p>{{ item.title }}</p>
                  </div>
                  <div class="choose">
                    <div class="choose1" @click.stop="scBtn(item.id)">
                      <img src="../../img/shoucang.png" alt="">
                    </div>
                    <div class="choose1" @click.stop="dzBtn(item.id)">
                      <img src="../../img/dianzan.png" alt="" v-if="item.isdianzan == true">
                      <img src="../../img/dianzan.png" alt="" v-else>
                    </div>
                  </div>
                </div>
              </div>
              <div class="des">
                <div class="title">
                  <p>{{ item.title }}</p>
                </div>
                <div class="xq">
                  <div class="xq1">
                    <!-- <img :src="item.author.tx" alt=""> -->
                    <p>{{ item.author }}</p>
                  </div>
                  <div class="xq2">
                    <div class="sc">
                      <img src="../../img/shoucang.png" alt="">
                      <p>{{ item.collect_count }}</p>
                    </div>
                    <div class="sc">
                      <img src="../../img/dianzan.png" alt="">
                      <p>{{ item.support_count }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="allList" v-if="choose == 'neirong'">
            <div class="list1" v-for="(item, index) in projectList" :key="index" @click="todetail2(item.id)">
              <div class="img">
                <img :src="item.cover[0]" alt="">
                <div class="tag">
                  <p>文章</p>
                </div>
                <div class="xuanze">
                  <div class="ms">
                    <p>{{ item.title }}</p>
                  </div>
                  <div class="choose">
                    <div class="choose1">
                      <img src="../../img/shoucang.png" alt="" @click.stop="scBtn(item.id)">
                    </div>
                    <div class="choose1" @click.stop="dzBtn(item.id)">
                      <img src="../../img/dianzan.png" alt="" v-if="item.isdianzan == true">
                      <img src="../../img/dianzan.png" alt="" v-else>
                    </div>
                  </div>
                </div>
              </div>
              <div class="des">
                <div class="title">
                  <p>{{ item.title }}</p>
                </div>
                <div class="xq">
                  <div class="xq1">
                    <!-- <img :src="item.author.tx" alt=""> -->
                    <p>{{ item.author }}</p>
                  </div>
                  <div class="xq2">
                    <div class="sc">
                      <img src="../../img/shoucang.png" alt="">
                      <p>{{ item.collect_count }}</p>
                    </div>
                    <div class="sc">
                      <img src="../../img/dianzan.png" alt="">
                      <p>{{ item.support_count }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footerbar></footerbar>


    <div class="xqTanchuang" v-if="xqzhanshi">
      <div class="closeanniu" @click="closeBtn">
        <img src="../../img/close.png" alt="">
      </div>
      <div class="index_nei">
        <div class="wz_left">
          <div class="content">
            <div class="title">
              <h2>{{ detail.title }}</h2>
              <div class="des">
                <div class="des1">
                  <p>{{ detail.published_at | datefmt('YYYY-MM-DD HH:mm') }}</p>
                  <i>·</i>
                  <p>{{ detail.origin }}</p>
                </div>
                <div class="des1">
                  <p>{{ detail.support_count }}点赞</p>
                  <i>·</i>
                  <p>{{ detail.read_count }}阅读</p>
                  <i>·</i>
                  <p>{{ detail.collect_count }}收藏</p>
                  <i>·</i>
                  <p>{{ detail.transfer_count }}转发</p>
                </div>
              </div>
            </div>
            <div class="con">
              <div v-if="detail.content != null">
                <div v-for="(item, index) in detail.content" :key="index">
                  <p v-html="item.data.text"></p>
                </div>
              </div>
              <img v-if="detail.cover.length > 0" :src="detail.cover[0]" alt="">
              <video v-if="detail.video != null && detail.cover != null" :src="detail.video[0]" controls
                :poster="detail.cover[0]"></video>
            </div>
            <div class="dibu">
              <div class="dibu1">
                <p>未经授权严禁转载，授权事宜请联系作者本人，侵权必究。</p>
              </div>
              <div class="dibu2">
                <div class="dibu2_1" @click="dzBtn(detail.id)" v-if="!detail.is_support">
                  <div class="img">
                    <img src="../../img/tuijian.png">
                    <!-- <img src="../../img/tuijian_a.png" alt="" v-else> -->
                  </div>
                  <p>{{ detail.support_count }}</p>
                </div>
                <div class="dibu2_1 active" @click="qxdzBtn(detail.id)" v-if="detail.is_support">
                  <div class="img">
                    <!-- <img src="../../img/tuijian.png" > -->
                    <img src="../../img/tuijian_a.png" alt="">
                  </div>
                  <p>{{ detail.support_count }}</p>
                </div>
                <div class="dibu2_1" @click="scBtn(detail.id)">
                  <div class="img">
                    <img src="../../img/sc.png" alt="">
                  </div>
                  <p>{{ detail.collect_count }}</p>
                </div>
                <div class="dibu2_1" @click="zfBtn(detail.id)">
                  <div class="img">
                    <img src="../../img/zhuanfa.png" alt="">
                  </div>
                  <p>{{ detail.transfer_count }}</p>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="wz_right">


          <div class="pinlun">
            <div class="pinglun_shuru">
              <h2>发表评论</h2>
              <div class="shuru">
                <!-- <div class="tx">
                                <img :src="myXinxi.pic" alt="">
                            </div> -->
                <div class="kuang" style="width:100%">
                  <el-input type="textarea" placeholder="说两句吧" v-model="textarea" maxlength="2000" show-word-limit
                    resize="none">
                  </el-input>
                  <div class="anniu" @click="pinglunBtn(detail.id)">
                    <p>发布</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="pl_list" v-if="pinglunList.length > 0">
              <h3>全部评论({{ pinglunList.length }})</h3>
              <div class="allList">
                <div class="list1" v-for="(item, index) in pinglunList" :key="index">
                  <div class="list1_img">
                    <img :src="item.user.avatar" alt="">
                  </div>
                  <div class="list_xq">
                    <div class="xq_left">
                      <div class="left_top">
                        <p>{{ item.user.name }}</p>
                        <span>{{ item.created_at | datefmt('YYYY-MM-DD HH:mm') }}</span>
                      </div>
                      <div class="left_bot">
                        <p>{{ item.content }}</p>
                        <div class="button" @click="huifuShowBtn(index)" v-if="activeIndex === ''">回复</div>
                        <div class="button" @click="huifuShowBtn1" v-if="index === activeIndex">取消回复</div>
                      </div>
                      <div class="huifu_shu" v-if="index === activeIndex">
                        <div class="shu1">
                          <el-input type="text" placeholder="请输入内容" v-model="text" maxlength="300" show-word-limit>
                          </el-input>
                          <div class="button" @click="huifu(item.id)">回复</div>
                        </div>
                      </div>
                    </div>
                    <div class="xq_right" :class="{ active: item.isdian === true }">
                      <img src="../../img/tuijian_a.png" alt="" v-if="item.isdian">
                      <img src="../../img/tuijian.png" alt="" v-else>
                      <p>{{ item.support_count }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="right1">
            <h3>发布者</h3>
            <div class="right1_con">
              <div class="con1" @click="niurentodetail(detail.user.id)">
                <div class="con1_left">
                  <img v-if="detail.user.avatar.length > 0" :src="detail.user.avatar[0]" alt="">
                  <div class="detail">
                    <p>{{ detail.user.name }} </p>
                    <span>{{ detail.user.intro }}</span>
                    <span>{{ detail.user.position }}</span>
                  </div>
                </div>
                <!--  <div class="con1_right">
                                <div class="anniu" v-if="zuozhe.isguanzhu">
                                    <span>+</span>
                                    <p>关注</p>
                                </div> -->
                <!-- <div class="anniuyi" v-else>
                                    <img src="../../img/duihao.png" alt="">
                                    <p>已关注</p>
                                </div> 
                            </div>-->
              </div>
              <div class="con2">
                <div class="con2_1">
                  <p>{{ detail.user.fans }}</p>
                  <span>粉丝</span>
                </div>
                <div class="con2_1">
                  <p>{{ detail.user.article_count }}</p>
                  <span>文章</span>
                </div>
                <div class="con2_1">
                  <p>{{ detail.user.case_count }}</p>
                  <span>案例</span>
                </div>
                <div class="con2_1">
                  <p>{{ detail.user.project_count }}</p>
                  <span>项目</span>
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="right1">
                    <h3>相关企业</h3>
                    <div class="right1_con">
                        <div class="con1">
                            <div class="con1_left">
                                <img :src="xgqy.pic" alt="">
                                <div class="detail">
                                    <p>{{ xgqy.name }} </p>
                                    <span>{{ xgqy.address }}</span>
                                    <span>{{ xgqy.gsname }}</span>
                                </div>
                            </div>
                            <div class="con1_right">
                                <div class="anniu" v-if="xgqy.isguanzhu">
                                    <span>+</span>
                                    <p>关注</p>
                                </div>
                                <div class="anniuyi" v-else>
                                    <img src="../../img/duihao.png" alt="">
                                    <p>已关注</p>
                                </div>
                            </div>
                        </div>
                        <div class="con2">
                            <div class="con2_1">
                                <p>{{ xgqy.fensi }}</p>
                                <span>粉丝</span>
                            </div>
                            <div class="con2_1">
                                <p>{{ xgqy.wenzang }}</p>
                                <span>文章</span>
                            </div>
                            <div class="con2_1">
                                <p>{{ xgqy.anli }}</p>
                                <span>案例</span>
                            </div>
                            <div class="con2_1">
                                <p>{{ xgqy.xiangmu }}</p>
                                <span>项目</span>
                            </div>
                        </div>
                    </div>
                </div> -->

          <!-- <div class="right2">
            <h3>热门文章</h3>
            <div class="list1" v-for="(item, index) in xgList" :key="index" @click="todetail(item.id)">
              <img :src="item.cover[0]" alt="">
              <p>{{ item.title }}</p>
            </div>
          </div> -->


        </div>
      </div>
    </div>

    <div class="xqTanchuang1" v-if="huodongShow">
      <div class="closeanniu" @click="closeBtn">
        <img src="../../img/close.png" alt="">
      </div>
      <div class="fudong">
        <!-- <div class="fu1">
                <img src="../../img/job2.png" alt="">
                <div class="anniu">
                    <p>+</p>
                </div>
            </div> -->
        <div class="fu2">
          <div class="img">
            <img src="../../img/xinxi1.png" alt="">
          </div>
          <div class="anniu">
            <p>{{ detail.comments.length }}</p>
          </div>
        </div>
        <div class="fu2" :class="{ active: detail.is_support == true }">
          <div class="img" @click="dzBtn(detail.id)">
            <img src="../../img/tuijian1.png" alt="" v-if="!detail.is_support">
            <img src="../../img/tuijian_a.png" alt="" v-else>
          </div>
          <div class="anniu">
            <p>{{ detail.support_count }}</p>
          </div>
        </div>
        <div class="fu2">
          <div class="img" @click="scBtn(detail.id)">
            <img src="../../img/sc1.png" alt="">
          </div>
          <div class="anniu">
            <p>{{ detail.collect_count }}</p>
          </div>
        </div>
        <div class="fu2">
          <div class="img" @click="zfBtn(detail.id)">
            <img src="../../img/zhuanfa1.png" alt="">
          </div>
          <div class="anniu">
            <p>{{ detail.transfer_count }}</p>
          </div>
        </div>
      </div>
      <div class="index_nei">
        <div class="content">
          <div class="title">
            <div class="detail">
              <!-- <img src="../../img/job1.png" alt="">
                        <img src="../../img/job2.png" alt=""> -->
              <div class="detail1">
                <p>{{ detail.author }}</p>
                <span>上线时间：{{ detail.published_at | datefmt('YYYY-MM-DD HH:mm') }}</span>
              </div>
            </div>
            <h1>{{ detail.title }}</h1>
          </div>
          <div class="des">
            <!-- <div v-if="detail.content != null">
                        <div v-for="(item, index) in detail.content" :key="index">
                            <p v-html="item.data.text"></p>
                        </div>
                    </div> -->
            <img v-for="(item, index) in detail.cover" :src="item" :key="index" alt="">
          </div>
          <div class="xiangqing">
            <div class="xiangqing_top">
              <div class="xq_img">
                <!-- <img src="../../img/job1.png" alt="">
                            <img src="../../img/job2.png" alt=""> -->
              </div>
              <div class="button" @click="canjia(detail.id)">
                <p>参与活动</p>
              </div>
            </div>
            <div class="xiangqing_bot">
              <h3>活动详情</h3>
              <div class="bot_c">
                <div class="bot1" v-if="detail.content != null">
                  <div class="list" v-for="(item, index) in detail.content" :key="index">
                    <p v-html="item.data.text"></p>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>
        <!-- <div class="zuopin">
                <div class="title">
                    <h2>更多作品</h2>
                    <p>查看更多</p>
                </div>
                <div class="list">
                    <div class="list1" v-for="(item, index) in tuijian" :key="index">
                        <img :src="item.pic" alt="">
                    </div>
                </div>
            </div> -->
        <div class="allDibu">
          <div class="dibu_left">
            <div class="pinlun">
              <div class="pinglun_shuru">
                <h2>发表评论</h2>
                <div class="shuru">
                  <!-- <div class="tx">
                                    <img :src="myXinxi.pic" alt="">
                                </div> -->
                  <div class="kuang" style="width:100%">
                    <el-input type="textarea" placeholder="说两句吧" v-model="textarea" maxlength="2000" show-word-limit
                      resize="none">
                    </el-input>
                    <div class="anniu" @click="pinglunBtn(detail.id)">
                      <p>发布</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pl_list" v-if="pinglunList.length > 0">
                <h3>全部评论({{ pinglunList.length }})</h3>
                <div class="allList">
                  <div class="list1" v-for="(item, index) in pinglunList" :key="index">
                    <div class="list1_img">
                      <img :src="item.user.avatar" alt="">
                    </div>
                    <div class="list_xq">
                      <div class="xq_left">
                        <div class="left_top">
                          <p>{{ item.user.name }}</p>
                          <!-- <span>{{ item.time }}</span> -->
                        </div>
                        <div class="left_bot">
                          <p>{{ item.content }}</p>
                          <div class="button" @click="huifu()">回复</div>
                        </div>
                      </div>
                      <div class="xq_right" :class="{ active: item.isdian === true }">
                        <img src="../../img/tuijian_a.png" alt="" v-if="item.isdian">
                        <img src="../../img/tuijian.png" alt="" v-else>
                        <p>{{ item.support_count }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="dibu_right">
            <div class="right1">
              <h3>发布者</h3>
              <div class="right1_con">
                <div class="con1" @click="gstodetail(detail.company.id)">
                  <div class="con1_left">
                    <img v-if="detail.company.logo != null" :src="detail.company.logo[0]" alt="">
                    <div class="detail">
                      <p>{{ detail.company.name }} </p>
                      <!-- <span>{{ detail.user.position }}</span> -->
                    </div>
                  </div>
                </div>
                <!-- <div class="con2">
                                <div class="con2_1">
                                    <p>{{ detail.user.fans }}</p>
                                    <span>粉丝</span>
                                </div>
                                <div class="con2_1">
                                    <p>{{ detail.user.article_count }}</p>
                                    <span>文章</span>
                                </div>
                                <div class="con2_1">
                                    <p>{{ detail.user.case_count }}</p>
                                    <span>案例</span>
                                </div>
                                <div class="con2_1">
                                    <p>{{ detail.user.project_count }}</p>
                                    <span>项目</span>
                                </div>
                            </div> -->
                <!-- <div class="con2">
                                <div class="con2_1" v-if="!zuozhe.isguanzhu">
                                    <img src="../../img/duihao.png" alt="">
                                    <p>已关注</p>
                                </div>
                                <div class="con2_2" v-else>
                                    <span>+</span>
                                    <p>关注</p>
                                </div>
                                <div class="con2_3">
                                    <img src="../../img/email_1.png" alt="">
                                    <p>发信息</p>
                                </div>
                            </div> -->
              </div>
            </div>
            <!-- <div class="right1">
                        <h3>发布者</h3>
                        <div class="right1_con">
                            <div class="list">
                                <img src="../../img/jiang.png" alt="">
                            </div>
                            <div class="list">
                                <img src="../../img/jiang1.png" alt="">
                            </div>
                            <div class="list">
                                <img src="../../img/jiang2.png" alt="">
                            </div>
                            <div class="list">
                                <img src="../../img/jiang3.png" alt="">
                            </div>
                        </div>
                    </div> -->
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
// import headerbar from '../../components/headerbar.vue';
import { eventBus } from '../../App.vue';
import footerbar from '../../components/footerbar.vue';
export default {
  name: 'chuangzao',
  data() {
    return {
      pageTitle: '公司详情',
      choose: 'guanyu',
      selectedNav: '品牌',
      address: '上海市',
      chuangli: '2010年创立',
      dingzhi: '定制版企业',
      huiyuan: '2010年会员',
      detail: '',
      id: '',
      allList: [
        {
          img: require('@/img/cz1.jpg'),
          tag: '文章',
          title: '现代汽车邀请凯文培根参加超级碗前的比赛',
          des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
          date: '2023-02-16',
          dianzan: '125',
          shoucang: '7895',
          isdianzan: false,
          author: {
            name: '用户123',
            tx: require('@/img/tx.jpg'),
          },
        },


        {
          img: require('@/img/cz2.jpg'),
          tag: '文章',
          title: '现代汽车邀请凯文培根参加超级碗前的比赛',
          des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
          date: '2023-02-16',
          dianzan: '125',
          shoucang: '7895',
          isdianzan: false,
          author: {
            name: '用户123',
            tx: require('@/img/tx.jpg'),
          },
        },

        {
          img: require('@/img/cz3.jpg'),
          tag: '文章',
          title: '现代汽车邀请凯文培根参加超级碗前的比赛',
          des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
          date: '2023-02-16',
          dianzan: '125',
          isdianzan: false,
          shoucang: '7895',
          author: {
            name: '用户123',
            tx: require('@/img/tx.jpg'),
          },
        },

        {
          img: require('@/img/cz4.jpg'),
          tag: '文章',
          title: '现代汽车邀请凯文培根参加超级碗前的比赛',
          des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
          date: '2023-02-16',
          dianzan: '125',
          shoucang: '7895',
          isdianzan: false,
          author: {
            name: '用户123',
            tx: require('@/img/tx.jpg'),
          },
        },

        {
          img: require('@/img/cz5.jpg'),
          tag: '文章',
          title: '现代汽车邀请凯文培根参加超级碗前的比赛',
          des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
          date: '2023-02-16',
          dianzan: '125',
          shoucang: '7895',
          isdianzan: false,
          author: {
            name: '用户123',
            tx: require('@/img/tx.jpg'),
          },
        },


        {
          img: require('@/img/cz6.jpg'),
          tag: '文章',
          title: '现代汽车邀请凯文培根参加超级碗前的比赛',
          des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
          date: '2023-02-16',
          dianzan: '125',
          shoucang: '7895',
          isdianzan: false,
          author: {
            name: '用户123',
            tx: require('@/img/tx.jpg'),
          },
        },
        {
          img: require('@/img/cz1.jpg'),
          tag: '文章',
          title: '现代汽车邀请凯文培根参加超级碗前的比赛',
          des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
          date: '2023-02-16',
          dianzan: '125',
          shoucang: '7895',
          isdianzan: false,
          author: {
            name: '用户123',
            tx: require('@/img/tx.jpg'),
          },
        },


        {
          img: require('@/img/cz2.jpg'),
          tag: '文章',
          title: '现代汽车邀请凯文培根参加超级碗前的比赛',
          des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
          date: '2023-02-16',
          dianzan: '125',
          shoucang: '7895',
          isdianzan: false,
          author: {
            name: '用户123',
            tx: require('@/img/tx.jpg'),
          },
        },

        {
          img: require('@/img/cz3.jpg'),
          tag: '文章',
          title: '现代汽车邀请凯文培根参加超级碗前的比赛',
          des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
          date: '2023-02-16',
          dianzan: '125',
          isdianzan: false,
          shoucang: '7895',
          author: {
            name: '用户123',
            tx: require('@/img/tx.jpg'),
          },
        },

        {
          img: require('@/img/cz4.jpg'),
          tag: '文章',
          title: '现代汽车邀请凯文培根参加超级碗前的比赛',
          des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
          date: '2023-02-16',
          dianzan: '125',
          shoucang: '7895',
          isdianzan: false,
          author: {
            name: '用户123',
            tx: require('@/img/tx.jpg'),
          },
        },

        {
          img: require('@/img/cz5.jpg'),
          tag: '文章',
          title: '现代汽车邀请凯文培根参加超级碗前的比赛',
          des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
          date: '2023-02-16',
          dianzan: '125',
          shoucang: '7895',
          isdianzan: false,
          author: {
            name: '用户123',
            tx: require('@/img/tx.jpg'),
          },
        },


        {
          img: require('@/img/cz6.jpg'),
          tag: '文章',
          title: '现代汽车邀请凯文培根参加超级碗前的比赛',
          des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
          date: '2023-02-16',
          dianzan: '125',
          shoucang: '7895',
          isdianzan: false,
          author: {
            name: '用户123',
            tx: require('@/img/tx.jpg'),
          },
        },



      ],
      chengyuanList: [
        {
          author: {
            name: '用户123',
            tx: require('@/img/tx.jpg'),
            address: '北京市',
          },
          isguanzhu: true,
          list: [
            {
              pic: require('@/img/cz1.jpg'),
            },
            {
              pic: require('@/img/cz2.jpg'),
            },
            {
              pic: require('@/img/cz3.jpg'),
            },
            {
              pic: require('@/img/cz4.jpg'),
            },
          ],
        },

        {
          author: {
            name: '用户123',
            tx: require('@/img/tx.jpg'),
            address: '北京市',
          },
          isguanzhu: false,
          list: [
            {
              pic: require('@/img/cz1.jpg'),
            },
            {
              pic: require('@/img/cz2.jpg'),
            },
            {
              pic: require('@/img/cz3.jpg'),
            },
            {
              pic: require('@/img/cz4.jpg'),
            },
          ],
        },

        {
          author: {
            name: '用户123',
            tx: require('@/img/tx.jpg'),
            address: '北京市',
          },
          isguanzhu: false,
          list: [
            {
              pic: require('@/img/cz1.jpg'),
            },
            {
              pic: require('@/img/cz2.jpg'),
            },
            {
              pic: require('@/img/cz3.jpg'),
            },
            {
              pic: require('@/img/cz4.jpg'),
            },
          ],
        },

        {
          author: {
            name: '用户123',
            tx: require('@/img/tx.jpg'),
            address: '北京市',
          },
          isguanzhu: false,
          list: [
            {
              pic: require('@/img/cz1.jpg'),
            },
            {
              pic: require('@/img/cz2.jpg'),
            },
            {
              pic: require('@/img/cz3.jpg'),
            },
            {
              pic: require('@/img/cz4.jpg'),
            },
          ],
        },

        {
          author: {
            name: '用户123',
            tx: require('@/img/tx.jpg'),
            address: '北京市',
          },
          isguanzhu: false,
          list: [
            {
              pic: require('@/img/cz1.jpg'),
            },
            {
              pic: require('@/img/cz2.jpg'),
            },
            {
              pic: require('@/img/cz3.jpg'),
            },
            {
              pic: require('@/img/cz4.jpg'),
            },
          ],
        },

        {
          author: {
            name: '用户123',
            tx: require('@/img/tx.jpg'),
            address: '北京市',
          },
          isguanzhu: true,
          list: [
            {
              pic: require('@/img/cz1.jpg'),
            },
            {
              pic: require('@/img/cz2.jpg'),
            },
            {
              pic: require('@/img/cz3.jpg'),
            },
            {
              pic: require('@/img/cz4.jpg'),
            },
          ],
        },
      ],

      shanchang: [
        '整合营销',
        '社会化运营',
        'TVC拍摄',
        '品牌推广',
        '标识',
        '用户体验',
        '多媒体',
      ],
      pinpaiList: [
        {
          img: require('../../img/pinpai.jpg'),
        },
        {
          img: require('../../img/pinpai1.jpg'),
        },
        {
          img: require('../../img/pinpai2.jpg'),
        },
        {
          img: require('../../img/pinpai3.jpg'),
        },
        {
          img: require('../../img/pinpai4.jpg'),
        },
        {
          img: require('../../img/pinpai5.jpg'),
        },
        {
          img: require('../../img/pinpai6.jpg'),
        },
        {
          img: require('../../img/pinpai7.jpg'),
        },
        {
          img: require('../../img/pinpai.jpg'),
        },
        {
          img: require('../../img/pinpai1.jpg'),
        },
        {
          img: require('../../img/pinpai2.jpg'),
        },
        {
          img: require('../../img/pinpai3.jpg'),
        },
        {
          img: require('../../img/pinpai4.jpg'),
        },
        {
          img: require('../../img/pinpai5.jpg'),
        },
        {
          img: require('../../img/pinpai6.jpg'),
        },
        {
          img: require('../../img/pinpai7.jpg'),
        },
      ],
      cyList: [
        {
          pic: require('../../img/shejishi.jpg'),
        },
        {
          pic: require('../../img/shejishi1.jpg'),
        },
        {
          pic: require('../../img/shejishi2.jpg'),
        },
        {
          pic: require('../../img/shejishi3.jpg'),
        },
        {
          pic: require('../../img/shejishi4.jpg'),
        },
        {
          pic: require('../../img/shejishi5.jpg'),
        },
        {
          pic: require('../../img/shejishi.jpg'),
        },
        {
          pic: require('../../img/shejishi1.jpg'),
        },
        {
          pic: require('../../img/shejishi2.jpg'),
        },
        {
          pic: require('../../img/shejishi3.jpg'),
        },
        {
          pic: require('../../img/shejishi4.jpg'),
        },
        {
          pic: require('../../img/shejishi5.jpg'),
        },
      ],
      projectList: "",
      gongzuoList: "",
      caseList: '',
      pinglunList: [],
      xqzhanshi: false,
      huodongShow: false,
      isScrollDisabled: false,
      textarea: '',
    }
  },
  created() {
    document.title = this.pageTitle;
  },
  components: {
    // headerbar,
    footerbar
  },
  mounted() {
    console.log(this.$route.query.id)
    this.id = this.$route.query.id
    this.getDetail()
    this.getgongzuo()
    this.getcaseList()
    this.getArticleList()
    this.getProjectList()
    this.getChengyuanList()
    this.setActivePage()
  },
  methods: {
    setActivePage() {
            // 触发事件，传递当前页面的名称
            eventBus.$emit('updateActivePage', '品牌');
        },
    jiaru(e) {
      this.$axios.get('/api/user/company_apply/' + e, {
        headers: {
          'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
        }
      }).then((result) => {
        console.log(result)
      }).catch((err) => {
        console.log(err)
        // this.$message.error(err.response.data.message);
        if (err.response.data.code == 403) {
          this.$message.error('请先登录进行操作！');
          setTimeout(() => {
            this.$router.push({
              name: 'login'
            })
          }, 1500);
        }
      });
    },
    chooseBtn(e) {
      this.choose = e
    },
    getDetail() {
      this.$axios.get('/api/company/info/' + this.id, {}).then((result) => {
        console.log(result)
        if (result.data.code == 200) {
          this.detail = result.data.data
        } else {
          this.$message.error(result.data.message);
        }
      }).catch((err) => {
        console.log(err)
      });
    },

    //获取工作列表
    getgongzuo() {
      this.$axios.get('/api/__yao/table/pc.employ/search', {
        "where.area.match": '',
        "where.title.match": '',
        "where.exp.match": '',
        "where.edu.match": '',
        "where.work_type.match": '',
        "where.salary_low.ge": '',
        "where.salary_up.le": '',
        "where.company_id.eq": this.id
      }).then((result) => {
        console.log(result)
        if (result.data.code == 200) {
          this.gongzuoList = result.data.data
        } else {
          this.$message.error(result.data.message);
        }
      }).catch((err) => {
        console.log(err)
      });
    },

    //获取案例列表
    getcaseList() {
      this.$axios.get('/api/__yao/table/pc.example/search', {
        "where.tags.match": '',
        "where.industry.match": '',
        "where.company_id.eq": this.id
      }).then((result) => {
        console.log(result)
        if (result.data.code == 200) {
          this.caseList = result.data.data
        } else {
          this.$message.error(result.data.message);
        }
      }).catch((err) => {
        console.log(err)
      });
    },

    //获取文章列表
    getArticleList() {
      this.$axios.get('/api/__yao/table/pc.article/search', {
        "order": 'created_at.desc',
        "where.categories.match": "1",
        "where.industry.match": '',
        "where.company_id.eq": this.id,
      }).then((result) => {
        console.log(result)
        if (result.data.code == 200) {
          this.allList = result.data.data
        } else {
          this.$message.error(result.data.message);
        }
      }).catch((err) => {
        console.log(err)
      });
    },

    //获取项目列表
    getProjectList() {
      this.$axios.get('/api/__yao/table/pc.project/search', {
        "order": 'created_at.desc',
        "where.categories.match": '1',
        "where.industry.match": '',
        "where.company_id.eq": this.id,
      }).then((result) => {
        console.log(result)
        if (result.data.code == 200) {
          this.projectList = result.data.data
        } else {
          this.$message.error(result.data.message);
        }
      }).catch((err) => {
        console.log(err)
      });
    },

    //获取成员列表
    getChengyuanList() {
      this.$axios.get('/api/__yao/table/pc.company_user/search', {
        headers: {
          'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
        }
      }, {
        "where.company_id.eq": this.id
      }).then((result) => {
        console.log(result)
        if (result.data.code == 200) {
          this.chengyuanList = result.data.data
        } else {
          this.$message.error(result.data.message);
        }
      }).catch((err) => {
        console.log(err)
        if (err.response.data.code == 403) {
          this.$message.error('请先登录在进行查看！');
          setTimeout(() => {
            this.$router.push({
              name: 'login'
            })
          }, 1500);
        }
      });
    },



    //收藏
    scBtn(e) {
      this.$axios.post('/api/user/collect/article', {
        id: e,
        type: 1,
      }, {
        headers: {
          'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
        }
      }).then((result) => {
        console.log(result)
        if (result.data.code == 200) {
          this.$message({
            message: '收藏成功！',
            type: 'success'
          });
        } else {
          this.$message.error(result.data.message);
        }
      }).catch((err) => {
        console.log(err)
        this.$message.error(err.response.data.message);
        if (err.response.data.code == 403) {
          setTimeout(() => {
            this.$router.push({
              name: 'login'
            })
          }, 1500);
        }
      });
    },


    //点赞
    dzBtn(e) {
      this.$axios.post('/api/user/support/content', {
        id: e,
        type: 1,
      }, {
        headers: {
          'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
        }
      }).then((result) => {
        console.log(result)
        if (result.data.code == 200) {
          this.$message({
            message: '点赞成功！',
            type: 'success'
          });
        } else {
          this.$message.error(result.data.message);
        }
      }).catch((err) => {
        console.log(err)
        this.$message.error(err.response.data.message);
        if (err.response.data.code == 403) {
          setTimeout(() => {
            this.$router.push({
              name: 'login'
            })
          }, 1500);
        }
      });
    },

    //跳转工作详情
    toJobDetail(e) {
      this.$router.push({
        name: 'jobDetail',
        query: {
          id: e
        }
      })
    },

    //跳转案例详情
    todetail1(e) {
      // this.$router.push({
      //   name: 'caseDetail',
      //   query: {
      //     id: e
      //   }
      // })

      document.body.classList.add('disable-body-scroll');
      this.xqzhanshi = true;

      this.$axios.get('api/pc/article/detail/' + e,).then((result) => {
        console.log(result)
        if (result.data.code == 200) {
          this.detail = result.data.data;
          this.pinglunList = result.data.data.comments
        } else {
          this.$message.error(result.data.message);
        }
      }).catch((err) => {
        console.log(err)
        this.$message.error(err.response.data.message);
        if (err.response.data.code == 403) {
          setTimeout(() => {
            this.$router.push({
              name: 'login'
            })
          }, 1500);
        }
      });


    },

    //项目详情
    todetail2(e) {
      // this.$router.push({
      //   name: 'projectDetail',
      //   query: {
      //     id: e
      //   }
      // })


      document.body.classList.add('disable-body-scroll');
      this.xqzhanshi = true;

      this.$axios.get('api/pc/article/detail/' + e,).then((result) => {
        console.log(result)
        if (result.data.code == 200) {
          this.detail = result.data.data;
          this.pinglunList = result.data.data.comments
        } else {
          this.$message.error(result.data.message);
        }
      }).catch((err) => {
        console.log(err)
        this.$message.error(err.response.data.message);
        if (err.response.data.code == 403) {
          setTimeout(() => {
            this.$router.push({
              name: 'login'
            })
          }, 1500);
        }
      });



    },
    //文章详情
    // todetail(e) {
    //   this.$router.push({
    //     name: 'articleDetail',
    //     query: {
    //       id: e
    //     }
    //   })
    // },

    addguanzhu(e) {
      console.log("关注")
      this.$axios.get('/api/user/user_following' + e, {
        headers: {
          'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
        }
      }).then((result) => {
        console.log(result)
        if (result.data.code == 200) {
          this.$message({
            message: result.data.message,
            type: 'success'
          });
          this.getList()
        } else {
          this.$message.error(result.data.message);
        }
      }).catch((err) => {
        console.log(err)
        // if (err.response.data.code == 403) {
        this.$message.error('请先登录！');
        setTimeout(() => {
          this.$router.push({
            name: 'login'
          })
        }, 1500);
        // }
      });
    },
    qxguanzhu(e) {
      console.log("quxiao")
      this.$axios.get('/api/user/user_following' + e, {
        headers: {
          'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
        }
      }).then((result) => {
        console.log(result)
        if (result.data.code == 200) {
          this.$message({
            message: result.data.message,
            type: 'success'
          });
          this.getList()
        } else {
          this.$message.error(result.data.message);
        }
      }).catch((err) => {
        console.log(err)
        // if (err.response.data.code == 403) {
        this.$message.error('请先登录！');
        setTimeout(() => {
          this.$router.push({
            name: 'login'
          })
        }, 1500);
        // }
      });
    },






    closeBtn() {
      this.xqzhanshi = false;
      this.huodongShow = false;
      document.body.classList.remove('disable-body-scroll');
    },


    //海报和文章详情弹窗
    todetail(e) {
      // this.$router.push('/articleDetail')
      // this.$router.push({
      //   name: 'articleDetail',
      //   query: {
      //     id: e
      //   }
      // })
      document.body.classList.add('disable-body-scroll');
      this.xqzhanshi = true;

      this.$axios.get('api/pc/article/detail/' + e,).then((result) => {
        console.log(result)
        if (result.data.code == 200) {
          this.detail = result.data.data;
          this.pinglunList = result.data.data.comments
        } else {
          this.$message.error(result.data.message);
        }
      }).catch((err) => {
        console.log(err)
        this.$message.error(err.response.data.message);
        if (err.response.data.code == 403) {
          setTimeout(() => {
            this.$router.push({
              name: 'login'
            })
          }, 1500);
        }
      });





    },
    //海报和文章详情弹窗
    posterDetail(e) {
      // this.$router.push({
      //   name: 'posterDetail',
      //   query: {
      //     id: e
      //   }
      // })

      document.body.classList.add('disable-body-scroll');
      this.xqzhanshi = true;

      this.$axios.get('api/pc/article/detail/' + e,).then((result) => {
        console.log(result)
        if (result.data.code == 200) {
          this.detail = result.data.data;
          this.pinglunList = result.data.data.comments
        } else {
          this.$message.error(result.data.message);
        }
      }).catch((err) => {
        console.log(err)
        this.$message.error(err.response.data.message);
        if (err.response.data.code == 403) {
          setTimeout(() => {
            this.$router.push({
              name: 'login'
            })
          }, 1500);
        }
      });



    },
    videoDetail(e) {
      // this.$router.push({
      //   name: 'videoDetail',
      //   query: {
      //     id: e
      //   }
      // })


      document.body.classList.add('disable-body-scroll');
      this.xqzhanshi = true;

      this.$axios.get('api/pc/article/detail/' + e,).then((result) => {
        console.log(result)
        if (result.data.code == 200) {
          this.detail = result.data.data;
          this.pinglunList = result.data.data.comments
        } else {
          this.$message.error(result.data.message);
        }
      }).catch((err) => {
        console.log(err)
        this.$message.error(err.response.data.message);
        if (err.response.data.code == 403) {
          setTimeout(() => {
            this.$router.push({
              name: 'login'
            })
          }, 1500);
        }
      });



    },

    gstodetail(e) {
      this.$router.push({
        name: 'chuangzao',
        query: {
          id: e
        }
      })
    },

    //评论
    pinglunBtn(e) {
      this.$axios.post('/api/user/comment/save', {
        type: 1,
        ext_id: e,
        content: this.textarea,
        comment_id: '',
      }, {
        headers: {
          'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
        }
      }).then((result) => {
        console.log(result)
        if (result.data.code == 200) {
          this.$message({
            message: result.data.message,
            type: 'success'
          });
          this.textarea = ""
        } else {
          this.$message.error(result.data.message);
        }
      }).catch((err) => {
        console.log(err)
        this.$message.error(err.response.data.message);
      });
    },

    //活动详情弹窗
    toxiangmu(e) {
      // this.$router.push({
      //   name: 'huodongDetail',
      //   query: {
      //     id: e
      //   }
      // })

      document.body.classList.add('disable-body-scroll');
      this.huodongShow = true;
      this.$axios.get('/api/pc/activity/detail/' + e, {}).then((result) => {
        console.log(result)
        if (result.data.code == 200) {
          this.detail = result.data.data;
          this.pinglunList = result.data.data.comments
        }
      }).catch((err) => {
        console.log(err)
      });

    },


    niurentodetail(e) {
      this.$router.push({
        name: 'niurenDetail',
        query: {
          id: e
        }
      })
    },






  }
}
</script>

<style scoped lang="scss">
@import "../chuangzao/chuangzao.scss"
</style>