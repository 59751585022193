<template>
  <div class="index">
    <!-- <headerbar :selected-nav="selectedNav"></headerbar> -->
    <div class="index">
      <div class="jobTop">
        <div class="jobTop_left">
          <div class="left_nav">
            <img v-if="detail2.avatar != null" :src="detail2.avatar" alt="">
            <p>{{ detail2.name }}</p>
          </div>
          <div class="miaoshu">
            <p>{{ detail2.intro }}</p>
          </div>

          <div class="anniu">
            <div class="anniu2" v-if="detail2.is_following" @click="anniuBtn(detail2.id)">
              <img src="../../img/duihao.png" alt="">
              <span>已关注</span>
            </div>
            <div class="anniu1" v-else @click="anniuBtn(detail2.id)">
              <img src="../../img/email_1.png" alt="">
              <p>关注</p>
            </div>
          </div>

        </div>
        <div class="jobTop_right">
          <img src="../../img/ge_detail.png" alt="">
        </div>
      </div>
      <div class="huodong">
        <div class="liebiao_top">
          <div class="top_left">
            <div class="choose" :class="{ active: choose === 'guanyu' }" @click="chooseBtn('guanyu')">
              <p>关于</p>
            </div>
            <div class="choose" :class="{ active: choose === 'zuopin' }" @click="chooseBtn('zuopin')">
              <p>文章{{ allList.length }}</p>
            </div>
            <div class="choose" :class="{ active: choose === 'case' }" @click="chooseBtn('case')">
              <p>案例{{ caseList.length }}</p>
            </div>
            <div class="choose" :class="{ active: choose === 'neirong' }" @click="chooseBtn('neirong')">
              <p>项目{{ projectList.length }}</p>
            </div>
            <div class="choose" :class="{ active: choose === 'video' }" @click="chooseBtn('video')">
              <p>视频{{ videoList.length }}</p>
            </div>
            <div class="choose" :class="{ active: choose === 'poster' }" @click="chooseBtn('poster')">
              <p>海报{{ posterList.length }}</p>
            </div>


          </div>
        </div>
        <div class="liebiao_con">
          <div class="allList" v-if="choose == 'zuopin'">
            <div class="list1" v-for="(item, index) in allList" :key="index" @click="todetail(item.id)">
              <div class="img">
                <img :src="item.cover[0]" alt="">
                <div class="tag">
                  <p>文章</p>
                </div>
                <div class="xuanze">
                  <div class="ms">
                    <p>{{ item.title }}</p>
                  </div>
                  <div class="choose">
                    <div class="choose1">
                      <img src="../../img/shoucang.png" alt="" @click.stop="scBtn(item.id)">
                    </div>
                    <div class="choose1" @click.stop="dzBtn(item.id)">
                      <img src="../../img/dianzan.png" alt="" v-if="item.isdianzan == true">
                      <img src="../../img/dianzan.png" alt="" v-else>
                    </div>
                  </div>
                </div>
              </div>
              <div class="des">
                <div class="title">
                  <p>{{ item.title }}</p>
                </div>
                <div class="xq">
                  <div class="xq1">
                    <!-- <img :src="item.author.tx" alt=""> -->
                    <p>{{ item.author }}</p>
                  </div>
                  <div class="xq2">
                    <div class="sc">
                      <img src="../../img/shoucang.png" alt="">
                      <p>{{ item.collect_count }}</p>
                    </div>
                    <div class="sc">
                      <img src="../../img/dianzan.png" alt="">
                      <p>{{ item.support_count }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="shipinList" v-if="choose == 'video'">
            <div class="spList">
              <div class="sp" v-for="(item, index) in videoList" :key="index" @click="todetail2(item.id)">
                <div class="img">
                  <img :src="item.cover[0]" alt="">
                  <img class="biaozhi" src="../../img/shipin.png" alt="">
                  <!-- <div class="time">
                                    <p>03:20</p>
                                </div> -->
                </div>
                <div class="des">
                  <div class="title">
                    <p>{{ item.title }}</p>
                  </div>
                  <div class="xq">
                    <div class="xq1">
                      <!-- <img :src="item.author.tx" alt=""> -->
                      <p>{{ item.author }}</p>
                    </div>
                    <div class="xq2">
                      <div class="sc">
                        <img src="../../img/shoucang.png" alt="">
                        <p>{{ item.collect_count }}</p>
                      </div>
                      <div class="sc">
                        <img src="../../img/dianzan.png" alt="">
                        <p>{{ item.support_count }}</p>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div class="zpList" v-if="choose == 'poster'">
            <div class="zp" v-for="(item, index) in posterList" :key="index" @click="todetail(item.id)">
              <div class="img">
                <img :src="item.cover[0]" alt="">
              </div>
              <div class="title">
                    <p>{{ item.title }}</p>
                  </div>
              <div class="xq">
                <div class="xq1">
                  <!-- <img :src="item.author.tx" alt=""> -->
                  <p>{{ item.author }}</p>
                </div>
                <div class="xq2">
                  <div class="sc">
                    <img src="../../img/shoucang.png" alt="">
                    <!-- <p>{{ item.collect_count }}</p> -->
                  </div>
                  <div class="sc">
                    <img src="../../img/dianzan.png" alt="">
                    <p>{{ item.support_count }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="guanyu" v-if="choose == 'guanyu'">
            <div class="guanyu_left">
              <div class="jianjie">
                <h2>简介</h2>
                <div class="neirong">
                  <p>{{ detail2.intro }}</p>
                </div>

              </div>
              <div class="shanchang">
                <h2>职位</h2>
                <div class="sc">
                  <div class="tags">
                    <p>{{ detail2.position }}</p>
                  </div>
                </div>
              </div>
              <!-- <div class="pinpai">
                  <div class="pp" v-for="(item, index) in pinpaiList" :key="index">
                    <img :src="item.img" alt="">
                  </div>
                </div> -->
            </div>
            <div class="guanyu_right">
              <div class="xinxi">
                <div class="xx1">
                  <img src="../../img/location1.jpg" alt="">
                  <p>{{ detail2.work_place }}</p>
                </div>

                <div class="xx1">
                  <img src="../../img/chuangli.jpg" alt="">
                  <p>{{ detail2.company.name }}</p>
                </div>

                <div class="xx1">
                  <img src="../../img/huiyuan.jpg" alt="">
                  <p>{{ huiyuan }}</p>
                </div>
              </div>

            </div>
          </div>
          <div class="jobList" v-if="choose == 'zhaopin'">
            <div class="list1" v-for="(item, index) in gongzuoList" :key="index">
              <div class="list1_left">
                <!-- <div class="img">
                  <img :src="item.pic" alt="">
                </div> -->
                <div class="des">
                  <p>{{ item.title }}</p>
                  <div class="tag">
                    <!-- <span v-for="(tag, index) in item.tags" :key="index">{{ tag.name }}</span> -->
                    <span>{{ item.slug }}</span>
                  </div>
                </div>
              </div>
              <div class="list1_right">
                <p>{{ item.salary_low }}-{{ item.salary_up }}</p>
                <span>{{ item.published_at | datefmt('YYYY-MM-DD HH:mm') }}</span>
              </div>
              <div class="choose">
                <div class="choose1" @click="toJobDetail(item.id)">
                  <img src="../../img/chakan.png" alt="">
                  <p>查看职位</p>
                </div>
                <!-- <div class="choose1">
                  <img src="../../img/shenqing.png" alt="">
                  <p>立即申请</p>
                </div> -->
                <div style="clear: both;"></div>
              </div>
            </div>
          </div>
          <div class="allList" v-if="choose == 'case'">
            <div class="list1" v-for="(item, index) in caseList" :key="index" @click="todetail1(item.id)">
              <div class="img">
                <img :src="item.cover[0]" alt="">
                <div class="tag">
                  <p>项目</p>
                </div>
                <div class="xuanze">
                  <div class="ms">
                    <p>{{ item.title }}</p>
                  </div>
                  <div class="choose">
                    <div class="choose1" @click.stop="scBtn(item.id)">
                      <img src="../../img/shoucang.png" alt="">
                    </div>
                    <div class="choose1" @click.stop="dzBtn(item.id)">
                      <img src="../../img/dianzan.png" alt="" v-if="item.isdianzan == true">
                      <img src="../../img/dianzan.png" alt="" v-else>
                    </div>
                  </div>
                </div>
              </div>
              <div class="des">
                <div class="title">
                  <p>{{ item.title }}</p>
                </div>
                <div class="xq">
                  <div class="xq1">
                    <!-- <img :src="item.author.tx" alt=""> -->
                    <p>{{ item.author }}</p>
                  </div>
                  <div class="xq2">
                    <div class="sc">
                      <img src="../../img/shoucang.png" alt="">
                      <p>{{ item.collect_count }}</p>
                    </div>
                    <div class="sc">
                      <img src="../../img/dianzan.png" alt="">
                      <p>{{ item.support_count }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="allList" v-if="choose == 'neirong'">
            <div class="list1" v-for="(item, index) in projectList" :key="index" @click="toxiangmu(item.id)">
              <div class="img">
                <img :src="item.cover[0]" alt="">
                <div class="tag">
                  <p>项目</p>
                </div>
                <div class="xuanze">
                  <div class="ms">
                    <p>{{ item.title }}</p>
                  </div>
                  <div class="choose">
                    <div class="choose1">
                      <img src="../../img/shoucang.png" alt="" @click.stop="scBtn(item.id)">
                    </div>
                    <div class="choose1" @click.stop="dzBtn(item.id)">
                      <img src="../../img/dianzan.png" alt="" v-if="item.isdianzan == true">
                      <img src="../../img/dianzan.png" alt="" v-else>
                    </div>
                  </div>
                </div>
              </div>
              <div class="des">
                <div class="title">
                  <p>{{ item.title }}</p>
                </div>
                <div class="xq">
                  <div class="xq1">
                    <!-- <img :src="item.author.tx" alt=""> -->
                    <p>{{ item.author }}</p>
                  </div>
                  <div class="xq2">
                    <div class="sc">
                      <img src="../../img/shoucang.png" alt="">
                      <p>{{ item.collect_count }}</p>
                    </div>
                    <div class="sc">
                      <img src="../../img/dianzan.png" alt="">
                      <p>{{ item.support_count }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footerbar></footerbar>


    <div class="xqTanchuang" v-if="xqzhanshi">
      <div class="closeanniu" @click="closeBtn">
        <img src="../../img/close.png" alt="">
      </div>
      <div class="index_nei">
        <div class="wz_left">
          <div class="content">
            <div class="title">
              <h2>{{ detail.title }}</h2>
              <div class="des">
                <div class="des1">
                  <p>{{ detail.published_at | datefmt('YYYY-MM-DD HH:mm') }}</p>
                  <i>·</i>
                  <p>{{ detail.origin }}</p>
                </div>
                <div class="des1">
                  <p>{{ detail.support_count }}点赞</p>
                  <i>·</i>
                  <p>{{ detail.read_count }}阅读</p>
                  <i>·</i>
                  <p>{{ detail.collect_count }}收藏</p>
                  <i>·</i>
                  <p>{{ detail.transfer_count }}转发</p>
                </div>
              </div>
            </div>
            <div class="con">
              
              <img v-if="detail.cover.length > 0" :src="detail.cover[0]" alt="">
              <video v-if="detail.video != null && detail.cover != null" :src="detail.video[0]" controls
                :poster="detail.cover[0]"></video>
                <div v-if="detail.content != null">
                <div v-for="(item, index) in detail.content" :key="index">
                  <p v-html="item.data.text"></p>
                  <ul>
                    <li>
                      <p v-for="(dd, i) in item.data.items" :key="i" v-html="dd.content"></p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="dibu">
              <div class="dibu1">
                <p>未经授权严禁转载，授权事宜请联系作者本人，侵权必究。</p>
              </div>
              <div class="dibu2">
                <div class="dibu2_1" @click="dzBtn(detail.id)" v-if="!detail.is_support">
                  <div class="img">
                    <img src="../../img/tuijian.png">
                    <!-- <img src="../../img/tuijian_a.png" alt="" v-else> -->
                  </div>
                  <p>{{ detail.support_count }}</p>
                </div>
                <div class="dibu2_1 active" @click="qxdzBtn(detail.id)" v-if="detail.is_support">
                  <div class="img">
                    <!-- <img src="../../img/tuijian.png" > -->
                    <img src="../../img/tuijian_a.png" alt="">
                  </div>
                  <p>{{ detail.support_count }}</p>
                </div>
                <div class="dibu2_1" @click="scBtn(detail.id)">
                  <div class="img">
                    <img src="../../img/sc.png" alt="">
                  </div>
                  <p>{{ detail.collect_count }}</p>
                </div>
                <div class="dibu2_1" @click="zfBtn(detail.id)">
                  <div class="img">
                    <img src="../../img/zhuanfa.png" alt="">
                  </div>
                  <p>{{ detail.transfer_count }}</p>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="wz_right">


          <div class="pinlun">
            <div class="pinglun_shuru">
              <h2>发表评论</h2>
              <div class="shuru">
                <!-- <div class="tx">
                                <img :src="myXinxi.pic" alt="">
                            </div> -->
                <div class="kuang" style="width:100%">
                  <el-input type="textarea" placeholder="说两句吧" v-model="textarea" maxlength="2000" show-word-limit
                    resize="none">
                  </el-input>
                  <div class="anniu" @click="pinglunBtn(detail.id)">
                    <p>发布</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="pl_list" v-if="pinglunList.length > 0">
              <h3>全部评论({{ pinglunList.length }})</h3>
              <div class="allList">
                <div class="list1" v-for="(item, index) in pinglunList" :key="index">
                  <div class="list1_img">
                    <img :src="item.user.avatar" alt="">
                  </div>
                  <div class="list_xq">
                    <div class="xq_left">
                      <div class="left_top">
                        <p>{{ item.user.name }}</p>
                        <span>{{ item.created_at | datefmt('YYYY-MM-DD HH:mm') }}</span>
                      </div>
                      <div class="left_bot">
                        <p>{{ item.content }}</p>
                        <div class="button" @click="huifuShowBtn(index)" v-if="activeIndex === ''">回复</div>
                        <div class="button" @click="huifuShowBtn1" v-if="index === activeIndex">取消回复</div>
                      </div>
                      <div class="huifu_shu" v-if="index === activeIndex">
                        <div class="shu1">
                          <el-input type="text" placeholder="请输入内容" v-model="text" maxlength="300" show-word-limit>
                          </el-input>
                          <div class="button" @click="huifu(item.id)">回复</div>
                        </div>
                      </div>
                    </div>
                    <div class="xq_right" :class="{ active: item.isdian === true }">
                      <img src="../../img/tuijian_a.png" alt="" v-if="item.isdian">
                      <img src="../../img/tuijian.png" alt="" v-else>
                      <p>{{ item.support_count }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="right1">
            <h3>发布者</h3>
            <div class="right1_con">
              <div class="con1" @click="niurentodetail(detail.user.id)">
                <div class="con1_left">
                  <img v-if="detail.user.avatar.length > 0" :src="detail.user.avatar[0]" alt="">
                  <div class="detail">
                    <p>{{ detail.user.name }} </p>
                    <span>{{ detail.user.intro }}</span>
                    <span>{{ detail.user.position }}</span>
                  </div>
                </div>
                <!--  <div class="con1_right">
                                <div class="anniu" v-if="zuozhe.isguanzhu">
                                    <span>+</span>
                                    <p>关注</p>
                                </div> -->
                <!-- <div class="anniuyi" v-else>
                                    <img src="../../img/duihao.png" alt="">
                                    <p>已关注</p>
                                </div> 
                            </div>-->
              </div>
              <div class="con2">
                <div class="con2_1">
                  <p>{{ detail.user.fans }}</p>
                  <span>粉丝</span>
                </div>
                <div class="con2_1">
                  <p>{{ detail.user.article_count }}</p>
                  <span>文章</span>
                </div>
                <div class="con2_1">
                  <p>{{ detail.user.case_count }}</p>
                  <span>案例</span>
                </div>
                <div class="con2_1">
                  <p>{{ detail.user.project_count }}</p>
                  <span>项目</span>
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="right1">
                    <h3>相关企业</h3>
                    <div class="right1_con">
                        <div class="con1">
                            <div class="con1_left">
                                <img :src="xgqy.pic" alt="">
                                <div class="detail">
                                    <p>{{ xgqy.name }} </p>
                                    <span>{{ xgqy.address }}</span>
                                    <span>{{ xgqy.gsname }}</span>
                                </div>
                            </div>
                            <div class="con1_right">
                                <div class="anniu" v-if="xgqy.isguanzhu">
                                    <span>+</span>
                                    <p>关注</p>
                                </div>
                                <div class="anniuyi" v-else>
                                    <img src="../../img/duihao.png" alt="">
                                    <p>已关注</p>
                                </div>
                            </div>
                        </div>
                        <div class="con2">
                            <div class="con2_1">
                                <p>{{ xgqy.fensi }}</p>
                                <span>粉丝</span>
                            </div>
                            <div class="con2_1">
                                <p>{{ xgqy.wenzang }}</p>
                                <span>文章</span>
                            </div>
                            <div class="con2_1">
                                <p>{{ xgqy.anli }}</p>
                                <span>案例</span>
                            </div>
                            <div class="con2_1">
                                <p>{{ xgqy.xiangmu }}</p>
                                <span>项目</span>
                            </div>
                        </div>
                    </div>
                </div> -->

          <!-- <div class="right2">
            <h3>热门文章</h3>
            <div class="list1" v-for="(item, index) in xgList" :key="index" @click="todetail(item.id)">
              <img :src="item.cover[0]" alt="">
              <p>{{ item.title }}</p>
            </div>
          </div> -->


        </div>
      </div>
    </div>

    <div class="xqTanchuang1" v-if="huodongShow">
      <div class="closeanniu" @click="closeBtn">
        <img src="../../img/close.png" alt="">
      </div>
      <div class="fudong">
        <div class="fu1">
          <img v-if="detail.user.avatar != null" :src="detail.user.avatar[0]" alt="">
          <div class="anniu" @click="anniuBtn(detail.id)">
                    <p>+</p>
                </div>
        </div>
        <div class="fu2">
          <div class="img">
            <img src="../../img/xinxi1.png" alt="">
          </div>
          <div class="anniu">
            <p>{{ detail.comments.length }}</p>
          </div>
        </div>
        <div class="fu2" :class="{ active: detail.is_support == true }">
          <div class="img" @click="dzBtn(detail.id)">
            <img src="../../img/tuijian1.png" alt="" v-if="!detail.is_support">
            <img src="../../img/tuijian_a.png" alt="" v-else>
          </div>
          <div class="anniu">
            <p>{{ detail.support_count }}</p>
          </div>
        </div>
        <div class="fu2">
          <div class="img" @click="scBtn(detail.id)">
            <img src="../../img/sc1.png" alt="">
          </div>
          <div class="anniu">
            <p>{{ detail.collect_count }}</p>
          </div>
        </div>
        <div class="fu2">
          <div class="img" @click="zfBtn(detail.id)">
            <img src="../../img/zhuanfa1.png" alt="">
          </div>
          <div class="anniu">
            <p>{{ detail.transfer_count }}</p>
          </div>
        </div>
      </div>
      <div class="index_nei">
        <div class="content">
          <div class="title">
            <div class="detail">
              <!-- <img src="../../img/job1.png" alt="">
                        <img src="../../img/job2.png" alt=""> -->
              <img v-if="detail.user.avatar != null" :src="detail.user.avatar[0]" alt="">
              <div class="detail1">
                <p>{{ detail.author }}</p>
                <span>上线时间：{{ detail.published_at | datefmt('YYYY-MM-DD HH:mm') }}</span>
              </div>
            </div>
            <h1>{{ detail.title }}</h1>
          </div>
          <div class="des">
            <!-- <div v-if="detail.content != null">
                        <div v-for="(item, index) in detail.content" :key="index">
                            <p v-html="item.data.text"></p>
                        </div>
                    </div> -->
            <img v-for="(item, index) in detail.cover" :src="item" :key="index" alt="">
          </div>
          <div class="xiangqing">
            <div class="xiangqing_top">
              <div class="xq_img">
                <!-- <img src="../../img/job1.png" alt="">
                            <img src="../../img/job2.png" alt=""> -->
                <img v-if="detail.user.avatar != null" :src="detail.user.avatar[0]" alt="">
              </div>
              <div class="button" @click="shenqing(detail.id)">
                <p>项目申请</p>
              </div>
            </div>
            <div class="xiangqing_bot">
              <h3>项目详情</h3>
              <div class="bot_c">
                <div class="bot1" v-if="detail.content != null">
                  <div class="list" v-for="(item, index) in detail.content" :key="index">
                    <p v-html="item.data.text"></p>
                    <ul>
                      <li>
                        <p v-for="(dd, i) in item.data.items" :key="i" v-html="dd.content"></p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="bot_renling">
                  <div class="role">
                    <h2>参与人名单</h2>
                    <ul>
                      <li v-for="(item, index) in detail.operator_user" :key="index">
                        <div class="info">
                          <img v-if="item.user.avater != null" :src="item.user.avater" alt="">
                          <!-- <img src="../../img/tx.jpg" alt=""> -->
                          <p>{{ item.user.name }}</p>
                        </div>
                        <i>{{ item.role }}</i>
                      </li>
                    </ul>

                    <!-- <ul>
                                            <li v-for="(item, index) in roleList" :key="index">
                                                <p>{{ item.label }}</p>
                                                <span @click="renlingBtn(item.label, detail.id)">认领</span>
                                            </li>
                                        </ul> -->
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <!-- <div class="zuopin">
                <div class="title">
                    <h2>更多作品</h2>
                    <p>查看更多</p>
                </div>
                <div class="list">
                    <div class="list1" v-for="(item, index) in tuijian" :key="index">
                        <img :src="item.pic" alt="">
                    </div>
                </div>
            </div> -->
        <div class="allDibu">
          <div class="dibu_left">
            <div class="pinlun">
              <div class="pinglun_shuru">
                <h2>发表评论</h2>
                <div class="shuru">
                  <!-- <div class="tx">
                                    <img :src="myXinxi.pic" alt="">
                                </div> -->
                  <div class="kuang" style="width:100%">
                    <el-input type="textarea" placeholder="说两句吧" v-model="textarea" maxlength="2000" show-word-limit
                      resize="none">
                    </el-input>
                    <div class="anniu" @click="pinglunBtn(detail.id)">
                      <p>发布</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pl_list" v-if="pinglunList.length > 0">
                <h3>全部评论({{ pinglunList.length }})</h3>
                <div class="allList">
                  <div class="list1" v-for="(item, index) in pinglunList" :key="index">
                    <div class="list1_img">
                      <img :src="item.user.avatar" alt="">
                    </div>
                    <div class="list_xq">
                      <div class="xq_left">
                        <div class="left_top">
                          <p>{{ item.user.name }}</p>
                          <!-- <span>{{ item.time }}</span> -->
                        </div>
                        <div class="left_bot">
                          <p>{{ item.content }}</p>
                          <div class="button" @click="huifu()">回复</div>
                        </div>
                      </div>
                      <div class="xq_right" :class="{ active: item.isdian === true }">
                        <img src="../../img/tuijian_a.png" alt="" v-if="item.isdian">
                        <img src="../../img/tuijian.png" alt="" v-else>
                        <p>{{ item.support_count }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="dibu_right">
            <div class="right1">
              <h3>发布者</h3>
              <div class="right1_con">
                <div class="con1" @click="gstodetail(detail.user.id)">
                  <div class="con1_left">
                    <img v-if="detail.user.avatar != null" :src="detail.user.avatar[0]" alt="">
                    <div class="detail">
                      <p>{{ detail.user.name }} </p>
                      <span>{{ detail.user.intro }}</span>
                      <span>{{ detail.user.position }}</span>
                    </div>
                  </div>
                </div>
                <div class="con2">
                  <div class="con2_1">
                    <p>{{ detail.user.fans }}</p>
                    <span>粉丝</span>
                  </div>
                  <div class="con2_1">
                    <p>{{ detail.user.article_count }}</p>
                    <span>文章</span>
                  </div>
                  <div class="con2_1">
                    <p>{{ detail.user.case_count }}</p>
                    <span>案例</span>
                  </div>
                  <div class="con2_1">
                    <p>{{ detail.user.project_count }}</p>
                    <span>项目</span>
                  </div>
                </div>
                <!-- <div class="con2">
                                <div class="con2_1">
                                    <p>{{ detail.user.fans }}</p>
                                    <span>粉丝</span>
                                </div>
                                <div class="con2_1">
                                    <p>{{ detail.user.article_count }}</p>
                                    <span>文章</span>
                                </div>
                                <div class="con2_1">
                                    <p>{{ detail.user.case_count }}</p>
                                    <span>案例</span>
                                </div>
                                <div class="con2_1">
                                    <p>{{ detail.user.project_count }}</p>
                                    <span>项目</span>
                                </div>
                            </div> -->
                <!-- <div class="con2">
                                <div class="con2_1" v-if="!zuozhe.isguanzhu">
                                    <img src="../../img/duihao.png" alt="">
                                    <p>已关注</p>
                                </div>
                                <div class="con2_2" v-else>
                                    <span>+</span>
                                    <p>关注</p>
                                </div>
                                <div class="con2_3">
                                    <img src="../../img/email_1.png" alt="">
                                    <p>发信息</p>
                                </div>
                            </div> -->
              </div>
            </div>
            <!-- <div class="right1">
                        <h3>发布者</h3>
                        <div class="right1_con">
                            <div class="list">
                                <img src="../../img/jiang.png" alt="">
                            </div>
                            <div class="list">
                                <img src="../../img/jiang1.png" alt="">
                            </div>
                            <div class="list">
                                <img src="../../img/jiang2.png" alt="">
                            </div>
                            <div class="list">
                                <img src="../../img/jiang3.png" alt="">
                            </div>
                        </div>
                    </div> -->
          </div>
        </div>
      </div>

    </div>

    <div class="shoucangjia" v-if="toshowSS">
      <div class="scList">
        <div class="scList_top">
          <p>我的收藏夹</p>
          <span @click="cjscj" v-if="!isChuang">创建</span>
          <span @click="cjscj" v-else>取消</span>
        </div>
        <div class="cccc" v-if="isChuang">
          <input type="text" v-model="scName">
          <p @click="cjBtn">确定</p>
        </div>
        <ul v-if="CjList.length > 0">
          <li v-for="(item, index) in CjList" :key="index" @click="scBtn1(item.id)">
            <p>{{ item.name }}</p>
            <span @click.stop="deleteSC(item.id)">删除</span>
          </li>
        </ul>
        <div class="scKong" v-if="CjList.length == 0">
          <p>暂时没有收藏夹</p>
        </div>
        <div class="qxsc">
          <p @click="quxiaoSc">取消</p>
        </div>
      </div>
    </div>

    <div class="sqtanchuang" v-if="sqtan">
      <img src="../../img/chahao.png" alt="" @click="closesq()">
      <ul>
        <li v-for="(item, index) in roleList" :key="index">
          <p>{{ item.label }}</p>
          <span @click="renlingBtn(item.label, detail.id)">认领</span>
        </li>
      </ul>
    </div>


  </div>
</template>

<script>
// import headerbar from '../../components/headerbar.vue';
import { eventBus } from '../../App.vue';
import footerbar from '../../components/footerbar.vue';
export default {
  name: 'chuangzao',
  data() {
    return {
      pageTitle: '创造者',
      choose: 'guanyu',
      selectedNav: '创造者',
      address: '上海市',
      chuangli: '2010年创立',
      dingzhi: '定制版企业',
      huiyuan: '2010年会员',
      detail: '',
      detail2: '',
      id: '',
      allList: [],
      chengyuanList: [],

      shanchang: [
        '整合营销',
        '社会化运营',
        'TVC拍摄',
        '品牌推广',
        '标识',
        '用户体验',
        '多媒体',
      ],
      pinpaiList: [],
      cyList: [],
      projectList: "",
      gongzuoList: "",
      caseList: '',
      posterList: '',
      videoList: '',
      pinglunList: [],
      xqzhanshi: false,
      huodongShow: false,
      isScrollDisabled: false,
      textarea: '',


      toshowSS: false,
      roleList: '',
      isChuang: false,
      scName: '',
      CjList: '',

      sqtan: false,

    }
  },
  created() {
    document.title = this.pageTitle;
  },
  beforeRouteLeave(to, from, next) {
    document.body.classList.remove('disable-body-scroll');
    next();
  },
  components: {
    // headerbar,
    footerbar
  },
  mounted() {
    console.log(this.$route.query.id)
    this.id = this.$route.query.id
    this.getDetail()
    this.getcaseList()
    this.getArticleList()
    this.getProjectList()
    this.getPosterList()
    this.getvideoList()
    this.setActivePage()
    this.getRole()
    this.getSCList()
  },
  methods: {


    cjscj() {
      this.isChuang = !this.isChuang
    },
    //创建文件夹
    cjBtn() {
      if (this.scName == '') {
        this.$message.error('请输入文件夹名!');
        return
      }
      this.$axios.post('api/collection/create', {
        name: this.scName
      }, {
        headers: {
          'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
        }
      }).then((result) => {
        console.log(result)
        if (result.data.code == 200) {
          this.$message({
            message: '创建成功!',
            type: 'success'
          });
          this.getSCList();
          this.isChuang = false;
        } else {
          this.$message.error(result.data.message);
        }
      }).catch((err) => {
        console.log(err)
        if (err.response.data.code == 403) {
          setTimeout(() => {
            this.$router.push({
              name: 'login'
            })
          }, 1500);
        }
      });
    },

    //删除收藏夹
    deleteSC(e) {
      this.$axios.get('api/collection/delete/' + e, {
        headers: {
          'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
        }
      }).then((result) => {
        if (result.data.code == 200) {
          this.$message({
            message: '删除成功!',
            type: 'success'
          });
          this.getSCList();
        } else {
          this.$message.error(result.data.message);
        }
      }).catch((err) => {
        console.log(err)
        if (err.response.data.code == 403) {
          setTimeout(() => {
            this.$router.push({
              name: 'login'
            })
          }, 1500);
        }
      });
    },

    //获取收藏列表
    getSCList() {
      this.$axios.get('api/collection/my_collect', {
        headers: {
          'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
        }
      }).then((result) => {
        console.log(result)
        if (result.data.code == 200) {
          this.CjList = result.data.data
        } else {
          this.$message.error(result.data.message);
        }
      }).catch((err) => {
        console.log(err)
      });
    },

    //获取项目认领角色
    getRole() {
      this.$axios.get('api/select/project_role', {}).then((result) => {
        console.log(result)
        if (result.data.code == 200) {
          this.roleList = result.data.data
        } else {
          this.$message.error(result.data.message);
        }
      }).catch((err) => {
        console.log(err)
        this.$message.error(err.response.data.message);
      });
    },

    shenqing() {
      this.sqtan = true;
    },
    closesq() {
      this.sqtan = false;
    },


    //项目认领
    renlingBtn(e, d) {
      this.$axios.post('api/user/project_apply', {
        article_id: d,
        role: e,
      }, {
        headers: {
          'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
        }
      }).then((result) => {
        console.log(result)
        if (result.data.code == 200) {
          this.$message({
            message: result.data.message,
            type: 'success'
          });
        } else {
          this.$message.error(result.data.message);
        }
      }).catch((err) => {
        console.log(err)
        this.$message.error(err.response.data.message);
        if (err.response.data.code == 403) {
          setTimeout(() => {
            this.$router.push({
              name: 'login'
            })
          }, 1500);
        }
      });
    },



    setActivePage() {
      // 触发事件，传递当前页面的名称
      eventBus.$emit('updateActivePage', '创造者');
    },
    chooseBtn(e) {
      this.choose = e
    },

    anniuBtn(e) {
      this.$axios.get('/api/user/user_following/' + e, {
        headers: {
          'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
        }
      }).then((result) => {
        console.log(result)
        if (result.data.code == 200) {
          this.$message({
            message: result.data.message,
            type: 'success'
          });
          setTimeout(() => {
            this.getDetail()
          }, 1500);
        }
      }).catch((err) => {
        console.log(err)
      });
    },



    getDetail() {
      this.$axios.get('/api/user/user_info/' + this.id, {
        headers: {
          'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
        }
      }).then((result) => {
        console.log(result)
        if (result.data.code == 200) {
          this.detail2 = result.data.data
        } else {
          this.$message.error(result.data.message);
        }
      }).catch((err) => {
        console.log(err)
        this.$message.error(err.response.data.message);
        if (err.response.data.code == 500) {
          setTimeout(() => {
            this.$router.push({
              name: 'login'
            })
          }, 1500);
        }
        if (err.response.data.code == 403) {
          this.$message.error('请先登录在进行查看！');
          setTimeout(() => {
            this.$router.push({
              name: 'login'
            })
          }, 1500);
        }
      });
    },



    //获取案例列表
    getcaseList() {
      this.$axios.get('/api/__yao/table/pc.example/search', {
        headers: {
          'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
        }
      }, {
        "where.type.eq": 'case',
        "where.user_id.eq": this.id,
      }).then((result) => {
        console.log(result)
        if (result.data.code == 200) {
          this.caseList = result.data.data
        } else {
          this.$message.error(result.data.message);
        }
      }).catch((err) => {
        console.log(err)
      });
    },

    //获取文章列表
    getArticleList() {
      this.$axios.get('/api/__yao/table/pc.article/search', {
        headers: {
          'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
        }
      }, {
        "where.type.eq": 'article',
        "where.user_id.eq": this.id,
      }).then((result) => {
        console.log(result)
        if (result.data.code == 200) {
          this.allList = result.data.data
        } else {
          this.$message.error(result.data.message);
        }
      }).catch((err) => {
        console.log(err)
      });
    },

    //获取项目列表
    getProjectList() {
      this.$axios.get('/api/__yao/table/pc.project/search', {
        headers: {
          'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
        }
      }, {
        "where.type.eq": 'project',
        "where.user_id.eq": this.id,
      }).then((result) => {
        console.log(result)
        if (result.data.code == 200) {
          this.projectList = result.data.data
        } else {
          this.$message.error(result.data.message);
        }
      }).catch((err) => {
        console.log(err)
      });
    },

    //海报列表
    getPosterList() {
      this.$axios.get('/api/__yao/table/pc.project/search', {
        headers: {
          'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
        }
      }, {
        "where.type.eq": 'image',
        "where.user_id.eq": this.id,
      }).then((result) => {
        console.log(result)
        if (result.data.code == 200) {
          this.posterList = result.data.data
        } else {
          this.$message.error(result.data.message);
        }
      }).catch((err) => {
        console.log(err)
      });
    },

    //获取成员列表
    getChengyuanList() {
      this.$axios.get('/api/__yao/table/pc.company_user/search', {
        headers: {
          'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
        }
      }, {
        "where.company_id.eq": this.id
      }).then((result) => {
        console.log(result)
        if (result.data.code == 200) {
          this.chengyuanList = result.data.data
        } else {
          this.$message.error(result.data.message);
        }
      }).catch((err) => {
        console.log(err)
        if (err.response.data.code == 403) {
          this.$message.error('请先登录在进行查看！');
          setTimeout(() => {
            this.$router.push({
              name: 'login'
            })
          }, 1500);
        }
      });
    },

    //获取视频列表
    getvideoList() {
      this.$axios.get('/api/__yao/table/pc.project/search', {
        headers: {
          'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
        }
      }, {
        "where.type.eq": 'video',
        "where.user_id.eq": this.id,
      }).then((result) => {
        console.log(result)
        if (result.data.code == 200) {
          this.videoList = result.data.data
        } else {
          this.$message.error(result.data.message);
        }
      }).catch((err) => {
        console.log(err)
      });
    },



    //收藏
    scBtn() {
      console.log(1312312)
      this.toshowSS = !this.toshowSS
      // this.$axios.post('/api/user/collect/article', {
      //     id: e,
      //     type: 1,
      // }, {
      //     headers: {
      //         'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
      //     }
      // }).then((result) => {
      //     console.log(result)
      //     if (result.data.code == 200) {
      //         this.$message({
      //             message: '收藏成功！',
      //             type: 'success'
      //         });
      //         setTimeout(() => {
      //             this.getDetail(e)
      //         }, 500);
      //     } else {
      //         this.$message.error(result.data.message);
      //     }
      // }).catch((err) => {
      //     console.log(err)
      //     this.$message.error(err.response.data.message);
      //     if (err.response.data.code == 403) {
      //         setTimeout(() => {
      //             this.$router.push({
      //                 name: 'login'
      //             })
      //         }, 1500);
      //     }
      // });
    },
    scBtn1() {
      this.$axios.post('/api/user/collect/article', {
        id: this.detail.id,
        type: 1,
      }, {
        headers: {
          'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
        }
      }).then((result) => {
        console.log(result)
        if (result.data.code == 200) {
          this.$message({
            message: '收藏成功！',
            type: 'success'
          });
          setTimeout(() => {
            this.getDetail(this.detail.id)
            this.toshowSS = !this.toshowSS
          }, 500);
        } else {
          this.$message.error(result.data.message);
          this.toshowSS = !this.toshowSS
        }
      }).catch((err) => {
        console.log(err)
        this.$message.error(err.response.data.message);
        if (err.response.data.code == 403) {
          setTimeout(() => {
            this.$router.push({
              name: 'login'
            })
          }, 1500);
        }
      });
    },


    //点赞
    dzBtn(e) {
      this.$axios.post('/api/user/support/content', {
        id: e,
        type: 1,
      }, {
        headers: {
          'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
        }
      }).then((result) => {
        console.log(result)
        if (result.data.code == 200) {
          this.$message({
            message: '点赞成功！',
            type: 'success'
          });
          if (this.choose == 'zuopin') {
            setTimeout(() => {
              this.todetail(e)
            }, 500);
          }
          if (this.choose == 'case') {
            setTimeout(() => {
              this.todetail1(e)
            }, 500);
          }
          if (this.choose == 'neirong') {
            setTimeout(() => {
              this.toxiangmu(e)
            }, 500);
          }
          if (this.choose == 'video') {
            setTimeout(() => {
              this.todetail2(e)
            }, 500);
          }


        } else {
          this.$message.error(result.data.message);
        }
      }).catch((err) => {
        console.log(err)
        this.$message.error(err.response.data.message);
        if (err.response.data.code == 403) {
          setTimeout(() => {
            this.$router.push({
              name: 'login'
            })
          }, 1500);
        }
      });
    },

    //取消点赞
    qxdzBtn(e) {
      this.$axios.post('/api/user/unsupport/content', {
        id: e,
        type: 1,
      }, {
        headers: {
          'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
        }
      }).then((result) => {
        console.log(result)
        if (result.data.code == 200) {
          this.$message({
            message: '取消点赞！',
            type: 'success'
          });

          //重新获取详情接口
          if (this.choose == 'zuopin') {
            setTimeout(() => {
              this.todetail(e)
            }, 500);
          }
          if (this.choose == 'case') {
            setTimeout(() => {
              this.todetail1(e)
            }, 500);
          }
          if (this.choose == 'neirong') {
            setTimeout(() => {
              this.toxiangmu(e)
            }, 500);
          }
          if (this.choose == 'video') {
            setTimeout(() => {
              this.todetail2(e)
            }, 500);
          }

        } else {
          this.$message.error(result.data.message);
        }

      }).catch((err) => {
        console.log(err)
        this.$message.error(err.response.data.message);
        if (err.response.data.code == 403) {
          setTimeout(() => {
            this.$router.push({
              name: 'login'
            })
          }, 1500);
        }
      });
    },


    //跳转工作详情
    toJobDetail(e) {
      this.$router.push({
        name: 'jobDetail',
        query: {
          id: e
        }
      })
    },

    //跳转案例详情
    todetail1(e) {
      // this.$router.push({
      //   name: 'caseDetail',
      //   query: {
      //     id: e
      //   }
      // })

      document.body.classList.add('disable-body-scroll');
      this.xqzhanshi = true;

      this.$axios.get('api/pc/article/detail/' + e,).then((result) => {
        console.log(result)
        if (result.data.code == 200) {
          this.detail = result.data.data;
          this.pinglunList = result.data.data.comments
        } else {
          this.$message.error(result.data.message);
        }
      }).catch((err) => {
        console.log(err)
        this.$message.error(err.response.data.message);
        if (err.response.data.code == 403) {
          setTimeout(() => {
            this.$router.push({
              name: 'login'
            })
          }, 1500);
        }
      });


    },

    //项目详情
    // todetail2(e) {
    //   this.$router.push({
    //     name: 'projectDetail',
    //     query: {
    //       id: e
    //     }
    //   })
    // },
    //文章详情
    // todetail(e) {
    //   this.$router.push({
    //     name: 'articleDetail',
    //     query: {
    //       id: e
    //     }
    //   })
    // },


    quxiaoSc() {
      this.toshowSS = false;
    },
    closeBtn() {
      this.xqzhanshi = false;
      this.huodongShow = false;
      this.toshowSS = false;
      document.body.classList.remove('disable-body-scroll');
    },


    //海报和文章详情弹窗
    todetail(e) {
      // this.$router.push('/articleDetail')
      // this.$router.push({
      //   name: 'articleDetail',
      //   query: {
      //     id: e
      //   }
      // })
      document.body.classList.add('disable-body-scroll');
      this.xqzhanshi = true;
      if (window.localStorage.getItem('token')) {
        this.$axios.get('api/pc/article/detail/' + e, {
          headers: {
            'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
          }
        }).then((result) => {
          console.log(result)
          if (result.data.code == 200) {
            this.detail = result.data.data;
            this.pinglunList = result.data.data.comments
          } else {
            this.$message.error(result.data.message);
          }
        }).catch((err) => {
          console.log(err)
          this.$message.error(err.response.data.message);
          if (err.response.data.code == 403) {
            setTimeout(() => {
              this.$router.push({
                name: 'login'
              })
            }, 1500);
          }
        });
      } else {
        this.$axios.get('api/pc/article/detail/' + e,).then((result) => {
          console.log(result)
          if (result.data.code == 200) {
            this.detail = result.data.data;
            this.pinglunList = result.data.data.comments
          } else {
            this.$message.error(result.data.message);
          }
        }).catch((err) => {
          console.log(err)
          this.$message.error(err.response.data.message);
          if (err.response.data.code == 403) {
            setTimeout(() => {
              this.$router.push({
                name: 'login'
              })
            }, 1500);
          }
        });
      }






    },
    //海报和文章详情弹窗
    posterDetail(e) {
      // this.$router.push({
      //   name: 'posterDetail',
      //   query: {
      //     id: e
      //   }
      // })

      document.body.classList.add('disable-body-scroll');
      this.xqzhanshi = true;
      if (window.localStorage.getItem('token')) {
        this.$axios.get('api/pc/article/detail/' + e, {
          headers: {
            'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
          }
        }).then((result) => {
          console.log(result)
          if (result.data.code == 200) {
            this.detail = result.data.data;
            this.pinglunList = result.data.data.comments
          } else {
            this.$message.error(result.data.message);
          }
        }).catch((err) => {
          console.log(err)
          this.$message.error(err.response.data.message);
          if (err.response.data.code == 403) {
            setTimeout(() => {
              this.$router.push({
                name: 'login'
              })
            }, 1500);
          }
        });
      } else {
        this.$axios.get('api/pc/article/detail/' + e,).then((result) => {
          console.log(result)
          if (result.data.code == 200) {
            this.detail = result.data.data;
            this.pinglunList = result.data.data.comments
          } else {
            this.$message.error(result.data.message);
          }
        }).catch((err) => {
          console.log(err)
          this.$message.error(err.response.data.message);
          if (err.response.data.code == 403) {
            setTimeout(() => {
              this.$router.push({
                name: 'login'
              })
            }, 1500);
          }
        });
      }




    },
    todetail2(e) {
      // this.$router.push({
      //   name: 'videoDetail',
      //   query: {
      //     id: e
      //   }
      // })


      document.body.classList.add('disable-body-scroll');
      this.xqzhanshi = true;
      if (window.localStorage.getItem('token')) {
        this.$axios.get('api/pc/article/detail/' + e, {
          headers: {
            'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
          }
        }).then((result) => {
          console.log(result)
          if (result.data.code == 200) {
            this.detail = result.data.data;
            this.pinglunList = result.data.data.comments
          } else {
            this.$message.error(result.data.message);
          }
        }).catch((err) => {
          console.log(err)
          this.$message.error(err.response.data.message);
          if (err.response.data.code == 403) {
            setTimeout(() => {
              this.$router.push({
                name: 'login'
              })
            }, 1500);
          }
        });
      } else {
        this.$axios.get('api/pc/article/detail/' + e,).then((result) => {
          console.log(result)
          if (result.data.code == 200) {
            this.detail = result.data.data;
            this.pinglunList = result.data.data.comments
          } else {
            this.$message.error(result.data.message);
          }
        }).catch((err) => {
          console.log(err)
          this.$message.error(err.response.data.message);
          if (err.response.data.code == 403) {
            setTimeout(() => {
              this.$router.push({
                name: 'login'
              })
            }, 1500);
          }
        });
      }




    },

    gstodetail(e) {
      this.$router.push({
        name: 'chuangzao',
        query: {
          id: e
        }
      })
    },

    //评论
    pinglunBtn(e) {
      this.$axios.post('/api/user/comment/save', {
        type: 1,
        ext_id: e,
        content: this.textarea,
        comment_id: '',
      }, {
        headers: {
          'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
        }
      }).then((result) => {
        console.log(result)
        if (result.data.code == 200) {
          this.$message({
            message: result.data.message,
            type: 'success'
          });
          this.textarea = ""
        } else {
          this.$message.error(result.data.message);
        }
      }).catch((err) => {
        console.log(err)
        this.$message.error(err.response.data.message);
      });
    },

    //活动详情弹窗
    toxiangmu(e) {
      // this.$router.push({
      //   name: 'huodongDetail',
      //   query: {
      //     id: e
      //   }
      // })

      document.body.classList.add('disable-body-scroll');
      this.huodongShow = true;
      if (window.localStorage.getItem('token')) {
        this.$axios.get('/api/pc/article/detail/' + e, {
          headers: {
            'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
          }
        }).then((result) => {
          console.log(result)
          if (result.data.code == 200) {
            this.detail = result.data.data;
            this.pinglunList = result.data.data.comments
          }
        }).catch((err) => {
          console.log(err)
        });
      } else {
        this.$axios.get('/api/pc/article/detail/' + e, {}).then((result) => {
          console.log(result)
          if (result.data.code == 200) {
            this.detail = result.data.data;
            this.pinglunList = result.data.data.comments
          }
        }).catch((err) => {
          console.log(err)
        });
      }


    },


    niurentodetail(e) {
      this.$router.push({
        name: 'niurenDetail',
        query: {
          id: e
        }
      })
    },





  }
}
</script>

<style scoped lang="scss">
@import "../niurenDetail/chuangzao.scss"
</style>