import Vue from 'vue'
import VueRouter from "vue-router";
import index from "../pages/index/index.vue"
import faxian from "../pages/faxian/faxian.vue"
import project from "../pages/project/faxian.vue"
import caseList from "../pages/case/faxian.vue"
import findJob from "../pages/findJob/findJob.vue"
import huodong from "../pages/huodong/huodong.vue"
import chuangzao from "../pages/chuangzao/chuangzao.vue"
import pinpai from "../pages/pinpai/niuren.vue"
import poster from "../pages/poster/faxian.vue"
import video from "../pages/video/faxian.vue"
import chuangzao_p from "../pages/chuangzao_p/chuangzao.vue"
import articleDetail from "../pages/articleDetail/articleDetail.vue"
import posterDetail from "../pages/posterDetail/articleDetail.vue"
import videoDetail from "../pages/videoDetail/articleDetail.vue"
import projectDetail from "../pages/projectDetail/projectDetail.vue"
import caseDetail from "../pages/caseDetail/projectDetail.vue"
import huodongDetail from "../pages/huodongDetail/huodongDetail.vue"
import searchResult from "../pages/searchResult/searchResult.vue"
import login from "../pages/login/login.vue"
import changepwd from "../pages/login/changepwd.vue"
import zhuce from "../pages/zhuce/zhuce.vue"
import zhuce_qy from "../pages/zhuce/zhuce_qy.vue"
import follow from "../pages/follow/follow.vue"
import fans from "../pages/follow/fans.vue"
import person from "../pages/person/person.vue"
import niuren from "../pages/niuren/niuren.vue"
import niurenDetail from "../pages/niurenDetail/chuangzao.vue"
import FavoriteArticle from "../components/myFavorite/article.vue"
import FavoriteProject from "../components/myFavorite/project.vue"
import FavoriteJob from "../components/myFavorite/job.vue"
import MyFavorite from "../components/myFavorite/myFavorite.vue"
import myArticle from "../pages/myArticle/myArticle.vue"
import myProject from "../pages/myProject/myProject.vue"
import myVideo from "../pages/myVideo/myVideo.vue"
import myPoster from "../pages/myPoster/myPoster.vue"
import myCase from "../pages/myCase/myCase.vue"
import myJianli from "../pages/myJianli/myJianli.vue"
import mySetting from "../pages/mySetting/mySetting.vue"
import editJianli from "../pages/myJianli/editJianli.vue"
import jobDetail from "../pages/jobDetail/jobDetail.vue"
import fabu from "../pages/fabu/fabu.vue"

import store from '@/store/index'; // 导入存储 Token 的 Vuex 模块
Vue.use(VueRouter)





const router = new VueRouter({
  // mode: 'history',
  // base: process.env.BASE_URL,
  // // 解决跳页面跳转后 新页面没有位于顶部的问题
  scrollBehavior: () => ({
    y: 0
  }),
  routes: [{
      path: '/',
      redirect: '/index',
    },

    {
      path: '/index',
      name: 'index',
      component: index
    },
    {
      path: '/faxian',
      name: 'faxian',
      component: faxian,
    },
    {
      path: '/project',
      name: 'project',
      component: project,
    },
    {
      path: '/caseList',
      name: 'caseList',
      component: caseList,
    },
    {
      path: '/poster',
      name: 'poster',
      component: poster,
    },
    {
      path: '/video',
      name: 'video',
      component: video,
    },
    {
      path: '/fabu',
      name: 'fabu',
      component: fabu,
    },
    {
      path: '/findJob',
      name: 'findJob',
      component: findJob
    },
    {
      path: '/huodong',
      name: 'huodong',
      component: huodong
    },
    {
      path: '/pinpai',
      name: 'pinpai',
      component: pinpai
    },
    {
      path: '/niuren',
      name: 'niuren',
      component: niuren
    },
    {
      path: '/niurenDetail',
      name: 'niurenDetail',
      component: niurenDetail
    },
    {
      path: '/chuangzao',
      name: 'chuangzao',
      component: chuangzao
    },
    {
      path: '/chuangzao_p',
      name: 'chuangzao_p',
      component: chuangzao_p
    },
    {
      path: '/articleDetail',
      name: 'articleDetail',
      component: articleDetail
    },
    {
      path: '/posterDetail',
      name: 'posterDetail',
      component: posterDetail
    },
    {
      path: '/projectDetail',
      name: 'projectDetail',
      component: projectDetail
    },
    {
      path: '/caseDetail',
      name: 'caseDetail',
      component: caseDetail
    },
    {
      path: '/huodongDetail',
      name: 'huodongDetail',
      component: huodongDetail
    },
    {
      path: '/videoDetail',
      name: 'videoDetail',
      component: videoDetail
    },
    {
      path: '/searchResult',
      name: 'searchResult',
      component: searchResult
    },
    {
      path: '/login',
      name: 'login',
      component: login
    },
    {
      path: '/changepwd',
      name: 'changepwd',
      component: changepwd
    },
    {
      path: '/zhuce',
      name: 'zhuce',
      component: zhuce
    },
    {
      path: '/zhuce_qy',
      name: 'zhuce_qy',
      component: zhuce_qy
    },
    {
      path: '/jobDetail',
      name: 'jobDetail',
      component: jobDetail
    },
    {
      path: '/person',
      name: 'person',
      component: person,
      redirect: 'FavoriteArticle',
      children: [{
          path: '/MyFavorite',
          name: 'MyFavorite', // 将name属性移到MyFavorite路由配置中
          component: MyFavorite,
          redirect: '/FavoriteArticle',
          children: [{
              path: '/FavoriteArticle', // 修改为相对路径
              component: FavoriteArticle // 将FavoriteArticle作为默认子路由
            },
            {
              path: '/FavoriteProject',
              name: 'FavoriteProject',
              component: FavoriteProject
            },
            {
              path: '/FavoriteJob',
              name: 'FavoriteJob',
              component: FavoriteJob
            }
          ]
        }, {
          path: '/myArticle',
          name: 'myArticle', // 将name属性移到MyFavorite路由配置中
          component: myArticle,
        },
        {
          path: '/myProject',
          name: 'myProject', // 将name属性移到MyFavorite路由配置中
          component: myProject,
        },
        {
          path: '/myVideo',
          name: 'myVideo', // 将name属性移到MyFavorite路由配置中
          component: myVideo,
        },
        {
          path: '/myPoster',
          name: 'myPoster', // 将name属性移到MyFavorite路由配置中
          component: myPoster,
        },

        {
          path: '/myCase',
          name: 'myCase', // 将name属性移到MyFavorite路由配置中
          component: myCase,
        },
        {
          path: '/myJianli',
          name: 'myJianli', // 将name属性移到MyFavorite路由配置中
          component: myJianli,
        },
        {
          path: '/editJianli',
          name: 'editJianli', // 将name属性移到MyFavorite路由配置中
          component: editJianli,
        },
        {
          path: '/mySetting',
          name: 'mySetting', // 将name属性移到MyFavorite路由配置中
          component: mySetting,
        },
        {
          path: '/follow',
          name: 'follow', // 将name属性移到MyFavorite路由配置中
          component: follow,
        },
        {
          path: '/fans',
          name: 'fans', // 将name属性移到MyFavorite路由配置中
          component: fans,
        },
      ]
    }



  ]
})


router.beforeEach((to, from, next) => {
  const token = store.state.token;

  if (to.meta.requiresAuth && !token) {
    // 需要登录但没有 Token，重定向到登录页面
    next({ name: 'login' });
  } else {
    // 其他情况继续导航
    next();
  }
});

export default router



