<template>
    <div class="index">
        <!-- <headerbar></headerbar> -->
        <div class="index">
            <div class="login">
                <div class="img">
                    <img src="../../img/logo1.png" alt="">
                </div>
                <div class="loginCon">
                    <div class="xuanze">
                        <div class="xuanze1">
                            <p>修改密码</p>
                        </div>
                        <!-- <div class="xuanze2" @click="zhuce">
                            <p>新注册</p>
                        </div> -->
                    </div>
                    <div class="shuru">
                        <div class="shuru1">
                            <input type="password" placeholder="旧密码" v-model="password">
                        </div>
                        <div class="shuru1">
                            <input type="password" placeholder="新密码" v-model="new_password">
                        </div>
                        <div class="shuru1">
                            <input type="password" placeholder="再次输入密码" v-model="password2nd">
                        </div>
                        <div class="shuru4">
                            <input type="text" placeholder="验证码" v-model="code">
                            <div class="anniu" @click="getcode">
                                <img :src="imageData" alt="Image">
                            </div>
                        </div>
                        <!-- <div class="shuru2">
                            <p>忘记密码?</p>
                        </div> -->
                        <div class="shuru3" @click="change">
                            <p>确认</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <footerbar></footerbar>
    </div>
</template>

<script>
// import headerbar from '../../components/headerbar.vue';
import { eventBus } from '../../App.vue';
import footerbar from '../../components/footerbar.vue';
export default {
    name: 'login',
    data() {
        return {
            pageTitle: '修改密码',
            mobile:'',
            password:'',
            new_password:'',
            password2nd:'',
            imageData:'',
            id:'',
            code:'',
        }
    },
    created() {
        document.title = this.pageTitle;
    },
    components: {
        // headerbar,
        footerbar
    },
    mounted(){
        this.getcode()
        this.setActivePage()
    },
    methods: {
        setActivePage() {
            // 触发事件，传递当前页面的名称
            eventBus.$emit('updateActivePage', '');
        },
        //获取验证码
        getcode() {
            this.$axios.get('/api/__yao/login/admin/captcha', {
                type: 'digit'
            }).then((result) => {
                console.log(result)
                this.imageData = result.data.content;
                this.id = result.data.id
            }).catch((err) => {
                console.log(err)
            });
        },
        change(){
            if(this.password == ""){
                this.$message.error('请输入旧密码！');
                return
            }
            if(this.new_password == ""){
                this.$message.error('请输入新密码！');
                return
            }
            if(this.password2nd == ""){
                this.$message.error('请再次输入新密码！');
                return
            }
            if(this.password2nd != this.new_password ){
                this.$message.error('新密码两次输入不一致！');
                return
            }

            this.$axios.post('/api/user/edit_pass',{
                password:this.password,
                new_password:this.new_password,
                password2nd:this.password2nd,
                captcha: {
                    code: this.code,
                    id: this.id
                },
            }, {
                headers: {
                    'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
                }
            }).then((result) => {
                console.log(result)
                if (result.data.code == 200) {
                    this.$message({
                        message: result.data.message,
                        type: 'success'
                    });
                    window.localStorage.clear();
                    setTimeout(() => {
                        this.$router.push({
                            name:'login'
                        })
                    }, 2000);
                }else{
                    this.$message.error(result.data.message);
                    this.getcode()
                }
            }).catch((err) => {
                console.log(err)
                this.$message.error(err.response.data.message);
                this.getcode()
            });

            // this.$router.push({
            //     name: 'person',
            // })
        },
        zhuce() {
            this.$router.push({
                name: 'zhuce',
                // query: {
                //     id: '123'
                // }
            })
        },
    }
}
</script>

<style scoped lang="scss">
@import '../login/login.scss'
</style>