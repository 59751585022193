<template>
    <div class="header">
        <div class="logo">
            <router-link to="/">
                <img src="../img/logo.png" alt="">
            </router-link>
        </div>
        <div class="nav">
            <ul>
                <li>
                    <router-link to="/" :class="{ active: selectedNav === 'index' }">首页</router-link>
                </li>
                <li v-for="(item, index) in navList" :key="index">
                    <router-link :to="getLink(item.name)" :class="{ active: selectedNav === item.name }">{{ item.name
                    }}</router-link>
                </li>
                <!-- <li>
                    <router-link to="/zuopin" :class="{ active: selectedNav === 'zuopin' }">作品</router-link>
                </li>
                <li>
                    <router-link to="/faxian" :class="{ active: selectedNav === 'faxian' }">发现</router-link>
                </li>
                <li>
                    <router-link to="/findJob" :class="{ active: selectedNav === 'gongzuo' }">找工作</router-link>
                </li>
                <li>
                    <router-link to="/huodong" :class="{ active: selectedNav === 'huodong' }">活动</router-link>
                </li>
                <li>
                    <router-link to="/" :class="{ active: selectedNav === 'zhuanlan' }">专栏</router-link>
                </li>
                <li>
                    <router-link to="/chuangzao" :class="{ active: selectedNav === 'chuangzao' }">创作者</router-link>
                </li> -->
            </ul>
        </div>
        <div class="search">
            <img src="../img/search.png" alt="">
            <el-input placeholder="搜索..." v-model="keywords" clearable @change="clickBtn">
            </el-input>
        </div>
        <div class="person" v-if="token != null">
            <!-- <div class="email">
                <img src="../img/email.png" alt="">
            </div> -->
            <div class="fenxiang">
                <div class="fenxiang_ccc">
                    <p>分享您的作品</p>
                </div>

                <div class="fx_list">
                    <ul>
                        <li class="fabu" @click="fabu('article')">
                            <p>发布文章</p>
                        </li>
                        <li class="fabu" @click="fabu('project')">
                            <p>发布项目</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="geren">
                <img v-if="userinfo.avatar != 'null'" :src="userinfo.avatar" alt="" @click="toperson">
                <img v-else src="../img/tx.jpg" alt="" @click="toperson">

                <p>{{ userinfo.name }}</p>
                <div class="subnav">
                    <!-- <div class="fabu" @click="fabu('article')">
                        <p>发布文章</p>
                    </div>
                    <div class="fabu" @click="fabu('image')">
                        <p>发布海报</p>
                    </div>
                    <div class="fabu" @click="fabu('project')">
                        <p>发布项目</p>
                    </div>
                    <div class="fabu" @click="fabu('video')">
                        <p>发布视频</p>
                    </div> -->
                    <div class="fabu" @click="xiugai">
                        <p>修改密码</p>
                    </div>
                    <div class="tuichu" @click="tuichu">
                        <p>退出</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="person" v-else>
            <div class="denglu" @click="tologin">
                <p>登录</p>
            </div>
            <div class="denglu zhuce" @click="tozhuce">
                <p>注册</p>
            </div>
        </div>

        <div class="caidan" @click="showNavBtn">
            <span></span>
            <span></span>
            <span></span>
        </div>

        <div class="xiala" v-if="shownav">
            <div class="search">
                <img src="../img/search.png" alt="">
                <el-input placeholder="搜索..." v-model="keywords" clearable @change="clickBtn">
                </el-input>
            </div>
            <div class="denglu">
                <div class="person" v-if="token != null">
                    <!-- <div class="email">
                <img src="../img/email.png" alt="">
            </div> -->
                    <div class="fenxiang">
                        <div class="fenxiang_ccc">
                            <p>分享您的作品</p>
                        </div>
                        <div class="fx_list">
                            <ul>
                                <li class="fabu" @click="fabu('article')">
                                    <p>发布文章</p>
                                </li>
                                <li class="fabu" @click="fabu('project')">
                                    <p>发布项目</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="geren">
                        <img v-if="userinfo.avatar != 'null'" :src="userinfo.avatar" alt="" @click="toperson">
                        <img v-else src="../img/tx.jpg" alt="" @click="toperson">
                        <p>{{ userinfo.name }}</p>
                        <div class="subnav">
                            <div class="fabu" @click="fabu('article')">
                                <p>发布文章</p>
                            </div>
                            <div class="fabu" @click="fabu('image')">
                                <p>发布海报</p>
                            </div>
                            <div class="fabu" @click="fabu('project')">
                                <p>发布项目</p>
                            </div>
                            <div class="fabu" @click="fabu('video')">
                                <p>发布视频</p>
                            </div>
                            <div class="fabu" @click="xiugai">
                                <p>修改密码</p>
                            </div>
                            <div class="tuichu" @click="tuichu">
                                <p>退出</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="person" v-else>
                    <div class="denglu" @click="tologin">
                        <p>登录</p>
                    </div>
                    <div class="denglu zhuce" @click="tozhuce">
                        <p>注册</p>
                    </div>
                </div>
            </div>
            <div class="nav">
                <ul>
                    <li>
                        <router-link to="/" :class="{ active: selectedNav === 'index' }">首页</router-link>
                    </li>
                    <li v-for="(item, index) in navList" :key="index">
                        <router-link :to="getLink(item.name)" :class="{ active: selectedNav === item.name }">{{ item.name
                        }}</router-link>
                    </li>
                    <!-- <li>
                    <router-link to="/zuopin" :class="{ active: selectedNav === 'zuopin' }">作品</router-link>
                </li>
                <li>
                    <router-link to="/faxian" :class="{ active: selectedNav === 'faxian' }">发现</router-link>
                </li>
                <li>
                    <router-link to="/findJob" :class="{ active: selectedNav === 'gongzuo' }">找工作</router-link>
                </li>
                <li>
                    <router-link to="/huodong" :class="{ active: selectedNav === 'huodong' }">活动</router-link>
                </li>
                <li>
                    <router-link to="/" :class="{ active: selectedNav === 'zhuanlan' }">专栏</router-link>
                </li>
                <li>
                    <router-link to="/chuangzao" :class="{ active: selectedNav === 'chuangzao' }">创作者</router-link>
                </li> -->
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import { eventBus } from '../App.vue';
export default {
    name: 'headerbar',
    // props: {
    //     selectedNav: String,
    // },
    data() {
        return {
            token: window.localStorage.getItem('token'),
            islogin: false,
            keywords: '',
            navList: [],
            linkMapping: {},
            userinfo: '',
            shownav: false,
            selectedNav: '',
            shareUrl: location.href,
        }
    },
    created() {
        // 监听事件，当事件触发时更新activePage状态
        eventBus.$on('updateActivePage', (page) => {
            this.selectedNav = page;
        });

        eventBus.$on('shownav', (page) => {
            console.log(page)
            this.shownav = false
        });


    },
    // computed: {
    //     token() {
    //         return window.localStorage.getItem('token'); // 或者 this.token，根据实际情况调整
    //     }
    // },
    mounted() {
        console.log(this.token)
        this.getinfo()
        setTimeout(() => {
            eventBus.$on('showtoken', (page) => {
                console.log(page)
                this.token = page             
                this.getinfo()
            });
        }, 200);

        eventBus.$on('clearToken', () => {
            console.log('清除token的方法触发')
            this.token = null
        });

        // if (this.token != null) {

        // }
        this.getnav()
        // 初始化计时器，每隔30分钟执行一次请求
        // this.startTimer();
    },
    beforeDestroy() {
        // 在组件销毁前清除计时器，以避免内存泄漏
        clearInterval(this.timer);
    },
    methods: {


        // startTimer() {
        // // 定义请求函数
        // const requestData = () => {
        //     this.getinfo()
        // };

        // // 每隔30分钟（1800000毫秒）执行请求
        // this.timer = setInterval(requestData, 3600000);
        // },


        /**
       * 分享到微博
       */
        shareToMicroblog() {
            //跳转地址
            window.open(
                "https://service.weibo.com/share/share.php?url=" +
                encodeURIComponent(this.shareUrl) +
                "&title=" +
                this.sysInfo
            );
        },
        /**
         * 分享到qq空间
         */
        shareToQQRom() {
            //跳转地址
            window.open(
                "https://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url=" +
                encodeURIComponent(this.shareUrl) +
                "&title=" +
                this.sysInfo +
                "&summary=" +
                this.sysInfo
            );
        },





        showNavBtn() {
            this.shownav = !this.shownav
        },
        fabu(e) {
            this.$router.push({
                name: 'fabu',
                query: {
                    type: e
                }
            })
        },
        xiugai() {
            this.$router.push({
                name: 'changepwd',
                // query: {
                //     type: e
                // }
            })
        },
        getinfo() {
            this.$axios.get('/api/user/info', {
                headers: {
                    'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
                }
            }).then((result) => {
                console.log(result)
                if (result.data.code == 200) {
                    this.userinfo = result.data.data
                } else {
                    this.$message.error(result.data.message);
                }
            }).catch((err) => {
                console.log(err)
                window.localStorage.clear()
                // this.$message.error(err.response.data.message);
                this.token = null;
                // if (err.response.data.code == 403) {                   
                //     setTimeout(() => {
                //         this.$router.push({
                //             name: 'login'
                //         })
                //     }, 1500);

                // }
            });
        },
        tuichu() {
            console.log(123)
            window.localStorage.clear();
            window.location.reload()
        },
        clickBtn() {
            console.log(this.keywords)
            this.$router.push({
                name: 'searchResult',
                query: {
                    keywords: this.keywords
                }
            })
        },
        tologin() {
            this.$router.push({
                name: 'login',
                // query: {
                //     id: '123'
                // }
            })
        },
        tozhuce() {
            this.$router.push({
                name: 'zhuce',
                // query: {
                //     id: '123'
                // }
            })
        },
        toperson() {
            this.$router.push({
                name: 'person'
            })
        },

        // 获取导航栏
        getnav() {
            this.$axios.get('/api/pc/nav', {}).then((result) => {
                console.log(result)
                if (result.data.code == 200) {
                    this.navList = result.data.data;
                    this.linkMapping = {
                        '发现': '/faxian',
                        '作品': '/project',
                        '活动': '/huodong',
                        '找工作': '/findJob',
                        '创造者': '/niuren',
                        '专栏': '/pinpai',
                        '海报': '/poster',
                        '视频': '/video',
                        '案例': '/caselist',
                    }
                } else {
                    this.$message.error(result.data.message);
                }
            }).catch((err) => {
                console.log(err)
                this.$message.error(err.response.data.message);
            });
        },

        getLink(name) {
            return this.linkMapping[name];
        }
    }

}
</script>

<style scoped lang="scss">
.header {
    position: relative;
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 0 30px;
    background: #fff;
    border-bottom: 1px solid #ccc;

    .logo {
        margin-right: 80px;
        width: 180px;
        height: 35px;

        img {
            width: 100%;
        }
    }

    .nav {
        width: 50%;
        height: auto;

        ul {
            width: 100%;
            height: auto;
            display: flex;
            align-items: center;

            li {
                margin-right: 40px;
                width: auto;
                height: auto;
                display: flex;
                align-items: center;
                justify-items: center;

                a {
                    font-size: 16px;
                    color: #212121;

                    &.active {
                        color: #ff6600;
                    }

                    &:hover {
                        color: #ff6600;
                    }
                }
            }
        }
    }

    .search {
        width: 300px;
        height: 40px;
        display: flex;
        align-items: center;
        border-radius: 45px;
        background: #f0f0f0;
        box-sizing: border-box;
        padding: 0 20px;

        img {
            margin-right: 15px;
            width: 17px;
            height: auto;
        }

        .el-input__inner {
            background: none;
        }

        input {
            width: 88%;
            background: none;
            border: none;
            outline: none;
            font-size: 15px;
        }
    }

    .person {
        display: flex;
        align-items: center;
        margin-left: 3%;
        width: 15%;
        display: flex;
        justify-content: flex-end;

        .email {
            margin-right: 15px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            border: 1px solid #212121;
            border-radius: 7px;

            img {
                width: 25px;
                height: auto;
            }
        }

        .fenxiang {
            position: relative;
            margin-right: 15px;

            .fenxiang_ccc {
                width: 120px;
                height: 40px;
                border-radius: 7px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                border: 1px solid rgba($color: #000000, $alpha: 0.3);
                cursor: pointer;
            }

            &:hover {
                .fx_list {
                    display: block;
                }
            }

            .fx_list {
                display: none;
                position: absolute;
                top: 40px;
                left: 0;
                width: 100%;
                height: auto;
                z-index: 999;
                background: #fff;
                border: 1px solid rgba($color: #000000, $alpha: 0.3);
                border-radius: 5px;

                ul {
                    width: 100%;
                    height: auto;

                    li {
                        width: 100%;
                        height: 35px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;

                        &:hover {
                            background: #f1f1f1;
                        }
                    }
                }
            }

            p {
                font-size: 15px;
            }
        }

        .geren {
            position: relative;
            display: flex;
            align-items: center;
            width: 150px;
            height: 40px;
            // overflow: hidden;
            cursor: pointer;

            &:hover {
                .subnav {
                    display: block;
                }
            }

            img {
                margin-right: 15px;
                width: 40px;
                height: 40px;
                object-fit: cover;
                border-radius: 50%;
                border: 1px solid #212121;
            }

            p {
                font-size: 16px;
                color: #212121;
                font-weight: bold;
            }

            .subnav {
                position: absolute;
                top: 100%;
                left: 0;
                z-index: 9999;
                width: 150px;
                height: auto;
                display: none;
                background: #fff;
                border: 1px #ccc solid;
                border-radius: 5px;

                .fabu {
                    width: 100%;
                    height: 45px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;

                    &:hover {
                        background: #f1f1f1;
                    }

                    p {
                        font-size: 15px;
                        color: #666;
                    }
                }

                .tuichu {
                    width: 100%;
                    height: 45px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;

                    &:hover {
                        background: #f1f1f1;
                    }

                    p {
                        background: none;
                        border: none;
                        font-size: 15px;
                        color: #ff6600;
                        outline: none;
                    }
                }
            }
        }



        .denglu {
            margin: 0 10px;
            width: 80px;
            height: 30px;
            border: 1px solid #666;
            border-radius: 5px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            p {
                font-size: 16px;
                color: #666;
            }
        }

        .zhuce {
            border: 1px solid #ff6600;

            p {
                color: #ff6600;
            }
        }
    }

    .caidan {
        display: none;
        width: 40px;
        height: 25px;
        cursor: pointer;

        span {
            display: block;
            width: 100%;
            height: 1px;
            background: #212121;
        }
    }

    .xiala {
        position: absolute;
        top: 80px;
        left: 0;
        width: 100%;
        height: auto;
        z-index: 9999;
        box-sizing: border-box;
        padding: 20px;
        background: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-shadow: 0 0 5px #ccc;

        .nav {
            display: block;
            width: 100%;
            height: auto;

            ul {
                width: 100%;
                height: auto;
                display: flex;
                align-items: center;
                flex-direction: column;

                li {
                    width: 100%;
                    height: 35px;
                    display: flex;
                    align-items: center;
                    justify-items: center;
                    justify-content: center;
                    border-bottom: 1px solid #f1f1f1;

                    a {
                        font-size: 16px;
                        color: #212121;

                        &.active {
                            color: #ff6600;
                        }

                        &:hover {
                            color: #ff6600;
                        }
                    }
                }
            }
        }

        .search {
            width: 300px;
            height: 40px;
            display: flex !important;
            align-items: center;
            border-radius: 45px;
            background: #f0f0f0;
            box-sizing: border-box;
            padding: 0 20px;

            img {
                margin-right: 15px;
                width: 17px;
                height: auto;
            }

            .el-input__inner {
                background: none;
            }

            input {
                width: 88%;
                background: none;
                border: none;
                outline: none;
                font-size: 15px;
            }
        }

        .denglu {
            margin: 15px 0;
            width: 100%;
            height: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .person {
            display: flex !important;
            align-items: center;
            margin-left: 0%;
            width: 300px;
            display: flex;
            justify-content: center;

            .email {
                margin-right: 15px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 40px;
                height: 40px;
                border: 1px solid #212121;
                border-radius: 7px;

                img {
                    width: 25px;
                    height: auto;
                }
            }

            .fenxiang {
                margin-right: 15px;
                width: 120px;
                height: 40px;
                border-radius: 7px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                border: 1px solid #212121;

                p {
                    font-size: 15px;
                }
            }

            .geren {
                position: relative;
                display: flex;
                align-items: center;
                width: 150px;
                height: 40px;
                // overflow: hidden;
                cursor: pointer;

                &:hover {
                    .subnav {
                        display: block;
                    }
                }

                img {
                    margin-right: 15px;
                    width: 40px;
                    height: 40px;
                    object-fit: cover;
                    border-radius: 50%;
                    border: 1px solid #212121;
                }

                p {
                    font-size: 16px;
                    color: #212121;
                    font-weight: bold;
                }

                .subnav {
                    position: absolute;
                    top: 100%;
                    left: 0;
                    z-index: 9999;
                    width: 150px;
                    height: auto;
                    display: none;
                    background: #fff;
                    border: 1px #ccc solid;

                    .fabu {
                        width: 100%;
                        height: 45px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;

                        &:hover {
                            background: #f1f1f1;
                        }

                        p {
                            font-size: 15px;
                            color: #666;
                        }
                    }

                    .tuichu {
                        width: 100%;
                        height: 45px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;

                        &:hover {
                            background: #f1f1f1;
                        }

                        p {
                            background: none;
                            border: none;
                            font-size: 15px;
                            color: #ff6600;
                            outline: none;
                        }
                    }
                }
            }



            .denglu {
                margin: 0 10px;
                width: 100px;
                height: 40px;
                border: 1px solid #666;
                border-radius: 10px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                p {
                    font-size: 16px;
                    color: #666;
                }
            }

            .zhuce {
                border: 1px solid #ff6600;

                p {
                    color: #ff6600;
                }
            }
        }
    }
}
</style>

<style>
.search .el-input__inner {
    background: none;
    border: none;
}
</style>