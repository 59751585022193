<template>
    <div class="index">
        <!-- <headerbar :selected-nav="selectedNav"></headerbar> -->
        <div class="index">
            <div class="jobTop">
                <div class="jobTop_left">
                    <!-- <div class="left_nav">
              <img src="../../img/job2.png" alt="">
              <p>UGEE</p>
            </div> -->
                    <div class="miaoshu">
                        <p>我们聚在一起是为了营销传播行业传递出更有价值的内容，我们用我们的专业为行业服务、为品牌服务、为普通用户服务。</p>
                    </div>

                    <!-- <div class="anniu">
              <div class="anniu2">
                <img src="../../img/duihao.png" alt="">
                <span>已关注</span>
              </div>
              <div class="anniu1">
                <img src="../../img/email_1.png" alt="">
                <p>雇佣我们</p>
              </div>
            </div> -->

                </div>
                <div class="jobTop_right">
                    <img src="../../img/chuangzaozhe.png" alt="">
                </div>
            </div>
            <div class="huodong">
                <div class="liebiao_top">
                    <div class="top_left">
                        <div class="right1">
                            <el-select v-model="value1" placeholder="请选择" @change="shaixuan1">
                                <el-option v-for="item in options1" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </div>
                        <!-- <div class="choose" :class="{ active: choose === 'zuopin' }" @click="chooseBtn('zuopin')">
                <p>作品560</p>
              </div>
              <div class="choose" :class="{ active: choose === 'neirong' }" @click="chooseBtn('neirong')">
                <p>内容236</p>
              </div>
              <div class="choose" :class="{ active: choose === 'chengyuan' }" @click="chooseBtn('chengyuan')">
                <p>成员46</p>
              </div>
              <div class="choose" :class="{ active: choose === 'zhaopin' }" @click="chooseBtn('zhaopin')">
                <p>招聘8</p>
              </div>
              <div class="choose" :class="{ active: choose === 'guanyu' }" @click="chooseBtn('guanyu')">
                <p>关于</p>
              </div> -->
                    </div>
                </div>
                <div class="liebiao_con">
                    <div class="allList chengyuan">
                        <div class="list1" v-for="(item, index) in chengyuanList" :key="index" @click="todetail(item.id)">
                            <div class="cy_top">
                                <div class="cy_1">
                                    <img v-if="item.avatar" :src="item.avatar[0]" alt="">
                                    <div class="detail">
                                        <p>{{ item.name }}</p>
                                        <span>{{ item.position }}</span>
                                    </div>
                                </div>
                                <div class="cy_2">
                                    <div class="guanzhu" v-if="item.is_following" @click.stop="qxguanzhu(item.id)">
                                        <img src="../../img/duihao.png" alt="">
                                        <p>已关注</p>
                                    </div>

                                    <div class="weiguanzhu" v-else @click.stop="addguanzhu(item.id)">
                                        <i> + </i>
                                        <p>关注</p>
                                    </div>
                                </div>
                            </div>
                            <div class="cy_bot">
                                <div class="bot1" v-for="(tt, index) in item.article" :key="index"
                                    @click.stop="wzDetail(tt.id)">
                                    <img v-if="tt.cover.length > 0" :src="tt.cover[0]" alt="">
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <footerbar></footerbar>
    </div>
</template>
  
<script>
// import headerbar from '../../components/headerbar.vue';
import { eventBus } from '../../App.vue';
import footerbar from '../../components/footerbar.vue';
export default {
    name: 'chuangzao',
    data() {
        return {
            pageTitle: '创造者',
            choose: 'zuopin',
            selectedNav: '创造者',
            address: '上海市',
            chuangli: '2010年创立',
            dingzhi: '定制版企业',
            huiyuan: '2010年会员',
            allList: [
                {
                    img: require('@/img/cz1.jpg'),
                    tag: '文章',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
                    date: '2023-02-16',
                    dianzan: '125',
                    shoucang: '7895',
                    isdianzan: false,
                    author: {
                        name: '用户123',
                        tx: require('@/img/tx.jpg'),
                    },
                },


                {
                    img: require('@/img/cz2.jpg'),
                    tag: '文章',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
                    date: '2023-02-16',
                    dianzan: '125',
                    shoucang: '7895',
                    isdianzan: false,
                    author: {
                        name: '用户123',
                        tx: require('@/img/tx.jpg'),
                    },
                },

                {
                    img: require('@/img/cz3.jpg'),
                    tag: '文章',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
                    date: '2023-02-16',
                    dianzan: '125',
                    isdianzan: false,
                    shoucang: '7895',
                    author: {
                        name: '用户123',
                        tx: require('@/img/tx.jpg'),
                    },
                },

                {
                    img: require('@/img/cz4.jpg'),
                    tag: '文章',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
                    date: '2023-02-16',
                    dianzan: '125',
                    shoucang: '7895',
                    isdianzan: false,
                    author: {
                        name: '用户123',
                        tx: require('@/img/tx.jpg'),
                    },
                },

                {
                    img: require('@/img/cz5.jpg'),
                    tag: '文章',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
                    date: '2023-02-16',
                    dianzan: '125',
                    shoucang: '7895',
                    isdianzan: false,
                    author: {
                        name: '用户123',
                        tx: require('@/img/tx.jpg'),
                    },
                },


                {
                    img: require('@/img/cz6.jpg'),
                    tag: '文章',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
                    date: '2023-02-16',
                    dianzan: '125',
                    shoucang: '7895',
                    isdianzan: false,
                    author: {
                        name: '用户123',
                        tx: require('@/img/tx.jpg'),
                    },
                },
                {
                    img: require('@/img/cz1.jpg'),
                    tag: '文章',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
                    date: '2023-02-16',
                    dianzan: '125',
                    shoucang: '7895',
                    isdianzan: false,
                    author: {
                        name: '用户123',
                        tx: require('@/img/tx.jpg'),
                    },
                },


                {
                    img: require('@/img/cz2.jpg'),
                    tag: '文章',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
                    date: '2023-02-16',
                    dianzan: '125',
                    shoucang: '7895',
                    isdianzan: false,
                    author: {
                        name: '用户123',
                        tx: require('@/img/tx.jpg'),
                    },
                },

                {
                    img: require('@/img/cz3.jpg'),
                    tag: '文章',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
                    date: '2023-02-16',
                    dianzan: '125',
                    isdianzan: false,
                    shoucang: '7895',
                    author: {
                        name: '用户123',
                        tx: require('@/img/tx.jpg'),
                    },
                },

                {
                    img: require('@/img/cz4.jpg'),
                    tag: '文章',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
                    date: '2023-02-16',
                    dianzan: '125',
                    shoucang: '7895',
                    isdianzan: false,
                    author: {
                        name: '用户123',
                        tx: require('@/img/tx.jpg'),
                    },
                },

                {
                    img: require('@/img/cz5.jpg'),
                    tag: '文章',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
                    date: '2023-02-16',
                    dianzan: '125',
                    shoucang: '7895',
                    isdianzan: false,
                    author: {
                        name: '用户123',
                        tx: require('@/img/tx.jpg'),
                    },
                },


                {
                    img: require('@/img/cz6.jpg'),
                    tag: '文章',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
                    date: '2023-02-16',
                    dianzan: '125',
                    shoucang: '7895',
                    isdianzan: false,
                    author: {
                        name: '用户123',
                        tx: require('@/img/tx.jpg'),
                    },
                },



            ],
            chengyuanList: [
                {
                    author: {
                        name: '用户123',
                        tx: require('@/img/tx.jpg'),
                        address: '北京市',
                    },
                    isguanzhu: true,
                    list: [
                        {
                            pic: require('@/img/cz1.jpg'),
                        },
                        {
                            pic: require('@/img/cz2.jpg'),
                        },
                        {
                            pic: require('@/img/cz3.jpg'),
                        },
                        {
                            pic: require('@/img/cz4.jpg'),
                        },
                    ],
                },

                {
                    author: {
                        name: '用户123',
                        tx: require('@/img/tx.jpg'),
                        address: '北京市',
                    },
                    isguanzhu: false,
                    list: [
                        {
                            pic: require('@/img/cz1.jpg'),
                        },
                        {
                            pic: require('@/img/cz2.jpg'),
                        },
                        {
                            pic: require('@/img/cz3.jpg'),
                        },
                        {
                            pic: require('@/img/cz4.jpg'),
                        },
                    ],
                },

                {
                    author: {
                        name: '用户123',
                        tx: require('@/img/tx.jpg'),
                        address: '北京市',
                    },
                    isguanzhu: false,
                    list: [
                        {
                            pic: require('@/img/cz1.jpg'),
                        },
                        {
                            pic: require('@/img/cz2.jpg'),
                        },
                        {
                            pic: require('@/img/cz3.jpg'),
                        },
                        {
                            pic: require('@/img/cz4.jpg'),
                        },
                    ],
                },

                {
                    author: {
                        name: '用户123',
                        tx: require('@/img/tx.jpg'),
                        address: '北京市',
                    },
                    isguanzhu: false,
                    list: [
                        {
                            pic: require('@/img/cz1.jpg'),
                        },
                        {
                            pic: require('@/img/cz2.jpg'),
                        },
                        {
                            pic: require('@/img/cz3.jpg'),
                        },
                        {
                            pic: require('@/img/cz4.jpg'),
                        },
                    ],
                },

                {
                    author: {
                        name: '用户123',
                        tx: require('@/img/tx.jpg'),
                        address: '北京市',
                    },
                    isguanzhu: false,
                    list: [
                        {
                            pic: require('@/img/cz1.jpg'),
                        },
                        {
                            pic: require('@/img/cz2.jpg'),
                        },
                        {
                            pic: require('@/img/cz3.jpg'),
                        },
                        {
                            pic: require('@/img/cz4.jpg'),
                        },
                    ],
                },

                {
                    author: {
                        name: '用户123',
                        tx: require('@/img/tx.jpg'),
                        address: '北京市',
                    },
                    isguanzhu: true,
                    list: [
                        {
                            pic: require('@/img/cz1.jpg'),
                        },
                        {
                            pic: require('@/img/cz2.jpg'),
                        },
                        {
                            pic: require('@/img/cz3.jpg'),
                        },
                        {
                            pic: require('@/img/cz4.jpg'),
                        },
                    ],
                },
            ],
            neirong: 'DGEE是享誉国际的设计顾问公司，由埃迪·洛巴诺夫斯基及大卫·科瓦列夫合伙经营。前身为设计顾问，经过近40年的发展，现在于香港、深圳设有公司，业务遍布全国，服务超过200多个国内外的客户，在业界获奖无数。公司以国际的视野与本土的经验，结合文化与商业，引领着中国的品牌设计。2013年正式更名为靳刘高设计，印证三代人的同心协力，传承创意，与时俱进，致力创造国际水平的中国设计。公司创作领域广泛，部门专业化管理，能为客户提供从文化研究、策略定位、形象系统、包装与产品设计、推广宣传、导视系统，以及空间设计等一系列整合性的设计服务。合作过的知名企业有中国银行、屈臣氏、鄂尔多斯、李宁、八马茶业等行业翘楚及政府项目如启德发展，不断为品牌创造价值，缔造行业标杆，影响著现代中国的生活。公司未来将进一步专业化管理与资源整合，发展战略性的品牌设计体系，互补互进，致力于成为中国最高设计水平的代表。',
            shanchang: [
                '整合营销',
                '社会化运营',
                'TVC拍摄',
                '品牌推广',
                '标识',
                '用户体验',
                '多媒体',
            ],
            pinpaiList: [
                {
                    img: require('../../img/pinpai.jpg'),
                },
                {
                    img: require('../../img/pinpai1.jpg'),
                },
                {
                    img: require('../../img/pinpai2.jpg'),
                },
                {
                    img: require('../../img/pinpai3.jpg'),
                },
                {
                    img: require('../../img/pinpai4.jpg'),
                },
                {
                    img: require('../../img/pinpai5.jpg'),
                },
                {
                    img: require('../../img/pinpai6.jpg'),
                },
                {
                    img: require('../../img/pinpai7.jpg'),
                },
                {
                    img: require('../../img/pinpai.jpg'),
                },
                {
                    img: require('../../img/pinpai1.jpg'),
                },
                {
                    img: require('../../img/pinpai2.jpg'),
                },
                {
                    img: require('../../img/pinpai3.jpg'),
                },
                {
                    img: require('../../img/pinpai4.jpg'),
                },
                {
                    img: require('../../img/pinpai5.jpg'),
                },
                {
                    img: require('../../img/pinpai6.jpg'),
                },
                {
                    img: require('../../img/pinpai7.jpg'),
                },
            ],
            cyList: [
                {
                    pic: require('../../img/shejishi.jpg'),
                },
                {
                    pic: require('../../img/shejishi1.jpg'),
                },
                {
                    pic: require('../../img/shejishi2.jpg'),
                },
                {
                    pic: require('../../img/shejishi3.jpg'),
                },
                {
                    pic: require('../../img/shejishi4.jpg'),
                },
                {
                    pic: require('../../img/shejishi5.jpg'),
                },
                {
                    pic: require('../../img/shejishi.jpg'),
                },
                {
                    pic: require('../../img/shejishi1.jpg'),
                },
                {
                    pic: require('../../img/shejishi2.jpg'),
                },
                {
                    pic: require('../../img/shejishi3.jpg'),
                },
                {
                    pic: require('../../img/shejishi4.jpg'),
                },
                {
                    pic: require('../../img/shejishi5.jpg'),
                },
            ],
            tiaojian1: 'created_at.desc',
            tiaojian2: '1',
            value1: '',
            options1: [
                {
                    value: '1',
                    label: '牛人'
                }, {
                    value: '2',
                    label: '特邀作者'
                }, {
                    value: '3',
                    label: '认证作者'
                }, {
                    value: '4',
                    label: '业界名人'
                }
            ],
        }
    },
    created() {
        document.title = this.pageTitle;
    },
    components: {
        // headerbar,
        footerbar
    },
    mounted() {
        this.getList()
        this.setActivePage()
        this.showMobileNav()
    },
    methods: {
        setActivePage() {
            // 触发事件，传递当前页面的名称
            eventBus.$emit('updateActivePage', '创造者');
        },
        showMobileNav(){
            eventBus.$emit('shownav', '');
        },
        chooseBtn(e) {
            this.choose = e
        },
        shaixuan1(e) {
            console.log(e)
            this.tiaojian2 = e;
            this.getList()
        },
        getList() {
            if (window.localStorage.getItem('token')) {
                this.$axios.get('/api/__yao/table/pc.user/search', {
                    headers: {
                        'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
                    }
                }, {
                    params: {
                        "order": this.tiaojian1,
                        "where.is_hot.eq": this.tiaojian2
                    }
                }).then((result) => {
                    console.log(result)
                    if (result.status == 200) {
                        this.chengyuanList = result.data.data;
                        // this.fanye = result.data
                    } else {
                        this.$message.error(result.data.message);
                    }
                }).catch((err) => {
                    console.log(err)
                    this.$message.error(err.response.data.message);
                });
            } else {
                this.$axios.get('/api/__yao/table/pc.user/search', {
                    params: {
                        "order": this.tiaojian1,
                        "where.is_hot.eq": this.tiaojian2
                    }
                }).then((result) => {
                    console.log(result)
                    if (result.status == 200) {
                        this.chengyuanList = result.data.data;
                        // this.fanye = result.data
                    } else {
                        this.$message.error(result.data.message);
                    }
                }).catch((err) => {
                    console.log(err)
                    this.$message.error(err.response.data.message);
                });
            }

        },

        addguanzhu(e) {
            console.log("关注")
            this.$axios.get('/api/user/user_following/' + e, {
                headers: {
                    'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
                }
            }).then((result) => {
                console.log(result)
                if (result.data.code == 200) {
                    this.$message({
                        message: result.data.message,
                        type: 'success'
                    });
                    this.getList()
                } else {
                    this.$message.error(result.data.message);
                }
            }).catch((err) => {
                console.log(err)
                // if (err.response.data.code == 403) {
                this.$message.error('请先登录！');
                setTimeout(() => {
                    this.$router.push({
                        name: 'login'
                    })
                }, 1500);
                // }
            });
        },
        qxguanzhu(e) {
            console.log("quxiao")
            this.$axios.get('/api/user/user_following/' + e, {
                headers: {
                    'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
                }
            }).then((result) => {
                console.log(result)
                if (result.data.code == 200) {
                    this.$message({
                        message: result.data.message,
                        type: 'success'
                    });
                    this.getList()
                } else {
                    this.$message.error(result.data.message);
                }
            }).catch((err) => {
                console.log(err)
                // if (err.response.data.code == 403) {
                this.$message.error('请先登录！');
                setTimeout(() => {
                    this.$router.push({
                        name: 'login'
                    })
                }, 1500);
                // }
            });
        },
        todetail(e) {
            this.$router.push({
                name: 'niurenDetail',
                query: {
                    id: e
                }
            })
        },
        wzDetail(e) {
            this.$router.push({
                name: 'articleDetail',
                query: {
                    id: e
                }
            })
        },
    }
}
</script>
  
<style scoped lang="scss">
@import "../niuren/niuren.scss"
</style>

<style>
.liebiao_top .el-input__inner {
    border: 1px solid #212121;
    height: 35px;
}
</style>