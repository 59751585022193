<template>
    <div class="index">
        <!-- <headerbar></headerbar> -->
        <div class="index">
            <div class="login">
                <div class="img">
                    <img src="../../img/logo1.png" alt="">
                </div>
                <div class="loginCon">
                    <div class="xuanze">
                        <div class="xuanze1">
                            <p>登录</p>
                        </div>
                        <div class="xuanze2" @click="zhuce">
                            <p>新注册</p>
                        </div>
                    </div>
                    <div class="shuru">
                        <div class="shuru1">
                            <input type="text" placeholder="手机号" v-model="mobile">
                        </div>
                        <div class="shuru1">
                            <input type="password" placeholder="密码" v-model="password">
                        </div>
                        <div class="shuru4">
                            <input type="text" placeholder="验证码" v-model="code">
                            <div class="anniu" @click="getcode">
                                <img :src="imageData" alt="Image">
                            </div>
                        </div>
                        <!-- <div class="shuru2">
                            <p>忘记密码?</p>
                        </div> -->
                        <div class="shuru3" @click="denglu">
                            <p>登录</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <footerbar></footerbar>
    </div>
</template>

<script>
// import headerbar from '../../components/headerbar.vue';
// import { mapGetters, mapActions } from 'vuex';
import { eventBus } from '../../App.vue';
import footerbar from '../../components/footerbar.vue';
export default {
    name: 'login',
    data() {
        return {
            pageTitle: '登录',
            mobile: '',
            password: '',
            imageData: '',
            id: '',
            code: '',
        }
    },
    created() {
        document.title = this.pageTitle;
    },
    components: {
        // headerbar,
        footerbar
    },
    computed: {
        // ...mapGetters('auth', ['isLoggedIn']),
    },
    mounted() {
        this.getcode()
        this.setActivePage()
        this.showMobileNav()
    },
    methods: {
        // ...mapActions('/auth', ['saveToken']),
        setActivePage() {
            // 触发事件，传递当前页面的名称
            eventBus.$emit('updateActivePage', '');
        },
        showMobileNav(){
            eventBus.$emit('shownav', '');
        },
        //获取验证码
        getcode() {
            this.$axios.get('/api/__yao/login/admin/captcha', {
                type: 'digit'
            }).then((result) => {
                console.log(result)
                this.imageData = result.data.content;
                this.id = result.data.id
            }).catch((err) => {
                console.log(err)
            });
        },
        denglu() {
            if (this.mobile == "") {
                this.$message.error('请输入账号名称！');
                return
            }
            if (this.password == "") {
                this.$message.error('请输入登录密码！');
                return
            }
            if (this.code == "") {
                this.$message.error('请输入验证码！');
                return
            }

            this.$axios.post('/api/user/login', {
                mobile: this.mobile,
                password: this.password,
                captcha: {
                    code: this.code,
                    id: this.id
                },
            }).then((result) => {
                console.log(result)
                if (result.data.code == 200) {
                    this.$message({
                        message: result.data.message,
                        type: 'success'
                    });
                    // this.$store.commit("setToken", result.data.data.token);
                    // this.$store.state("token");
                    // this.saveToken(result.data.data.token);
                    localStorage.setItem('token', result.data.data.token)
                    
                    setTimeout(() => {
                        this.$router.push({
                            name: 'person'
                        })
                        eventBus.$emit('shownav', '');
                        eventBus.$emit('showtoken', result.data.data.token);
                    }, 2000);
                } else {
                    this.$message.error(result.data.message);
                    this.getcode()
                }
            }).catch((err) => {
                console.log(err)
                this.$message.error(err.response.data.message);
                this.getcode()
            });

            // this.$router.push({
            //     name: 'person',
            // })
        },
        zhuce() {
            this.$router.push({
                name: 'zhuce',
                // query: {
                //     id: '123'
                // }
            })
        },
    }
}
</script>

<style scoped lang="scss">
@import '../login/login.scss'
</style>