<template>
    <div class="index">
        <div class="liebiao_con" v-if="shipinList.length > 0">
            <div class="spList">
                <div class="sp" v-for="(item, index) in shipinList" :key="index" @click="todetail(item.id)">
                    <div class="img">
                        <img class="tu" :src="item.cover" alt="">
                        <img class="biaozhi" src="../../img/shipin.png" alt="">
                        <!-- <div class="time">
                            <p>03:20</p>
                        </div> -->
                    </div>
                    <div class="des">
                        <div class="title">
                            <p>{{ item.title }}</p>
                        </div>
                        <div class="xq">
                            <div class="xq1">
                                <!-- <img :src="item.author.tx" alt=""> -->
                                <p>{{ item.author }}</p>
                            </div>
                            <div class="xq2">
                                <div class="sc">
                                    <img src="../../img/shoucang.png" alt="">
                                    <p>{{ item.collect_count }}</p>
                                </div>
                                <div class="sc">
                                    <img src="../../img/dianzan.png" alt="">
                                    <p>{{ item.support_count }}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="kong">
            <!-- <div class="kong1">
                <i class="el-icon-warning-outline"></i>
                <p>您还有没发布视频</p>
            </div> -->
            <!-- <div class="anniu" @click="fabu">
                <p>发布视频</p>
            </div> -->
        </div>


        <div class="xqTanchuang" v-if="xqzhanshi">
            <div class="closeanniu" @click="closeBtn" @keyup="handleKeyUp">
                <img src="../../img/close.png" alt="">
            </div>
            <div class="index_nei">
                <div class="wz_left">
                    <div class="content">
                        <div class="title">
                            <h2>{{ detail.title }}</h2>
                            <div class="des">
                                <div class="des1">
                                    <p>{{ detail.published_at | datefmt('YYYY-MM-DD HH:mm') }}</p>
                                    <i>·</i>
                                    <p>{{ detail.origin }}</p>
                                </div>
                                <div class="des1">
                                    <p>{{ detail.support_count }}点赞</p>
                                    <i>·</i>
                                    <p>{{ detail.read_count }}阅读</p>
                                    <i>·</i>
                                    <p>{{ detail.collect_count }}收藏</p>
                                    <i>·</i>
                                    <p>{{ detail.transfer_count }}转发</p>
                                </div>
                            </div>
                        </div>
                        <div class="con">

                            <img v-if="detail.cover.length > 0" :src="detail.cover[0]" alt="">
                            <video v-if="detail.video != null && detail.cover != null" :src="detail.video[0]"
                                controls :poster="detail.cover[0]"></video>
                            <div v-if="detail.content != null">
                                <div v-for="(item, index) in detail.content" :key="index">
                                    <p v-html="item.data.text"></p>
                                    <ul>
                                        <li>
                                            <p v-for="(dd, i) in item.data.items" :key="i" v-html="dd.content"></p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="dibu">
                            <div class="dibu1">
                                <p>未经授权严禁转载，授权事宜请联系作者本人，侵权必究。</p>
                            </div>
                            <div class="dibu2">
                                <div class="dibu2_1" @click="dzBtn(detail.id)" v-if="!detail.is_support">
                                    <div class="img">
                                        <img src="../../img/tuijian.png">
                                        <!-- <img src="../../img/tuijian_a.png" alt="" v-else> -->
                                    </div>
                                    <p>{{ detail.support_count }}</p>
                                </div>
                                <div class="dibu2_1 active" @click="qxdzBtn(detail.id)" v-if="detail.is_support">
                                    <div class="img">
                                        <!-- <img src="../../img/tuijian.png" > -->
                                        <img src="../../img/tuijian_a.png" alt="">
                                    </div>
                                    <p>{{ detail.support_count }}</p>
                                </div>
                                <div class="dibu2_1" @click="scBtn(detail.id)">
                                    <div class="img">
                                        <img src="../../img/sc.png" alt="">
                                    </div>
                                    <p>{{ detail.collect_count }}</p>
                                </div>
                                <div class="dibu2_1" @click="zfBtn(detail.id)">
                                    <div class="img">
                                        <img src="../../img/zhuanfa.png" alt="">
                                    </div>
                                    <p>{{ detail.transfer_count }}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="wz_right">


                    <div class="pinlun">
                        <div class="pinglun_shuru">
                            <h2>发表评论</h2>
                            <div class="shuru">
                                <!-- <div class="tx">
                                <img :src="myXinxi.pic" alt="">
                            </div> -->
                                <div class="kuang" style="width:100%">
                                    <el-input type="textarea" placeholder="说两句吧" v-model="textarea" maxlength="2000"
                                        show-word-limit resize="none">
                                    </el-input>
                                    <div class="anniu" @click="pinglunBtn(detail.id)">
                                        <p>发布</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="pl_list" v-if="pinglunList.length > 0">
                            <h3>全部评论({{ pinglunList.length }})</h3>
                            <div class="allList">
                                <div class="list1" v-for="(item, index) in pinglunList" :key="index">
                                    <div class="list1_img">
                                        <img :src="item.user.avatar" alt="">
                                    </div>
                                    <div class="list_xq">
                                        <div class="xq_left">
                                            <div class="left_top">
                                                <p>{{ item.user.name }}</p>
                                                <span>{{ item.created_at | datefmt('YYYY-MM-DD HH:mm') }}</span>
                                            </div>
                                            <div class="left_bot">
                                                <p>{{ item.content }}</p>
                                                <div class="button" @click="huifuShowBtn(index, item.id, item.user.name)"
                                                    v-if="activeIndex === ''">
                                                    回复</div>
                                                <div class="button" @click="huifuShowBtn1" v-if="index === activeIndex">
                                                    取消回复
                                                </div>
                                            </div>
                                            <div class="zichildren" v-for="(dd, ii) in item.children" :key="ii">
                                                <div class="tx">
                                                    <img :src="dd.user.avatar" alt="">
                                                </div>
                                                <div class="zi_detail">
                                                    <div class="zi_top">
                                                        <div class="zi_1">
                                                            <p>{{ dd.user.name }}</p>
                                                            <span>{{ dd.created_at | datefmt('YYYY-MM-DD HH:mm') }}</span>
                                                        </div>
                                                        <div class="zi_2">
                                                            <img src="../../img/tuijian_a.png" alt="" v-if="dd.isdian">
                                                            <img src="../../img/tuijian.png" alt="" v-else>
                                                            <p>{{ dd.support_count }}</p>
                                                        </div>
                                                    </div>
                                                    <div class="zi_right">
                                                        <p>{{ dd.content }}</p>
                                                        <div class="button" @click="huifuShowBtn(index, dd.id, dd.name)">回复
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="huifu_shu" v-if="index === activeIndex">
                                                <div class="shu1">
                                                    <el-input type="text" placeholder="请输入内容" v-model="text" maxlength="300"
                                                        show-word-limit>
                                                    </el-input>
                                                    <div class="button" @click="huifu(detail.id)">回复</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="xq_right" :class="{ active: item.isdian === true }">
                                            <img src="../../img/tuijian_a.png" alt="" v-if="item.isdian">
                                            <img src="../../img/tuijian.png" alt="" v-else>
                                            <p>{{ item.support_count }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="right1">
                        <h3>发布者</h3>
                        <div class="right1_con">
                            <div class="con1" @click="niurentodetail(detail.user.id)">
                                <div class="con1_left">
                                    <img v-if="detail.user.avatar.length > 0" :src="detail.user.avatar[0]" alt="">
                                    <div class="detail">
                                        <p>{{ detail.user.name }} </p>
                                        <span>{{ detail.user.intro }}</span>
                                        <span>{{ detail.user.position }}</span>
                                    </div>
                                </div>
                                <!--  <div class="con1_right">
                                <div class="anniu" v-if="zuozhe.isguanzhu">
                                    <span>+</span>
                                    <p>关注</p>
                                </div> -->
                                <!-- <div class="anniuyi" v-else>
                                    <img src="../../img/duihao.png" alt="">
                                    <p>已关注</p>
                                </div> 
                            </div>-->
                            </div>
                            <div class="con2">
                                <div class="con2_1">
                                    <p>{{ detail.user.fans }}</p>
                                    <span>粉丝</span>
                                </div>
                                <div class="con2_1">
                                    <p>{{ detail.user.article_count }}</p>
                                    <span>文章</span>
                                </div>
                                <div class="con2_1">
                                    <p>{{ detail.user.case_count }}</p>
                                    <span>案例</span>
                                </div>
                                <div class="con2_1">
                                    <p>{{ detail.user.project_count }}</p>
                                    <span>项目</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- <div class="right1">
                    <h3>相关企业</h3>
                    <div class="right1_con">
                        <div class="con1">
                            <div class="con1_left">
                                <img :src="xgqy.pic" alt="">
                                <div class="detail">
                                    <p>{{ xgqy.name }} </p>
                                    <span>{{ xgqy.address }}</span>
                                    <span>{{ xgqy.gsname }}</span>
                                </div>
                            </div>
                            <div class="con1_right">
                                <div class="anniu" v-if="xgqy.isguanzhu">
                                    <span>+</span>
                                    <p>关注</p>
                                </div>
                                <div class="anniuyi" v-else>
                                    <img src="../../img/duihao.png" alt="">
                                    <p>已关注</p>
                                </div>
                            </div>
                        </div>
                        <div class="con2">
                            <div class="con2_1">
                                <p>{{ xgqy.fensi }}</p>
                                <span>粉丝</span>
                            </div>
                            <div class="con2_1">
                                <p>{{ xgqy.wenzang }}</p>
                                <span>文章</span>
                            </div>
                            <div class="con2_1">
                                <p>{{ xgqy.anli }}</p>
                                <span>案例</span>
                            </div>
                            <div class="con2_1">
                                <p>{{ xgqy.xiangmu }}</p>
                                <span>项目</span>
                            </div>
                        </div>
                    </div>
                </div> -->

                    <!-- <div class="right2">
            <h3>热门文章</h3>
            <div class="list1" v-for="(item, index) in xgList" :key="index" @click="todetail(item.id)">
              <img :src="item.cover[0]" alt="">
              <p>{{ item.title }}</p>
            </div>
          </div> -->


                </div>
            </div>
        </div>

        <div class="shoucangjia" v-if="toshowSS">
            <div class="scList">
                <div class="scList_top">
                    <p>我的收藏夹</p>
                    <span @click="cjscj" v-if="!isChuang">创建</span>
                    <span @click="cjscj" v-else>取消</span>
                </div>
                <div class="cccc" v-if="isChuang">
                    <input type="text" v-model="scName">
                    <p @click="cjBtn">确定</p>
                </div>
                <ul v-if="CjList.length > 0">
                    <li v-for="(item, index) in CjList" :key="index" @click="scBtn1(item.id)">
                        <p>{{ item.name }}</p>
                        <span @click.stop="deleteSC(item.id)">删除</span>
                    </li>
                </ul>
                <div class="scKong" v-if="CjList.length == 0">
                    <p>暂时没有收藏夹</p>
                </div>
                <div class="qxsc">
                    <p @click="quxiaoSc">取消</p>
                </div>
            </div>
        </div>




    </div>
</template>

<script>
import { eventBus } from '../../App.vue';
export default {
    name: 'PageswangzhanMyVideo',

    data() {
        return {
            pageTitle: '我的视频',
            shipinList: [],
            detail: "",
            pinglunList: [],
            xqzhanshi: false,
            huodongShow: false,
            isScrollDisabled: false,
            textarea: '',

            toshowSS: false,
            isChuang: false,
            scName: '',
            CjList: '',

            activeIndex: '',
            textppp: '',
            plid: '',
            shuruttt: '',


        };
    },

    mounted() {
        document.addEventListener("keyup", this.handleKeyUp);
        this.getList()
        this.getSCList()
    },
    created() {
        document.title = this.pageTitle;
    },

    beforeUnmount() {
        // Remove the keyup event listener before the component is unmounted to prevent memory leaks
        document.removeEventListener("keyup", this.handleKeyUp);
        document.body.classList.remove('disable-body-scroll');
    },

    methods: {

        huifu(e) {
            this.$axios.post('/api/user/comment/save', {
                type: 1,
                ext_id: e,
                content: this.textppp,
                comment_id: this.plid,
            }, {
                headers: {
                    'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
                }
            }).then((result) => {
                console.log(result)
                if (result.data.code == 200) {
                    this.$message({
                        message: result.data.message,
                        type: 'success'
                    });
                    this.textppp = "";
                    this.activeIndex = ''
                } else {
                    this.$message.error(result.data.message);
                }
            }).catch((err) => {
                console.log(err)
                // this.$message.error(err.response.data.message);
                if (err.response.data.code == 403 || err.response.data.code == 500) {
                    this.$message.error("请重新登录!");
                    setTimeout(() => {
                        eventBus.$emit('clearToken', '');
                        this.$router.push({
                            name: 'login'
                        })
                    }, 1500);
                } else {
                    this.$message.error(err.response.data.message)
                }
            });
        },

        huifuShowBtn(e, d, f) {
            console.log(e, d, f)
            this.activeIndex = e;
            this.plid = d;
            this.shuruttt = f
        },

        huifuShowBtn1() {
            this.activeIndex = ''
            this.plid = '';
            this.shuruttt = ''
        },


        fabu(){
            this.$router.push({
                name:'fabu',
                query:{
                    type:'video'
                }
            })
        },


        cjscj() {
            this.isChuang = !this.isChuang
        },
        //创建文件夹
        cjBtn() {
            if (this.scName == '') {
                this.$message.error('请输入文件夹名!');
                return
            }
            this.$axios.post('api/collection/create', {
                name: this.scName
            }, {
                headers: {
                    'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
                }
            }).then((result) => {
                console.log(result)
                if (result.data.code == 200) {
                    this.$message({
                        message: '创建成功!',
                        type: 'success'
                    });
                    this.getSCList();
                    this.isChuang = false;
                } else {
                    this.$message.error(result.data.message);
                }
            }).catch((err) => {
                console.log(err)
                if (err.response.data.code == 403) {
                    this.$message.error("请重新登录！");
                    setTimeout(() => {
                        eventBus.$emit('clearToken', '');
                        this.$router.push({
                            name: 'login'
                        })
                    }, 1500);
                }else{
                this.$message.error(err.response.data.message)
                }
            });
        },

        //删除收藏夹
        deleteSC(e) {
            this.$axios.get('api/collection/delete/' + e, {
                headers: {
                    'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
                }
            }).then((result) => {
                if (result.data.code == 200) {
                    this.$message({
                        message: '删除成功!',
                        type: 'success'
                    });
                    this.getSCList();
                } else {
                    this.$message.error(result.data.message);
                }
            }).catch((err) => {
                console.log(err)
                if (err.response.data.code == 403) {
                    this.$message.error("请重新登录！");
                    setTimeout(() => {
                        eventBus.$emit('clearToken', '');
                        this.$router.push({
                            name: 'login'
                        })
                    }, 1500);
                }else{
                this.$message.error(err.response.data.message)
                }
            });
        },

        //获取收藏列表
        getSCList() {
            this.$axios.get('api/collection/my_collect', {
                headers: {
                    'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
                }
            }).then((result) => {
                console.log(result)
                if (result.data.code == 200) {
                    this.CjList = result.data.data
                } else {
                    this.$message.error(result.data.message);
                }
            }).catch((err) => {
                console.log(err)
            });
        },

        quxiaoSc() {
            this.toshowSS = false;
        },

        closeBtn() {
            this.xqzhanshi = false;
            this.huodongShow = false;
            this.toshowSS = false;

            this.huifuShowBtn1()

            document.body.classList.remove('disable-body-scroll');
        },


        handleKeyUp(event) {
            console.log(event)
            if (event.key === 'Escape') {
                // 处理按下 Enter 键的逻辑
                console.log('esc 键被按下');
                this.closeBtn()
            }
        },


        getList() {
            this.$axios.get('/api/__yao/table/personal.article/search', {
                headers: {
                    'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
                }
            },{
                "where.type.eq":'video'
            }).then((result) => {
                console.log(result)
                if (result.data.code == 200) {
                    this.shipinList = result.data.data
                }else{
                    this.$message.error(result.data.message);
                }
            }).catch((err) => {
                console.log(err)
                if (err.response.data.code == 403) {
                    this.$message.error("请重新登录！");
                    setTimeout(() => {
                        eventBus.$emit('clearToken', '');
                        this.$router.push({
                            name: 'login'
                        })
                    }, 1500);
                }else{
                this.$message.error(err.response.data.message)
                }
            });
        },
        todetail(e) {
            // this.$router.push({
            //     name: 'articleDetail',
            //     query: {
            //         id: e
            //     }
            // })


            document.body.classList.add('disable-body-scroll');
            this.xqzhanshi = true;
            if (window.localStorage.getItem('token')) {
                this.$axios.get('api/pc/article/detail/' + e, {
                    headers: {
                        'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
                    }
                }).then((result) => {
                    console.log(result)
                    if (result.data.code == 200) {
                        this.detail = result.data.data;
                        this.pinglunList = result.data.data.comments
                    } else {
                        this.$message.error(result.data.message);
                    }
                }).catch((err) => {
                    console.log(err)
                    if (err.response.data.code == 403) {
                    this.$message.error("请重新登录！");
                    setTimeout(() => {
                        eventBus.$emit('clearToken', '');
                        this.$router.push({
                            name: 'login'
                        })
                    }, 1500);
                }else{
                this.$message.error(err.response.data.message)
                }
                });
            } else {
                this.$axios.get('api/pc/article/detail/' + e).then((result) => {
                    console.log(result)
                    if (result.data.code == 200) {
                        this.detail = result.data.data;
                        this.pinglunList = result.data.data.comments
                    } else {
                        this.$message.error(result.data.message);
                    }
                }).catch((err) => {
                    console.log(err)
                    if (err.response.data.code == 403) {
                    this.$message.error("请重新登录！");
                    setTimeout(() => {
                        eventBus.$emit('clearToken', '');
                        this.$router.push({
                            name: 'login'
                        })
                    }, 1500);
                }else{
                this.$message.error(err.response.data.message)
                }
                });
            }



        },
        //收藏
        scBtn() {
            console.log(1312312)
            this.toshowSS = !this.toshowSS
            // this.$axios.post('/api/user/collect/article', {
            //   id: e,
            //   type: 1,
            // }, {
            //   headers: {
            //     'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
            //   }
            // }).then((result) => {
            //   console.log(result)
            //   if (result.data.code == 200) {
            //     this.$message({
            //       message: '收藏成功！',
            //       type: 'success'
            //     });
            //   } else {
            //     this.$message.error(result.data.message);
            //   }
            // }).catch((err) => {
            //   console.log(err)
            //   this.$message.error(err.response.data.message);
            //   // this.$message.error("请先登录进行操作!");
            //   if (err.response.data.code == 403) {
            //     setTimeout(() => {
            //       this.$router.push({
            //         name: 'login'
            //       })
            //     }, 1500);
            //   }
            // });
        },


        scBtn1() {
            this.$axios.post('/api/user/collect/article', {
                id: this.detail.id,
                type: 1,
            }, {
                headers: {
                    'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
                }
            }).then((result) => {
                console.log(result)
                if (result.data.code == 200) {
                    this.$message({
                        message: '收藏成功！',
                        type: 'success'
                    });
                    setTimeout(() => {
                        this.$axios.get('api/pc/article/detail/' + this.detail.id, {
                            headers: {
                                'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
                            }
                        }).then((result) => {
                            console.log(result)
                            if (result.data.code == 200) {
                                this.detail = result.data.data;
                                this.pinglunList = result.data.data.comments
                            } else {
                                this.$message.error(result.data.message);
                            }
                        }).catch((err) => {
                            console.log(err)
                            if (err.response.data.code == 403) {
                    this.$message.error("请重新登录！");
                    setTimeout(() => {
                        eventBus.$emit('clearToken', '');
                        this.$router.push({
                            name: 'login'
                        })
                    }, 1500);
                }else{
                this.$message.error(err.response.data.message)
                }
                        });
                        this.toshowSS = !this.toshowSS
                    }, 500);
                } else {
                    this.$message.error(result.data.message);
                    this.toshowSS = !this.toshowSS
                }
            }).catch((err) => {
                console.log(err)
                this.$message.error(err.response.data.message);
                if (err.response.data.code == 403) {
                    this.$message.error("请重新登录！");
                    setTimeout(() => {
                        eventBus.$emit('clearToken', '');
                        this.$router.push({
                            name: 'login'
                        })
                    }, 1500);
                }else{
                this.$message.error(err.response.data.message)
                }
            });
        },


        //点赞
        dzBtn(e) {
            this.$axios.post('/api/user/support/content', {
                id: e,
                type: 1,
            }, {
                headers: {
                    'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
                }
            }).then((result) => {
                console.log(result)
                if (result.data.code == 200) {
                    this.$message({
                        message: '点赞成功！',
                        type: 'success'
                    });

                    //重新获取详情接口
                    this.$axios.get('api/pc/article/detail/' + e, {
                        headers: {
                            'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
                        }
                    }).then((result) => {
                        console.log(result)
                        if (result.data.code == 200) {
                            this.detail = result.data.data;
                            this.pinglunList = result.data.data.comments
                        } else {
                            this.$message.error(result.data.message);
                        }
                    }).catch((err) => {
                        console.log(err)
                        this.$message.error(err.response.data.message);
                        if (err.response.data.code == 403) {
                    this.$message.error("请重新登录！");
                    setTimeout(() => {
                        eventBus.$emit('clearToken', '');
                        this.$router.push({
                            name: 'login'
                        })
                    }, 1500);
                }else{
                this.$message.error(err.response.data.message)
                }
                    });



                    //重新获取详情接口结束


                } else {
                    this.$message.error(result.data.message);
                }
            }).catch((err) => {
                console.log(err)
                if (err.response.data.code == 403) {
                    this.$message.error("请重新登录！");
                    setTimeout(() => {
                        eventBus.$emit('clearToken', '');
                        this.$router.push({
                            name: 'login'
                        })
                    }, 1500);
                }else{
                this.$message.error(err.response.data.message)
                }
            });
        },

        //取消点赞
        qxdzBtn(e) {
            this.$axios.post('/api/user/unsupport/content', {
                id: e,
                type: 1,
            }, {
                headers: {
                    'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
                }
            }).then((result) => {
                console.log(result)
                if (result.data.code == 200) {
                    this.$message({
                        message: '取消点赞！',
                        type: 'success'
                    });

                    //重新获取详情接口
                    this.$axios.get('api/pc/article/detail/' + e, {
                        headers: {
                            'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
                        }
                    }).then((result) => {
                        console.log(result)
                        if (result.data.code == 200) {
                            this.detail = result.data.data;
                            this.pinglunList = result.data.data.comments
                        } else {
                            this.$message.error(result.data.message);
                        }
                    }).catch((err) => {
                        console.log(err)
                        if (err.response.data.code == 403) {
                    this.$message.error("请重新登录！");
                    setTimeout(() => {
                        eventBus.$emit('clearToken', '');
                        this.$router.push({
                            name: 'login'
                        })
                    }, 1500);
                }else{
                this.$message.error(err.response.data.message)
                }
                    });

                } else {
                    this.$message.error(result.data.message);
                }

            }).catch((err) => {
                console.log(err)
                // this.$message.error(err.response.data.message);
                if (err.response.data.code == 403) {
                    this.$message.error("请重新登录！");
                    setTimeout(() => {
                        eventBus.$emit('clearToken', '');
                        this.$router.push({
                            name: 'login'
                        })
                    }, 1500);
                }else{
                this.$message.error(err.response.data.message)
                }
            });
        },

        //评论
        pinglunBtn(e) {
            this.$axios.post('/api/user/comment/save', {
                type: 1,
                ext_id: e,
                content: this.textarea,
                comment_id: '',
            }, {
                headers: {
                    'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
                }
            }).then((result) => {
                console.log(result)
                if (result.data.code == 200) {
                    this.$message({
                        message: result.data.message,
                        type: 'success'
                    });
                    this.textarea = ""
                } else {
                    this.$message.error(result.data.message);
                }
            }).catch((err) => {
                if (err.response.data.code == 403) {
                    this.$message.error("请重新登录！");
                    setTimeout(() => {
                        eventBus.$emit('clearToken', '');
                        this.$router.push({
                            name: 'login'
                        })
                    }, 1500);
                }else{
                this.$message.error(err.response.data.message)
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.index {
    margin-top: 30px;
    width: 100%;
    height: auto;

    .liebiao_con {
        width: 100%;
        height: auto;

        .spList {
            width: 100%;
            height: auto;
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            .sp {
                margin-right: 1.5%;
                margin-bottom: 25px;
                width: 18.8%;
                height: auto;
                cursor: pointer;

                &:nth-child(5n) {
                    margin-right: 0;
                }

                .img {
                    position: relative;
                    margin-bottom: 10px;
                    width: 100%;
                    height: 245px;
                    border-radius: 10px;
                    overflow: hidden;

                    .tu {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }

                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;

                    .biaozhi {
                        position: absolute;
                        width: 50px;
                        height: 50px;
                    }

                    .time {
                        position: absolute;
                        bottom: 10px;
                        left: 10px;
                        width: auto;
                        padding: 5px;
                        border-radius: 5px;
                        background: rgba($color: #000000, $alpha: 0.6);

                        p {
                            color: #fff;
                            font-size: 12px;
                        }
                    }
                }

                .des {
                    width: 100%;
                    height: auto;

                    .title {
                        margin-bottom: 10px;
                        width: 100%;
                        height: auto;

                        p {
                            font-size: 15px;
                            color: #212121;
                            width: 100%;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                        }
                    }

                    .xq {
                        width: 100%;
                        height: auto;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        .xq1 {
                            display: flex;
                            align-items: center;

                            img {
                                margin-right: 10px;
                                width: 26px;
                                height: 26px;
                                border-radius: 7px;
                            }

                            p {
                                font-size: 14px;
                                color: #666;
                            }
                        }

                        .xq2 {
                            display: flex;
                            align-items: center;

                            .sc {
                                display: flex;
                                align-items: center;
                                margin-right: 15px;

                                img {
                                    margin-right: 5px;
                                    width: 26px;
                                    height: 26px;
                                }

                                p {
                                    font-size: 15px;
                                    color: #666;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .kong {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .kong1 {
            width: 100%;
            height: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 20px;

            i {
                margin-right: 15px;
                font-size: 22px;
                color: #ff6600;
            }

            p {
                font-size: 16px;
                color: #ff6600;
                font-weight: bold;
            }
        }

        .anniu {
            width: 200px;
            height: 35px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background-color: #ff6600;
            border-radius: 5px;

            p {
                color: #fff;
                font-size: 15px;
            }
        }
    }


    .xqTanchuang {
        position: fixed;
        left: 0;
        top: 0;
        background: rgba($color: #000000, $alpha: 0.8);
        width: 100%;
        height: 100vh;
        // display: flex;
        // align-items: center;
        // justify-content: center;
        z-index: 888;
        box-sizing: border-box;
        overflow: auto;

        .closeanniu {
            position: fixed;
            right: 20px;
            top: 30px;
            width: 36px;
            height: 36px;
            cursor: pointer;

            img {
                width: 30px;
                height: 30px;
            }
        }





        .index_nei {
            margin: auto;
            width: 70%;
            // height: 100%;
            box-sizing: border-box;
            padding: 0 2%;
            padding-top: 30px;
            padding-bottom: 100px;
            // display: flex;
            justify-content: space-between;
            background-color: #fff;
        }

        .wz_left {
            width: 100%;
            height: auto;

            .content {
                width: 100%;
                height: auto;
                margin: 0;
                padding: 0;

                .title {
                    margin-bottom: 30px;
                    width: 100%;
                    height: auto;

                    h2 {
                        font-size: 32px;
                        color: #212121;
                        font-weight: bold;
                        margin-bottom: 10px;
                    }

                    .des {
                        width: 100%;
                        display: flex;
                        align-items: center;

                        .des1 {
                            margin-right: 20px;
                            width: auto;
                            display: flex;
                            align-items: center;

                            p {
                                font-size: 14px;
                                color: #999;
                            }
                        }
                    }
                }

                .con {
                    width: 100%;
                    height: auto;
                    display: flex;
                    flex-direction: column;

                    p {
                        font-size: 15px;
                        color: #212121;
                        line-height: 30px;
                        text-indent: 32px;
                    }

                    img {
                        margin-top: 30px;
                    }
                }

                .dibu {
                    margin-top: 40px;
                    width: 100%;
                    height: auto;
                    display: flex;
                    flex-direction: column;

                    .dibu1 {
                        width: 100%;
                        height: auto;
                        border-top: 1px solid #ccc;
                        padding-top: 20px;

                        p {
                            font-size: 14px;
                            color: #999;
                        }
                    }

                    .dibu2 {
                        width: 100%;
                        height: auto;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        .dibu2_1 {
                            margin: 0 10px;
                            width: 90px;
                            height: auto;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            cursor: pointer;

                            &.active {
                                .img {
                                    border: 1px solid #ff6600;

                                }

                                p {
                                    color: #ff6600;
                                }


                            }

                            .img {
                                margin-bottom: 10px;
                                width: 70px;
                                height: 70px;
                                border: 1px solid #212121;
                                border-radius: 50%;
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                justify-content: center;

                                img {
                                    width: 30px;
                                    height: auto;
                                }
                            }

                            p {
                                font-size: 16px;
                                color: #212121;
                            }
                        }
                    }
                }
            }


        }

        .wz_right {
            margin-top: 50px;
            width: 100%;
            height: auto;
            display: flex;
            justify-content: space-between;

            .right1 {
                margin-bottom: 50px;
                width: 33%;
                height: auto;
                display: flex;
                flex-direction: column;

                h3 {
                    font-size: 20px;
                    margin-bottom: 10px;
                    color: #212121;
                    font-weight: bold;
                }

                .right1_con {
                    width: 100%;
                    height: 210px;
                    background-color: #eeeeee;
                    border-radius: 10px;
                    box-sizing: border-box;
                    padding: 20px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;

                    .con1 {
                        width: 100%;
                        height: auto;
                        display: flex;
                        justify-content: space-between;
                        cursor: pointer;

                        .con1_left {
                            display: flex;
                            align-items: center;
                            width: 100%;

                            img {
                                margin-right: 10px;
                                height: 90px;
                                width: 90px;
                                border-radius: 10px;
                                object-fit: cover;
                            }

                            .detail {
                                display: flex;
                                flex-direction: column;
                                width: 70%;

                                p {
                                    font-size: 16px;
                                    color: #212121;
                                    font-weight: bold;
                                    margin-bottom: 15px;
                                }

                                span {
                                    font-size: 12px;
                                    color: #999;
                                    // white-space: nowrap;
                                    width: 100%;
                                    // text-overflow: ellipsis;
                                    // overflow: hidden;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 2;
                                    line-clamp: 2;
                                    -webkit-box-orient: vertical;

                                    &:last-child {
                                        margin-top: 10px;
                                    }
                                }
                            }
                        }

                        .con1_right {
                            width: 85px;
                            height: 30px;

                            .anniu {
                                width: 100%;
                                height: 100%;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                border: 1px solid #ff6600;
                                border-radius: 5px;

                                span {
                                    font-size: 18px;
                                    margin-right: 5px;
                                    color: #ff6600;
                                }

                                p {
                                    font-size: 14px;
                                    color: #ff6600;
                                }
                            }

                            .anniuyi {
                                width: 100%;
                                height: 100%;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                border: 1px solid #212121;
                                border-radius: 5px;

                                img {
                                    width: 16px;
                                    margin-right: 5px;
                                }

                                p {
                                    font-size: 14px;
                                    color: #212121;
                                }
                            }
                        }
                    }

                    .con2 {
                        width: 100%;
                        height: auto;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        .con2_1 {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;

                            p {
                                font-size: 17px;
                                color: #212121;
                                font-weight: bold;
                            }

                            span {
                                font-size: 14px;
                                color: #212121;
                            }
                        }
                    }
                }
            }

            .pinlun {
                width: 64%;
                height: auto;

                .pinglun_shuru {
                    margin-bottom: 20px;
                    width: 100%;
                    height: auto;

                    h2 {
                        font-size: 20px;
                        color: #212121;
                        font-weight: bold;
                        margin-bottom: 20px;
                    }

                    .shuru {
                        width: 100%;
                        height: 215px;
                        display: flex;
                        justify-content: space-between;

                        .tx {

                            width: 50px;
                            height: 50px;
                            border-radius: 8px;
                            overflow: hidden;

                            img {
                                width: 100%;
                                height: 100%;
                            }
                        }

                        .kuang {
                            width: 93%;
                            height: 215px;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-end;

                            .el-textarea {
                                margin-bottom: 15px;
                                width: 100%;
                                height: 160px;

                            }

                            .anniu {
                                width: 100px;
                                height: 40px;
                                border-radius: 8px;
                                background-color: #ff6600;
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                justify-content: center;
                                cursor: pointer;

                                p {
                                    font-size: 16px;
                                    color: #fff;
                                }
                            }
                        }
                    }
                }

                .pl_list {
                    width: 100%;
                    height: auto;

                    h3 {
                        margin-bottom: 30px;
                        font-size: 20px;
                        color: #212121;
                    }

                    .allList {
                        width: 100%;
                        height: auto;

                        .list1 {
                            margin-bottom: 40px;
                            width: 100%;
                            height: auto;
                            display: flex;
                            justify-content: space-between;
                            padding-bottom: 40px;
                            border-bottom: 1px solid #f2f2f2;

                            .list1_img {

                                width: 50px;
                                height: 50px;
                                border-radius: 8px;
                                overflow: hidden;

                                img {
                                    width: 100%;
                                    height: 100%;
                                }
                            }

                            .list_xq {
                                width: 93%;
                                height: auto;
                                display: flex;
                                justify-content: space-between;

                                .xq_left {
                                    width: 92%;
                                    height: auto;
                                    display: flex;
                                    flex-direction: column;

                                    .left_top {
                                        margin-bottom: 10px;
                                        width: 100%;
                                        height: auto;
                                        display: flex;
                                        flex-direction: column;

                                        p {
                                            font-size: 16px;
                                            color: #212121;
                                            font-weight: bold;
                                        }

                                        span {
                                            font-size: 14px;
                                            color: #999;
                                        }
                                    }

                                    .left_bot {
                                        width: 100%;
                                        height: auto;

                                        p {
                                            font-size: 15px;
                                            color: #212121;
                                            line-height: 30px;
                                        }

                                        .button {
                                            margin-top: 15px;
                                            width: 85px;
                                            height: 30px;
                                            display: flex;
                                            flex-direction: column;
                                            align-items: center;
                                            justify-content: center;
                                            font-size: 15px;
                                            background-color: #eeeeee;
                                            border-radius: 5px;
                                            color: #212121;
                                            cursor: pointer;
                                        }
                                    }

                                    .zichildren {
                                                display: flex;
                                                align-items: center;
                                                margin: 10px 0;
                                                width: 100%;
                                                height: auto;
        
                                                .tx {
                                                    margin-right: 10px;
                                                    width: 30px;
                                                    height: 30px;
                                                    border-radius: 5px;
                                                    overflow: hidden;
        
                                                    img {
                                                        width: 100%;
                                                        height: 100%;
                                                        object-fit: cover;
                                                    }
                                                }
        
                                                .zi_detail {
                                                    width: 100%;
                                                    height: auto;
        
                                                    .zi_top {
                                                        width: 100%;
                                                        height: auto;
                                                        display: flex;
                                                        align-items: center;
                                                        justify-content: space-between;
        
                                                        p {
                                                            font-size: 15px;
                                                            color: #000;
                                                        }
        
                                                        .zi_right {
                                                            width: auto;
                                                            display: flex;
                                                            align-items: center;
        
                                                            &.active {
                                                                p {
                                                                    color: #ff6600;
                                                                }
                                                            }
        
                                                            img {
                                                                margin-right: 5px;
                                                                width: 20px;
                                                                height: auto;
                                                            }
        
                                                            p {
                                                                font-size: 14px;
                                                            }
                                                        }
        
                                                    }
                                                }
                                            }
                                            .huifu_shu {
                                                margin-top: 15px;
                                                width: 100%;
                                                height: auto;
        
                                                .shu1 {
                                                    width: 100%;
                                                    height: auto;
                                                    background: #fff;
        
                                                    .el-input {
                                                        margin-bottom: 10px;
                                                    }
        
                                                    .button {
                                                        width: 65px;
                                                        height: 30px;
                                                        display: flex;
                                                        flex-direction: column;
                                                        align-items: center;
                                                        justify-content: center;
                                                        font-size: 15px;
                                                        background-color: #eeeeee;
                                                        border-radius: 5px;
                                                        color: #212121;
                                                        cursor: pointer;
                                                    }
                                                }
        
                                            }
                                }

                                .xq_right {
                                    width: auto;
                                    display: flex;
                                    align-items: center;

                                    &.active {
                                        p {
                                            color: #ff6600;
                                        }
                                    }

                                    img {
                                        margin-right: 5px;
                                        width: 20px;
                                        height: auto;
                                    }

                                    p {
                                        font-size: 14px;
                                    }
                                }
                            }

                        }
                    }
                }
            }

        }
    }

    .shoucangjia {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        z-index: 7777;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        .scList {
            width: 450px;
            height: auto;
            background-color: #fff;
            border-radius: 10px;
            padding: 15px;
            box-shadow: 0 0 10px #ccc;
            display: flex;
            flex-direction: column;

            .scList_top {
                margin-bottom: 15px;
                width: 100%;
                height: 35px;
                padding-bottom: 10px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-bottom: 1px solid #ccc;

                p {
                    font-size: 16px;
                    color: #212121;
                }

                span {
                    width: 60px;
                    height: 26px;
                    background-color: #ff6600;
                    line-height: 26px;
                    text-align: center;
                    border-radius: 5px;
                    cursor: pointer;
                    color: #fff;
                }
            }

            .cccc {
                width: 100%;
                height: 30px;
                background-color: #fff;
                display: flex;
                align-items: center;
                justify-content: space-between;

                input {
                    width: 78%;
                    height: 26px;
                    border: 1px solid #ccc;
                    border-radius: 5px;
                    box-sizing: border-box;
                    padding-left: 10px;
                    background: none;
                    outline: none;
                }

                p {
                    cursor: pointer;
                    width: 20%;
                    height: 26px;
                    background-color: #ff6600;
                    line-height: 26px;
                    text-align: center;
                    border-radius: 5px;
                    color: #fff;
                }
            }

            ul {
                width: 100%;
                height: auto;
                display: flex;
                flex-direction: column;

                li {
                    width: 100%;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    cursor: pointer;
                    border-bottom: 1px dashed #ccc;

                    p {
                        font-size: 14px;
                        color: #212121;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        width: 80%;
                    }

                    span {
                        width: 60px;
                        height: 26px;
                        background-color: #ff6600;
                        line-height: 26px;
                        text-align: center;
                        border-radius: 5px;
                        cursor: pointer;
                        color: #fff;
                    }
                }
            }

            .qxsc {
                margin-top: 20px;
                display: flex;
                align-items: center;
                justify-content: flex-end;

                p {
                    font-size: 14px;
                    color: #fff;
                    background-color: #999;
                    width: 70px;
                    height: 26px;
                    text-align: center;
                    line-height: 26px;
                    border-radius: 5px;
                    cursor: pointer;
                }
            }
        }
    }




}
</style>