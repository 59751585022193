var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"index"},[_c('div',{staticClass:"jianli"},[_c('div',{staticClass:"jianli_left"},[_c('div',{staticClass:"left1"},[_vm._m(0),_c('div',{staticClass:"tx_des"},[_c('div',{staticClass:"des1"},[_c('h2',[_vm._v(_vm._s(_vm.detail.name))])]),_c('div',{staticClass:"des2"},[_c('p',[_vm._v("性别:"+_vm._s(_vm.detail.gender))]),_c('p',[_vm._v("年龄:"+_vm._s(_vm.detail.age))])]),_c('div',{staticClass:"des3"},[_c('router-link',{staticClass:"anniu2",attrs:{"to":"/editJianli"}},[_c('img',{attrs:{"src":require("../../img/bianji.png"),"alt":""}}),_c('p',[_vm._v("编辑简历")])])],1)])]),_c('div',{staticClass:"left2"},[_c('div',{staticClass:"left2_1"},[_vm._m(1),_c('div',{staticClass:"left2_con"},[_c('p',[_vm._v(_vm._s(_vm.detail.intro))])])]),_c('div',{staticClass:"left2_2"},[_vm._m(2),_c('div',{staticClass:"left2_2_con"},[_c('p',[_vm._v(_vm._s(_vm.detail.edu))])])]),_c('div',{staticClass:"left2_2"},[_vm._m(3),_c('div',{staticClass:"left2_2_con"},[_c('p',[_vm._v(_vm._s(_vm.detail.exp))])])]),_c('div',{staticClass:"left2_2"},[_vm._m(4),_c('div',{staticClass:"left2_2_con"},[(_vm.detail.is_visibled == 0)?_c('p',[_vm._v("不可见")]):_c('p',[_vm._v("可见")])])]),_c('div',{staticClass:"left2_3"},[_vm._m(5),_c('div',{staticClass:"left2_3_1"},[_c('div',{staticClass:"list1"},[_c('p',[_vm._v("电话号码:")]),_c('span',[_vm._v(_vm._s(_vm.detail.mobile))])]),_c('div',{staticClass:"list1"},[_c('p',[_vm._v("籍贯名称::")]),(_vm.detail.province_name != null)?_c('span',[_vm._v(_vm._s(_vm.detail.province_name[0]))]):_vm._e()])])])])]),_c('div',{staticClass:"jianli_right"},[_c('div',{staticClass:"right1"},[_c('div',{staticClass:"right_top"},[_c('div',{staticClass:"yuan"},[_c('div',{staticClass:"yuan_nei",style:({ height: _vm.dynamicHeight })})]),_vm._m(6)])])]),_c('div',{staticStyle:{"clear":"both"}})]),_c('router-view')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"touxiang"},[_c('img',{attrs:{"src":require("../../img/morentx.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left2_head"},[_c('img',{attrs:{"src":require("../../img/mioashu.png"),"alt":""}}),_c('p',[_vm._v("自我描述")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left2_head"},[_c('img',{attrs:{"src":require("../../img/jingli.png"),"alt":""}}),_c('p',[_vm._v("教育经历")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left2_head"},[_c('img',{attrs:{"src":require("../../img/jingli.png"),"alt":""}}),_c('p',[_vm._v("工作经验")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left2_head"},[_c('img',{attrs:{"src":require("../../img/jingli.png"),"alt":""}}),_c('p',[_vm._v("是否企业可见")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left2_head"},[_c('img',{attrs:{"src":require("../../img/ziliao.png"),"alt":""}}),_c('p',[_vm._v("基本资料")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"yuan_des"},[_c('p',[_vm._v("我的简历完成60%")]),_c('span',[_vm._v("编辑简历")])])
}]

export { render, staticRenderFns }