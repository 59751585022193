<template>
    <div class="index">
        <div class="liebiao_con" v-if="allList.length > 0">
            <div class="allList">
                <div class="list1" v-for="(item, index) in allList" :key="index" @click="todetail(item.id)">
                    <div class="img">
                        <img :src="item.cover" alt="">
                    </div>
                    <div class="des">
                        <div class="title">
                            <p>{{ item.title }}</p>
                        </div>
                        <div class="xq">
                            <div class="xq1">
                                <!-- <img :src="item.author.tx" alt=""> -->
                                <p>{{ item.author }}</p>
                            </div>
                            <div class="xq2">
                                <div class="sc">
                                    <img src="../../img/shoucang.png" alt="">
                                    <p>{{ item.collect_count }}</p>
                                </div>
                                <div class="sc">
                                    <img src="../../img/dianzan.png" alt="">
                                    <p>{{ item.support_count }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="kong">
            <!-- <div class="kong1">
                <i class="el-icon-warning-outline"></i>
                <p>您还有没发布项目</p>
            </div> -->
            <div class="anniu" @click="fabu">
                <p>发布项目</p>
            </div>
        </div>

        <div class="xqTanchuang1" v-if="huodongShow">
            <div class="closeanniu" @click="closeBtn">
                <img src="../../img/close.png" alt="">
            </div>
            <div class="fudong">
                <div class="fu1">
                    <img v-if="detail.user.avatar != null" :src="detail.user.avatar[0]" alt="">
                    <!-- <div class="anniu" @click="anniuBtn(detail.id)">
                        <p>+</p>
                    </div> -->
                </div>
                <div class="fu2">
                    <div class="img">
                        <img src="../../img/xinxi1.png" alt="">
                    </div>
                    <div class="anniu">
                        <p>{{ detail.comments.length }}</p>
                    </div>
                </div>
                <div class="fu2" :class="{ active: detail.is_support == true }">
                    <div class="img" @click="dzBtn(detail.id)">
                        <img src="../../img/tuijian1.png" alt="" v-if="!detail.is_support">
                        <img src="../../img/tuijian_a.png" alt="" v-else>
                    </div>
                    <div class="anniu">
                        <p>{{ detail.support_count }}</p>
                    </div>
                </div>
                <div class="fu2">
                    <div class="img" @click="scBtn(detail.id)">
                        <img src="../../img/sc1.png" alt="">
                    </div>
                    <div class="anniu">
                        <p>{{ detail.collect_count }}</p>
                    </div>
                </div>
                <div class="fu2">
                    <div class="img" @click="zfBtn(detail.id)">
                        <img src="../../img/zhuanfa1.png" alt="">
                    </div>
                    <div class="anniu">
                        <p>{{ detail.transfer_count }}</p>
                    </div>
                </div>
            </div>
            <div class="index_nei">
                <div class="content">
                    <div class="title">
                        <div class="detail">
                            <!-- <img src="../../img/job1.png" alt="">
                        <img src="../../img/job2.png" alt=""> -->
                            <img v-if="detail.brand != null" :src="detail.brand.logo[0]" alt="">
                            <img v-if="detail.agent != null" :src="detail.agent.logo[0]" alt="">
                            <div class="detail1">
                                <p v-if="detail.agent != null">{{ detail.agent.name }}</p>
                                <span>上线时间：{{ detail.published_at | datefmt('YYYY-MM-DD HH:mm') }}</span>
                            </div>
                        </div>
                        <h1>{{ detail.title }}</h1>
                    </div>
                    <div class="des">
                        <!-- <div v-if="detail.content != null">
                        <div v-for="(item, index) in detail.content" :key="index">
                            <p v-html="item.data.text"></p>
                        </div>
                    </div> -->
                        <img v-for="(item, index) in detail.cover" :src="item" :key="index" alt="">
                    </div>
                    <div class="xiangqing">
                        <!-- <div class="xiangqing_top">
                            <div class="xq_img">
                                <img v-if="detail.user.avatar != null" :src="detail.user.avatar[0]" alt="">
                            </div>
                            <div class="button" @click="shenqing(detail.id)">
                                <p>项目申请</p>
                            </div>
                        </div> -->
                        <div class="xiangqing_bot">
                            <!-- <h3>项目详情</h3> -->
                            <div class="bot_c">
                                <div class="bot1" v-if="detail.content != null">
                                    <div class="list" v-for="(item, index) in detail.content" :key="index">
                                        <p v-if="item.type == 'paragraph'" v-html="item.data.text"></p>
                                        <div class="tu" v-if="item.type == 'images'">
                                            <img v-for="(ii, ee) in item.data.images" :key="ee" :src="ii.src" alt="" />
                                        </div>

                                        <div class="tu" v-if="item.type == 'video'">
                                            <video v-for="(ii, ee) in item.data.video" :key="ee" :src="ii.src" alt=""
                                                controls></video>
                                        </div>


                                        <table class="your-table-class" v-if="item.type == 'table'">
                                            <thead>
                                                <tr>
                                                    <!-- 动态渲染表头 -->
                                                    <th v-for="(header, index) in item.data.content[0]" :key="index">{{
                                                        header }}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <!-- 动态渲染表格内容 -->
                                                <tr v-for="(row, rowIndex) in item.data.content.slice(1)" :key="rowIndex">
                                                    <td v-for="(column, colIndex) in row" :key="colIndex">
                                                        <!-- 使用 v-html 渲染包含 HTML 标签的内容 -->
                                                        <span v-if="colIndex === 2 || colIndex === 4 || colIndex === 7"
                                                            v-html="column"></span>
                                                        <span v-else>{{ column }}</span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>


                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="zuopin">
                <div class="title">
                    <h2>更多作品</h2>
                    <p>查看更多</p>
                </div>
                <div class="list">
                    <div class="list1" v-for="(item, index) in tuijian" :key="index">
                        <img :src="item.pic" alt="">
                    </div>
                </div>
            </div> -->
                <div class="allDibu">
                    <div class="dibu_left">
                        <div class="pinlun">
                            <div class="pinglun_shuru">
                                <h2>发表评论</h2>
                                <div class="shuru">
                                    <!-- <div class="tx">
                                    <img :src="myXinxi.pic" alt="">
                                </div> -->
                                    <div class="kuang" style="width:100%">
                                        <el-input type="textarea" placeholder="说两句吧" v-model="textarea" maxlength="2000"
                                            show-word-limit resize="none">
                                        </el-input>
                                        <div class="anniu" @click="pinglunBtn(detail.id)">
                                            <p>发布</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="pl_list" v-if="pinglunList.length > 0">
                                <h3>全部评论({{ pinglunList.length }})</h3>
                                <div class="allList">
                                    <div class="list1" v-for="(item, index) in pinglunList" :key="index">
                                        <div class="list1_img">
                                            <img :src="item.user.avatar" alt="">
                                        </div>
                                        <div class="list_xq">
                                            <div class="xq_left">
                                                <div class="left_top">
                                                    <p>{{ item.user.name }}</p>
                                                    <!-- <span>{{ item.time }}</span> -->
                                                </div>
                                                <div class="left_bot">
                                                <p>{{ item.content }}</p>
                                                <div class="button" @click="huifuShowBtn(index, item.id, item.user.name)"
                                                    v-if="activeIndex === ''">
                                                    回复</div>
                                                <div class="button" @click="huifuShowBtn1" v-if="index === activeIndex">
                                                    取消回复
                                                </div>
                                            </div>
                                            <div class="zichildren" v-for="(dd, ii) in item.children" :key="ii">
                                                <div class="tx">
                                                    <img :src="dd.user.avatar" alt="">
                                                </div>
                                                <div class="zi_detail">
                                                    <div class="zi_top">
                                                        <div class="zi_1">
                                                            <p>{{ dd.user.name }}</p>
                                                            <span>{{ dd.created_at | datefmt('YYYY-MM-DD HH:mm') }}</span>
                                                        </div>
                                                        <div class="zi_2">
                                                            <img src="../../img/tuijian_a.png" alt="" v-if="dd.isdian">
                                                            <img src="../../img/tuijian.png" alt="" v-else>
                                                            <p>{{ dd.support_count }}</p>
                                                        </div>
                                                    </div>
                                                    <div class="zi_right">
                                                        <p>{{ dd.content }}</p>
                                                        <div class="button" @click="huifuShowBtn(index, dd.id, dd.name)">回复
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="huifu_shu" v-if="index === activeIndex">
                                                <div class="shu1">
                                                    <el-input type="text" placeholder="请输入内容" v-model="text" maxlength="300"
                                                        show-word-limit>
                                                    </el-input>
                                                    <div class="button" @click="huifu(detail.id)">回复</div>
                                                </div>
                                            </div>
                                            </div>
                                            <div class="xq_right" :class="{ active: item.isdian === true }">
                                                <img src="../../img/tuijian_a.png" alt="" v-if="item.isdian">
                                                <img src="../../img/tuijian.png" alt="" v-else>
                                                <p>{{ item.support_count }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="dibu_right">
                        <div class="right1">
                            <h3>发布者</h3>
                            <div class="right1_con">
                                <div class="con1" @click="gstodetail(detail.user.id)">
                                    <div class="con1_left">
                                        <img v-if="detail.user.avatar != null" :src="detail.user.avatar[0]" alt="">
                                        <div class="detail">
                                            <p>{{ detail.user.name }} </p>
                                            <span>{{ detail.user.intro }}</span>
                                            <span>{{ detail.user.position }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="con2">
                                    <div class="con2_1">
                                        <p>{{ detail.user.fans }}</p>
                                        <span>粉丝</span>
                                    </div>
                                    <div class="con2_1">
                                        <p>{{ detail.user.article_count }}</p>
                                        <span>文章</span>
                                    </div>
                                    <div class="con2_1">
                                        <p>{{ detail.user.case_count }}</p>
                                        <span>案例</span>
                                    </div>
                                    <div class="con2_1">
                                        <p>{{ detail.user.project_count }}</p>
                                        <span>项目</span>
                                    </div>
                                </div>
                                <!-- <div class="con2">
                                <div class="con2_1">
                                    <p>{{ detail.user.fans }}</p>
                                    <span>粉丝</span>
                                </div>
                                <div class="con2_1">
                                    <p>{{ detail.user.article_count }}</p>
                                    <span>文章</span>
                                </div>
                                <div class="con2_1">
                                    <p>{{ detail.user.case_count }}</p>
                                    <span>案例</span>
                                </div>
                                <div class="con2_1">
                                    <p>{{ detail.user.project_count }}</p>
                                    <span>项目</span>
                                </div>
                            </div> -->
                                <!-- <div class="con2">
                                <div class="con2_1" v-if="!zuozhe.isguanzhu">
                                    <img src="../../img/duihao.png" alt="">
                                    <p>已关注</p>
                                </div>
                                <div class="con2_2" v-else>
                                    <span>+</span>
                                    <p>关注</p>
                                </div>
                                <div class="con2_3">
                                    <img src="../../img/email_1.png" alt="">
                                    <p>发信息</p>
                                </div>
                            </div> -->
                            </div>
                        </div>
                        <div class="bot_renling">
              <div class="role">
                <h2 v-if="detail.operator_user.length >0">创作人员</h2>
                <ul>
                  <li v-for="(item, index) in detail.operator_user" :key="index">
                    <div class="info">
                      <img v-if="item.user.avatar != null" :src="item.user.avatar[0]" alt="">
                      <p>{{ item.user.name }} <span>{{ item.role }}</span></p>
                    </div>
                    <div class="button" v-if="item.is_following == false" @click="addguanzhu(item.user)">
                      <p>关注</p>
                    </div>
                    <div class="button wei" v-else @click="qxguanzhu(item.user)">
                      <p>已关注</p>
                    </div>
                  </li>
                </ul>
                <div class="button" @click="shenqing(detail.id)">
                  <p>项目申请</p>
                </div>
              </div>
            </div>
                        <!-- <div class="right1">
                        <h3>发布者</h3>
                        <div class="right1_con">
                            <div class="list">
                                <img src="../../img/jiang.png" alt="">
                            </div>
                            <div class="list">
                                <img src="../../img/jiang1.png" alt="">
                            </div>
                            <div class="list">
                                <img src="../../img/jiang2.png" alt="">
                            </div>
                            <div class="list">
                                <img src="../../img/jiang3.png" alt="">
                            </div>
                        </div>
                    </div> -->
                    </div>
                </div>
            </div>

        </div>


        <div class="shoucangjia" v-if="toshowSS">
            <div class="scList">
                <div class="scList_top">
                    <p>我的收藏夹</p>
                    <span @click="cjscj" v-if="!isChuang">创建</span>
                    <span @click="cjscj" v-else>取消</span>
                </div>
                <div class="cccc" v-if="isChuang">
                    <input type="text" v-model="scName">
                    <p @click="cjBtn">确定</p>
                </div>
                <ul v-if="CjList.length > 0">
                    <li v-for="(item, index) in CjList" :key="index" @click="scBtn1(item.id)">
                        <p>{{ item.name }}</p>
                        <span @click.stop="deleteSC(item.id)">删除</span>
                    </li>
                </ul>
                <div class="scKong" v-if="CjList.length == 0">
                    <p>暂时没有收藏夹</p>
                </div>
                <div class="qxsc">
                    <p @click="quxiaoSc">取消</p>
                </div>
            </div>
        </div>

        <div class="sqtanchuang" v-if="sqtan">
            <div class="sq_ccc">
                <div class="sq_ccc_t">
                    <h3>创作者申请</h3>
                    <img src="../../img/close_b.png" alt="" @click="closesq()">
                </div>

                <div class="sq_ccc_b">
                    <div class="search">
                        <div class="shuru">
                            <input type="text" placeholder="品牌" v-model="role" />
                            <img src="../../img/search.png" alt="">
                        </div>
                        <div class="button" @click="renlingBtn(detail.id)">
                            <p>立即申请</p>
                        </div>
                    </div>
                    <div class="bbb">
                        <ul class="bbb_list">
                            <li v-for="(item, index) in roleList1" :class="ssss == index ? 'active' : ''" :key="index"
                                @click="shaixuan1(item.label, index)">
                                <p>{{ item.label }}</p>
                                <span v-if="item.is_apply">
                                    <img src="../../img/duihao.png" alt="">
                                    已申请
                                </span>
                            </li>
                        </ul>
                        <div class="ms">
                            <p>没有匹配的职位，</p>
                            <p>填写后直接申请。</p>
                        </div>
                    </div>
                </div>
                <ul>


                    <!-- <el-select v-model="role" placeholder="请选择角色" @change="shaixuan1">
            <el-option v-for="item in roleList" :key="item.value" :label="item.label" :value="item.label">
            </el-option>
          </el-select> -->

                    <!-- <span @click="renlingBtn(detail.id)">认领</span> -->

                </ul>
            </div>
        </div>



    </div>
</template>

<script>
import { eventBus } from '../../App.vue';
export default {
    name: 'FavoriteArticle',

    data() {
        return {
            pageTitle: '我的项目',
            allList: [],
            role: '',
            toshowSS: false,
            detail: "",
            pinglunList: [],
            xqzhanshi: false,
            huodongShow: false,
            isScrollDisabled: false,
            textarea: '',
            roleList: '',
            isChuang: false,
            scName: '',
            CjList: '',

            sqtan: false,
            ssss: -1,

            activeIndex: '',
            textppp: '',
            plid: '',
            shuruttt: '',

        };
    },
    created() {
        document.title = this.pageTitle;
    },
    computed: {
        roleList1: {
            get() {
                console.log(this.roleList)
                if (this.roleList.length > 0) {
                    return this.roleList.filter(item => item.label.includes(this.role));
                } else {
                    return null;
                }

            },
            set(value) {
                // 如果需要，你可以在这里添加设置计算属性时的逻辑
                console.log(value)
            }

        }
    },
    mounted() {
        this.getList()
        this.getRole()
        this.getSCList()
        document.addEventListener("keyup", this.handleKeyUp);
    },

    methods: {

        huifu(e) {
            this.$axios.post('/api/user/comment/save', {
                type: 1,
                ext_id: e,
                content: this.textppp,
                comment_id: this.plid,
            }, {
                headers: {
                    'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
                }
            }).then((result) => {
                console.log(result)
                if (result.data.code == 200) {
                    this.$message({
                        message: result.data.message,
                        type: 'success'
                    });
                    this.textppp = "";
                    this.activeIndex = ''
                } else {
                    this.$message.error(result.data.message);
                }
            }).catch((err) => {
                console.log(err)
                // this.$message.error(err.response.data.message);
                if (err.response.data.code == 403 || err.response.data.code == 500) {
                    this.$message.error("请重新登录!");
                    setTimeout(() => {
                        eventBus.$emit('clearToken', '');
                        this.$router.push({
                            name: 'login'
                        })
                    }, 1500);
                } else {
                    this.$message.error(err.response.data.message)
                }
            });
        },

        huifuShowBtn(e, d, f) {
            console.log(e, d, f)
            this.activeIndex = e;
            this.plid = d;
            this.shuruttt = f
        },

        huifuShowBtn1() {
            this.activeIndex = ''
            this.plid = '';
            this.shuruttt = ''
        },




        searchBb() {
            console.log(this.role)
            setTimeout(() => {
                if (this.role != '') {
                    let newobject = {
                        label: '',
                    }
                    for (var i = 0; i < this.roleList.length; i++) {
                        if (this.role == this.roleList[i].label) {
                            this.roleList.splice(0, this.roleList.length)
                            newobject.label = this.role
                            this.roleList.push(newobject)
                        }
                    }
                    console.log(this.roleList)
                } else {
                    this.getRole()
                    console.log(456456)
                }
            }, 2000);

        },


        addguanzhu(e) {
            console.log("关注")
            this.$axios.get('/api/user/user_following/' + e.id, {
                headers: {
                    'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
                }
            }).then((result) => {
                console.log(result)
                if (result.data.code == 200) {
                    this.$message({
                        message: result.data.message,
                        type: 'success'
                    });
                    this.toxiangmu1(this.detail.id)
                } else {
                    this.$message.error(result.data.message);
                }
            }).catch((err) => {
                console.log(err)
                if (err.response.data.code == 403 || err.response.data.code == 500) {
                    this.$message.error('请先登录！');
                    setTimeout(() => {
                        eventBus.$emit('clearToken', '');
                        this.$router.push({
                            name: 'login'
                        })
                    }, 1500);
                }
            });
        },
        qxguanzhu(e) {
            console.log("quxiao")
            this.$axios.get('/api/user/user_following/' + e.id, {
                headers: {
                    'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
                }
            }).then((result) => {
                console.log(result)
                if (result.data.code == 200) {
                    this.$message({
                        message: result.data.message,
                        type: 'success'
                    });
                    this.toxiangmu1(this.detail.id)
                } else {
                    this.$message.error(result.data.message);
                }
            }).catch((err) => {
                console.log(err)
                if (err.response.data.code == 403 || err.response.data.code == 500) {
                    this.$message.error('请先登录！');
                    setTimeout(() => {
                        eventBus.$emit('clearToken', '');
                        this.$router.push({
                            name: 'login'
                        })
                    }, 1500);
                }
            });
        },



        shaixuan1(e, d) {
            console.log(e)
            console.log(d)
            this.role = e;
            this.ssss = d
        },
        anniuBtn(e) {
            this.$axios.get('/api/user/user_following/' + e, {
                headers: {
                    'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
                }
            }).then((result) => {
                console.log(result)
                if (result.data.code == 200) {
                    this.$message({
                        message: '关注成功!',
                        type: 'success'
                    });
                    // setTimeout(() => {
                    //   this.getDetail()
                    // }, 1500);
                }
            }).catch((err) => {
                console.log(err)
            });
        },
        //获取项目认领角色
        getRole() {
            this.$axios.get('api/select/project_role', {}).then((result) => {
                console.log(result)
                if (result.data.code == 200) {
                    this.roleList = result.data.data
                    this.roleList1 = result.data.data
                } else {
                    this.$message.error(result.data.message);
                }
            }).catch((err) => {
                console.log(err)
                this.$message.error(err.response.data.message);
            });
        },

        shenqing() {
            this.sqtan = true;
        },
        closesq() {
            this.sqtan = false;
        },


        //项目认领
        renlingBtn(d) {
            this.$axios.post('api/user/project_apply', {
                article_id: d,
                role: this.role,
            }, {
                headers: {
                    'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
                }
            }).then((result) => {
                console.log(result)
                if (result.data.code == 200) {
                    this.$message({
                        message: result.data.message,
                        type: 'success'
                    });
                    setTimeout(() => {
                        this.sqtan = false;
                        this.role = '';
                    }, 1000);
                } else {
                    this.$message.error(result.data.message);
                    setTimeout(() => {
                        this.sqtan = false;
                        this.role = '';
                    }, 1000);
                }
            }).catch((err) => {
                console.log(err)
                this.$message.error(err.response.data.message);
                setTimeout(() => {
                        this.sqtan = false;
                        this.role = '';
                    }, 1000);
                if (err.response.data.code == 403) {
                    this.$message.error("请重新登录！");
                    setTimeout(() => {
                        eventBus.$emit('clearToken', '');
                        this.$router.push({
                            name: 'login'
                        })
                    }, 1500);
                } else {
                    this.$message.error(err.response.data.message)
                }
            });
        },

        cjscj() {
            this.isChuang = !this.isChuang
        },
        //创建文件夹
        cjBtn() {
            if (this.scName == '') {
                this.$message.error('请输入文件夹名!');
                return
            }
            this.$axios.post('api/collection/create', {
                name: this.scName
            }, {
                headers: {
                    'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
                }
            }).then((result) => {
                console.log(result)
                if (result.data.code == 200) {
                    this.$message({
                        message: '创建成功!',
                        type: 'success'
                    });
                    this.getSCList();
                    this.isChuang = false;
                } else {
                    this.$message.error(result.data.message);
                }
            }).catch((err) => {
                console.log(err)
                if (err.response.data.code == 403) {
                    this.$message.error("请重新登录！");
                    setTimeout(() => {
                        eventBus.$emit('clearToken', '');
                        this.$router.push({
                            name: 'login'
                        })
                    }, 1500);
                } else {
                    this.$message.error(err.response.data.message)
                }
            });
        },

        //删除收藏夹
        deleteSC(e) {
            this.$axios.get('api/collection/delete/' + e, {
                headers: {
                    'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
                }
            }).then((result) => {
                if (result.data.code == 200) {
                    this.$message({
                        message: '删除成功!',
                        type: 'success'
                    });
                    this.getSCList();
                } else {
                    this.$message.error(result.data.message);
                }
            }).catch((err) => {
                console.log(err)
                if (err.response.data.code == 403) {
                    this.$message.error("请重新登录！");
                    setTimeout(() => {
                        eventBus.$emit('clearToken', '');
                        this.$router.push({
                            name: 'login'
                        })
                    }, 1500);
                } else {
                    this.$message.error(err.response.data.message)
                }
            });
        },

        //获取收藏列表
        getSCList() {
            this.$axios.get('api/collection/my_collect', {
                headers: {
                    'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
                }
            }).then((result) => {
                console.log(result)
                if (result.data.code == 200) {
                    this.CjList = result.data.data
                } else {
                    this.$message.error(result.data.message);
                }
            }).catch((err) => {
                console.log(err)
            });
        },

        quxiaoSc() {
            this.toshowSS = false;
        },

        closeBtn() {
            this.xqzhanshi = false;
            this.huodongShow = false;
            this.toshowSS = false;
            this.sqtan = false;

            this.huifuShowBtn1()

            document.body.classList.remove('disable-body-scroll');
        },

        handleKeyUp(event) {
            // console.log(event)
            if (event.key === 'Escape') {
                // 处理按下 Enter 键的逻辑
                console.log('esc 键被按下');
                this.closeBtn()
            }
        },

        //收藏
        scBtn() {
            console.log(1312312)
            this.toshowSS = !this.toshowSS
            // this.$axios.post('/api/user/collect/article', {
            //   id: e,
            //   type: 1,
            // }, {
            //   headers: {
            //     'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
            //   }
            // }).then((result) => {
            //   console.log(result)
            //   if (result.data.code == 200) {
            //     this.$message({
            //       message: '收藏成功！',
            //       type: 'success'
            //     });
            //   } else {
            //     this.$message.error(result.data.message);
            //   }
            // }).catch((err) => {
            //   console.log(err)
            //   this.$message.error(err.response.data.message);
            //   // this.$message.error("请先登录进行操作!");
            //   if (err.response.data.code == 403) {
            //     setTimeout(() => {
            //       this.$router.push({
            //         name: 'login'
            //       })
            //     }, 1500);
            //   }
            // });
        },


        scBtn1() {
            this.$axios.post('/api/user/collect/article', {
                id: this.detail.id,
                type: 1,
            }, {
                headers: {
                    'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
                }
            }).then((result) => {
                console.log(result)
                if (result.data.code == 200) {
                    this.$message({
                        message: '收藏成功！',
                        type: 'success'
                    });
                    setTimeout(() => {
                        this.$axios.get('api/pc/article/detail/' + this.detail.id, {
                            headers: {
                                'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
                            }
                        }).then((result) => {
                            console.log(result)
                            if (result.data.code == 200) {
                                this.detail = result.data.data;
                                this.pinglunList = result.data.data.comments
                            } else {
                                this.$message.error(result.data.message);
                            }
                        }).catch((err) => {
                            console.log(err)
                            if (err.response.data.code == 403) {
                                this.$message.error("请重新登录！");
                                setTimeout(() => {
                                    eventBus.$emit('clearToken', '');
                                    this.$router.push({
                                        name: 'login'
                                    })
                                }, 1500);
                            } else {
                                this.$message.error(err.response.data.message)
                            }
                        });
                        this.toshowSS = !this.toshowSS
                    }, 500);
                } else {
                    this.$message.error(result.data.message);
                    this.toshowSS = !this.toshowSS
                }
            }).catch((err) => {
                console.log(err)
                if (err.response.data.code == 403) {
                    this.$message.error("请重新登录！");
                    setTimeout(() => {
                        eventBus.$emit('clearToken', '');
                        this.$router.push({
                            name: 'login'
                        })
                    }, 1500);
                } else {
                    this.$message.error(err.response.data.message)
                }
            });
        },


        //点赞
        dzBtn(e) {
            this.$axios.post('/api/user/support/content', {
                id: e,
                type: 1,
            }, {
                headers: {
                    'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
                }
            }).then((result) => {
                console.log(result)
                if (result.data.code == 200) {
                    this.$message({
                        message: '点赞成功！',
                        type: 'success'
                    });

                    //重新获取详情接口
                    this.$axios.get('api/pc/article/detail/' + e, {
                        headers: {
                            'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
                        }
                    }).then((result) => {
                        console.log(result)
                        if (result.data.code == 200) {
                            this.detail = result.data.data;
                            this.pinglunList = result.data.data.comments
                        } else {
                            this.$message.error(result.data.message);
                        }
                    }).catch((err) => {
                        console.log(err)
                        if (err.response.data.code == 403) {
                            this.$message.error("请重新登录！");
                            setTimeout(() => {
                                eventBus.$emit('clearToken', '');
                                this.$router.push({
                                    name: 'login'
                                })
                            }, 1500);
                        } else {
                            this.$message.error(err.response.data.message)
                        }
                    });



                    //重新获取详情接口结束


                } else {
                    this.$message.error(result.data.message);
                }
            }).catch((err) => {
                console.log(err)
                if (err.response.data.code == 403) {
                    this.$message.error("请重新登录！");
                    setTimeout(() => {
                        eventBus.$emit('clearToken', '');
                        this.$router.push({
                            name: 'login'
                        })
                    }, 1500);
                } else {
                    this.$message.error(err.response.data.message)
                }
            });
        },

        //取消点赞
        qxdzBtn(e) {
            this.$axios.post('/api/user/unsupport/content', {
                id: e,
                type: 1,
            }, {
                headers: {
                    'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
                }
            }).then((result) => {
                console.log(result)
                if (result.data.code == 200) {
                    this.$message({
                        message: '取消点赞！',
                        type: 'success'
                    });

                    //重新获取详情接口
                    this.$axios.get('api/pc/article/detail/' + e, {
                        headers: {
                            'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
                        }
                    }).then((result) => {
                        console.log(result)
                        if (result.data.code == 200) {
                            this.detail = result.data.data;
                            this.pinglunList = result.data.data.comments
                        } else {
                            this.$message.error(result.data.message);
                        }
                    }).catch((err) => {
                        console.log(err)
                        if (err.response.data.code == 403) {
                            this.$message.error("请重新登录！");
                            setTimeout(() => {
                                eventBus.$emit('clearToken', '');
                                this.$router.push({
                                    name: 'login'
                                })
                            }, 1500);
                        } else {
                            this.$message.error(err.response.data.message)
                        }
                    });

                } else {
                    this.$message.error(result.data.message);
                }

            }).catch((err) => {
                console.log(err)
                if (err.response.data.code == 403) {
                    this.$message.error("请重新登录！");
                    setTimeout(() => {
                        eventBus.$emit('clearToken', '');
                        this.$router.push({
                            name: 'login'
                        })
                    }, 1500);
                } else {
                    this.$message.error(err.response.data.message)
                }
            });
        },



        fabu() {
            this.$router.push({
                name: 'fabu',
                query: {
                    type: 'project'
                }
            })
        },
        getList() {
            this.$axios.get('/api/__yao/table/personal.article/search', {
                headers: {
                    'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
                }
            }, {

                params: {
                    "where.type.eq": 'project'
                }
            }).then((result) => {
                console.log(result)
                if (result.data.code == 200) {
                    this.allList = result.data.data
                } else {
                    this.$message.error(result.data.message);
                }
            }).catch((err) => {
                console.log(err)
                if (err.response.data.code == 403) {
                    this.$message.error("请重新登录！");
                    setTimeout(() => {
                        eventBus.$emit('clearToken', '');
                        this.$router.push({
                            name: 'login'
                        })
                    }, 1500);
                } else {
                    this.$message.error(err.response.data.message)
                }
            });
        },
        todetail(e) {
            // this.$router.push({
            //     name: 'articleDetail',
            //     query: {
            //         id: e
            //     }
            // })


            document.body.classList.add('disable-body-scroll');
            this.huodongShow = true;
            if (window.localStorage.getItem('token')) {
                this.$axios.get('api/pc/article/detail/' + e, {
                    headers: {
                        'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
                    }
                }).then((result) => {
                    console.log(result)
                    if (result.data.code == 200) {
                        this.detail = result.data.data;
                        this.pinglunList = result.data.data.comments
                    } else {
                        this.$message.error(result.data.message);
                    }
                }).catch((err) => {
                    console.log(err)
                    if (err.response.data.code == 403) {
                        this.$message.error("请重新登录！");
                        setTimeout(() => {
                            eventBus.$emit('clearToken', '');
                            this.$router.push({
                                name: 'login'
                            })
                        }, 1500);
                    } else {
                        this.$message.error(err.response.data.message)
                    }
                });
            } else {
                this.$axios.get('api/pc/article/detail/' + e).then((result) => {
                    console.log(result)
                    if (result.data.code == 200) {
                        this.detail = result.data.data;
                        this.pinglunList = result.data.data.comments
                    } else {
                        this.$message.error(result.data.message);
                    }
                }).catch((err) => {
                    console.log(err)
                    if (err.response.data.code == 403) {
                        this.$message.error("请重新登录！");
                        setTimeout(() => {
                            eventBus.$emit('clearToken', '');
                            this.$router.push({
                                name: 'login'
                            })
                        }, 1500);
                    } else {
                        this.$message.error(err.response.data.message)
                    }
                });
            }



        },
        //收藏


        //评论
        pinglunBtn(e) {
            this.$axios.post('/api/user/comment/save', {
                type: 1,
                ext_id: e,
                content: this.textarea,
                comment_id: '',
            }, {
                headers: {
                    'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
                }
            }).then((result) => {
                console.log(result)
                if (result.data.code == 200) {
                    this.$message({
                        message: result.data.message,
                        type: 'success'
                    });
                    this.textarea = ""
                } else {
                    this.$message.error(result.data.message);
                }
            }).catch((err) => {
                console.log(err)
                this.$message.error(err.response.data.message);
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.index {
    margin-top: 30px;
    width: 100%;
    height: auto;

    .liebiao_con {
        width: 100%;
        height: auto;

        .allList {
            width: 100%;
            height: auto;
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            .list1 {
                margin-bottom: 30px;
                margin-right: 2%;
                width: 32%;
                height: 300px;
                cursor: pointer;

                &:nth-child(3n) {
                    margin-right: 0;
                }

                &:hover {
                    .img {
                        .xuanze {
                            opacity: 1;
                        }
                    }
                }

                .img {
                    position: relative;
                    width: 100%;
                    height: 200px;
                    border-radius: 10px;
                    overflow: hidden;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }

                    .tag {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 60px;
                        height: 30px;
                        border-radius: 10px 0 10px 0;
                        background: rgba($color: #000000, $alpha: 0.6);
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;

                        p {
                            color: #fff;
                            font-size: 14px;
                        }
                    }

                    .xuanze {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        z-index: 99;
                        opacity: 0;
                        width: 100%;
                        height: auto;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        box-sizing: border-box;
                        padding: 10px 20px;
                        background: linear-gradient(to bottom, transparent, #00000f);
                        transition: all 0.35s;

                        .ms {
                            width: 80%;
                            height: auto;

                            p {
                                font-size: 14px;
                                color: #fff;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                width: 100%;
                            }
                        }

                        .choose {
                            width: 20%;
                            height: 35px;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;

                            .choose1 {
                                width: 35px;
                                height: 35px;
                                cursor: pointer;

                                img {
                                    width: 100%;
                                    height: auto;
                                }
                            }
                        }
                    }
                }

                .des {
                    margin-top: 20px;
                    width: 100%;
                    height: auto;

                    .title {
                        margin-bottom: 10px;
                        width: 100%;
                        height: auto;

                        p {
                            font-size: 15px;
                            color: #212121;
                            width: 100%;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                        }
                    }

                    .xq {
                        width: 100%;
                        height: auto;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        .xq1 {
                            display: flex;
                            align-items: center;

                            img {
                                margin-right: 10px;
                                width: 26px;
                                height: 26px;
                                border-radius: 7px;
                            }

                            p {
                                font-size: 14px;
                                color: #666;
                            }
                        }

                        .xq2 {
                            display: flex;
                            align-items: center;

                            .sc {
                                display: flex;
                                align-items: center;
                                margin-right: 15px;

                                img {
                                    margin-right: 5px;
                                    width: 26px;
                                    height: 26px;
                                }

                                p {
                                    font-size: 15px;
                                    color: #666;
                                }
                            }
                        }
                    }
                }
            }
        }

        .chengyuan {
            .list1 {
                height: 330px;
                border-radius: 10px;
                box-sizing: border-box;
                padding: 20px;
                border: 1px solid #ccc;
                border-radius: 10px;

                &:hover {
                    background-color: #fafafa;
                }

                .cy_top {
                    margin-bottom: 20px;
                    width: 100%;
                    height: auto;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .cy_1 {
                        width: auto;
                        display: flex;
                        align-items: center;

                        img {
                            margin-right: 10px;
                            width: 50px;
                            height: 50px;
                        }

                        .detail {
                            display: flex;
                            flex-direction: column;

                            p {
                                font-size: 16px;
                                color: #212121;
                            }

                            span {
                                font-size: 14px;
                                color: #999;
                            }
                        }
                    }

                    .cy_2 {
                        width: 100px;
                        height: 35px;

                        .guanzhu {
                            width: 100%;
                            height: 100%;
                            border: 1px solid #212121;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 8px;

                            img {
                                margin-right: 10px;
                                width: auto;
                            }

                            p {
                                font-size: 14px;
                                color: #212121;
                            }
                        }

                        .weiguanzhu {
                            border: 1px solid #ff6600;
                            width: 100%;
                            height: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 8px;

                            i {
                                margin-right: 10px;
                                font-size: 20px;
                                color: #ff6600;
                            }

                            p {
                                font-size: 14px;
                                color: #ff6600;
                            }
                        }
                    }
                }

                .cy_bot {
                    width: 100%;
                    height: auto;
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;

                    .bot1 {
                        margin-bottom: 20px;
                        margin-right: 4%;
                        width: 48%;
                        height: 95px;

                        &:nth-child(2n) {
                            margin-right: 0;
                        }

                        img {
                            width: 100%;
                            border-radius: 10px;
                        }
                    }
                }
            }
        }

        .guanyu {
            width: 100%;
            height: auto;
            display: flex;
            align-items: baseline;
            justify-content: space-between;

            .guanyu_left {
                width: 75%;
                height: auto;
                display: flex;
                flex-direction: column;

                .jianjie {
                    width: 100%;
                    height: auto;
                    margin-bottom: 40px;

                    h2 {
                        margin-bottom: 25px;
                        font-size: 24px;
                        color: #212121;
                        font-weight: bold;
                    }

                    .neirong {
                        margin-bottom: 45px;
                        width: 100%;
                        height: auto;

                        p {
                            font-size: 15px;
                            color: #212121;
                            line-height: 33px;
                        }
                    }


                }

                .shanchang {
                    width: 100%;
                    height: auto;
                    margin-bottom: 40px;

                    h2 {
                        margin-bottom: 25px;
                        font-size: 24px;
                        color: #212121;
                        font-weight: bold;
                    }

                    .sc {
                        width: 100%;
                        height: auto;
                        display: flex;
                        align-items: center;
                        flex-wrap: wrap;

                        .tags {
                            width: auto;
                            padding: 0 15px;
                            margin-right: 15px;
                            height: 35px;
                            background-color: #f5f5f5;
                            border-radius: 8px;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;

                            p {
                                font-size: 15px;
                                color: #212121;
                            }
                        }
                    }
                }

                .pinpai {
                    width: 100%;
                    height: auto;
                    display: flex;
                    flex-wrap: wrap;

                    .pp {
                        width: 100px;
                        height: 100px;
                        border-radius: 8px;
                        overflow: hidden;
                        margin-right: 20px;
                        margin-bottom: 20px;

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }

            }

            .guanyu_right {
                width: 23%;
                height: auto;

                .xinxi {
                    margin-bottom: 40px;
                    width: 100%;
                    height: 145px;
                    border: 1px solid #ccc;
                    border-radius: 10px;
                    background-color: #fafafa;
                    box-sizing: border-box;
                    padding: 35px;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;

                    .xx1 {
                        margin-bottom: 20px;
                        width: 45%;
                        height: auto;
                        display: flex;
                        align-items: center;

                        img {
                            width: auto;
                            margin-right: 10px;
                        }

                        p {
                            font-size: 16px;
                            color: #212121;
                        }
                    }
                }

                .chengyuan {
                    width: 100%;
                    height: auto;

                    .chengyuan1 {
                        display: flex;
                        flex-wrap: wrap;

                        .cc {
                            margin-bottom: 15px;
                            width: 23%;
                            height: 90px;
                            margin-right: 2.6%;

                            &:nth-child(4n) {
                                margin-right: 0;
                            }

                            img {
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }

                    .more {
                        margin-top: 10px;
                        width: auto;

                        a {
                            color: #ff6600;
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }

    .kong {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .kong1 {
            width: 100%;
            height: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 20px;

            i {
                margin-right: 15px;
                font-size: 22px;
                color: #ff6600;
            }

            p {
                font-size: 16px;
                color: #ff6600;
                font-weight: bold;
            }
        }

        .anniu {
            width: 200px;
            height: 35px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background-color: #ff6600;
            border-radius: 5px;
            cursor: pointer;

            p {
                color: #fff;
                font-size: 15px;
            }
        }
    }

    .xqTanchuang1 {
        position: fixed;
        left: 0;
        top: 0;
        background: rgba($color: #000000, $alpha: 0.8);
        width: 100%;
        height: 100vh;
        // display: flex;
        // align-items: center;
        // justify-content: center;
        z-index: 777;
        box-sizing: border-box;
        overflow: auto;

        .closeanniu {
            position: fixed;
            right: 20px;
            top: 30px;
            width: 36px;
            height: 36px;
            cursor: pointer;

            img {
                width: 30px;
                height: 30px;
            }
        }

        .fudong {
            position: fixed;
            top: 7%;
            right: 9%;
            height: auto;
            width: 55px;
            z-index: 9999;
            display: flex;
            flex-direction: column;
            align-items: center;

            .fu1 {
                margin-bottom: 10px;
                width: 55px;
                height: auto;
                display: flex;
                flex-direction: column;
                align-items: center;

                img {
                    width: 55px;
                    height: 55px;
                    border: 1px solid #fff;
                    border-radius: 5px;
                }

                .anniu {
                    margin-top: -10px;
                    width: 15px;
                    height: 15px;
                    background-color: #ff6600;
                    border-radius: 50%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;

                    p {
                        font-size: 16px;
                        color: #fff;
                    }
                }
            }

            .fu2 {
                margin-bottom: 5px;
                width: 55px;
                height: auto;
                display: flex;
                flex-direction: column;
                align-items: center;
                cursor: pointer;

                &.active {
                    .img {
                        border: 1px solid #ff6600;
                    }

                    .anniu {
                        p {
                            color: #ff6600;
                        }
                    }
                }

                .img {
                    // margin-bottom: 5px;
                    width: 55px;
                    height: 55px;
                    border: 1px solid #fff;
                    border-radius: 5px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;

                    img {
                        width: 25px;
                        height: auto;
                    }
                }

                p {
                    font-size: 16px;
                    color: #fff;
                    font-weight: bold;
                }
            }
        }

        .index_nei {
            margin: auto;
            width: 70%;
            // height: 100%;
            box-sizing: border-box;
            padding: 0 2%;
            padding-top: 30px;
            padding-bottom: 100px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            background-color: #fff;

            .content {
                margin-bottom: 40px;
                width: 100%;
                height: auto;
                padding: 0;

                .title {
                    margin-bottom: 20px;
                    width: 100%;
                    height: auto;
                    display: flex;
                    flex-direction: column;

                    .detail {
                        margin-bottom: 20px;
                        width: 100%;
                        height: auto;
                        display: flex;
                        align-items: center;

                        img {
                            margin-right: 10px;
                            width: 60px;
                            height: 60px;
                            border-radius: 5px;
                        }

                        .detail1 {
                            display: flex;
                            flex-direction: column;

                            p {
                                font-size: 16px;
                                color: #212121;
                                font-weight: bold;
                                margin-bottom: 10px;
                            }

                            span {
                                font-size: 14px;
                                color: #212121;
                            }
                        }

                        p {
                            font-size: 30px;
                            color: #212121;
                            font-weight: bold;
                        }
                    }

                    h1 {
                        font-size: 30px;
                        color: #333;
                        font-weight: bold;
                    }
                }

                .des {
                    width: 100%;
                    height: auto;

                    p {
                        font-size: 15px;
                        color: #212121;
                        line-height: 30px;
                        text-indent: 32px;
                    }

                    img {
                        width: 100%;
                        height: auto;
                    }
                }

                .xiangqing {
                    width: 100%;
                    height: auto;
                    box-sizing: border-box;
                    padding: 25px;
                    background-color: #eeeeee;
                    display: flex;
                    flex-direction: column;

                    .xiangqing_top {
                        margin-bottom: 20px;
                        width: 100%;
                        height: auto;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        .xq_img {
                            display: flex;
                            align-items: center;

                            img {
                                margin-right: 10px;
                                width: 60px;
                                height: 60px;
                                border-radius: 5px;
                            }
                        }

                        .button {
                            width: auto;
                            height: 35px;
                            border: 1px solid #fff;
                            border-radius: 8px;
                            padding: 0 35px;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            cursor: pointer;
                            background-color: #ff6600;

                            p {
                                color: #fff;
                                font-size: 16px;
                            }
                        }
                    }

                    .xiangqing_bot {
                        width: 100%;
                        height: auto;

                        h3 {
                            font-size: 18px;
                            color: #212121;
                            margin-bottom: 15px;
                        }

                        .bot_c {
                            width: 100%;
                            height: auto;
                            // display: flex;
                            // align-items: center;
                            justify-content: space-between;

                            .bot1 {
                                float: left;
                                width: 100%;
                                height: auto;
                                display: flex;
                                flex-direction: column;

                                .list {
                                    margin-top: 15px;
                                    width: auto;
                                    display: flex;
                                    align-items: center;

                                    p {
                                        font-size: 15px;
                                        color: #212121;
                                        line-height: 30px;
                                        text-indent: 32px;
                                    }

                                    span {
                                        font-size: 15px;
                                        color: #000;
                                    }
                                }
                            }

                            .bot_renling {
                                float: right;
                                width: 19%;
                                height: auto;
                                box-sizing: border-box;
                                padding: 15px 10px;
                                background-color: #f4f4f4;
                                border: 1px solid #ccc;
                                border-radius: 10px;

                                .role {
                                    width: 100%;
                                    height: 100%;

                                    h2 {
                                        font-size: 18px;
                                        color: #212121;
                                        margin-bottom: 8px;
                                        padding-bottom: 8px;
                                        border-bottom: 1px solid #ccc;
                                    }

                                    ul {
                                        width: 100%;
                                        height: 100%;
                                        display: flex;
                                        flex-direction: column;

                                        li {
                                            display: flex;
                                            align-items: center;
                                            justify-content: space-between;
                                            width: 100%;
                                            height: 40px;

                                            .info {
                                                display: flex;
                                                align-items: center;

                                                img {
                                                    margin-right: 10px;
                                                    width: 35px;
                                                    height: 35px;
                                                    border-radius: 50%;
                                                    border: 1px solid #ccc;
                                                }
                                            }

                                            p {
                                                font-size: 14px;
                                                color: #212121;
                                            }

                                            i {
                                                font-size: 12px;
                                                color: #666;
                                                font-style: normal;
                                            }

                                            span {
                                                width: 60px;
                                                height: 30px;
                                                background-color: #ff6600;
                                                border-radius: 5px;
                                                text-align: center;
                                                line-height: 30px;
                                                color: #fff;
                                                cursor: pointer;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                    }
                }
            }

            .zuopin {
                margin-bottom: 40px;
                width: 100%;
                height: auto;
                display: flex;
                flex-direction: column;

                .title {
                    margin-bottom: 15px;
                    width: 100%;
                    height: auto;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    h2 {
                        font-size: 20px;
                        color: #212121;
                        font-weight: bold;
                    }

                    p {
                        font-size: 16px;
                        color: #212121;
                    }
                }

                .list {
                    width: 100%;
                    height: auto;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;

                    .list1 {
                        margin-bottom: 15px;
                        width: 23%;
                        height: 145px;
                        overflow: hidden;
                        border-radius: 8px;

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }

            .allDibu {
                width: 100%;
                height: auto;
                display: flex;
                justify-content: space-between;

                .dibu_left {
                    width: 73%;
                    height: auto;

                    .pinlun {
                        width: 100%;
                        height: auto;

                        .pinglun_shuru {
                            margin-bottom: 20px;
                            width: 100%;
                            height: auto;

                            h2 {
                                font-size: 20px;
                                color: #212121;
                                font-weight: bold;
                                margin-bottom: 20px;
                            }

                            .shuru {
                                width: 100%;
                                height: 215px;
                                display: flex;
                                justify-content: space-between;

                                .tx {

                                    width: 50px;
                                    height: 50px;
                                    border-radius: 8px;
                                    overflow: hidden;

                                    img {
                                        width: 100%;
                                        height: 100%;
                                    }
                                }

                                .kuang {
                                    width: 93%;
                                    height: 215px;
                                    display: flex;
                                    flex-direction: column;
                                    align-items: flex-end;

                                    .el-textarea {
                                        margin-bottom: 15px;
                                        width: 100%;
                                        height: 160px;

                                    }

                                    .anniu {
                                        width: 100px;
                                        height: 40px;
                                        border-radius: 8px;
                                        background-color: #ff6600;
                                        display: flex;
                                        flex-direction: column;
                                        align-items: center;
                                        justify-content: center;

                                        p {
                                            font-size: 16px;
                                            color: #fff;
                                        }
                                    }
                                }
                            }
                        }

                        .pl_list {
                            width: 100%;
                            height: auto;

                            h3 {
                                margin-bottom: 30px;
                                font-size: 20px;
                                color: #212121;
                            }

                            .allList {
                                width: 100%;
                                height: auto;

                                .list1 {
                                    margin-bottom: 40px;
                                    width: 100%;
                                    height: auto;
                                    display: flex;
                                    justify-content: space-between;

                                    .list1_img {

                                        width: 50px;
                                        height: 50px;
                                        border-radius: 8px;
                                        overflow: hidden;

                                        img {
                                            width: 100%;
                                            height: 100%;
                                        }
                                    }

                                    .list_xq {
                                        width: 93%;
                                        height: auto;
                                        display: flex;
                                        justify-content: space-between;

                                        .xq_left {
                                            width: 92%;
                                            height: auto;
                                            display: flex;
                                            flex-direction: column;

                                            .left_top {
                                                margin-bottom: 10px;
                                                width: 100%;
                                                height: auto;
                                                display: flex;
                                                flex-direction: column;

                                                p {
                                                    font-size: 16px;
                                                    color: #212121;
                                                    font-weight: bold;
                                                }

                                                span {
                                                    font-size: 14px;
                                                    color: #999;
                                                }
                                            }

                                            .left_bot {
                                                width: 100%;
                                                height: auto;

                                                p {
                                                    font-size: 15px;
                                                    color: #212121;
                                                    line-height: 30px;
                                                }

                                                .button {
                                                    margin-top: 15px;
                                                    width: 65px;
                                                    height: 30px;
                                                    display: flex;
                                                    flex-direction: column;
                                                    align-items: center;
                                                    justify-content: center;
                                                    font-size: 15px;
                                                    background-color: #eeeeee;
                                                    border-radius: 5px;
                                                    color: #212121;
                                                    cursor: pointer;
                                                }
                                            }

                                            .zichildren {
                                                display: flex;
                                                align-items: center;
                                                margin: 10px 0;
                                                width: 100%;
                                                height: auto;
        
                                                .tx {
                                                    margin-right: 10px;
                                                    width: 30px;
                                                    height: 30px;
                                                    border-radius: 5px;
                                                    overflow: hidden;
        
                                                    img {
                                                        width: 100%;
                                                        height: 100%;
                                                        object-fit: cover;
                                                    }
                                                }
        
                                                .zi_detail {
                                                    width: 100%;
                                                    height: auto;
        
                                                    .zi_top {
                                                        width: 100%;
                                                        height: auto;
                                                        display: flex;
                                                        align-items: center;
                                                        justify-content: space-between;
        
                                                        p {
                                                            font-size: 15px;
                                                            color: #000;
                                                        }
        
                                                        .zi_right {
                                                            width: auto;
                                                            display: flex;
                                                            align-items: center;
        
                                                            &.active {
                                                                p {
                                                                    color: #ff6600;
                                                                }
                                                            }
        
                                                            img {
                                                                margin-right: 5px;
                                                                width: 20px;
                                                                height: auto;
                                                            }
        
                                                            p {
                                                                font-size: 14px;
                                                            }
                                                        }
        
                                                    }
                                                }
                                            }
                                            .huifu_shu {
                                                margin-top: 15px;
                                                width: 100%;
                                                height: auto;
        
                                                .shu1 {
                                                    width: 100%;
                                                    height: auto;
                                                    background: #fff;
        
                                                    .el-input {
                                                        margin-bottom: 10px;
                                                    }
        
                                                    .button {
                                                        width: 65px;
                                                        height: 30px;
                                                        display: flex;
                                                        flex-direction: column;
                                                        align-items: center;
                                                        justify-content: center;
                                                        font-size: 15px;
                                                        background-color: #eeeeee;
                                                        border-radius: 5px;
                                                        color: #212121;
                                                        cursor: pointer;
                                                    }
                                                }
        
                                            }


                                        }

                                        .xq_right {
                                            width: auto;
                                            display: flex;
                                            align-items: center;

                                            &.active {
                                                p {
                                                    color: #ff6600;
                                                }
                                            }

                                            img {
                                                margin-right: 5px;
                                                width: 20px;
                                                height: auto;
                                            }

                                            p {
                                                font-size: 14px;
                                            }
                                        }
                                    }

                                }
                            }
                        }
                    }
                }

                .dibu_right {
                    width: 23%;
                    height: auto;

                    .right1 {
                        margin-bottom: 50px;
                        width: 100%;
                        height: auto;
                        display: flex;
                        flex-direction: column;

                        h3 {
                            font-size: 20px;
                            margin-bottom: 10px;
                            color: #212121;
                            font-weight: bold;
                        }

                        .right1_con {
                            width: 100%;
                            height: auto;
                            background-color: #fafafa;
                            border-radius: 10px;
                            box-sizing: border-box;
                            padding: 20px;
                            display: flex;
                            flex-direction: column;
                            justify-content: space-around;
                            border: 1px solid #ccc;

                            .con1 {
                                width: 100%;
                                height: auto;
                                display: flex;
                                justify-content: space-between;
                                margin-bottom: 15px;
                                cursor: pointer;

                                .con1_left {
                                    display: flex;
                                    align-items: center;

                                    img {
                                        margin-right: 10px;
                                        height: 90px;
                                        width: 90px;
                                        border-radius: 10px;
                                        object-fit: cover;
                                    }

                                    .detail {
                                        display: flex;
                                        flex-direction: column;
                                        width: 60%;

                                        p {
                                            font-size: 16px;
                                            color: #212121;
                                            font-weight: bold;
                                            margin-bottom: 15px;
                                        }

                                        span {
                                            font-size: 12px;
                                            color: #999;
                                            overflow: hidden;
                                            text-overflow: ellipsis;
                                            display: -webkit-box;
                                            -webkit-line-clamp: 2;
                                            line-clamp: 2;
                                            -webkit-box-orient: vertical;
                                        }
                                    }
                                }

                                .con1_right {
                                    width: 85px;
                                    height: 30px;

                                    .anniu {
                                        width: 100%;
                                        height: 100%;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        border: 1px solid #ff6600;
                                        border-radius: 5px;

                                        span {
                                            font-size: 18px;
                                            margin-right: 5px;
                                            color: #ff6600;
                                        }

                                        p {
                                            font-size: 14px;
                                            color: #ff6600;
                                        }
                                    }

                                    .anniuyi {
                                        width: 100%;
                                        height: 100%;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        border: 1px solid #212121;
                                        border-radius: 5px;

                                        img {
                                            width: 16px;
                                            margin-right: 5px;
                                        }

                                        p {
                                            font-size: 14px;
                                            color: #212121;
                                        }
                                    }
                                }
                            }

                            .con2 {
                                width: 100%;
                                height: auto;
                                display: flex;
                                align-items: center;
                                justify-content: space-between;

                                .con2_1 {
                                    display: flex;
                                    flex-direction: column;
                                    align-items: center;
                                    justify-content: center;

                                    p {
                                        font-size: 17px;
                                        color: #212121;
                                        font-weight: bold;
                                    }

                                    span {
                                        font-size: 14px;
                                        color: #212121;
                                    }
                                }
                            }

                            // .con2 {
                            //     width: 100%;
                            //     height: auto;
                            //     display: flex;
                            //     align-items: center;
                            //     justify-content: space-between;

                            //     .con2_1 {
                            //         display: flex;
                            //         align-items: center;
                            //         justify-content: center;
                            //         border: 1px solid #212121;
                            //         border-radius: 8px;
                            //         height: 35px;
                            //         width: 48%;
                            //         img{
                            //             margin-right: 5px;
                            //             width: 15px;
                            //         }
                            //         p {
                            //             font-size: 15px;
                            //             color: #212121;
                            //             font-weight: normal;
                            //         }


                            //     }
                            //     .con2_2 {
                            //         display: flex;
                            //         align-items: center;
                            //         justify-content: center;
                            //         border: 1px solid #ff6600;
                            //         border-radius: 8px;
                            //         padding: 0 15px;
                            //         height: 35px;
                            //         width: 48%;
                            //         p {
                            //             font-size: 15px;
                            //             color: #ff6600;
                            //             font-weight: bold;
                            //         }

                            //         span {
                            //             margin-right: 5px;
                            //             font-size: 20px;
                            //             color: #ff6600;
                            //         }
                            //     }
                            //     .con2_3{
                            //         display: flex;
                            //         align-items: center;
                            //         justify-content: center;
                            //         border: 1px solid #ff6600;
                            //         border-radius: 8px;
                            //         padding: 0 15px;
                            //         height: 35px;
                            //         width: 48%;
                            //         background-color: #ff6600;
                            //         img{
                            //             width: 15px;
                            //             margin-right: 5px;
                            //         }
                            //         p{
                            //             font-size: 15px;
                            //             color: #fff;
                            //         }
                            //     }
                            // }

                            .list {
                                width: 100%;
                                height: 95px;
                                border-radius: 8px;
                                overflow: hidden;
                                margin-bottom: 10px;
                                border: 1px solid #ccc;

                                img {
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                        }
                    }

                    .bot_renling {
                        // float: right;
                        width: 100%;
                        height: auto;
                        box-sizing: border-box;
                        padding: 15px 10px;
                        background-color: #f4f4f4;
                        border: 1px solid #ccc;
                        border-radius: 10px;

                        .role {
                            width: 100%;
                            height: 100%;

                            h2 {
                                font-size: 18px;
                                color: #212121;
                                margin-bottom: 8px;
                                padding-bottom: 8px;
                                border-bottom: 1px solid #ccc;
                            }

                            ul {
                                margin-bottom: 20px;
                                width: 100%;
                                height: 100%;
                                display: flex;
                                flex-direction: column;

                                li {
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                    width: 100%;
                                    height: 40px;

                                    .info {
                                        display: flex;
                                        align-items: center;

                                        img {
                                            margin-right: 10px;
                                            width: 35px;
                                            height: 35px;
                                            border-radius: 50%;
                                            border: 1px solid #ccc;
                                        }

                                        p {
                                            display: flex;
                                            flex-direction: column;
                                            align-items: baseline;
                                            justify-content: center;
                                            font-size: 15px;
                                            color: #212121;

                                            span {
                                                font-size: 12px;
                                                color: #666;
                                                font-style: normal;
                                            }
                                        }
                                    }

                                    .button {
                                        width: 70px;
                                        height: 30px;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        border-radius: 5px;
                                        background-color: #ff6600;
                                        border: 1px solid #ff6600;

                                        &.wei {
                                            background-color: #ccc;
                                            border: 1px solid #333;

                                            p {
                                                color: #212121;
                                            }

                                            img {
                                                width: auto;
                                                margin-right: 5px;
                                            }
                                        }

                                        p {
                                            font-size: 14px;
                                            color: #fff;
                                            line-height: 30px;
                                        }
                                    }

                                    i {
                                        font-size: 12px;
                                        color: #666;
                                        font-style: normal;
                                    }

                                    // span {
                                    //     width: 60px;
                                    //     height: 30px;
                                    //     background-color: #ff6600;
                                    //     border-radius: 5px;
                                    //     text-align: center;
                                    //     line-height: 30px;
                                    //     color: #fff;
                                    //     cursor: pointer;
                                    // }
                                }
                            }

                            .button {
                                width: 100%;
                                height: 42px;
                                background-color: #ff6600;
                                border-radius: 6px;
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                justify-content: center;
                                cursor: pointer;

                                p {
                                    color: #fff;
                                    font-size: 16px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .shoucangjia {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        z-index: 7777;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        .scList {
            width: 450px;
            height: auto;
            background-color: #fff;
            border-radius: 10px;
            padding: 15px;
            box-shadow: 0 0 10px #ccc;
            display: flex;
            flex-direction: column;

            .scList_top {
                margin-bottom: 15px;
                width: 100%;
                height: 35px;
                padding-bottom: 10px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-bottom: 1px solid #ccc;

                p {
                    font-size: 16px;
                    color: #212121;
                }

                span {
                    width: 60px;
                    height: 26px;
                    background-color: #ff6600;
                    line-height: 26px;
                    text-align: center;
                    border-radius: 5px;
                    cursor: pointer;
                    color: #fff;
                }
            }

            .cccc {
                width: 100%;
                height: 30px;
                background-color: #fff;
                display: flex;
                align-items: center;
                justify-content: space-between;

                input {
                    width: 78%;
                    height: 26px;
                    border: 1px solid #ccc;
                    border-radius: 5px;
                    box-sizing: border-box;
                    padding-left: 10px;
                    background: none;
                    outline: none;
                }

                p {
                    cursor: pointer;
                    width: 20%;
                    height: 26px;
                    background-color: #ff6600;
                    line-height: 26px;
                    text-align: center;
                    border-radius: 5px;
                    color: #fff;
                }
            }

            ul {
                width: 100%;
                height: auto;
                display: flex;
                flex-direction: column;

                li {
                    width: 100%;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    cursor: pointer;
                    border-bottom: 1px dashed #ccc;

                    p {
                        font-size: 14px;
                        color: #212121;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        width: 80%;
                    }

                    span {
                        width: 60px;
                        height: 26px;
                        background-color: #ff6600;
                        line-height: 26px;
                        text-align: center;
                        border-radius: 5px;
                        cursor: pointer;
                        color: #fff;
                    }
                }
            }

            .qxsc {
                margin-top: 20px;
                display: flex;
                align-items: center;
                justify-content: flex-end;

                p {
                    font-size: 14px;
                    color: #fff;
                    background-color: #999;
                    width: 70px;
                    height: 26px;
                    text-align: center;
                    line-height: 26px;
                    border-radius: 5px;
                    cursor: pointer;
                }
            }
        }
    }

    .sqtanchuang {


        position: fixed;
        left: 0;
        top: 0;
        background: rgba($color: #000000, $alpha: 0.8);
        width: 100%;
        height: 100vh;
        // display: flex;
        // align-items: center;
        // justify-content: center;
        z-index: 777;
        box-sizing: border-box;
        overflow: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .sq_ccc {
            width: 550px;
            height: 430px;
            box-sizing: border-box;
            padding: 15px 15px;
            background-color: #fff;
            border: 1px solid #ccc;
            border-radius: 10px;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            flex-direction: column;
        }

        .sq_ccc_t {
            width: 100%;
            height: auto;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #eee;
            padding-bottom: 15px;
            margin-bottom: 25px;

            h3 {
                font-size: 16px;
                color: #000;
            }

            img {
                position: relative;
                top: 4%;
                float: right;
                width: 20px;
                cursor: pointer;
            }
        }

        .sq_ccc_b {
            width: 100%;
            height: 400px;

            .search {
                width: 100%;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .shuru {
                    display: flex;
                    align-items: center;
                    width: 70%;
                    height: 42px;
                    box-sizing: border-box;
                    padding: 0 2%;
                    border: 1px solid #eee;
                    border-radius: 10px;

                    input {
                        width: 90%;
                        height: 42px;
                        border: none;
                        outline: none;
                        background: none;
                    }

                    img {
                        width: 18px;
                    }
                }

                .button {
                    width: 26%;
                    height: 40px;
                    background-color: #ff6600;
                    border-radius: 6px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;

                    p {
                        font-size: 15px;
                        color: #fff;
                    }
                }
            }

            .bbb {
                width: 100%;
                height: 300px;
                display: flex;
                justify-content: space-between;

                .bbb_list {
                    width: 70%;
                    height: 300px;
                    overflow: auto;
                    display: flex;
                    flex-direction: column;
                    box-sizing: border-box;
                    padding: 3% 0;
                    border-right: 1px solid #eee;


                    /* 设置滚动条的样式 */
                    &::-webkit-scrollbar {
                        width: 5px;
                        height: 5px;
                    }

                    /* 滚动槽 */
                    &::-webkit-scrollbar-track {
                        -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.3);
                        border-radius: 10px;
                        background: #fff;
                    }

                    /* 滚动条滑块 */
                    &::-webkit-scrollbar-thumb {
                        border-radius: 10px;
                        /* background:rgba(0,0,0,0.3); */
                        background: #F7F7F7;
                        -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
                    }

                    li {
                        width: 100%;
                        height: 40px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        cursor: pointer;
                        box-sizing: border-box;
                        padding-left: 3%;

                        &.active {
                            background-color: #eee;
                        }

                        &:hover {
                            background-color: #eee;
                        }
                    }
                }

                .ms {
                    width: 26%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    box-sizing: border-box;
                    padding-top: 4%;

                    p {
                        margin-bottom: 5px;
                        font-size: 13px;
                        color: #999;
                    }
                }
            }
        }


        // ul{
        //     padding-top: 4%;
        //     margin-top: 2%;
        //     width: 100%;
        //     border-top: 1px solid #ccc;
        //     height: 100%;
        //     display: flex;
        //     justify-content: space-around;
        //     li{
        //         display: flex;
        //         align-items: center;
        //         justify-content: space-between;
        //         width: 100%;
        //         height: 40px;
        //         .info{
        //             display: flex;
        //             align-items: center;
        //             img{
        //                 margin-right: 10px;
        //                 width: 40px;
        //                 height: 40px;
        //                 border-radius: 50%;
        //                 border: 1px solid #ccc;
        //             }
        //         }
        //         p{
        //             font-size: 14px;
        //             color: #212121;
        //         }
        //         i{
        //             font-size: 12px;
        //             color: #666;
        //             font-style: normal;
        //         }
        //         span{
        //             width:  60px;
        //             height: 30px;
        //             background-color: #ff6600;
        //             border-radius: 5px;
        //             text-align: center;
        //             line-height: 30px;
        //             color: #fff;
        //             cursor: pointer;
        //         }
        //     }

        //     span{
        //         width:  70px;
        //         height: 40px;
        //         background-color: #ff6600;
        //         border-radius: 5px;
        //         text-align: center;
        //         line-height: 40px;
        //         color: #fff;
        //         cursor: pointer;
        //         font-size: 16px;
        //     }


        // }
    }

    .your-table-class {
        border-collapse: collapse; /* 合并边框模型 */
        width: 100%;
        max-width: 100%;
        /* 设置表格的最大宽度 */
        margin: 0 auto;
        overflow-y: auto;
        /* 居中显示 */
    }

    /* 控制表格列宽度 */
    .your-table-class th,
    .your-table-class td {
        width: auto;
        /* 或者设置具体的宽度值 */
        border: 1px solid #ddd; /* 1像素宽度的实线边框，可以根据需要调整颜色 */
        padding: 8px; /* 为单元格添加一些内边距，使内容不会紧贴边框 */
    }

    .your-table-class th {
        background-color: #f2f2f2; /* 设置表头背景色 */
        color: #333; /* 设置表头文字颜色 */
    }

    /* 控制表格下方的间距 */
    .table-container {
        margin-bottom: 20px;
        /* 适当调整间距 */
    }

    /* 如果下方有其他元素，也要控制它们的间距 */
    .below-table-element {
        margin-top: 20px;
        /* 或者设置适当的间距 */
    }


}
</style>