<template>
  <div class="index">
    <!-- <headerbar :selected-nav="selectedNav"></headerbar> -->
    <div class="index">
      <div class="jobTop">
        <div class="jobTop_left">
          <div class="left_nav">
            <div class="nav" :class="{ active: choose === 'quanzhi' }" @click="chooseBtn('quanzhi')">
              <p>全职</p>
            </div>
            <div class="nav" :class="{ active: choose === 'ziyou' }" @click="chooseBtn('ziyou')">
              <p>自由职业</p>
            </div>
          </div>
          <div class="miaoshu" v-if="choose === 'quanzhi'">
            <p>平面设计工作排名第一的工作委员会</p>
            <span>会晤是设计社区的核心，也是发现和联系全球设计师和工作的最佳资源。</span>
          </div>
          <div class="miaoshu" v-if="choose === 'ziyou'">
            <p>自由平面设计工作</p>
            <span>项目库是合同工作的专属资源。它非常适合自由职业者、代理机构和兼职者。</span>
          </div>
          <div class="anniu" v-if="choose === 'quanzhi'">
            <div class="anniu1">
              <p>发布工作</p>
              <span>199元/月</span>
            </div>
            <div class="anniu2">
              <p>了解更多</p>
            </div>
          </div>
          <div class="anniu" v-if="choose === 'ziyou'">
            <div class="anniu1">
              <p>创建项目</p>
              <span>9.9元/个</span>
            </div>
            <div class="anniu2">
              <p>你是自由职业者吗？</p>
            </div>
          </div>
        </div>
        <div class="jobTop_right" v-if="choose === 'quanzhi'">
          <img src="../../img/job.png" alt="">
        </div>
        <div class="jobTop_right" v-if="choose === 'ziyou'">
          <img src="../../img/ziyou.png" alt="">
        </div>
      </div>
      <div class="jobCon" v-if="choose === 'quanzhi'">
        <div class="jobCon_1">
          <p>工作列表</p>
        </div>
        <div class="jobCon_c">

          <!-- <div class="shaixuanList">
            <div class="shaixuan1">
              <p>教育经历:</p>
              <el-radio-group v-model="jiaoyu" size="medium" @input="sxBtn">
                <el-radio-button v-for="(item,index) in edu" :key="index" :label="item.label"></el-radio-button>
              </el-radio-group>
            </div>
            <div class="shaixuan1">
              <p>工作经历:</p>
              <el-radio-group v-model="gongzuo" size="medium" @input="sxBtn1">
                <el-radio-button v-for="(item,index) in exp" :key="index" :label="item.label"></el-radio-button>
              </el-radio-group>
            </div>
            <div class="shaixuan1">
              <p>工作类型:</p>
              <el-radio-group v-model="leixing" size="medium" @input="sxBtn2">
                <el-radio-button v-for="(item,index) in work_type" :key="index" :label="item.label"></el-radio-button>
              </el-radio-group>
            </div>
          </div> -->

          <div class="jobList" v-if="jobList.length > 0">
            <div class="list1" v-for="(item, index) in jobList" :key="index">
              <div class="list1_left">
                <!-- <div class="img">
                  <img :src="item.pic" alt="">
                </div> -->
                <div class="des">
                  <p>{{ item.title }}</p>
                  <div class="tag">
                    <!-- <span v-for="(tag, index) in item.tags" :key="index">{{ tag.name }}</span> -->
                    <span>{{ item.slug }}</span>
                    <span>{{ item.address }}</span>
                  </div>
                </div>
              </div>
              <div class="list1_right">
                <p>{{ item.salary_low | integer }}-{{ item.salary_up | integer }}</p>
                <span>{{ item.published_at | datefmt('YYYY-MM-DD HH:mm') }}</span>
              </div>
              <div class="choose">
                <div class="choose1" @click="toDetail(item.id)">
                  <img src="../../img/chakan.png" alt="">
                  <p>查看职位</p>
                </div>
                <!-- <div class="choose1">
                  <img src="../../img/shenqing.png" alt="">
                  <p>立即申请</p>
                </div> -->
                <div style="clear: both;"></div>
              </div>
            </div>
          </div>
          <div class="kong" v-else>
            <img src="../../img/kong.png" alt="">
            <p>暂时没有职位信息</p>
          </div>
          <div class="jobRight">
            <div class="right1">

              <div class="right_con">
                <div class="con1">
                  <p>学历</p>
                </div>
                <el-radio-group v-model="jiaoyu" size="medium" @input="sxBtn">
                  <el-radio v-for="(item, index) in edu" :key="index" :label="item.label"></el-radio>
                </el-radio-group>
                <!-- <el-radio-group v-model="shuxing" @change="handleChecked">
                  <el-radio v-for="(city, index) in shuxingList" :label="city" :key="index">{{ city }}</el-radio>
                </el-radio-group> -->
                <div class="con1">
                  <p>年限</p>
                </div>
                <el-radio-group v-model="gongzuo" size="medium" @input="sxBtn1">
                  <el-radio v-for="(item, index) in exp" :key="index" :label="item.label"></el-radio>
                </el-radio-group>
              </div>
            </div>
            <div class="right1">
              <div class="right_top">
                <p>地点</p>
                <el-select v-model="value2" placeholder="全国" @change="shaixuan1">
                  <el-option v-for="item in options2" :key="item.code" :label="item.name" :value="item.code">
                  </el-option>
                </el-select>
              </div>
            </div>

            <div class="right1">
              <div class="right_top">
                <p>职位</p>
                <el-input placeholder="输入职位" suffix-icon="el-icon-search" v-model="timu">
                </el-input>
              </div>
              <!-- <div class="right_con">
                <el-radio-group v-model="zhiwu" @change="handleChecked1">
                  <el-radio v-for="(zw, index) in zhiwuList" :label="city" :key="index">{{ zw }}</el-radio>
                </el-radio-group>
              </div> -->
            </div>

            <div class="button" @click="getList">
              <p>筛选</p>
            </div>

          </div>
          <div style="clear: both;"></div>
        </div>

      </div>
      <div class="jobCon_ziyou" v-if="choose === 'ziyou'">
        <div class="jobCon_1">
          <p>近期新项目</p>
          <!-- <span>近日发布了12个新项目</span> -->
        </div>
        <div class="jobCon_c">
          <div class="jobList" v-if="jobList.length > 0">
            <div class="list1" v-for="(item, index) in jobList" :key="index">
              <div class="list1_top">
                <div class="title">
                  <div class="title_1">
                    <p>{{ item.title }}</p>
                    <!-- <span v-show="item.isNew">NEW</span> -->
                  </div>
                  <div class="title_2">
                    <p>{{ item.published_at | datefmt('YYYY-MM-DD HH:mm') }}</p>
                  </div>
                </div>
                <div class="money">
                  <p>{{ item.salary_low }}-{{ item.salary_up }}</p>
                </div>
              </div>
              <div class="list1_bottom">
                <div class="bottom1">
                  <p>{{ item.slug }}</p>
                </div>
                <div class="bottom2">
                  <div class="xuanze">
                    <div class="xuanze1" v-for="(tag, index) in item.标签" :key="index">
                      <p>{{ tag }}</p>
                    </div>
                  </div>
                  <div class="anniu" @click="todetail(item.id)">
                    <img src="../../img/chakan1.png" alt="">
                    <p>查看项目</p>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div class="kong" v-else>
            <img src="../../img/kong.png" alt="">
            <p>暂时没有职位信息</p>
          </div>

          <div class="jobRight">
            <div class="right1">

              <div class="right_con">
                <div class="con1">
                  <p>学历</p>
                </div>
                <el-radio-group v-model="jiaoyu" size="medium" @input="sxBtn">
                  <el-radio v-for="(item, index) in edu" :key="index" :label="item.label"></el-radio>
                </el-radio-group>
                <!-- <el-radio-group v-model="shuxing" @change="handleChecked">
                  <el-radio v-for="(city, index) in shuxingList" :label="city" :key="index">{{ city }}</el-radio>
                </el-radio-group> -->
                <div class="con1">
                  <p>年限</p>
                </div>
                <el-radio-group v-model="gongzuo" size="medium" @input="sxBtn1">
                  <el-radio v-for="(item, index) in exp" :key="index" :label="item.label"></el-radio>
                </el-radio-group>
              </div>
            </div>
            <div class="right1">
              <div class="right_top">
                <p>地点</p>
                <el-select v-model="value2" placeholder="全国" @change="shaixuan1">
                  <el-option v-for="item in options2" :key="item.code" :label="item.name" :value="item.code">
                  </el-option>
                </el-select>
              </div>
            </div>

            <div class="right1">
              <div class="right_top">
                <p>职位</p>
                <el-input placeholder="输入职位" suffix-icon="el-icon-search" v-model="timu">
                </el-input>
              </div>
              <!-- <div class="right_con">
                <el-radio-group v-model="zhiwu" @change="handleChecked1">
                  <el-radio v-for="(zw, index) in zhiwuList" :label="city" :key="index">{{ zw }}</el-radio>
                </el-radio-group>
              </div> -->
            </div>

            <div class="button" @click="getList">
              <p>筛选</p>
            </div>

          </div>
          <div style="clear: both;"></div>
        </div>

      </div>
    </div>
    <footerbar></footerbar>
  </div>
</template>

<script>
// import headerbar from '../../components/headerbar.vue';
import { eventBus } from '../../App.vue';
import footerbar from '../../components/footerbar.vue';
export default {
  name: 'findJob',
  data() {
    return {
      pageTitle: '找工作',
      choose: 'quanzhi',
      selectedNav: '找工作',
      input1: '',
      yuancheng: false,
      yuancheng1: false,
      address: '',
      address1: '',
      yusuan: '',
      city: '',
      value2: '',
      options2: [],
      jobList: [
        {
          pic: require('../../img/job1.png'),
          title: '资深营销设计师',
          money: '26k-30k',
          date: '2023-03-16',
          tags: [
            {
              name: '北京市',
            },
            {
              name: '全职',
            },
            {
              name: '可线上办公',
            },
          ],
        }, {
          pic: require('../../img/job1.png'),
          title: '资深营销设计师',
          money: '26k-30k',
          date: '2023-03-16',
          tags: [
            {
              name: '北京市',
            },
            {
              name: '全职',
            },
            {
              name: '可线上办公',
            },
          ],
        },

        {
          pic: require('../../img/job2.png'),
          title: '资深营销设计师',
          money: '26k-30k',
          date: '2023-03-16',
          tags: [
            {
              name: '北京市',
            },
            {
              name: '全职',
            },
            {
              name: '可线上办公',
            },
          ],
        },

        {
          pic: require('../../img/job3.png'),
          title: '资深营销设计师',
          money: '26k-30k',
          date: '2023-03-16',
          tags: [
            {
              name: '北京市',
            },
            {
              name: '全职',
            },
            {
              name: '可线上办公',
            },
          ],
        },

        {
          pic: require('../../img/job4.png'),
          title: '资深营销设计师',
          money: '26k-30k',
          date: '2023-03-16',
          tags: [
            {
              name: '北京市',
            },
            {
              name: '全职',
            },
            {
              name: '可线上办公',
            },
          ],
        },

        {
          pic: require('../../img/job5.png'),
          title: '资深营销设计师',
          money: '26k-30k',
          date: '2023-03-16',
          tags: [
            {
              name: '北京市',
            },
            {
              name: '全职',
            },
            {
              name: '可线上办公',
            },
          ],
        },



      ],
      ziyouList: [
        {
          title: '心理健康日记应用程序',
          money: '26k-30k',
          des: '我们了解简单徽标的重要性。识别和记忆很重要。但我们相信这个品牌的复杂标志符合我们的使命宣言。地球是复杂的，摄入我们的补充剂时发生的生物过程也是如此。我们想要..',
          isNew: true,
          tags: [
            {
              name: '用户界面/视觉设计',
            },
            {
              name: '插图',
            },
            {
              name: '品牌/平面设计',
            },
          ],
        },
        {
          title: '心理健康日记应用程序',
          money: '26k-30k',
          isNew: true,
          des: '我们了解简单徽标的重要性。识别和记忆很重要。但我们相信这个品牌的复杂标志符合我们的使命宣言。地球是复杂的，摄入我们的补充剂时发生的生物过程也是如此。我们想要..',
          tags: [
            {
              name: '用户界面/视觉设计',
            },
            {
              name: '插图',
            },
            {
              name: '品牌/平面设计',
            },
          ],
        },
        {
          title: '心理健康日记应用程序',
          money: '26k-30k',
          isNew: true,
          des: '我们了解简单徽标的重要性。识别和记忆很重要。但我们相信这个品牌的复杂标志符合我们的使命宣言。地球是复杂的，摄入我们的补充剂时发生的生物过程也是如此。我们想要..',
          tags: [
            {
              name: '用户界面/视觉设计',
            },
            {
              name: '插图',
            },
            {
              name: '品牌/平面设计',
            },
          ],
        },
        {
          title: '心理健康日记应用程序',
          money: '26k-30k',
          isNew: true,
          des: '我们了解简单徽标的重要性。识别和记忆很重要。但我们相信这个品牌的复杂标志符合我们的使命宣言。地球是复杂的，摄入我们的补充剂时发生的生物过程也是如此。我们想要..',
          tags: [
            {
              name: '用户界面/视觉设计',
            },
            {
              name: '插图',
            },
            {
              name: '品牌/平面设计',
            },
          ],
        },
        {
          title: '心理健康日记应用程序',
          money: '26k-30k',
          isNew: true,
          des: '我们了解简单徽标的重要性。识别和记忆很重要。但我们相信这个品牌的复杂标志符合我们的使命宣言。地球是复杂的，摄入我们的补充剂时发生的生物过程也是如此。我们想要..',
          tags: [
            {
              name: '用户界面/视觉设计',
            },
            {
              name: '插图',
            },
            {
              name: '品牌/平面设计',
            },
          ],
        },
      ],
      shuxing: [],
      shuxing1: [],
      zhiwu: [],
      zhiwuList: [
        '全职',
        '自由/合同'
      ],
      yusuanList: [
        '￥50-￥1000',
        '￥1000-￥10000',
        '￥10000-￥20000',
        '￥20000-￥50000',
        '￥50000以上',
      ],
      shuxingList: [
        '动画片',
        '平面/平面设计',
        '插图',
        '网页设计',
        'UI设计',
        '用户界面/视觉设计',
        '产品设计',
        '动效设计',
        '其他',
      ],
      shuxingList1: [
        '动画片',
        '平面/平面设计',
        '插图',
        '网页设计',
        'UI设计',
        '用户界面/视觉设计',
        '产品设计',
        '动效设计',
        '其他',
      ],
      jiaoyu: "不限",
      gongzuo: "不限",
      leixing: "全职",
      edu: '', //教育经历
      exp: '', //工作经历
      work_type: '',  //工作类型
      code: '',
      timu: '',

    }
  },
  created() {
    document.title = this.pageTitle;
  },
  mounted() {
    this.getList()
    this.getTiaojian()
    this.getprovince()
    this.setActivePage()
    this.showMobileNav()
  },
  components: {
    // headerbar,
    footerbar
  },
  methods: {
    setActivePage() {
      // 触发事件，传递当前页面的名称
      eventBus.$emit('updateActivePage', '找工作');
    },
    showMobileNav(){
            eventBus.$emit('shownav', '');
        },
    shaixuan1(e) {
      console.log(e)
      this.code = e;
      // this.getList()
    },
    //获取地区
    getprovince() {
      this.$axios.get('/api/pc/area', {}).then((result) => {
        console.log(result)
        if (result.data.code == 200) {
          this.options2 = result.data.data
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    chooseBtn(e) {
      this.choose = e;
      this.getList()
    },
    ycBtn(e) {
      console.log(e)
    },
    handleChecked(e) {
      console.log(e)
    },
    handleChecked1(e) {
      console.log(e)
    },
    handleChecked2(e) {
      console.log(e)
    },
    handleChecked3(e) {
      console.log(e)
    },

    sxBtn(e) {
      console.log(e)
      this.jiaoyu = e;
      // this.getList()
    },
    sxBtn1(e) {
      console.log(e)
      this.gongzuo = e;
      // this.getList()
    },
    sxBtn2(e) {
      console.log(e)
      this.leixing = e;
      this.getList()
    },
    //获取工作列表
    getList() {
      var work_type = '';
      var gongzuo = '';
      var jiaoyu = '';
      if (this.choose == "quanzhi") {
        work_type = "全职"
      }
      if (this.choose == "ziyou") {
        work_type = "兼职"
      }
      if (this.jiaoyu == '不限') {
        jiaoyu = ''
      } else {
        jiaoyu = this.jiaoyu
      }
      if (this.gongzuo == '不限') {
        gongzuo = ''
      } else {
        gongzuo = this.gongzuo
      }

      if (window.localStorage.getItem('token')) {
        this.$axios.get('api/__yao/table/pc.employ/search', {
          params: {
            "where.area.match": this.code,  //省市区筛选:传编码
            "where.title.match": this.timu, //职位标题搜索
            "where.exp.match": gongzuo, // 工作年限搜索
            "where.edu.match": jiaoyu, //学历筛选
            "where.work_type.match": work_type,  //工作性质搜索
            // "where.salary_low.ge": '',  //工资下限搜索
            // "where.salary_up.le": ''  //工资上限搜索
          }
        }, {
          headers: {
            'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
          }
        }).then((result) => {
          console.log(result)
          if (result.data.code == 200) {
            this.jobList = result.data.data
          } else {
            this.$message.error(result.data.message);
          }
        }).catch((err) => {
          console.log(err)
          this.$message.error(err.response.data.message);
        });
      } else {
        this.$axios.get('api/__yao/table/pc.employ/search', {
          params: {
            "where.area.match": this.code,  //省市区筛选:传编码
            "where.title.match": this.timu, //职位标题搜索
            "where.exp.match": gongzuo, // 工作年限搜索
            "where.edu.match": jiaoyu, //学历筛选
            "where.work_type.match": work_type,  //工作性质搜索
            // "where.salary_low.ge": '',  //工资下限搜索
            // "where.salary_up.le": ''  //工资上限搜索
          }
        }, {}).then((result) => {
          console.log(result)
          if (result.data.code == 200) {
            this.jobList = result.data.data
          } else {
            this.$message.error(result.data.message);
          }
        }).catch((err) => {
          console.log(err)
          this.$message.error(err.response.data.message);
        });
      }

    },

    //获取筛选条件
    getTiaojian() {
      this.$axios.get('/api/pc/job/select', {}).then((result) => {
        console.log(result)
        this.edu = result.data.edu;
        this.exp = result.data.exp;
        this.work_type = result.data.work_type;
      }).catch((err) => {
        console.log(err)
      });
    },

    //跳转详情
    toDetail(e) {
      this.$router.push({
        name: 'jobDetail',
        query: {
          id: e
        }
      })
    },



  },
}
</script>

<style scoped lang="scss">
@import '../findJob/findJob.scss'
</style>

<style>
.right_con .el-radio__inner {
  width: 23px;
  height: 23px;
  border-radius: 50%;
}

.right_con .el-radio__label {
  font-size: 15px;
}

.jobRight .el-select {
  width: 100%;
}

.yusuan .el-radio__label {
  font-weight: bold;
  color: #333;
  font-size: 16px;
  letter-spacing: 1px;
}

.right_con .el-radio__inner::after {
  left: 10px;
  top: 10px;
  width: 12px;
  height: 12px;
}

.right_con .el-radio {
  margin-bottom: 10px;
}

.right_con .el-radio__input.is-checked .el-radio__inner,
.el-radio__input.is-indeterminate .radio__inner {
  background: #ff6600;
  border-color: #ff6600;
}

.right_con .el-radio__input.is-checked+.el-radio__label {
  color: #212121;
}
</style>