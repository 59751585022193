<template>
  <div class="footer">
    <div class="footer_con">
      <div class="footer_top">
        <div class="footer1">
          <div class="logo">
            <img src="../img/logo1.png" alt="">
            <p>会晤网站是全球领先的创意分享、成长和就业社区</p>
          </div>
          <div class="fenxiang">
            <div class="f1">
              <img src="../img/facebook.png" alt="">
            </div>
            <div class="f1">
              <img src="../img/twitter.png" alt="">
            </div>
            <div class="f1">
              <img src="../img/google.png" alt="">
            </div>
            <div class="f1">
              <img src="../img/tubiao.png" alt="">
            </div>
          </div>
        </div>
        <div class="footer2">
          <div class="footer2_1">
            <p>关于我们</p>
            <ul>
              <li>
                <a href="###">关于我们</a>
              </li>
              <li>
                <a href="###">合作合作</a>
              </li>
              <li>
                <a href="###">招聘信息</a>
              </li>

            </ul>
          </div>
          <div class="footer2_1">
            <p>联系我们</p>
            <ul>
              <li>
                <a href="###">联系我们</a>
              </li>
              <li>
                <a href="###">商务合作</a>
              </li>
              <li>
                <a href="###">投稿</a>
              </li>

            </ul>
          </div>
          <div class="footer2_1">
            <p>帮助</p>
            <ul>
              <li>
                <a href="###">帮助中心</a>
              </li>
              <li>
                <a href="###">用户协议</a>
              </li>
              <li>
                <a href="###">意见反馈</a>
              </li>

            </ul>
          </div>
        </div>
        <div class="footer3">
          <div class="footer3_1">
            <img src="../img/erweima.png" alt="">
            <p>微信公众号</p>
          </div>
          <div class="footer3_1">
            <img src="../img/erweima.png" alt="">
            <p>商务微信</p>
          </div>
          <div class="footer3_1">
            <img src="../img/erweima.png" alt="">
            <p>其他公众号</p>
          </div>
        </div> 
      </div>
      <div class="footer_bot">
        <p>Copyright©2016-2022 会晤网站 版权所有</p>
        <p>沪ICP备16053114号ICP证:沪B2-20180032</p>
        <p>沪公网安备31011502400137号 </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped lang="scss">
.footer {
  width: 100%;
  height: 290px;
  background: #212121;
  display: flex;
  align-items: center;
  justify-content: center;
  .footer_con{
    width: 95%;
    height: 290px;
    .footer_top{
      margin: 40px 0;
      width: 100%;
      height: 170px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .footer1{
        display: flex;
        flex-direction: column;
        .logo{
          display: flex;
          flex-direction: column;
          margin-bottom: 30px;
          img{
            width: 175px;
            margin-bottom: 10px;
          }
          p{
            font-size: 14px;
            color: #999;
          }
        }
        .fenxiang{
          width: auto;
          display: flex;
          align-items: center;
          .f1{
            margin-right: 15px;
            width: auto;
            img{
              width: auto;
            }
          }
        }
      }
      .footer2{
        width: auto;
        height: auto;
        display: flex;
        align-items: center;
        .footer2_1{
          width: auto;
          height: auto;
          display: flex;
          flex-direction: column;
         justify-content: flex-start;
          margin: 0 40px;
          p{
            margin-bottom: 18px;
            font-size: 16px;
            color: #fff;
            text-align: left;
          }
          ul{
            width:auto;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            li{
              width: auto;
              margin-bottom: 18px;
              text-align: left;
              a{
                font-size: 16px;
                color: #999;
              }
            }
          }
        }
      }
      .footer3{
        width: auto;
        display: flex;
        align-items: center;
        .footer3_1{
          margin: 0 10px;
          width: 120px;
          height: auto;
          img{
            width: 120px;
            height: 120px;
            border-radius: 10px;
            margin-bottom: 10px;
          }
          p{
            font-size: 14px;
            color: #999;
            text-align: center;
          }
        }
      }
    }
    .footer_bot{
      margin-top: 20px;
      width: 100%;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      p{
        font-size: 14px;
        color: #999;
        margin: 0 20px;
      }
    }
  }

}
</style>