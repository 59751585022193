<template>
    <div class="person_1">
        <div class="person_1_head">
            <!-- <router-link to="FavoriteArticle" class="sc_1" active-class="active">
                <p>收藏的文章</p>
            </router-link> -->
            <!-- <router-link to="/FavoriteProject" class="sc_1" active-class="active">
                <p>收藏的项目(0)</p>
            </router-link>
            <router-link to="/FavoriteJob" class="sc_1" active-class="active">
                <p>收藏的职位(0)</p>
            </router-link> -->
        </div>
        <div class="person_1_con">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MyFavorite',

    data() {
        return {

        };
    },

    mounted() {

    },

    methods: {

    },
};
</script>

<style lang="scss" scoped>
.person_1 {
    width: 100%;
    height: auto;

    .person_1_head {
        margin-top: 15px;
        margin-bottom: 25px;
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;

        .sc_1 {
            margin-right: 20px;
            height: 40px;
            cursor: pointer;

            &.active {
                color: #ff6600;
                font-weight: bold;
            }

            p {
                line-height: 40px;
                font-size: 15px;
            }
        }
    }
}</style>