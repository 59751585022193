<template>
  <div class="index">
    <!-- <headerbar :selected-nav="selectedNav"></headerbar> -->
    <div class="banner">

      <swiper ref="visualizationSwiper" :options="swiperOptions" v-if="bannerList.length > 0">
        <swiper-slide v-for="(item, index) in bannerList" :key="index">
          <div class="pic">
            <img :src="item.banner" alt="">
          </div>
        </swiper-slide>
      </swiper>
      <div class="swiper-progress-bar"></div>
      <div class="swiper-pagination" slot="pagination"></div>
      <div class="swiper-arrow swiper-prev"></div>
      <div class="swiper-arrow swiper-next"></div>

    </div>
    <div class="content">
      <!-- <div class="content_top">
        <div class="leftb">
          <p>TOPYS Live正式上线，更多创意节目等你来点播！</p>
        </div>
        <div class="rightb">
          <p>进入观看</p>
          <img src="../../img/index_arrow.png" alt="">
        </div>
      </div> -->
      <div class="content_con">
        <div class="con_left">
          <div class="left_top">
            <div class="choose">
              <div class="choose1" :class="tuijian ? 'active' : ''" @click="tuijianBtn">
                <img src="../../img/tuijian_a.png" alt="" v-if="tuijian">
                <img src="../../img/tuijian_a.png" alt="" v-else>
                <p>为您推荐</p>
              </div>
              <div class="choose1" :class="guanzhu ? 'active' : ''" @click="guanzhuBtn">
                <img src="../../img/guanzhu_a.png" alt="" v-if="guanzhu">
                <img src="../../img/guanzhu.png" alt="" v-else>
                <p>关注</p>
              </div>
              <div class="choose1" :class="dingyue ? 'active' : ''" @click="dingyueBtn">
                <img src="../../img/dingyue_a.png" alt="" v-if="dingyue">
                <img src="../../img/dingyue.png" alt="" v-else>
                <p>您的活动</p>
              </div>

              <!-- <div @click="cccc">清楚</div> -->

            </div>
            <div class="fenlei">
              <div class="fenlei1" :class="shupai ? 'active' : ''" @click="shupaiBtn">
                <img src="../../img/shupai_a.png" alt="" v-if="shupai">
                <img src="../../img/shupai.png" alt="" v-else>
              </div>
              <div class="fenlei1" :class="hengpai ? 'active' : ''" @click="hengpaiBtn">
                <img src="../../img/hengpai_a.png" alt="" v-if="hengpai">
                <img src="../../img/hengpai.png" alt="" v-else>
              </div>
            </div>
          </div>
          <div class="left_list" v-if="hengpai && tuijian">
            <div class="list1" v-for="(item, index) in displayedData" :key="index" @click="todetail(item)">
              <div class="img">
                <img :src="item.cover[0]" alt="">
                <div class="tag">
                  <p v-if="item.type == 'article'">文章</p>
                  <p v-if="item.type == 'project'">项目</p>
                </div>
                <div class="xuanze">
                  <div class="ms">
                    <p>{{ item.title }}</p>
                  </div>
                  <div class="choose">
                    <div class="choose1">
                      <img src="../../img/shoucang.png" alt="" @click.stop="scBtn(item.id)">
                    </div>
                    <div class="choose1" @click.stop="dzBtn(item.id)">
                      <img src="../../img/dianzan.png" alt="" v-if="item.is_support == true">
                      <img src="../../img/dianzan.png" alt="" v-else>
                    </div>
                  </div>
                </div>
              </div>
              <div class="des">
                <div class="title">
                  <p>{{ item.title }}</p>
                </div>
                <div class="xq">
                  <div class="xq1">
                    <img v-if="item.user.avatar != null" :src="item.user.avatar" alt="">
                    <img src="@/img/tx.jpg" alt="" v-else>
                    <p>{{ item.author }}</p>
                  </div>
                  <div class="xq2">
                    <div class="sc">
                      <img src="../../img/shoucang.png" alt="">
                      <p>{{ item.collect_count }}</p>
                    </div>
                    <div class="sc">
                      <img src="../../img/tuijian_a.png" alt="" v-if="item.is_support">
                      <img src="../../img/dianzan.png" alt="" v-else>
                      <p>{{ item.support_count }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="left_list_shu" v-if="shupai && tuijian">
            <div class="list1" v-for="(item, index) in displayedData" :key="index" @click="todetail(item)">
              <div class="img">
                <img :src="item.cover[0]" alt="">
                <div class="tag">
                  <p v-if="item.type == article">文章</p>
                  <p v-if="item.type == project">项目</p>
                </div>

              </div>
              <div class="des">
                <div class="title">
                  <p>{{ item.title }}</p>
                  <span v-if="item.content != null">{{ item.content[0].data.text }}</span>
                </div>
                <div class="xq">
                  <div class="xq1">
                    <img v-if="item.user.avatar != null" :src="item.user.avatar" alt="">
                    <p>{{ item.author }}</p>
                    <span>{{ item.published_at | datefmt('YYYY-MM-DD HH:mm') }}</span>
                  </div>
                  <div class="xq2">
                    <div class="sc">
                      <img src="../../img/shoucang.png" alt="">
                      <p>{{ item.collect_count }}</p>
                    </div>
                    <div class="sc">
                      <img src="../../img/dianzan.png" alt="">
                      <p>{{ item.support_count }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="left_list" v-if="guanzhu && hengpai && guanzhuList.length > 0">
            <div class="list1" v-for="(item, index) in guanzhuList" :key="index" @click="todetail(item)">
              <div class="img">
                <img :src="item.cover[0]" alt="">
                <div class="tag">
                  <p v-if="item.type == 'article'">文章</p>
                  <p v-if="item.type == 'project'">项目</p>
                </div>
                <div class="xuanze">
                  <div class="ms">
                    <p>{{ item.title }}</p>
                  </div>
                  <div class="choose">
                    <div class="choose1">
                      <img src="../../img/shoucang.png" alt="" @click.stop="scBtn(item.id)">
                    </div>
                    <div class="choose1" @click.stop="dzBtn(item.id)">
                      <img src="../../img/dianzan.png" alt="" v-if="item.isdianzan == true">
                      <img src="../../img/dianzan.png" alt="" v-else>
                    </div>
                  </div>
                </div>
              </div>
              <div class="des">
                <div class="title">
                  <p>{{ item.title }}</p>
                </div>
                <div class="xq">
                  <div class="xq1">
                    <!-- <img v-if="item.user.avatar != null" :src="item.user.avatar" alt="">
                <img src="@/img/tx.jpg" alt="" v-else> -->
                    <p>{{ item.author }}</p>
                  </div>
                  <div class="xq2">
                    <div class="sc">
                      <img src="../../img/shoucang.png" alt="">
                      <p>{{ item.collect_count }}</p>
                    </div>
                    <div class="sc">
                      <img src="../../img/dianzan.png" alt="">
                      <p>{{ item.support_count }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="left_list_shu" v-if="guanzhu && shupai && guanzhuList.length > 0">
            <div class="list1" v-for="(item, index) in guanzhuList" :key="index" @click="todetail(item)">
              <div class="img">
                <img :src="item.cover[0]" alt="">
                <div class="tag">
                  <p v-if="item.type == 'article'">文章</p>
                  <p v-if="item.type == 'project'">项目</p>
                </div>
                <div class="xuanze">
                  <div class="ms">
                    <p>{{ item.title }}</p>
                  </div>
                  <div class="choose">
                    <div class="choose1">
                      <img src="../../img/shoucang.png" alt="" @click.stop="scBtn(item.id)">
                    </div>
                    <div class="choose1" @click.stop="dzBtn(item.id)">
                      <img src="../../img/dianzan.png" alt="" v-if="item.isdianzan == true">
                      <img src="../../img/dianzan.png" alt="" v-else>
                    </div>
                  </div>
                </div>
              </div>
              <div class="des">
                <div class="title">
                  <p>{{ item.title }}</p>
                </div>
                <div class="xq">
                  <div class="xq1">
                    <!-- <img v-if="item.user.avatar != null" :src="item.user.avatar" alt="">
                <img src="@/img/tx.jpg" alt="" v-else> -->
                    <p>{{ item.author }}</p>
                  </div>
                  <div class="xq2">
                    <div class="sc">
                      <img src="../../img/shoucang.png" alt="">
                      <p>{{ item.collect_count }}</p>
                    </div>
                    <div class="sc">
                      <img src="../../img/dianzan.png" alt="">
                      <p>{{ item.support_count }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="guanzhu_kong"
            v-if="guanzhu == true && guanzhuList.length == 0 || dingyueList.length == 0 && dingyue == true">
            <img src="../../img/kong.png" alt="">
            <p>还没有相关的信息哦！</p>
          </div>



          <div class="left_list" v-if="dingyue && hengpai && dingyueList.length > 0">
            <div class="list1" v-for="(item, index) in dingyueList" :key="index" @click="toxiangmu(item.id)">
              <div class="img">
                <img :src="item.cover[0]" alt="">
                <div class="tag">
                  <p>活动</p>
                </div>
                <div class="xuanze">
                  <div class="ms">
                    <p>{{ item.title }}</p>
                  </div>
                  <div class="choose">
                    <div class="choose1">
                      <img src="../../img/shoucang.png" alt="" @click.stop="scBtn(item.id)">
                    </div>
                    <div class="choose1" @click.stop="dzBtn(item.id)">
                      <img src="../../img/dianzan.png" alt="" v-if="item.isdianzan == true">
                      <img src="../../img/dianzan.png" alt="" v-else>
                    </div>
                  </div>
                </div>
              </div>
              <div class="des">
                <div class="title">
                  <p>{{ item.title }}</p>
                </div>
                <div class="xq">
                  <div class="xq1">
                    <!-- <img v-if="item.user.avatar != null" :src="item.user.avatar" alt="">
                <img src="@/img/tx.jpg" alt="" v-else> -->
                    <p>{{ item.author }}</p>
                  </div>
                  <div class="xq2">
                    <div class="sc">
                      <img src="../../img/shoucang.png" alt="">
                      <p>{{ item.collect_count }}</p>
                    </div>
                    <div class="sc">
                      <img src="../../img/dianzan.png" alt="">
                      <p>{{ item.support_count }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="left_list_shu" v-if="dingyue && shupai && dingyueList.length > 0">
            <div class="list1" v-for="(item, index) in dingyueList" :key="index" @click="toxiangmu(item.id)">
              <div class="img">
                <img :src="item.cover[0]" alt="">
                <div class="tag">
                  <p>活动</p>
                </div>
                <div class="xuanze">
                  <div class="ms">
                    <p>{{ item.title }}</p>
                  </div>
                  <div class="choose">
                    <div class="choose1">
                      <img src="../../img/shoucang.png" alt="" @click.stop="scBtn(item.id)">
                    </div>
                    <div class="choose1" @click.stop="dzBtn(item.id)">
                      <img src="../../img/dianzan.png" alt="" v-if="item.isdianzan == true">
                      <img src="../../img/dianzan.png" alt="" v-else>
                    </div>
                  </div>
                </div>
              </div>
              <div class="des">
                <div class="title">
                  <p>{{ item.title }}</p>
                </div>
                <div class="xq">
                  <div class="xq1">
                    <!-- <img v-if="item.user.avatar != null" :src="item.user.avatar" alt=""> -->
                    <!-- <img src="@/img/tx.jpg" alt="" v-else> -->
                    <p>{{ item.author }}</p>
                  </div>
                  <div class="xq2">
                    <div class="sc">
                      <img src="../../img/shoucang.png" alt="">
                      <p>{{ item.collect_count }}</p>
                    </div>
                    <div class="sc">
                      <img src="../../img/dianzan.png" alt="">
                      <p>{{ item.support_count }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div class="more" @click="loadMore" v-if="tuijian && hasMore">
            <p>加载更多</p>
          </div>
          <div class="more" v-if="tuijian && !hasMore">
            <p>没有更多了！</p>
          </div>
        </div>
        <div class="con_right">
          <div class="right1" v-for="(item, index) in ggList.slice(0, 3)" :key="index" @click="toxiangmu(item.id)">
            <img :src="item.cover[0]" alt="">
          </div>
          <div class="zuozhe" v-if="zuozheList.length > 0">
            <div class="zuozhe_top">
              <p>他们在这里</p>
              <img src="../../img/arrow1.png" alt="">
            </div>
            <div class="zuozheList">
              <div class="zuozhe1" v-for="(item, index) in zuozheList" :key="index" @click="niurentodetail(item.id)">
                <img :src="item.avatar" alt="">
              </div>
            </div>
          </div>
          <div class="chuangshi" v-if="csList.length > 0">
            <div class="zuozhe_top">
              <p>创始人在这里</p>
              <img src="../../img/arrow1.png" alt="">
            </div>
            <div class="csList">
              <div class="cs" v-for="(item, index) in csList" :key="index" @click="gstodetail(item.id)">
                <img :src="item.logo" alt="">
                <p>{{ item.name }}</p>
                <!-- <span>{{ item.des }}</span> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="shipinList">
        <div class="shipin_top">
          <p>更多项目</p>
        </div>
        <div class="spList">
          <div class="sp" v-for="(item, index) in projectList" :key="index" @click="toxiangmu1(item.id)">
            <div class="img">
              <img class="tu" :src="item.cover[0]" alt="">
              <!-- <img class="biaozhi" src="../../img/shipin.png" alt=""> -->
              <!-- <div class="time">
                <p>03:20</p>
              </div> -->
            </div>
            <div class="des">
              <div class="title">
                <p>{{ item.title }}</p>
              </div>
              <div class="xq">
                <div class="xq1">
                  <img v-if="item.user.avatar != null" :src="item.user.avatar" alt="">
                  <img src="@/img/tx.jpg" alt="" v-else>
                  <p>{{ item.author }}</p>
                </div>
                <div class="xq2">
                  <div class="sc">
                    <img src="../../img/shoucang.png" alt="">
                    <p>{{ item.collect_count }}</p>
                  </div>
                  <div class="sc">
                    <img src="../../img/dianzan.png" alt="">
                    <p>{{ item.support_count }}</p>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div class="more" @click="moreproject">
          <p>查看更多项目</p>
        </div>
      </div>
      <div class="zuopinmore">
        <div class="z_top">
          <p>更多设计作品</p>
        </div>
        <div class="zpList">
          <div class="zp" v-for="(item, index) in zuopinList" :key="index" @click="posterDetail(item.id)">
            <div class="img">
              <img :src="item.cover[0]" alt="">
            </div>
            <div class="xq">
              <div class="xq1">
                <img v-if="item.user.avatar != null" :src="item.user.avatar" alt="">
                <img src="@/img/tx.jpg" alt="" v-else>
                <p>{{ item.author }}</p>
              </div>
              <div class="xq2">
                <div class="sc">
                  <img src="../../img/shoucang.png" alt="">
                  <p>{{ item.collect_count }}</p>
                </div>
                <div class="sc">
                  <img src="../../img/dianzan.png" alt="">
                  <p>{{ item.support_count }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="more" @click="moresheji">
          <p>查看更多设计</p>
        </div>
      </div>
      <div class="shipinList">
        <div class="shipin_top">
          <p>更多视频作品</p>
        </div>
        <div class="spList">
          <div class="sp" v-for="(item, index) in shipinList" :key="index" @click="videoDetail(item.id)">
            <div class="img">
              <img class="tu" :src="item.cover[0]" alt="">
              <img class="biaozhi" src="../../img/shipin.png" alt="">
              <!-- <div class="time">
                <p>03:20</p>
              </div> -->
            </div>
            <div class="des">
              <div class="title">
                <p>{{ item.title }}</p>
              </div>
              <div class="xq">
                <div class="xq1">
                  <img v-if="item.user.avatar != null" :src="item.user.avatar" alt="">
                  <img src="@/img/tx.jpg" alt="" v-else>
                  <p>{{ item.author }}</p>
                </div>
                <div class="xq2">
                  <div class="sc">
                    <img src="../../img/shoucang.png" alt="">
                    <p>{{ item.collect_count }}</p>
                  </div>
                  <div class="sc">
                    <img src="../../img/dianzan.png" alt="">
                    <p>{{ item.support_count }}</p>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div class="more" @click="moresheji">
          <p>查看更多视频</p>
        </div>
      </div>
    </div>
    <footerbar></footerbar>



    <div class="xqTanchuang" v-if="xqzhanshi">
      <div class="closeanniu" @click="closeBtn" @keyup="handleKeyUp">
        <img src="../../img/close.png" alt="">
      </div>
      <div class="index_nei">
        <div class="wz_left">
          <div class="content">
            <div class="title">
              <h2>{{ detail.title }}</h2>
              <div class="des">
                <div class="des1">
                  <p>{{ detail.published_at | datefmt('YYYY-MM-DD HH:mm') }}</p>
                  <i>·</i>
                  <img v-if="detail.user.avatar.length > 0" :src="detail.user.avatar[0]" alt="">
                  <p>{{ detail.origin }}</p>
                </div>
                <div class="des1">
                  <p>{{ detail.support_count }}点赞</p>
                  <i>·</i>
                  <p>{{ detail.read_count }}阅读</p>
                  <i>·</i>
                  <p>{{ detail.collect_count }}收藏</p>
                  <i>·</i>
                  <p>{{ detail.transfer_count }}转发</p>
                </div>
              </div>
            </div>
            <div class="con">

              <img v-if="detail.cover.length > 0" :src="detail.cover[0]" alt="">
              <video v-if="detail.video != null && detail.cover != null" :src="detail.video[0]" controls
                :poster="detail.cover[0]"></video>
              <div v-if="detail.content != null">
                <div v-for="(item, index) in detail.content" :key="index">
                  <p v-if="item.type == 'paragraph'" v-html="item.data.text"></p>
                  <div class="tu" v-if="item.type == 'images'">
                    <img v-for="(ii, ee) in item.data.images" :key="ee" :src="ii.src" alt="" />
                  </div>

                  <div class="tu" v-if="item.type == 'video'">
                    <video v-for="(ii, ee) in item.data.video" :key="ee" :src="ii.src" alt="" controls></video>
                  </div>


                  <table class="your-table-class" v-if="item.type == 'table'">
                    <thead>
                      <tr>
                        <!-- 动态渲染表头 -->
                        <th v-for="(header, index) in item.data.content[0]" :key="index">{{ header }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <!-- 动态渲染表格内容 -->
                      <tr v-for="(row, rowIndex) in item.data.content.slice(1)" :key="rowIndex">
                        <td v-for="(column, colIndex) in row" :key="colIndex">
                          <!-- 使用 v-html 渲染包含 HTML 标签的内容 -->
                          <span v-if="colIndex === 2 || colIndex === 4 || colIndex === 7" v-html="column"></span>
                          <span v-else>{{ column }}</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="dibu">
              <div class="dibu1">
                <p>未经授权严禁转载，授权事宜请联系作者本人，侵权必究。</p>
              </div>
              <div class="dibu2">
                <div class="dibu2_1" @click="dzBtn(detail.id)" v-if="!detail.is_support">
                  <div class="img">
                    <img src="../../img/tuijian.png">
                    <!-- <img src="../../img/tuijian_a.png" alt="" v-else> -->
                  </div>
                  <p>{{ detail.support_count }}</p>
                </div>
                <div class="dibu2_1 active" @click="qxdzBtn(detail.id)" v-if="detail.is_support">
                  <div class="img">
                    <!-- <img src="../../img/tuijian.png" > -->
                    <img src="../../img/tuijian_a.png" alt="">
                  </div>
                  <p>{{ detail.support_count }}</p>
                </div>
                <div class="dibu2_1" @click="scBtn(detail.id)">
                  <div class="img">
                    <img src="../../img/sc.png" alt="">
                  </div>
                  <p>{{ detail.collect_count }}</p>
                </div>
                <div class="dibu2_1" @click="zfBtn(detail.id)">
                  <div class="img">
                    <img src="../../img/zhuanfa.png" alt="">
                  </div>
                  <p>{{ detail.transfer_count }}</p>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="wz_right">


          <div class="pinlun">
            <div class="pinglun_shuru">
              <h2>发表评论</h2>
              <div class="shuru">
                <!-- <div class="tx">
                                <img :src="myXinxi.pic" alt="">
                            </div> -->
                <div class="kuang" style="width:100%">
                  <el-input type="textarea" placeholder="说两句吧" v-model="textarea" maxlength="2000" show-word-limit
                    resize="none">
                  </el-input>
                  <div class="anniu" @click="pinglunBtn(detail.id)">
                    <p>发布</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="pl_list" v-if="pinglunList.length > 0">
              <h3>全部评论({{ pinglunList.length }})</h3>
              <div class="allList">
                <div class="list1" v-for="(item, index) in pinglunList" :key="index">
                  <div class="list1_img">
                    <img :src="item.user.avatar" alt="">
                  </div>
                  <div class="list_xq">
                    <div class="xq_left">
                      <div class="left_top">
                        <p>{{ item.user.name }}</p>
                        <span>{{ item.created_at | datefmt('YYYY-MM-DD HH:mm') }}</span>
                      </div>
                      <div class="left_bot">
                        <p>{{ item.content }}</p>
                        <div class="button" @click="huifuShowBtn(index, item.id, item.user.name)"
                          v-if="activeIndex === ''">
                          回复</div>
                        <div class="button" @click="huifuShowBtn1" v-if="index === activeIndex">取消回复
                        </div>
                      </div>
                      <div class="zichildren" v-for="(dd, ii) in item.children" :key="ii">
                        <div class="tx">
                          <img :src="dd.user.avatar" alt="">
                        </div>
                        <div class="zi_detail">
                          <div class="zi_top">
                            <div class="zi_1">
                              <p>{{ dd.user.name }}</p>
                              <span>{{ dd.created_at | datefmt('YYYY-MM-DD HH:mm') }}</span>
                            </div>
                            <div class="zi_2">
                              <img src="../../img/tuijian_a.png" alt="" v-if="dd.isdian">
                              <img src="../../img/tuijian.png" alt="" v-else>
                              <p>{{ dd.support_count }}</p>
                            </div>
                          </div>
                          <div class="zi_right">
                            <p>{{ dd.content }}</p>
                            <div class="button" @click="huifuShowBtn(index, dd.id, dd.name)">回复</div>
                          </div>
                        </div>
                      </div>
                      <div class="huifu_shu" v-if="index === activeIndex">
                        <div class="shu1">
                          <el-input type="text" :placeholder="'回复' + shuruttt" v-model="textppp" maxlength="300"
                            show-word-limit>
                          </el-input>
                          <div class="button" @click="huifu(detail.id)">回复</div>
                        </div>
                      </div>
                    </div>
                    <div class="xq_right" :class="{ active: item.isdian === true }">
                      <img src="../../img/tuijian_a.png" alt="" v-if="item.isdian">
                      <img src="../../img/tuijian.png" alt="" v-else>
                      <p>{{ item.support_count }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="right1">
            <h3>发布者</h3>
            <div class="right1_con">
              <div class="con1" @click="niurentodetail(detail.user.id)">
                <div class="con1_left">
                  <img v-if="detail.user.avatar.length > 0" :src="detail.user.avatar[0]" alt="">
                  <div class="detail">
                    <p>{{ detail.user.name }} </p>
                    <span>{{ detail.user.intro }}</span>
                    <span>{{ detail.user.position }}</span>
                  </div>
                </div>
                <!--  <div class="con1_right">
                                <div class="anniu" v-if="zuozhe.isguanzhu">
                                    <span>+</span>
                                    <p>关注</p>
                                </div> -->
                <!-- <div class="anniuyi" v-else>
                                    <img src="../../img/duihao.png" alt="">
                                    <p>已关注</p>
                                </div> 
                            </div>-->
              </div>
              <div class="con2">
                <div class="con2_1">
                  <p>{{ detail.user.fans }}</p>
                  <span>粉丝</span>
                </div>
                <div class="con2_1">
                  <p>{{ detail.user.article_count }}</p>
                  <span>文章</span>
                </div>
                <div class="con2_1">
                  <p>{{ detail.user.case_count }}</p>
                  <span>案例</span>
                </div>
                <div class="con2_1">
                  <p>{{ detail.user.project_count }}</p>
                  <span>项目</span>
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="right1">
                    <h3>相关企业</h3>
                    <div class="right1_con">
                        <div class="con1">
                            <div class="con1_left">
                                <img :src="xgqy.pic" alt="">
                                <div class="detail">
                                    <p>{{ xgqy.name }} </p>
                                    <span>{{ xgqy.address }}</span>
                                    <span>{{ xgqy.gsname }}</span>
                                </div>
                            </div>
                            <div class="con1_right">
                                <div class="anniu" v-if="xgqy.isguanzhu">
                                    <span>+</span>
                                    <p>关注</p>
                                </div>
                                <div class="anniuyi" v-else>
                                    <img src="../../img/duihao.png" alt="">
                                    <p>已关注</p>
                                </div>
                            </div>
                        </div>
                        <div class="con2">
                            <div class="con2_1">
                                <p>{{ xgqy.fensi }}</p>
                                <span>粉丝</span>
                            </div>
                            <div class="con2_1">
                                <p>{{ xgqy.wenzang }}</p>
                                <span>文章</span>
                            </div>
                            <div class="con2_1">
                                <p>{{ xgqy.anli }}</p>
                                <span>案例</span>
                            </div>
                            <div class="con2_1">
                                <p>{{ xgqy.xiangmu }}</p>
                                <span>项目</span>
                            </div>
                        </div>
                    </div>
                </div> -->

          <!-- <div class="right2">
            <h3>热门文章</h3>
            <div class="list1" v-for="(item, index) in xgList" :key="index" @click="todetail(item.id)">
              <img :src="item.cover[0]" alt="">
              <p>{{ item.title }}</p>
            </div>
          </div> -->


        </div>
      </div>
    </div>

    <div class="xqTanchuang1" v-if="huodongShow">
      <div class="closeanniu" @click="closeBtn">
        <img src="../../img/close.png" alt="">
      </div>
      <div class="fudong">
        <div class="fu1">
          <img v-if="detail.company.logo != null" :src="detail.company.logo[0]" alt="">
          <!-- <div class="anniu" @click="anniuBtn(detail.id)">
            <p>+</p>
          </div> -->
        </div>
        <div class="fu2">
          <div class="img">
            <img src="../../img/xinxi1.png" alt="">
          </div>
          <div class="anniu">
            <p>{{ detail.comments.length }}</p>
          </div>
        </div>
        <div class="fu2" :class="{ active: detail.is_support == true }">
          <div class="img" @click="dzBtnHD(detail.id)">
            <img src="../../img/tuijian1.png" alt="" v-if="!detail.is_support">
            <img src="../../img/tuijian_a.png" alt="" v-else>
          </div>
          <div class="anniu">
            <p>{{ detail.support_count }}</p>
          </div>
        </div>
        <div class="fu2">
          <div class="img" @click="scBtn(detail.id)">
            <img src="../../img/sc1.png" alt="">
          </div>
          <div class="anniu">
            <p>{{ detail.collect_count }}</p>
          </div>
        </div>
        <div class="fu2">
          <div class="img" @click="zfBtn1(detail.id)">
            <img src="../../img/zhuanfa1.png" alt="">
          </div>
          <div class="anniu">
            <p>{{ detail.transfer_count }}</p>
          </div>
        </div>
      </div>
      <div class="index_nei">
        <div class="content">
          <div class="title">
            <div class="detail">
              <!-- <img src="../../img/job1.png" alt="">
                        <img src="../../img/job2.png" alt=""> -->
              <img v-if="detail.company.logo != null" :src="detail.company.logo[0]" alt="">
              <div class="detail1">
                <p>{{ detail.author }}</p>
                <span>上线时间：{{ detail.published_at | datefmt('YYYY-MM-DD HH:mm') }}</span>
              </div>
            </div>
            <h1>{{ detail.title }}</h1>
          </div>
          <div class="des">
            <!-- <div v-if="detail.content != null">
                        <div v-for="(item, index) in detail.content" :key="index">
                            <p v-html="item.data.text"></p>
                        </div>
                    </div> -->
            <img v-for="(item, index) in detail.cover" :src="item" :key="index" alt="">
          </div>
          <div class="xiangqing">
            <div class="xiangqing_top">
              <div class="xq_img">
                <!-- <img src="../../img/job1.png" alt="">
                            <img src="../../img/job2.png" alt=""> -->
              </div>
              <div class="button" @click="canjia">
                <div class="buttonN">
                  <p>参与活动</p>
                </div>
              </div>
            </div>
            <div class="xiangqing_bot">
              <h3>活动详情</h3>
              <div class="bot_c">
                <div class="bot1" v-if="detail.content != null">
                  <div class="list" v-for="(item, index) in detail.content" :key="index">
                    <p v-if="item.type == 'paragraph'" v-html="item.data.text"></p>
                    <div class="tu" v-if="item.type == 'images'">
                      <img v-for="(ii, ee) in item.data.images" :key="ee" :src="ii.src" alt="" />
                    </div>

                    <div class="tu" v-if="item.type == 'video'">
                      <video v-for="(ii, ee) in item.data.video" :key="ee" :src="ii.src" alt="" controls></video>
                    </div>


                    <table class="your-table-class" v-if="item.type == 'table'">
                      <thead>
                        <tr>
                          <!-- 动态渲染表头 -->
                          <th v-for="(header, index) in item.data.content[0]" :key="index">{{ header }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <!-- 动态渲染表格内容 -->
                        <tr v-for="(row, rowIndex) in item.data.content.slice(1)" :key="rowIndex">
                          <td v-for="(column, colIndex) in row" :key="colIndex">
                            <!-- 使用 v-html 渲染包含 HTML 标签的内容 -->
                            <span v-if="colIndex === 2 || colIndex === 4 || colIndex === 7" v-html="column"></span>
                            <span v-else>{{ column }}</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>
        <!-- <div class="zuopin">
                <div class="title">
                    <h2>更多作品</h2>
                    <p>查看更多</p>
                </div>
                <div class="list">
                    <div class="list1" v-for="(item, index) in tuijian" :key="index">
                        <img :src="item.pic" alt="">
                    </div>
                </div>
            </div> -->
        <div class="allDibu">
          <div class="dibu_left">
            <div class="pinlun">
              <div class="pinglun_shuru">
                <h2>发表评论</h2>
                <div class="shuru">
                  <!-- <div class="tx">
                                    <img :src="myXinxi.pic" alt="">
                                </div> -->
                  <div class="kuang" style="width:100%">
                    <el-input type="textarea" placeholder="说两句吧" v-model="textarea" maxlength="2000" show-word-limit
                      resize="none">
                    </el-input>
                    <div class="anniu" @click="pinglunBtn1(detail.id)">
                      <p>发布</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pl_list" v-if="pinglunList.length > 0">
                <h3>全部评论({{ pinglunList.length }})</h3>
                <div class="allList">
                  <div class="list1" v-for="(item, index) in pinglunList" :key="index">
                    <div class="list1_img">
                      <img :src="item.user.avatar" alt="">
                    </div>
                    <div class="list_xq">
                      <div class="xq_left">
                        <div class="left_top">
                          <p>{{ item.user.name }}</p>
                          <span>{{ item.created_at | datefmt('YYYY-MM-DD HH:mm') }}</span>
                        </div>
                        <div class="left_bot">
                          <p>{{ item.content }}</p>
                          <div class="button" @click="huifuShowBtn(index, item.id, item.user.name)"
                            v-if="activeIndex === ''">
                            回复</div>
                          <div class="button" @click="huifuShowBtn1" v-if="index === activeIndex">
                            取消回复
                          </div>
                        </div>
                        <div class="zichildren" v-for="(dd, ii) in item.children" :key="ii">
                          <div class="tx">
                            <img :src="dd.user.avatar" alt="">
                          </div>
                          <div class="zi_detail">
                            <div class="zi_top">
                              <div class="zi_1">
                                <p>{{ dd.user.name }}</p>
                                <span>{{ dd.created_at | datefmt('YYYY-MM-DD HH:mm') }}</span>
                              </div>
                              <div class="zi_2">
                                <img src="../../img/tuijian_a.png" alt="" v-if="dd.isdian">
                                <img src="../../img/tuijian.png" alt="" v-else>
                                <p>{{ dd.support_count }}</p>
                              </div>
                            </div>
                            <div class="zi_right">
                              <p>{{ dd.content }}</p>
                              <div class="button" @click="huifuShowBtn(index, dd.id, dd.name)">回复</div>
                            </div>
                          </div>
                        </div>
                        <div class="huifu_shu" v-if="index === activeIndex">
                          <div class="shu1">
                            <el-input type="text" :placeholder="'回复' + shuruttt" v-model="textppp" maxlength="300"
                              show-word-limit>
                            </el-input>
                            <div class="button" @click="huifu1(detail.id)">回复</div>
                          </div>
                        </div>
                      </div>
                      <div class="xq_right" :class="{ active: item.isdian === true }">
                        <img src="../../img/tuijian_a.png" alt="" v-if="item.isdian">
                        <img src="../../img/tuijian.png" alt="" v-else>
                        <p>{{ item.support_count }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="dibu_right">
            <div class="right1">
              <h3>发布者</h3>
              <div class="right1_con">
                <div class="con1" @click="gstodetail(detail.company.id)">
                  <div class="con1_left">
                    <img v-if="detail.company.logo != null" :src="detail.company.logo[0]" alt="">
                    <div class="detail">
                      <p>{{ detail.company.name }} </p>
                      <!-- <span>{{ detail.user.position }}</span> -->
                    </div>
                  </div>
                </div>
                <!-- <div class="con2">
                                <div class="con2_1">
                                    <p>{{ detail.user.fans }}</p>
                                    <span>粉丝</span>
                                </div>
                                <div class="con2_1">
                                    <p>{{ detail.user.article_count }}</p>
                                    <span>文章</span>
                                </div>
                                <div class="con2_1">
                                    <p>{{ detail.user.case_count }}</p>
                                    <span>案例</span>
                                </div>
                                <div class="con2_1">
                                    <p>{{ detail.user.project_count }}</p>
                                    <span>项目</span>
                                </div>
                            </div> -->
                <!-- <div class="con2">
                                <div class="con2_1" v-if="!zuozhe.isguanzhu">
                                    <img src="../../img/duihao.png" alt="">
                                    <p>已关注</p>
                                </div>
                                <div class="con2_2" v-else>
                                    <span>+</span>
                                    <p>关注</p>
                                </div>
                                <div class="con2_3">
                                    <img src="../../img/email_1.png" alt="">
                                    <p>发信息</p>
                                </div>
                            </div> -->
              </div>
            </div>
            <!-- <div class="right1">
                        <h3>发布者</h3>
                        <div class="right1_con">
                            <div class="list">
                                <img src="../../img/jiang.png" alt="">
                            </div>
                            <div class="list">
                                <img src="../../img/jiang1.png" alt="">
                            </div>
                            <div class="list">
                                <img src="../../img/jiang2.png" alt="">
                            </div>
                            <div class="list">
                                <img src="../../img/jiang3.png" alt="">
                            </div>
                        </div>
                    </div> -->
          </div>
        </div>
      </div>

    </div>

    <div class="xqTanchuang2" v-if="huodongShow1">
      <div class="closeanniu" @click="closeBtn">
        <img src="../../img/close.png" alt="">
      </div>
      <div class="fudong">
        <div class="fu1">
          <img v-if="detail.user.avatar != null" :src="detail.user.avatar[0]" alt="">
          <!-- <div class="anniu">
                    <p>+</p>
                </div>-->
        </div>
        <div class="fu2">
          <div class="img">
            <img src="../../img/xinxi1.png" alt="">
          </div>
          <div class="anniu">
            <p>{{ detail.comments.length }}</p>
          </div>
        </div>
        <div class="fu2" :class="{ active: detail.is_support == true }">
          <div class="img" @click="dzBtn(detail.id)">
            <img src="../../img/tuijian1.png" alt="" v-if="!detail.is_support">
            <img src="../../img/tuijian_a.png" alt="" v-else>
          </div>
          <div class="anniu">
            <p>{{ detail.support_count }}</p>
          </div>
        </div>
        <div class="fu2">
          <div class="img" @click="scBtn(detail.id)">
            <img src="../../img/sc1.png" alt="">
          </div>
          <div class="anniu">
            <p>{{ detail.collect_count }}</p>
          </div>
        </div>
        <div class="fu2">
          <div class="img" @click="zfBtn(detail.id)">
            <img src="../../img/zhuanfa1.png" alt="">
          </div>
          <div class="anniu">
            <p>{{ detail.transfer_count }}</p>
          </div>
        </div>
      </div>
      <div class="index_nei">
        <div class="content">
          <div class="title">
            <div class="detail">
              <!-- <img src="../../img/job1.png" alt="">
                        <img src="../../img/job2.png" alt=""> -->
              <img v-if="detail.brand.logo != null" :src="detail.brand.logo[0]" alt="">
              <img v-if="detail.agent.logo != null" :src="detail.agent.logo[0]" alt="">
              <div class="detail1">
                <p>{{ detail.agent.name }}</p>
                <span>上线时间：{{ detail.published_at | datefmt('YYYY-MM-DD HH:mm') }}</span>
              </div>
            </div>
            <h1>{{ detail.title }}</h1>
          </div>
          <div class="des">
            <!-- <div v-if="detail.content != null">
                        <div v-for="(item, index) in detail.content" :key="index">
                            <p v-html="item.data.text"></p>
                        </div>
                    </div> -->
            <img v-for="(item, index) in detail.cover" :src="item" :key="index" alt="">
          </div>
          <div class="xiangqing">
            <!-- <div class="xiangqing_top">
              <div class="xq_img">
                <img v-if="detail.user.avatar != null" :src="detail.user.avatar[0]" alt="">
              </div>
              <div class="button" @click="shenqing(detail.id)">
                <p>项目申请</p>
              </div>
            </div> -->
            <div class="xiangqing_bot">
              <!-- <h3>项目详情</h3> -->
              <div class="bot_c">
                <div class="bot1" v-if="detail.content != null">
                  <div class="list" v-for="(item, index) in detail.content" :key="index">
                    <p v-if="item.type == 'paragraph'" v-html="item.data.text"></p>
                    <div class="tu" v-if="item.type == 'images'">
                      <img v-for="(ii, ee) in item.data.images" :key="ee" :src="ii.src" alt="" />
                    </div>

                    <div class="tu" v-if="item.type == 'video'">
                      <video v-for="(ii, ee) in item.data.video" :key="ee" :src="ii.src" alt="" controls></video>
                    </div>


                    <table class="your-table-class" v-if="item.type == 'table'">
                      <thead>
                        <tr>
                          <!-- 动态渲染表头 -->
                          <th v-for="(header, index) in item.data.content[0]" :key="index">{{ header }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <!-- 动态渲染表格内容 -->
                        <tr v-for="(row, rowIndex) in item.data.content.slice(1)" :key="rowIndex">
                          <td v-for="(column, colIndex) in row" :key="colIndex">
                            <!-- 使用 v-html 渲染包含 HTML 标签的内容 -->
                            <span v-if="colIndex === 2 || colIndex === 4 || colIndex === 7" v-html="column"></span>
                            <span v-else>{{ column }}</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>


                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>
        <div class="zuopin">
          <div class="title">
            <h2>更多作品</h2>
            <p>查看更多</p>
          </div>
          <div class="list">
            <div class="list1" v-for="(item, index) in projectList.slice(1, 5)" :key="index" @click="toxiangmu1(item.id)">
              <img :src="item.cover[0]" alt="">
            </div>
          </div>
        </div>
        <div class="allDibu">
          <div class="dibu_left">
            <div class="pinlun">
              <div class="pinglun_shuru">
                <h2>发表评论</h2>
                <div class="shuru">
                  <!-- <div class="tx">
                                    <img :src="myXinxi.pic" alt="">
                                </div> -->
                  <div class="kuang" style="width:100%">
                    <el-input type="textarea" placeholder="说两句吧" v-model="textarea" maxlength="2000" show-word-limit
                      resize="none">
                    </el-input>
                    <div class="anniu" @click="pinglunBtn(detail.id)">
                      <p>发布</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pl_list" v-if="pinglunList.length > 0">
                <h3>全部评论({{ pinglunList.length }})</h3>
                <div class="allList">
                  <div class="list1" v-for="(item, index) in pinglunList" :key="index">
                    <div class="list1_img">
                      <img :src="item.user.avatar" alt="">
                    </div>
                    <div class="list_xq">
                      <div class="xq_left">
                        <div class="left_top">
                          <p>{{ item.user.name }}</p>
                          <span>{{ item.created_at | datefmt('YYYY-MM-DD HH:mm') }}</span>
                        </div>
                        <div class="left_bot">
                          <p>{{ item.content }}</p>
                          <div class="button" @click="huifuShowBtn(index, item.id, item.user.name)"
                            v-if="activeIndex === ''">
                            回复</div>
                          <div class="button" @click="huifuShowBtn1" v-if="index === activeIndex">
                            取消回复
                          </div>
                        </div>
                        <div class="zichildren" v-for="(dd, ii) in item.children" :key="ii">
                          <div class="tx">
                            <img :src="dd.user.avatar" alt="">
                          </div>
                          <div class="zi_detail">
                            <div class="zi_top">
                              <div class="zi_1">
                                <p>{{ dd.user.name }}</p>
                                <span>{{ dd.created_at | datefmt('YYYY-MM-DD HH:mm') }}</span>
                              </div>
                              <div class="zi_2">
                                <img src="../../img/tuijian_a.png" alt="" v-if="dd.isdian">
                                <img src="../../img/tuijian.png" alt="" v-else>
                                <p>{{ dd.support_count }}</p>
                              </div>
                            </div>
                            <div class="zi_right">
                              <p>{{ dd.content }}</p>
                              <div class="button" @click="huifuShowBtn(index, dd.id, dd.name)">回复</div>
                            </div>
                          </div>
                        </div>
                        <div class="huifu_shu" v-if="index === activeIndex">
                          <div class="shu1">
                            <el-input type="text" :placeholder="'回复' + shuruttt" v-model="textppp" maxlength="300"
                              show-word-limit>
                            </el-input>
                            <div class="button" @click="huifu(detail.id)">回复</div>
                          </div>
                        </div>
                      </div>
                      <div class="xq_right" :class="{ active: item.isdian === true }">
                        <img src="../../img/tuijian_a.png" alt="" v-if="item.isdian">
                        <img src="../../img/tuijian.png" alt="" v-else>
                        <p>{{ item.support_count }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="dibu_right">
            <div class="right1">
              <h3>发布者</h3>
              <div class="right1_con">
                <div class="con1" @click="gstodetail(detail.user.id)">
                  <div class="con1_left">
                    <img v-if="detail.user.avatar != null" :src="detail.user.avatar[0]" alt="">
                    <div class="detail">
                      <p>{{ detail.user.name }} </p>
                      <span>{{ detail.user.intro }}</span>
                      <span>{{ detail.user.position }}</span>
                    </div>
                  </div>
                </div>
                <div class="con2">
                  <div class="con2_1">
                    <p>{{ detail.user.fans }}</p>
                    <span>粉丝</span>
                  </div>
                  <div class="con2_1">
                    <p>{{ detail.user.article_count }}</p>
                    <span>文章</span>
                  </div>
                  <div class="con2_1">
                    <p>{{ detail.user.case_count }}</p>
                    <span>案例</span>
                  </div>
                  <div class="con2_1">
                    <p>{{ detail.user.project_count }}</p>
                    <span>项目</span>
                  </div>
                </div>
                <!-- <div class="con2">
                                <div class="con2_1">
                                    <p>{{ detail.user.fans }}</p>
                                    <span>粉丝</span>
                                </div>
                                <div class="con2_1">
                                    <p>{{ detail.user.article_count }}</p>
                                    <span>文章</span>
                                </div>
                                <div class="con2_1">
                                    <p>{{ detail.user.case_count }}</p>
                                    <span>案例</span>
                                </div>
                                <div class="con2_1">
                                    <p>{{ detail.user.project_count }}</p>
                                    <span>项目</span>
                                </div>
                            </div> -->
                <!-- <div class="con2">
                                <div class="con2_1" v-if="!zuozhe.isguanzhu">
                                    <img src="../../img/duihao.png" alt="">
                                    <p>已关注</p>
                                </div>
                                <div class="con2_2" v-else>
                                    <span>+</span>
                                    <p>关注</p>
                                </div>
                                <div class="con2_3">
                                    <img src="../../img/email_1.png" alt="">
                                    <p>发信息</p>
                                </div>
                            </div> -->
              </div>
            </div>
            <div class="bot_renling">
              <div class="role">
                <h2 v-if="detail.operator_user.length > 0">创作人员</h2>
                <ul>
                  <li v-for="(item, index) in detail.operator_user" :key="index">
                    <div class="info">
                      <img v-if="item.user.avatar != null" :src="item.user.avatar[0]" alt="">
                      <p>{{ item.user.name }} <span>{{ item.role }}</span></p>
                    </div>
                    <div class="button" v-if="item.is_following == false" @click="addguanzhu(item.user)">
                      <p>关注</p>
                    </div>
                    <div class="button wei" v-else @click="qxguanzhu(item.user)">
                      <p>已关注</p>
                    </div>
                  </li>
                </ul>
                <div class="button" @click="shenqing(detail.id)">
                  <p>项目申请</p>
                </div>
              </div>
            </div>

            <!-- <div class="right1">
                        <h3>发布者</h3>
                        <div class="right1_con">
                            <div class="list">
                                <img src="../../img/jiang.png" alt="">
                            </div>
                            <div class="list">
                                <img src="../../img/jiang1.png" alt="">
                            </div>
                            <div class="list">
                                <img src="../../img/jiang2.png" alt="">
                            </div>
                            <div class="list">
                                <img src="../../img/jiang3.png" alt="">
                            </div>
                        </div>
                    </div> -->
          </div>
        </div>
      </div>

    </div>


    <div class="shoucangjia" v-if="toshowSS">
      <div class="scList">
        <div class="scList_top">
          <p>我的收藏夹</p>
          <span @click="cjscj" v-if="!isChuang">创建</span>
          <span @click="cjscj" v-else>取消</span>
        </div>
        <div class="cccc" v-if="isChuang">
          <input type="text" v-model="scName">
          <p @click="cjBtn">确定</p>
        </div>
        <ul v-if="CjList.length > 0">
          <li v-for="(item, index) in CjList" :key="index" @click="scBtn1(item.id)">
            <p>{{ item.name }}</p>
            <span @click.stop="deleteSC(item.id)">删除</span>
          </li>
        </ul>
        <div class="scKong" v-if="CjList.length == 0">
          <p>暂时没有收藏夹</p>
        </div>
        <div class="qxsc">
          <p @click="quxiaoSc">取消</p>
        </div>
      </div>
    </div>

    <div class="sqtanchuang" v-if="sqtan">
      <div class="sq_ccc">
        <div class="sq_ccc_t">
          <h3>创作者申请</h3>
          <img src="../../img/close_b.png" alt="" @click="closesq()">
        </div>

        <div class="sq_ccc_b">
          <div class="search">
            <div class="shuru">
              <input type="text" placeholder="品牌" v-model="role" />
              <img src="../../img/search.png" alt="">
            </div>
            <div class="button" @click="renlingBtn(detail.id)">
              <p>立即申请</p>
            </div>
          </div>
          <div class="bbb">
            <ul class="bbb_list">
              <li v-for="(item, index) in roleList1" :class="ssss == index ? 'active' : ''" :key="index"
                @click="shaixuan1(item.label, index)">
                <p>{{ item.label }}</p>
                <span v-if="item.is_apply">
                  <img src="../../img/duihao.png" alt="">
                  已申请
                </span>
              </li>
            </ul>
            <div class="ms">
              <p>没有匹配的职位，</p>
              <p>填写后直接申请。</p>
            </div>
          </div>
        </div>
        <ul>


          <!-- <el-select v-model="role" placeholder="请选择角色" @change="shaixuan1">
            <el-option v-for="item in roleList" :key="item.value" :label="item.label" :value="item.label">
            </el-option>
          </el-select> -->

          <!-- <span @click="renlingBtn(detail.id)">认领</span> -->

        </ul>
      </div>
    </div>

    <div class="bmtanchuang" v-if="baoming">
      <div class="baoming_top">
        <h3>填写报名单</h3>
        <img src="../../img/chahao.png" alt="" @click="closebm()">
      </div>
      <div class="baoming_con">
        <div class="con1">
          <p>姓名<i>*</i></p>
          <input type="text" placeholder="请填写联系人姓名" v-model="contactName">
        </div>

        <div class="con1">
          <p>昵称<i>*</i></p>
          <input type="text" placeholder="请填写联系人昵称" v-model="contactNickName">
        </div>

        <div class="con1">
          <p>电话<i>*</i></p>
          <input type="text" placeholder="请填写联系电话" v-model="contactPhone">
        </div>

        <div class="con1">
          <p>电子邮箱<i>*</i></p>
          <input type="text" placeholder="请填写电子邮箱" v-model="contactEmail">
        </div>
        <div class="tijiao" @click="tijiao((detail.id))">
          <p>提交</p>
        </div>
      </div>
    </div>





  </div>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'
// import headerbar from '../../components/headerbar.vue';
import footerbar from '../../components/footerbar.vue';
import { eventBus } from '../../App.vue';
const phoneRegex = /^1[3456789]\d{9}$/;
const emailRegex = /^[A-Za-z0-9+_.-]+@(.+)$/;
export default {
  name: 'index',
  data() {
    return {
      pageTitle: '会晤网站首页',
      selectedNav: 'index',
      tuijian: true,
      guanzhu: false,
      dingyue: false,
      shupai: false,
      hengpai: true,
      bannerList: [],
      bodyOverflow: 'auto',
      swiperOptions: {
        loop: true,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,//操作swiper后不会停止播放
        },
        observer: true,
        observeParents: true,
        navigation: {
          nextEl: '.swiper-next',
          prevEl: '.swiper-prev',
        },
        pagination: {
          el: ".swiper-pagination",
        },
        // watchSlidesProgress: true,
        // on: {
        //   progress: function () {
        //     var progressBar = document.querySelector('.swiper-progress-bar');
        //     progressBar.style.width = (this.progress * 100) + '%';
        //   }
        // }
      },

      ggList: [],

      zuozheList: [],

      csList: [],

      tuijianList: [],
      displayedData: [],
      currentIndex: 12,    // 当前页数
      batchSize: 6,      // 每页数据数量
      hasMoreData: true, // 是否还有更多数据可加载

      zuopinList: [],

      shipinList: [],

      dingyueList: "",

      guanzhuList: "",

      projectList: '',

      detail: "",
      pinglunList: [],
      xqzhanshi: false,
      huodongShow: false,
      huodongShow1: false,
      isScrollDisabled: false,
      textarea: '',

      toshowSS: false,
      isChuang: false,
      scName: '',
      CjList: '',

      sqtan: false,
      role: '',

      baoming: false,
      contactName: '',
      contactNickName: '',
      contactPhone: '',
      contactEmail: '',

      roleList: '',
      // roleList1: '',
      ssss: -1,

      activeIndex: '',
      textppp: '',
      plid: '',
      shuruttt: '',

    }
  },
  components: {
    // headerbar,
    footerbar,
    swiper,
    swiperSlide,
  },
  created() {
    document.title = this.pageTitle;
    // this.$nextTick(() => {
    //   this.$refs.visualizationSwiper.swiper.init();
    // });
  },
  computed: {
    hasMore() {
      // 检查是否还有更多数据可供加载
      return this.currentIndex < this.tuijianList.length;
    },

    roleList1: {
      get() {
        console.log(this.roleList)
        if (this.roleList.length > 0) {
          return this.roleList.filter(item => item.label.includes(this.role));
        } else {
          return null;
        }

      },
      set(value) {
        // 如果需要，你可以在这里添加设置计算属性时的逻辑
        console.log(value)
      }

    }



  },
  beforeRouteLeave(to, from, next) {
    document.body.classList.remove('disable-body-scroll');
    next();
  },
  mounted() {

    this.getBanner()
    this.getindex()
    document.addEventListener("keyup", this.handleKeyUp);
    this.setActivePage()
    this.getSCList()
    this.loadMore()
  },

  beforeUnmount() {
    // Remove the keyup event listener before the component is unmounted to prevent memory leaks
    document.removeEventListener("keyup", this.handleKeyUp);
  },
  methods: {

    //项目详情二级评论
    huifu(e) {
      this.$axios.post('/api/user/comment/save', {
        type: 1,
        ext_id: e,
        content: this.textppp,
        comment_id: this.plid,
      }, {
        headers: {
          'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
        }
      }).then((result) => {
        console.log(result)
        if (result.data.code == 200) {
          this.$message({
            message: result.data.message,
            type: 'success'
          });
          this.textppp = "";
          this.activeIndex = ''
        } else {
          this.$message.error(result.data.message);
        }
      }).catch((err) => {
        console.log(err)
        // this.$message.error(err.response.data.message);
        if (err.response.data.code == 403 || err.response.data.code == 500) {
          this.$message.error("请重新登录!");
          setTimeout(() => {
            eventBus.$emit('clearToken', '');
            this.$router.push({
              name: 'login'
            })
          }, 1500);
        } else {
          this.$message.error(err.response.data.message)
        }
      });
    },

    //活动详情二级评论
    huifu1(e) {
      this.$axios.post('/api/user/comment/save', {
        type: 2,
        ext_id: e,
        content: this.textppp,
        comment_id: this.plid,
      }, {
        headers: {
          'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
        }
      }).then((result) => {
        console.log(result)
        if (result.data.code == 200) {
          this.$message({
            message: result.data.message,
            type: 'success'
          });
          this.textppp = "";
          this.activeIndex = ''
        } else {
          this.$message.error(result.data.message);
        }
      }).catch((err) => {
        console.log(err)
        // this.$message.error(err.response.data.message);
        if (err.response.data.code == 403 || err.response.data.code == 500) {
          this.$message.error("请重新登录!");
          setTimeout(() => {
            eventBus.$emit('clearToken', '');
            this.$router.push({
              name: 'login'
            })
          }, 1500);
        } else {
          this.$message.error(err.response.data.message)
        }
      });
    },

    huifuShowBtn(e, d, f) {
      console.log(e, d, f)
      this.activeIndex = e;
      this.plid = d;
      this.shuruttt = f
    },

    huifuShowBtn1() {
      this.activeIndex = ''
      this.plid = '';
      this.shuruttt = ''
    },

    // cccc(){
    //   eventBus.$emit('clearToken', '');
    // },
    searchBb() {
      console.log(this.role)
      setTimeout(() => {
        if (this.role != '') {
          let newobject = {
            label: '',
          }
          for (var i = 0; i < this.roleList.length; i++) {
            if (this.role == this.roleList[i].label) {
              this.roleList.splice(0, this.roleList.length)
              newobject.label = this.role
              this.roleList.push(newobject)
            }
          }
          console.log(this.roleList)
        } else {
          this.getRole()
          console.log(456456)
        }
      }, 2000);

    },


    addguanzhu(e) {
      console.log("关注")
      this.$axios.get('/api/user/user_following/' + e.id, {
        headers: {
          'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
        }
      }).then((result) => {
        console.log(result)
        if (result.data.code == 200) {
          this.$message({
            message: result.data.message,
            type: 'success'
          });
          this.toxiangmu1(this.detail.id)
        } else {
          this.$message.error(result.data.message);
        }
      }).catch((err) => {
        console.log(err)
        if (err.response.data.code == 403 || err.response.data.code == 500) {
          this.$message.error('请先登录！');
          setTimeout(() => {
            eventBus.$emit('clearToken', '');
            this.$router.push({
              name: 'login'
            })
          }, 1500);
        }
      });
    },
    qxguanzhu(e) {
      console.log("quxiao")
      this.$axios.get('/api/user/user_following/' + e.id, {
        headers: {
          'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
        }
      }).then((result) => {
        console.log(result)
        if (result.data.code == 200) {
          this.$message({
            message: result.data.message,
            type: 'success'
          });
          this.toxiangmu1(this.detail.id)
        } else {
          this.$message.error(result.data.message);
        }
      }).catch((err) => {
        console.log(err)
        if (err.response.data.code == 403 || err.response.data.code == 500) {
          this.$message.error('请先登录！');
          setTimeout(() => {
            eventBus.$emit('clearToken', '');
            this.$router.push({
              name: 'login'
            })
          }, 1500);
        }
      });
    },



    shaixuan1(e, d) {
      console.log(e)
      console.log(d)
      this.role = e;
      this.ssss = d
    },

    moresheji() {
      this.$router.push({
        name: 'faxian'
      })
    },


    moreproject() {
      this.$router.push({
        name: 'project'
      })
    },

    loadMore() {
      const nextData = this.tuijianList.slice(
        this.currentIndex,
        this.currentIndex + this.batchSize
      );
      // 将加载的数据添加到已渲染的数据中
      this.displayedData = this.displayedData.concat(nextData);
      // 更新当前已渲染的数据索引
      this.currentIndex += this.batchSize;
    },

    shenqing() {
      this.sqtan = true;
      this.getRole()
    },
    closesq() {
      this.sqtan = false;
    },

    //获取项目认领角色
    getRole() {
      console.log(this.detail.id)
      this.$axios.get('api/select/project_role', {
        headers: {
          'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
        }
      }, {
        article_id: this.detail.id
      }).then((result) => {
        console.log(result)
        if (result.data.code == 200) {
          this.roleList = result.data.data
          this.roleList1 = result.data.data
        } else {
          this.$message.error(result.data.message);
        }
      }).catch((err) => {
        console.log(err)
        if (err.response.data.code == 500 || err.response.data.code == 403) {
          this.$message.error("请重新登录!");
          setTimeout(() => {
            eventBus.$emit('clearToken', '');
            this.$router.push({
              name: 'login'
            })
          }, 1500);
        } else {
          this.$message.error(err.response.data.message)
        }
      });
    },


    //项目认领
    renlingBtn(d) {
      this.$axios.post('api/user/project_apply', {
        article_id: d,
        role: this.role,
      }, {
        headers: {
          'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
        }
      }).then((result) => {
        console.log(result)
        if (result.data.code == 200) {
          this.$message({
            message: result.data.message,
            type: 'success'
          });
          setTimeout(() => {
            this.sqtan = false;
            this.role = '';
          }, 1000);
        } else {
          this.$message.error(result.data.message);
          setTimeout(() => {
            this.sqtan = false
            this.role = '';
          }, 1000);
        }
      }).catch((err) => {
        console.log(err)
        // this.$message.error(err.response.data.message);
        if (err.response.data.code == 403) {
          this.$message.error("请重新登录!");
          setTimeout(() => {
            eventBus.$emit('clearToken', '');
            this.$router.push({
              name: 'login'
            })
          }, 1500);
        } else {
          this.$message.error(err.response.data.message)
          setTimeout(() => {
            this.sqtan = false;
            this.role = '';
          }, 1000);
        }
      });
    },


    cjscj() {
      this.isChuang = !this.isChuang
    },
    //创建文件夹
    cjBtn() {
      if (this.scName == '') {
        this.$message.error('请输入文件夹名!');
        return
      }
      this.$axios.post('api/collection/create', {
        name: this.scName
      }, {
        headers: {
          'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
        }
      }).then((result) => {
        console.log(result)
        if (result.data.code == 200) {
          this.$message({
            message: '创建成功!',
            type: 'success'
          });
          this.getSCList();
          this.isChuang = false;
        } else {
          this.$message.error(result.data.message);
        }
      }).catch((err) => {
        console.log(err)
        if (err.response.data.code == 403) {
          this.$message.error("请重新登录!");
          setTimeout(() => {
            eventBus.$emit('clearToken', '');
            this.$router.push({
              name: 'login'
            })
          }, 1500);
        } else {
          this.$message.error(err.response.data.message)
        }
      });
    },

    //删除收藏夹
    deleteSC(e) {
      this.$axios.get('api/collection/delete/' + e, {
        headers: {
          'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
        }
      }).then((result) => {
        if (result.data.code == 200) {
          this.$message({
            message: '删除成功!',
            type: 'success'
          });
          this.getSCList();
        } else {
          this.$message.error(result.data.message);
        }
      }).catch((err) => {
        console.log(err)
        if (err.response.data.code == 403) {
          this.$message.error("请重新登录!");
          setTimeout(() => {
            eventBus.$emit('clearToken', '');
            this.$router.push({
              name: 'login'
            })
          }, 1500);
        } else {
          this.$message.error(err.response.data.message)
        }
      });
    },

    //获取收藏列表
    getSCList() {
      this.$axios.get('api/collection/my_collect', {
        headers: {
          'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
        }
      }).then((result) => {
        console.log(result)
        if (result.data.code == 200) {
          this.CjList = result.data.data
        } else {
          this.$message.error(result.data.message);
        }
      }).catch((err) => {
        console.log(err)
        if (err.response.data.code == 500 || err.response.data.code == 403) {
          // this.$message.error("请重新登录!");
          setTimeout(() => {
            eventBus.$emit('clearToken', '');
            // this.$router.push({
            //   name: 'login'
            // })
          }, 1500);
        } else {
          this.$message.error(err.response.data.message)
        }

      });
    },


    setActivePage() {
      // 触发事件，传递当前页面的名称
      eventBus.$emit('updateActivePage', 'index');
    },


    tuijianBtn() {
      this.tuijian = true;
      this.guanzhu = false;
      this.dingyue = false;
    },
    guanzhuBtn() {
      this.tuijian = false;
      this.guanzhu = true;
      this.dingyue = false;
      this.getguanzhuList()
    },
    dingyueBtn() {
      this.tuijian = false;
      this.guanzhu = false;
      this.dingyue = true;
      this.getdingyueList()
    },
    shupaiBtn() {
      console.log(123123)
      this.shupai = true;
      this.hengpai = false;
    },
    hengpaiBtn() {
      console.log(456456)
      this.shupai = false;
      this.hengpai = true;
    },

    //获取订阅活动
    getdingyueList() {
      this.$axios.get('/api/__yao/table/pc.focus_activity/search', {
        headers: {
          'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
        }
      }, {
        params: {
          "where.published_at.match": "",
          "where.industry.match": "",
          "where.area.match": "",
        }
      }).then((result) => {
        console.log(result)
        if (result.data.code == 200) {
          this.dingyueList = result.data.data
        } else {
          this.$message.error(result.data.message);
        }
      }).catch((err) => {
        // this.$message.error(err.response.data.message);
        if (err.response.data.code == 403) {
          this.$message.error("请重新登录!");
          setTimeout(() => {
            eventBus.$emit('clearToken', '');
            this.$router.push({
              name: 'login'
            })
          }, 1500);
        } else {
          this.$message.error(err.response.data.message)
        }
      });
    },

    // 获取关注的人的文章
    getguanzhuList() {
      this.$axios.get('/api/__yao/table/pc.focus_article/search', {
        headers: {
          'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
        }
      }, {
        params: {
          "order": "created_at.desc",
          "where.categories.match": "1",
          "where.industry.match": "",
        }
      }).then((result) => {
        console.log(result)
        if (result.data.code == 200) {
          this.guanzhuList = result.data.data
        } else {
          this.$message.error(result.data.message);
        }
      }).catch((err) => {
        // this.$message.error(err.response.data.message);

        if (err.response.data.code == 403) {
          this.$message.error("请重新登录!");
          setTimeout(() => {
            eventBus.$emit('clearToken', '');
            this.$router.push({
              name: 'login'
            })
          }, 1500);
        } else {
          this.$message.error(err.response.data.message)
        }
      });
    },

    handleKeyUp(event) {
      // console.log(123)
      if (event.key === 'Escape') {
        // 处理按下 Enter 键的逻辑
        console.log('esc 键被按下');
        this.closeBtn()
      }
    },

    quxiaoSc() {
      this.toshowSS = false;
    },

    closeBtn() {
      this.xqzhanshi = false;
      this.huodongShow = false;
      this.huodongShow1 = false;
      this.toshowSS = false;
      this.baoming = false;
      this.sqtan = false;

      this.huifuShowBtn1()



      document.body.classList.remove('disable-body-scroll');
    },


    anniuBtn(e) {
      this.$axios.get('/api/user/user_following/' + e, {
        headers: {
          'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
        }
      }).then((result) => {
        console.log(result)
        if (result.data.code == 200) {
          this.$message({
            message: '关注成功!',
            type: 'success'
          });
          // setTimeout(() => {
          //   this.getDetail()
          // }, 1500);
        }
      }).catch((err) => {
        console.log(err)
      });
    },


    //海报和文章详情弹窗
    todetail(e) {
      // this.$router.push('/articleDetail')
      // this.$router.push({
      //   name: 'articleDetail',
      //   query: {
      //     id: e
      //   }
      // })
      if (e.type == 'article') {
        document.body.classList.add('disable-body-scroll');
        this.xqzhanshi = true;
        if (window.localStorage.getItem('token')) {
          this.$axios.get('api/pc/article/detail/' + e.id, {
            headers: {
              'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
            }
          }).then((result) => {
            console.log(result)
            if (result.data.code == 200) {
              this.detail = result.data.data;
              this.pinglunList = result.data.data.comments
            } else {
              this.$message.error(result.data.message);
            }
          }).catch((err) => {
            console.log(err)
            // this.$message.error(err.response.data.message);
            if (err.response.data.code == 403) {
              this.$message.error("请重新登录!");
              setTimeout(() => {
                eventBus.$emit('clearToken', '');
                this.$router.push({
                  name: 'login'
                })
              }, 1500);
            } else {
              this.$message.error(err.response.data.message)
            }
          });
        } else {
          this.$axios.get('api/pc/article/detail/' + e.id).then((result) => {
            console.log(result)
            if (result.data.code == 200) {
              this.detail = result.data.data;
              this.pinglunList = result.data.data.comments
            } else {
              this.$message.error(result.data.message);
            }
          }).catch((err) => {
            console.log(err)
            // this.$message.error(err.response.data.message);
            if (err.response.data.code == 403) {
              this.$message.error("请重新登录!");
              setTimeout(() => {
                eventBus.$emit('clearToken', '');
                this.$router.push({
                  name: 'login'
                })
              }, 1500);
            } else {
              this.$message.error(err.response.data.message)
            }
          });
        }
      } else {
        this.toxiangmu1(e.id)
      }






    },
    //海报和文章详情弹窗
    posterDetail(e) {
      // this.$router.push({
      //   name: 'posterDetail',
      //   query: {
      //     id: e
      //   }
      // })

      document.body.classList.add('disable-body-scroll');
      this.xqzhanshi = true;
      if (window.localStorage.getItem('token')) {
        this.$axios.get('api/pc/article/detail/' + e, {
          headers: {
            'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
          }
        }).then((result) => {
          console.log(result)
          if (result.data.code == 200) {
            this.detail = result.data.data;
            this.pinglunList = result.data.data.comments
          } else {
            this.$message.error(result.data.message);
          }
        }).catch((err) => {
          console.log(err)
          // this.$message.error(err.response.data.message);
          if (err.response.data.code == 403) {
            this.$message.error("请重新登录!");
            setTimeout(() => {
              eventBus.$emit('clearToken', '');
              this.$router.push({
                name: 'login'
              })
            }, 1500);
          } else {
            this.$message.error(err.response.data.message)
          }
        });
      } else {
        this.$axios.get('api/pc/article/detail/' + e,).then((result) => {
          console.log(result)
          if (result.data.code == 200) {
            this.detail = result.data.data;
            this.pinglunList = result.data.data.comments
          } else {
            this.$message.error(result.data.message);
          }
        }).catch((err) => {
          console.log(err)
          // this.$message.error(err.response.data.message);
          if (err.response.data.code == 403) {
            this.$message.error("请重新登录!");
            setTimeout(() => {
              eventBus.$emit('clearToken', '');
              this.$router.push({
                name: 'login'
              })
            }, 1500);
          } else {
            this.$message.error(err.response.data.message)
          }
        })
      }



    },
    videoDetail(e) {
      // this.$router.push({
      //   name: 'videoDetail',
      //   query: {
      //     id: e
      //   }
      // })


      document.body.classList.add('disable-body-scroll');
      this.xqzhanshi = true;
      if (window.localStorage.getItem('token')) {
        this.$axios.get('api/pc/article/detail/' + e, {
          headers: {
            'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
          }
        }).then((result) => {
          console.log(result)
          if (result.data.code == 200) {
            this.detail = result.data.data;
            this.pinglunList = result.data.data.comments
          } else {
            this.$message.error(result.data.message);
          }
        }).catch((err) => {
          console.log(err)
          // this.$message.error(err.response.data.message);
          if (err.response.data.code == 403) {
            this.$message.error("请重新登录!");
            setTimeout(() => {
              eventBus.$emit('clearToken', '');
              this.$router.push({
                name: 'login'
              })
            }, 1500);
          } else {
            this.$message.error(err.response.data.message)
          }
        });
      } else {
        this.$axios.get('api/pc/article/detail/' + e,).then((result) => {
          console.log(result)
          if (result.data.code == 200) {
            this.detail = result.data.data;
            this.pinglunList = result.data.data.comments
          } else {
            this.$message.error(result.data.message);
          }
        }).catch((err) => {
          console.log(err)
          // this.$message.error(err.response.data.message);
          if (err.response.data.code == 403) {
            this.$message.error("请重新登录!");
            setTimeout(() => {
              eventBus.$emit('clearToken', '');
              this.$router.push({
                name: 'login'
              })
            }, 1500);
          } else {
            this.$message.error(err.response.data.message)
          }
        });
      }



    },

    gstodetail(e) {
      this.$router.push({
        name: 'chuangzao',
        query: {
          id: e
        }
      })
    },

    //评论
    pinglunBtn(e) {
      this.$axios.post('/api/user/comment/save', {
        type: 1,
        ext_id: e,
        content: this.textarea,
        comment_id: '',
      }, {
        headers: {
          'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
        }
      }).then((result) => {
        console.log(result)
        if (result.data.code == 200) {
          this.$message({
            message: result.data.message,
            type: 'success'
          });
          this.textarea = ""
        } else {
          this.$message.error(result.data.message);
        }
      }).catch((err) => {
        console.log(err)
        if (err.response.data.code == 403 || err.response.data.code == 500) {
          this.$message.error("请重新登录!");
          setTimeout(() => {
            eventBus.$emit('clearToken', '');
            this.$router.push({
              name: 'login'
            })
          }, 1500);
        } else {
          this.$message.error(err.response.data.message)
        }
      });
    },

    pinglunBtn1(e) {
      this.$axios.post('/api/user/comment/save', {
        type: 2,
        ext_id: e,
        content: this.textarea,
        comment_id: '',
      }, {
        headers: {
          'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
        }
      }).then((result) => {
        console.log(result)
        if (result.data.code == 200) {
          this.$message({
            message: result.data.message,
            type: 'success'
          });
          this.textarea = ""
        } else {
          this.$message.error(result.data.message);
        }
      }).catch((err) => {
        console.log(err)
        if (err.response.data.code == 403 || err.response.data.code == 500) {
          this.$message.error("请重新登录!");
          setTimeout(() => {
            eventBus.$emit('clearToken', '');
            this.$router.push({
              name: 'login'
            })
          }, 1500);
        } else {
          this.$message.error(err.response.data.message)
        }
      });
    },


    //活动详情弹窗
    toxiangmu(e) {
      // this.$router.push({
      //   name: 'huodongDetail',
      //   query: {
      //     id: e
      //   }
      // })

      document.body.classList.add('disable-body-scroll');
      this.huodongShow = true;
      this.$axios.get('/api/pc/activity/detail/' + e, {}).then((result) => {
        console.log(result)
        if (result.data.code == 200) {
          this.detail = result.data.data;
          this.pinglunList = result.data.data.comments
        }
      }).catch((err) => {
        console.log(err)
      });

    },

    //项目详情
    toxiangmu1(e) {
      document.body.classList.add('disable-body-scroll');
      this.huodongShow1 = true;
      console.log(e)
      console.log(this.huodongShow1)
      if (window.localStorage.getItem('token')) {
        this.$axios.get('/api/pc/article/detail/' + e, {
          headers: {
            'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
          }
        }).then((result) => {
          console.log(result)
          if (result.data.code == 200) {
            this.detail = result.data.data;
            this.pinglunList = result.data.data.comments
          }
        }).catch((err) => {
          console.log(err)
          if (err.response.data.code == 403 || err.response.data.code == 500) {
            this.$message.error("请重新登录!");
            setTimeout(() => {
              eventBus.$emit('clearToken', '');
              this.$router.push({
                name: 'login'
              })
            }, 1500);
          } else {
            this.$message.error(err.response.data.message)
          }

        });
      } else {
        this.$axios.get('/api/pc/article/detail/' + e, {}).then((result) => {
          console.log(result)
          if (result.data.code == 200) {
            this.detail = result.data.data;
            this.pinglunList = result.data.data.comments
          }
        }).catch((err) => {
          console.log(err)
        });
      }

    },

    canjia() {
      this.baoming = true;
    },

    closebm() {
      this.baoming = false;
    },

    //参加活动
    tijiao(e) {
      if (this.contactName == "") {
        this.$message.error('联系人姓名不能为空！');
        return
      }
      if (this.contactPhone == "") {
        this.$message.error('联系人手机不能为空！');
        return
      }
      if (!phoneRegex.test(this.contactPhone)) {
        this.$message.error('手机号格式不正确！');
        return
      }
      if (this.contactEmail == "") {
        this.$message.error('电子邮箱不能为空！');
        return
      }
      if (!emailRegex.test(this.contactEmail)) {
        this.$message.error('电子邮箱格式不正确！');
        return
      }
      this.$axios.post('/api/user/activity/join/', {
        id: e,
        name: this.contactName,
        mobile: this.contactPhone,
        nickname: this.contactNickName,
        email: this.contactEmail,
      }, {
        headers: {
          'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
        }
      }).then((result) => {
        console.log(result)
        if (result.data.code == 200) {
          this.$message({
            message: '参与成功！',
            type: 'success'
          });
          setTimeout(() => {
            this.baoming = false;
            this.contactName = "";
            this.contactPhone = "";
            this.contactEmail = "";
            this.contactNickName = "";
          }, 1500);
        } else {
          this.$message.error(result.data.message);
        }
      }).catch((err) => {
        console.log(err)
        // this.$message.error(err.response.data.message);
        if (err.response.data.code == 403 || err.response.data.code == 500) {
          this.$message.error("请重新登录!");
          setTimeout(() => {
            eventBus.$emit('clearToken', '');
            this.$router.push({
              name: 'login'
            })
          }, 1500);
        } else {
          this.$message.error(err.response.data.message)
        }
      });
    },



    niurentodetail(e) {
      this.$router.push({
        name: 'niurenDetail',
        query: {
          id: e
        }
      })
    },

    getBanner() {
      this.$axios.get('/api/pc/index/banner', {}).then((result) => {
        console.log(result)
        if (result.data.code == 200) {
          this.bannerList = result.data.data
        } else {
          this.$message.error(result.data.message);
        }
      }).catch((err) => {
        console.log(err)
        this.$message.error(err.response.data.message);
      });
    },

    getindex() {
      if (window.localStorage.getItem('token')) {
        this.$axios.get('/api/pc/index/index', {
          headers: {
            'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
          }
        }).then((result) => {
          console.log(result)
          if (result.data.code == 200) {
            this.ggList = result.data.data.hot_activity;
            this.tuijianList = result.data.data.hot_article;
            this.displayedData = result.data.data.hot_article.slice(0, 12)
            this.zuopinList = result.data.data.poster;
            this.shipinList = result.data.data.video;
            this.zuozheList = result.data.data.user;
            this.csList = result.data.data.hot_company;
            this.projectList = result.data.data.hot_project
          }
        }).catch((err) => {
          console.log(err)
          // this.$message.error(err.response.data.message);
          if (err.response.data.code == 403 || err.response.data.code == 500) {
            this.$message.error("请重新登录!");
            setTimeout(() => {
              eventBus.$emit('clearToken', '');
              this.$router.push({
                name: 'login'
              })
            }, 1500);
          } else {
            this.$message.error(err.response.data.message)
          }
        });
      } else {
        this.$axios.get('/api/pc/index/index',).then((result) => {
          console.log(result)
          if (result.data.code == 200) {
            this.ggList = result.data.data.hot_activity;
            this.tuijianList = result.data.data.hot_article;
            this.displayedData = result.data.data.hot_article.slice(0, 12)
            this.zuopinList = result.data.data.poster;
            this.shipinList = result.data.data.video;
            this.zuozheList = result.data.data.user;
            this.csList = result.data.data.hot_company;
            this.projectList = result.data.data.hot_project
          }
        }).catch((err) => {
          console.log(err)

          if (err.response.data.code == 403) {
            this.$message.error("请重新登录!");
            setTimeout(() => {
              eventBus.$emit('clearToken', '');
              this.$router.push({
                name: 'login'
              })
            }, 1500);
          } else {
            this.$message.error(err.response.data.message)
          }
        });
      }
    },


    //收藏
    scBtn() {
      console.log(1312312)
      this.toshowSS = !this.toshowSS
    },


    scBtn1() {
      this.$axios.post('/api/user/collect/article', {
        id: this.detail.id,
        type: 1,
      }, {
        headers: {
          'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
        }
      }).then((result) => {
        console.log(result)
        if (result.data.code == 200) {
          this.$message({
            message: '收藏成功！',
            type: 'success'
          });
          setTimeout(() => {
            this.$axios.get('api/pc/article/detail/' + this.detail.id, {
              headers: {
                'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
              }
            }).then((result) => {
              console.log(result)
              if (result.data.code == 200) {
                this.detail = result.data.data;
                this.pinglunList = result.data.data.comments
              } else {
                this.$message.error(result.data.message);
              }
            }).catch((err) => {
              console.log(err)
              // this.$message.error(err.response.data.message);
              if (err.response.data.code == 403 || err.response.data.code == 500) {
                this.$message.error("请重新登录!");
                setTimeout(() => {
                  eventBus.$emit('clearToken', '');
                  this.$router.push({
                    name: 'login'
                  })
                }, 1500);
              } else {
                this.$message.error(err.response.data.message)
              }
            });
            this.toshowSS = !this.toshowSS
          }, 500);
        } else {
          this.$message.error(result.data.message);
          this.toshowSS = !this.toshowSS
        }
      }).catch((err) => {
        console.log(err)
        // this.$message.error(err.response.data.message);
        if (err.response.data.code == 403 || err.response.data.code == 500) {
          this.$message.error("请重新登录!");
          setTimeout(() => {
            eventBus.$emit('clearToken', '');
            this.$router.push({
              name: 'login'
            })
          }, 1500);
        } else {
          this.$message.error(err.response.data.message)
        }
      });
    },


    //点赞
    dzBtn(e) {
      this.$axios.post('/api/user/support/content', {
        id: e,
        type: 1,
      }, {
        headers: {
          'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
        }
      }).then((result) => {
        console.log(result)
        if (result.data.code == 200) {
          this.$message({
            message: '点赞成功！',
            type: 'success'
          });

          //重新获取详情接口
          this.$axios.get('api/pc/article/detail/' + e, {
            headers: {
              'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
            }
          }).then((result) => {
            console.log(result)
            if (result.data.code == 200) {
              this.detail = result.data.data;
              this.pinglunList = result.data.data.comments
            } else {
              this.$message.error(result.data.message);
            }
          }).catch((err) => {
            console.log(err)
            // this.$message.error(err.response.data.message);
            if (err.response.data.code == 403 || err.response.data.code == 500) {
              this.$message.error("请重新登录!");
              setTimeout(() => {
                eventBus.$emit('clearToken', '');
                this.$router.push({
                  name: 'login'
                })
              }, 1500);
            } else {
              this.$message.error(err.response.data.message)
            }
          });



          //重新获取详情接口结束


        } else {
          this.$message.error(result.data.message);
        }
      }).catch((err) => {
        console.log(err)
        // this.$message.error(err.response.data.message);
        if (err.response.data.code == 403 || err.response.data.code == 500) {
          this.$message.error("请重新登录!");
          setTimeout(() => {
            eventBus.$emit('clearToken', '');
            this.$router.push({
              name: 'login'
            })
          }, 1500);
        } else {
          this.$message.error(err.response.data.message)
        }
      });
    },
    dzBtnHD(e) {
      this.$axios.post('/api/user/support/content', {
        id: e,
        type: 2,
      }, {
        headers: {
          'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
        }
      }).then((result) => {
        console.log(result)
        if (result.data.code == 200) {
          this.$message({
            message: '点赞成功！',
            type: 'success'
          });

          //重新获取详情接口
          this.$axios.get('/api/pc/activity/detail/' + e, {
            headers: {
              'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
            }
          }).then((result) => {
            console.log(result)
            if (result.data.code == 200) {
              this.detail = result.data.data;
              this.pinglunList = result.data.data.comments
            }
          }).catch((err) => {
            console.log(err)
            if (err.response.data.code == 403 || err.response.data.code == 500) {
              this.$message.error("请重新登录!");
              setTimeout(() => {
                eventBus.$emit('clearToken', '');
                this.$router.push({
                  name: 'login'
                })
              }, 1500);
            } else {
              this.$message.error(err.response.data.message)
            }
          });



          //重新获取详情接口结束


        } else {
          this.$message.error(result.data.message);
        }
      }).catch((err) => {
        console.log(err)
        // this.$message.error(err.response.data.message);
        if (err.response.data.code == 403 || err.response.data.code == 500) {
          this.$message.error("请重新登录!");
          setTimeout(() => {
            eventBus.$emit('clearToken', '');
            this.$router.push({
              name: 'login'
            })
          }, 1500);
        } else {
          this.$message.error(err.response.data.message);
        }
      });
    },

    //取消点赞
    qxdzBtn(e) {
      this.$axios.post('/api/user/unsupport/content', {
        id: e,
        type: 1,
      }, {
        headers: {
          'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
        }
      }).then((result) => {
        console.log(result)
        if (result.data.code == 200) {
          this.$message({
            message: '取消点赞！',
            type: 'success'
          });

          //重新获取详情接口
          this.$axios.get('api/pc/article/detail/' + e, {
            headers: {
              'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
            }
          }).then((result) => {
            console.log(result)
            if (result.data.code == 200) {
              this.detail = result.data.data;
              this.pinglunList = result.data.data.comments
            } else {
              this.$message.error(result.data.message);
            }
          }).catch((err) => {
            console.log(err)
            // this.$message.error(err.response.data.message);
            if (err.response.data.code == 403 || err.response.data.code == 500) {
              this.$message.error("请重新登录!");
              setTimeout(() => {
                eventBus.$emit('clearToken', '');
                this.$router.push({
                  name: 'login'
                })
              }, 1500);
            } else {
              this.$message.error(err.response.data.message)
            }
          });

        } else {
          this.$message.error(result.data.message);
        }

      }).catch((err) => {
        console.log(err)
        // this.$message.error(err.response.data.message);
        if (err.response.data.code == 403 || err.response.data.code == 500) {
          this.$message.error("请重新登录!");
          setTimeout(() => {
            eventBus.$emit('clearToken', '');
            this.$router.push({
              name: 'login'
            })
          }, 1500);
        } else {
          this.$message.error(err.response.data.message)
        }
      });
    },


    //转发
    zfBtn(e) {
      this.$axios.post('/api/user/transfer/article', {
        id: e
      }, {
        headers: {
          'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
        }
      }).then((result) => {
        console.log(result)
        if (result.data.code == 200) {
          this.$message({
            message: '转发成功！',
            type: 'success'
          });
          setTimeout(() => {
            this.getDetail()
          }, 1500);
        } else {
          this.$message.error(result.data.message);
        }
      }).catch((err) => {
        console.log(err)
        // this.$message.error(err.response.data.message);
        if (err.response.data.code == 403 || err.response.data.code == 500) {
          this.$message.error("请重新登录!");
          setTimeout(() => {
            eventBus.$emit('clearToken', '');
            this.$router.push({
              name: 'login'
            })
          }, 1500);
        } else {
          this.$message.error(err.response.data.message)
        }
      });
    },

    //转发
    zfBtn1(e) {
      this.$axios.post('/api/pc/transfer/activity', {
        id: e
      }, {
        headers: {
          'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
        }
      }).then((result) => {
        console.log(result)
        if (result.data.code == 200) {
          this.$message({
            message: '转发成功！',
            type: 'success'
          });
          setTimeout(() => {
            this.getDetail(e)
          }, 500);
        } else {
          this.$message.error(result.data.message);
        }
      }).catch((err) => {
        console.log(err)
        // this.$message.error(err.response.data.message);
        if (err.response.data.code == 403 || err.response.data.code == 500) {
          this.$message.error("请重新登录!");
          setTimeout(() => {
            eventBus.$emit('clearToken', '');
            this.$router.push({
              name: 'login'
            })
          }, 1500);
        } else {
          this.$message.error(err.response.data.message)
        }
      });
    },





  }


}
</script>

<style lang="scss" scoped>
@import "../index/index.scss";
</style>