<template>
    <div class="index">
        <div class="jianli">
            <div class="jianli_left">
                <div class="left1">
                    <div class="touxiang">
                        <img src="../../img/morentx.png" alt="">
                        <!-- <div class="bianji">
                            <p>编辑头像</p>
                        </div> -->
                    </div>
                    <div class="tx_des">
                        <div class="des1">
                            <h2>{{ detail.name }}</h2>
                            <!-- <p>(6天后可编辑)</p> -->
                            <!-- <div class="renzheng">
                                <img src="../../img/renzheng.png" alt="">
                                <p>未认证</p>
                            </div> -->
                        </div>
                        <div class="des2">
                            <p>性别:{{ detail.gender }}</p>
                            <p>年龄:{{ detail.age }}</p>
                        </div>
                        <div class="des3">
                            <!-- <div class="anniu">
                                <p>账户认证</p>
                            </div> -->
                            <router-link to="/editJianli" class="anniu2">
                                <img src="../../img/bianji.png" alt="">
                                <p>编辑简历</p>
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="left2">
                    <div class="left2_1">
                        <div class="left2_head">
                            <img src="../../img/mioashu.png" alt="">
                            <p>自我描述</p>
                        </div>
                        <div class="left2_con">
                            <p>{{ detail.intro }}</p>
                        </div>
                    </div>
                    <div class="left2_2">
                        <div class="left2_head">
                            <img src="../../img/jingli.png" alt="">
                            <p>教育经历</p>
                        </div>
                        <div class="left2_2_con">
                            <p>{{ detail.edu }}</p>
                        </div>
                    </div>
                    <div class="left2_2">
                        <div class="left2_head">
                            <img src="../../img/jingli.png" alt="">
                            <p>工作经验</p>
                        </div>
                        <div class="left2_2_con">
                            <p>{{ detail.exp }}</p>
                        </div>
                    </div>
                    <div class="left2_2">
                        <div class="left2_head">
                            <img src="../../img/jingli.png" alt="">
                            <p>是否企业可见</p>
                        </div>
                        <div class="left2_2_con">
                            <p v-if="detail.is_visibled == 0">不可见</p>
                            <p v-else>可见</p>
                        </div>
                    </div>
                    <!-- <div class="left2_2">
                        <div class="left2_head">
                            <img src="../../img/jiangbei.png" alt="">
                            <p>获奖荣誉</p>
                        </div>
                        <div class="left2_2_con">
                            <div class="list1">
                                <img src="../../img/rongyu.png" alt="">
                                <p>2022</p>
                                <p>The Webby Awards</p>
                                <span>Artist of the Year</span>
                            </div>
                        </div>
                    </div> -->
                    <div class="left2_3">
                        <div class="left2_head">
                            <img src="../../img/ziliao.png" alt="">
                            <p>基本资料</p>
                        </div>
                        <div class="left2_3_1">
                            <div class="list1">
                                <p>电话号码:</p>
                                <span>{{detail.mobile}}</span>
                            </div>
                            <div class="list1">
                                <p>籍贯名称::</p>
                                <span v-if="detail.province_name != null">{{detail.province_name[0]}}</span>
                            </div>
                            <!-- <div class="list1">
                                <p>昵称:</p>
                                <span>september0729</span>
                            </div>
                            <div class="list1">
                                <p>真实姓名:</p>
                                <span></span>
                            </div>
                            <div class="list1">
                                <p>所在区域:</p>
                                <span>山东潍坊</span>
                            </div>
                            <div class="list1">
                                <p>工作职能:</p>
                                <span>技术/制作</span>
                            </div>
                            <div class="list1">
                                <p>手机:</p>
                                <span>18354278770</span>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="jianli_right">
                <div class="right1">
                    <div class="right_top">
                        <div class="yuan">
                            <div class="yuan_nei" :style="{ height: dynamicHeight }"></div>
                        </div>
                        <div class="yuan_des">
                            <p>我的简历完成60%</p>
                            <span>编辑简历</span>
                        </div>
                    </div>
                </div>
            </div>
            <div style="clear: both;"></div>
        </div>
        <router-view></router-view>
    </div>
</template>

<script>
export default {
    name: 'PageswangzhanMyJianli',

    data() {
        return {
            pageTitle: '我的简历',
            contentHeight: 70, // 用于计算 height 的数据属性
            userinfo:'',
            detail:'',
        };
    },
    created() {
        document.title = this.pageTitle;
    },
    computed: {
        dynamicHeight() {
            // 根据需要的逻辑计算动态的 height 值
            return this.contentHeight + '%';
        }
    },
    mounted() {
        this.getjianli();
        this.getinfo()
    },

    methods: {

        getjianli() {
            this.$axios.get('/api/user/resume', {
                headers: {
                    'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
                }
            }).then((result) => {
                console.log(result)
                if(result.data.code == 200){
                    this.detail = result.data.data
                }else{
                    this.$message.error(result.data.message);
                }
            }).catch((err) => {
                console.log(err)
                this.$message.error(err.response.data.message);
                if (err.response.data.code == 403) {
                    setTimeout(() => {
                        this.$router.push({
                            name: 'login'
                        })
                    }, 1500);
                }

            });
        },
        
        getinfo(){
            this.$axios.get('/api/user/info',{
                headers: {
                    'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
                }
            }).then((result) => {
                console.log(result)
                if(result.data.code == 200){
                    this.userinfo = result.data.data
                }else{
                    this.$message.error(result.data.message);
                }
            }).catch((err) => {
                console.log(err)
                this.$message.error(err.response.data.message);
                if (err.response.data.code == 403) {
                    setTimeout(() => {
                        this.$router.push({
                            name: 'login'
                        })
                    }, 1500);
                }
            });
        },

        bianji() {
            this.$router.push({
                name: 'editJianli'
            })
        },
    },
};
</script>

<style lang="scss" scoped>
@import '../myJianli/myJianli.scss'
</style>