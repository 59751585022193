<template>
    <div class="index">
        <!-- <headerbar></headerbar> -->
        <div class="index_nei">
            <div class="login">
                <div class="img">
                    <img src="../../img/logo1.png" alt="">
                </div>
                <div class="loginCon">
                    <div class="xuanze">
                        <div class="xuanze1">
                            <p>注册</p>
                            <span @click="gszhuce">公司注册</span>
                        </div>
                        <div class="xuanze2">
                            <p>已有账号？</p>
                            <router-link to="/login">登录</router-link>
                        </div>
                    </div>
                    <div class="shuru">
                        <div class="shuru1">
                            <input type="text" placeholder="账号名称" v-model="name">
                        </div>
                        <div class="shuru1">
                            <input type="text" placeholder="手机号" v-model="mobile">
                        </div>
                        <div class="shuru2">
                            <input type="text" placeholder="验证码" v-model="code">
                            <div class="anniu" @click="getcode">
                                <img :src="imageData" alt="Image">
                            </div>
                        </div>
                        <div class="shuru1">
                            <input type="password" placeholder="输入密码" v-model="password">
                        </div>
                        <div class="shuru1">
                            <input type="password" placeholder="再次输入密码" v-model="password2nd">
                        </div>
                        <div class="shuru3" @click="zhuce">
                            <p>注册</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <footerbar></footerbar>
    </div>
</template>

<script>
// import headerbar from '../../components/headerbar.vue';
import { eventBus } from '../../App.vue';
import footerbar from '../../components/footerbar.vue';
export default {
    name: 'zhuce',
    data() {
        return {
            pageTitle: '注册',
            name: '',
            mobile: '',
            code: '',
            password: '',
            password2nd: '',
            id: '',
            imageData: '',
        }
    },
    created() {
        document.title = this.pageTitle;
    },
    components: {
        // headerbar,
        footerbar
    },
    mounted() {
        this.getcode()
        this.setActivePage()
    },
    methods: {
        setActivePage() {
            // 触发事件，传递当前页面的名称
            eventBus.$emit('updateActivePage', '');
        },
        //获取验证码
        getcode() {
            this.$axios.get('/api/__yao/login/admin/captcha', {
                type: 'digit'
            }).then((result) => {
                console.log(result)
                this.imageData = result.data.content;
                this.id = result.data.id
            }).catch((err) => {
                console.log(err)
            });
        },

        //公司注册
        gszhuce(){
            this.$router.push({
                name:'zhuce_qy',
            })
        },

        //注册
        zhuce() {
            if (this.name == "") {
                this.$message.error('请输入姓名!');
                // this.$message({
                //     message:"请输入账号名字!",
                //     type:'warning'
                // });
                return
            }
            if (this.mobile == "") {
                this.$message.error('请输入登录账号!');
                return
            }
            if (this.code == "") {
                this.$message.error('请输入验证码!');
                return
            }
            if (this.password == "") {
                this.$message.error('请输入密码!');
                return
            }
            if (this.password2nd == "") {
                this.$message.error('请再次输入密码!');
                return
            }
            if (this.password != this.password2nd) {
                this.$message.error('两次密码不一致!');
                return
            }
            this.$axios.post('/api/user/register', {
                name: this.name,
                mobile: this.mobile,
                password: this.password,
                password2nd: this.password2nd,
                captcha: {
                    code: this.code,
                    id: this.id
                },
            }).then((result) => {
                if (result.data.code == 200) {
                    this.$message({
                        message: result.data.message,
                        type: 'success'
                    });
                    setTimeout(() => {
                        this.$router.push({
                            name:'login'
                        })
                    }, 2000);
                }else{
                    this.$message.error(result.data.message);
                }
                console.log(result)
            }).catch((err) => {
                console.log(err)
                this.$message.error(err.response.data.message);
            });
        },


    },
}
</script>

<style scoped lang="scss">
@import '../zhuce/zhuce.scss'
</style>