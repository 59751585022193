<template>
    <div class="index">
        <!-- <headerbar></headerbar> -->
        <div class="index_nei">
            <div class="toubu">
                <p v-if="type == 'article'">发布文章</p>
                <p v-if="type == 'video'">发布视频</p>
                <p v-if="type == 'image'">发布海报</p>
                <p v-if="type == 'project'">发布项目</p>
                <p v-if="type == 'case'">发布案例</p>
            </div>
            <div class="neirong">
                <div class="biaoti">
                    <div class="biaoti_left">
                        <div class="biaoti1">
                            <p>标题:</p>
                            <el-input type="text" placeholder="请输入标题" v-model="title" maxlength="30"
                                show-word-limit></el-input>
                        </div>
                        <div class="detail">
                            <div class="detail1">
                                <p>作者:</p>
                                <!-- <el-input v-model="author" placeholder="请输入作者"></el-input> -->
                                <el-select v-model="author" placeholder="请选择作者" @change="shaixuan1">
                                    <el-option v-for="item in dailiList" :key="item.value" :label="item.label"
                                        :value="item.label">
                                    </el-option>
                                </el-select>
                            </div>
                            <div class="detail1">
                                <p>来源:</p>
                                <el-input v-model="origin" placeholder="请输入来源"></el-input>
                            </div>

                            <div class="detail1">
                                <p>时间:</p>
                                <el-date-picker v-model="published_at" type="datetime" placeholder="选择发布时间"
                                    @change="tochoose">
                                </el-date-picker>

                            </div>
                        </div>
                        <div class="hangye">
                            <p>行业:</p>
                            <el-select v-model="value1" multiple placeholder="请选择" @change="shaixuan">
                                <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.name">
                                </el-option>
                            </el-select>
                        </div>



                    </div>

                </div>
                <div class="biaoti_right">
                    <!-- <p>设置封面</p> -->
                    <div class="tutu">

                        <!-- <el-upload class="upload-demo" ref="upload" action="/api/utils/upload" :on-preview="handlePreview"
                            :on-remove="handleRemove" :auto-upload="true" :limit="1" accept="image/*"
                            list-type="picture-card" :on-success="handleAvatarSuccess" :before-upload="beforeUploadAction">
                            <el-button slot="trigger" size="small" type="primary">选取封面</el-button>
                        </el-upload> -->

                        <el-upload class="upload-demo" action="/" :limit="1" accept="image/*"
                            :before-upload="beforeUploadAction" :on-preview="handlePreview" list-type="picture-card">
                            <el-button slot="trigger" size="small" type="primary">选取封面</el-button>
                        </el-upload>

                        <div class="imgShow" v-if="cover">
                            <img class="tu1" :src="cover" alt="">
                            <img class="shanchu" src="@/img/shanchu.png" alt="" @click="deleteImage">
                        </div>
                        <div class="tanchuang" v-if="tcshow">
                            <img class="close" src="../../img/close.png" alt="" @click="closetc">
                            <div class="tanchuang1">
                                <PictureCropper ref="picture" :initialImg="initialImg" :cropperHeight="cropperHeight"
                                    :cropperWidth="cropperWidth" :autoCropWidth="autoCropWidth"
                                    :autoCropHeight="autoCropHeight" @picturePreview="picturePreview" />

                                <div class="anniuxuanze" style="display: flex;align-items: center;">
                                    <el-button class="common-button blue-border" @click="rotateLeft">向左旋转</el-button>
                                    <el-button class="common-button blue-border" @click="rotateRight">向右旋转</el-button>
                                    <el-button class="common-button blue-border" @click="zoom(1)">放大</el-button>
                                    <el-button class="common-button blue-border" @click="zoom(-1)">缩小</el-button>
                                    <!-- <el-button class="common-button blue-border" @click="download">下载图片</el-button> -->
                                </div>
                            </div>



                            <div class="preview-picture">
                                <div class="picture">
                                    <div class="preview-container" :style="previews.div">
                                        <img class="preview-img" :src="previews.url" :style="previews.img">
                                    </div>
                                    <!-- <div class="preview-container preview-radius" :style="previews.div">
                                        <img class="preview-img" :src="previews.url" :style="previews.img">
                                    </div> -->
                                </div>
                                <el-button class="common-button blue-border upload-avatar"
                                    @click="uploadAvatar('blob')">上传封面</el-button>
                            </div>

                        </div>






                    </div>
                </div>
                <div class="haibao">
                    <el-upload class="poster111" action="/api/utils/upload" :limit="1" list-type="picture-card"
                        :on-preview="handlePreview" :on-remove="handleRemove" :on-success="handleAvatarSuccess1">
                        <i>品牌logo</i>
                    </el-upload>
                </div>

                <div class="biaoti" style="height: auto;">
                    <div class="biaoti_left">
                        <div class="biaoti1">
                            <p>品牌名:</p>
                            <!-- <el-input type="text" placeholder="请输入品牌名" v-model="brand" maxlength="30"
                                show-word-limit></el-input> -->
                            <el-select v-model="brand" placeholder="请选择品牌名" @change="shaixuan2">
                                <el-option v-for="item in brandList" :key="item.value" :label="item.label"
                                    :value="item.label">
                                </el-option>
                            </el-select>
                        </div>
                    </div>

                </div>


                <!--  <div class="shipin">
                    <div class="shipin1">
                        <label for="file-upload" class="custom-file-upload">
                            <span>上传视频</span>
                        </label>
                        <input id="file-upload" type="file" ref="uploadFile2" @change="handleFileChange"
                            enctype="multipart/form-data" accept="video/*" />
                        <video v-if="videoUrl" :src="videoUrl" controls></video>
                        <img class="shanchu" src="@/img/shanchu.png" alt="" @click="deleteVideo" v-if="videoUrl">
                    </div>

                </div> -->
                <div class="shuru">
                    <!-- <quill-editor v-model="content" :options="editorOptions"></quill-editor> -->
                    <!-- <div ref="editor" :class="['editor']"></div> -->
                    <div class="shuru_top">
                        <p>使用Tab快捷键可唤出选项列表</p>
                    </div>
                    <div id="editorjs-container" ref="editor" class="editor"></div>
                </div>
                <div class="anniu">
                    <button @click="publish">发布</button>
                </div>
            </div>
        </div>
        <footerbar></footerbar>
        <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>
    </div>
</template>

<script>
import VideoBlock from '@/assets/video-block.js';
import EmbedExternalTool from '@/assets/EmbedExternalTool.js';
import PictureCropper from '@/components/pictureCropper.vue'
import moment from 'moment';
// import headerbar from '../../components/headerbar.vue';
import footerbar from '../../components/footerbar.vue';
// import { quillEditor } from 'vue-quill-editor';
// import 'quill/dist/quill.snow.css';
import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
// import Paragraph from '@editorjs/paragraph';
import List from '@editorjs/list';
import SimpleImage from '@editorjs/image';
// import Embed from '@editorjs/embed';
import Quote from '@editorjs/quote';
import Warning from '@editorjs/warning';
// import Marker from '@editorjs/marker';
import CodeTool from '@editorjs/code';
import Delimiter from '@editorjs/delimiter';
// import InlineCode from '@editorjs/code';
import Table from '@editorjs/table';
import LinkTool from '@editorjs/link';
// import Checklist from "@editorjs/checklist"
// import LocalVideoTool from "@/LocalVideoTool"
import { eventBus } from '../../App.vue';
export default {
    name: 'PageswangzhanFabu',

    data() {
        return {
            pageTitle: '发布',
            title: '',
            brand: '',
            content: '',
            origin: '',
            author: '',
            type: '',
            published_at: '',
            value1: '',
            options: [],
            cover: '',
            video: '',
            poster: [],
            brand_logo: [],
            thumbnail: null,
            editorOptions: {
                // 根据需要配置Quill编辑器选项
                // 例如：设置图片上传的处理函数等
            },
            dialogImageUrl: '',
            dialogVisible: false,
            videoFile: null,
            videoUrl: '',



            initialImg: '',
            previews: {

            },
            cropperHeight: '500px',  // 裁剪图片容器高度
            cropperWidth: '800px',   // 裁剪图片容器宽度
            autoCropWidth: 200,
            autoCropHeight: 200,
            tcshow: false,

            dailiList: [],
            brandList: [],

        };

    },
    components: {
        // headerbar,
        footerbar,
        PictureCropper,
        // quillEditor
    },
    created() {
        document.title = this.pageTitle;
    },

    mounted() {

        const editor = new EditorJS({

            // 配置项
            language: 'zh-cn', // 或 'zh-cn'
            holder: 'editorjs-container', // 指定容器元素的ID或类名
            tools: {
                header: Header,
                image: {
                    class: SimpleImage,
                    config: {
                        uploader: {
                            uploadByFile: this.handleImageUpload,
                        },
                    }
                },
                // embed: {
                //     class: Embed,
                //     config: {
                //         services: {
                //             youtube: true,
                //             vimeo: true,
                //             // 其他支持的服务
                //         },
                //     },
                // },
                list: List,
                video: VideoBlock,
                customEmbed: {
                    class: EmbedExternalTool,
                },
                quote: Quote,
                warning: Warning,
                code: CodeTool,
                delimiter: Delimiter,
                linkTool: LinkTool,
                table: Table

            },


            i18n: {
                /**
                 * @type {I18nDictionary}
                 */
                messages: {
                    /**
                     * Other below: translation of different UI components of the editor.js core
                     */
                    ui: {
                        "toolbar": {
                            "toolbox": {
                                "Add": "添加"
                            }
                        }
                    },

                    /**
                     * Section for translation Tool Names: both block and inline tools
                     */
                    toolNames: {
                        "Text": "文本",
                        "Heading": "标题",
                        "List": "列表",
                        "Warning": "警告",
                        "Image": '图片',
                        "Embed": '插入',
                        // "LocalVideoTool": '视频',
                        // "Checklist": "列表",
                        "Quote": "引用",
                        "Code": "代码",
                        "Delimiter": "分隔符",
                        "Raw HTML": "HTML",
                        "Table": "表格",
                        "Link": "链接",
                        "Marker": "标记",
                        "Bold": "加粗",
                        "Italic": "斜体",
                        // "InlineCode": "Моноширинный",
                    },

                    /**
                     * Section for passing translations to the external tools classes
                     */
                    tools: {
                        /**
                         * Each subsection is the i18n dictionary that will be passed to the corresponded plugin
                         * The name of a plugin should be equal the name you specify in the 'tool' section for that plugin
                         */
                        "warning": { // <-- 'Warning' tool will accept this dictionary section
                            "Title": "标题",
                            "Message": "信息",
                        },

                        /**
                         * Link is the internal Inline Tool
                         */
                        "link": {
                            "Add a link": "添加链接"
                        },
                        /**
                         * The "stub" is an internal block tool, used to fit blocks that does not have the corresponded plugin
                         */
                        "stub": {
                            'The block can not be displayed correctly.': '该板块不能正确显示'
                        }
                    },

                    /**
                     * Section allows to translate Block Tunes
                     */
                    blockTunes: {
                        /**
                         * Each subsection is the i18n dictionary that will be passed to the corresponded Block Tune plugin
                         * The name of a plugin should be equal the name you specify in the 'tunes' section for that plugin
                         *
                         * Also, there are few internal block tunes: "delete", "moveUp" and "moveDown"
                         */
                        "delete": {
                            "Delete": "删除"
                        },
                        "moveUp": {
                            "Move up": "上移"
                        },
                        "moveDown": {
                            "Move down": "下移"
                        }
                    },
                }
            },



            // 其他配置项...
            // onChange: (api) => {
            //     console.log(api)
            //     api.save().then((outputData) => {
            //         this.content = outputData;
            //     });
            // },
        });

        this.editor = editor;
        console.log(this.editor)
        // this.$nextTick(() => {
        //     const videoTool = new LocalVideoTool({
        //         config: {}, // 根据你的需求设置 config
        //         api: editor, // 将 Editor.js 的 api 对象传递给 LocalVideoTool
        //     });
        //     console.log(this.editor)
        //     console.log(this.editor.configuration)
        //     this.editor.configuration.tools.video = videoTool;
        //     // 其他逻辑...
        // });
        console.log(this.$route.query.type)
        this.type = this.$route.query.type;
        // window.location.reload();
        this.$watch(
            () => this.$route.query.type,
            (newVal, oldVal) => {
                // 参数发生变化时进行处理
                console.log('参数变化：', newVal);
                console.log(oldVal)
                this.type = this.$route.query.type;
                // 更新组件内容或执行其他逻辑
                // ...
            }
        );
        this.gethangye()
        this.setActivePage()

        this.getdaili();
        this.getpinpai();

    },

    methods: {

        //获取代理商
        getdaili() {
            this.$axios.get('/api/select/agent').then((result) => {
                console.log(result)
                if (result.data.code == 200) {
                    this.dailiList = result.data.data
                } else {
                    this.$message.error(result.data.message);
                }
            }).catch((err) => {
                console.log(err)
            });
        },
        //获取品牌
        getpinpai() {
            this.$axios.get('/api/select/brand').then((result) => {
                console.log(result)
                if (result.data.code == 200) {
                    this.brandList = result.data.data
                } else {
                    this.$message.error(result.data.message);
                }
            }).catch((err) => {
                console.log(err)
            });
        },


        closetc() {
            this.tcshow = false
        },

        picturePreview(data) {
            this.previews = data
        },
        // 向左旋转
        rotateLeft() {
            this.$refs.picture.rotateLeft()
        },
        // 向左旋转
        rotateRight() {
            this.$refs.picture.rotateLeft()
        },
        // 放大、缩小
        zoom(num) {
            num = num || 1
            this.$refs.picture.changeScale(num)
        },
        // 下载图片
        async download() {
            let aLink = document.createElement('a')
            aLink.download = '下载裁剪图片'
            let data = await this.$refs.picture.getBase64()
            this.tcshow = false;
            aLink.href = data
            aLink.click()
        },




        // 上传图片之前
        beforeUploadAction(file) {
            this.tcshow = true;
            return new Promise((resolve, reject) => {
                // 转换为blob
                var reader = new FileReader()
                let reg = /\.jpg$|\.jpeg$|\.gif$|\.png$/i
                // 转化为base64
                reader.readAsDataURL(file)
                let name = file.name
                if (reg.test(name)) {
                    reader.onload = (e) => {
                        let data
                        if (typeof e.target.result === 'object') {
                            data = window.URL.createObjectURL(new Blob([e.target.result]))
                        } else {
                            data = e.target.result
                        }
                        this.initialImg = data
                        resolve(data)
                    }
                } else {
                    this.$message.error('请上传图片')
                    reject()
                }
            })
        },

        async uploadAvatar(type) {
            if (type === 'blob') {
                // 获取截图的blob数据
                let data = await this.$refs.picture.getBlob()
                this.uploadPhoto(data);
                console.log(data)

            }
        },
        async uploadPhoto(blob) {
            try {
                const formData = new FormData()
                formData.append('file', blob, '.jpg')


                const response = await fetch('/api/utils/upload', {
                    method: 'POST',
                    body: formData,
                });
                console.log(response)
                if (response.ok) {
                    this.$message({
                        message: "上传成功!",
                        type: 'success'
                    });
                    const data = await response.json();
                    console.log(data)
                    this.tcshow = false;
                    this.cover = data.data.file;
                    // this.dialogVisible = true;
                    // return {
                    //     success: 1,
                    //     file: {
                    //         url: data.data.file,
                    //     },
                    // };
                } else {
                    return {
                        success: 0,
                        message: 'Failed to upload image',
                    };
                }



                // 上传头像的业务代码...省略
                // this.$message.success('修改头像成功')
            } catch (error) {
                // this.$message.error('失败')
                return {
                    success: 0,
                    message: '上传失败!',
                };
            }
        },








        setActivePage() {
            // 触发事件，传递当前页面的名称
            eventBus.$emit('updateActivePage', '');
        },
        // initializeEditor() {
        //     const editor = new EditorJS({
        //         // holder: 'editorjs',
        //         holder: this.$refs.editor,
        //         tools: {
        //             header: Header,
        //             paragraph: Paragraph,
        //             list: List,
        //             image: Image,
        //             embed: Embed,

        //         },
        //         // 可以在这里配置其他Editor.js选项
        //     });

        //     // 可以在这里访问编辑器实例并执行其他操作
        //     console.log(editor);
        // },



        shaixuan(e) {
            console.log(e)
            this.value1 = e
        },
        shaixuan1(e) {
            console.log(e)
            this.author = e
        },
        shaixuan2(e) {
            console.log(e)
            this.brand = e
        },

        tochoose(e) {
            console.log(moment(e).format('YYYY-MM-DD HH:mm:ss'))
            // this.published_at = 
        },

        submitUpload() {
            this.$refs.upload.submit();
        },
        handleAvatarSuccess(res) {
            console.log(res)
            if (res.code == 200) {
                this.$message({
                    message: res.message,
                    type: 'success'
                });
                this.cover = res.data.file;
                this.fmShow = false
            }
            // this.cover = URL.createObjectURL(file.raw);
        },
        handleAvatarSuccess1(res) {
            console.log(res)
            if (res.code == 200) {
                this.$message({
                    message: res.message,
                    type: 'success'
                });
                this.poster.push(res.data.file);
                this.brand_logo.push(res.data.file);
                this.fmShow = false
            }
        },
        handleRemove(file, fileList) {
            console.log(file, fileList);
        },
        handlePreview(file) {
            console.log(file);
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },


        handleRemove1(file, fileList) {
            console.log(file, fileList);
        },
        handlePreview1(file) {
            console.log(file);
            // this.dialogImageUrl = file.url;
            // this.dialogVisible = true;
        },

        async handleImageUpload(file) {
            try {
                const formData = new FormData();
                formData.append('file', file);

                const response = await fetch('/api/utils/upload', {
                    method: 'POST',
                    body: formData,
                });
                console.log(response)
                if (response.ok) {
                    this.$message({
                        message: "上传成功!",
                        type: 'success'
                    });
                    const data = await response.json();
                    console.log(data)
                    return {
                        success: 1,
                        file: {
                            url: data.data.file,
                        },
                    };
                } else {
                    return {
                        success: 0,
                        message: 'Failed to upload image',
                    };
                }
            } catch (error) {
                return {
                    success: 0,
                    message: 'An error occurred during image upload',
                };
            }
        },

        // handleImageUpload(file){
        //     const formData = new FormData();
        //     formData.append("file", file);
        //     this.$axios.post('/api/utils/upload', formData).then((res) => {
        //         console.log(res)
        //         if (res.code == 200) {
        //             this.$message({
        //                 message: res.message,
        //                 type: 'success'
        //             });
        //         }
        //     }).catch((err) => {
        //         console.log(err)
        //     });
        // },
        deleteVideo() {
            this.videoUrl = '';

        },
        deleteImage() {
            this.cover = '';
        },

        handleFileChange() {
            this.videoFile = event.target.files[0];
            this.videoUrl = URL.createObjectURL(this.videoFile);
            console.log(this.videoUrl)
            const formData = new FormData();
            formData.append("file", this.videoFile);
            this.$axios.post('/api/utils/upload', formData).then((res) => {
                console.log(res)
                if (res.code == 200) {
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });
                    this.video = res.data.file;
                }
            }).catch((err) => {
                console.log(err)
            });






        },
        // uploadVideo() {
        //     // 在这里执行上传视频的操作，可以使用你喜欢的HTTP库（如axios）发送视频文件给服务器
        //     console.log(this.videoFile);
        // },

        //获取行业列表
        gethangye() {
            this.$axios.get('/api/pc/industry', {}).then((result) => {
                console.log(result)
                if (result.data.code == 200) {
                    this.options = result.data.data
                } else {
                    this.$message.error(result.data.message);
                }
            }).catch((err) => {
                console.log(err)
                this.$message.error(err.response.data.message);
            });
        },




        async publish() {
            const savedData = await this.editor.save();
            // 处理发布逻辑，获取编辑器中的内容、封面等
            console.log(savedData)
            console.log(this.content);
            this.content = savedData;
            if (this.title == '') {
                this.$message.error('请输入文章标题！');
                return
            }
            var cover = [];
            var video = [];
            var poster = this.poster;
            var brand_logo = this.brand_logo;
            video.push(this.video)
            cover.push(this.cover)
            // poster.push(this.poster)
            this.$axios.post('/api/__yao/form/company.article/save', {
                type: this.type,
                industry: this.value1,
                title: this.title,
                brand: this.brand,
                published_at: this.published_at,
                content: [
                    {
                        data: this.content
                    }
                ],
                author: this.author,
                origin: this.origin,
                cover: cover,
                video: video,
                poster: poster,
                brand_logo: brand_logo,
            }, {
                headers: {
                    'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
                }
            }).then((result) => {
                console.log(result)
                if (result.status == 200) {
                    this.$message({
                        message: '发布成功！等待审核~',
                        type: 'success'
                    });
                    setTimeout(() => {
                        window.location.reload()
                    }, 1500);
                }
            }).catch((err) => {
                console.log(err)
                this.$message.error(err.response.data.message);
                if (err.response.data.code == 403) {
                    setTimeout(() => {
                        this.$router.push({
                            name: 'login'
                        })
                    }, 1500);
                }
            });
        }
    },
    beforeUnmount() {
        // 在组件销毁前释放创建的临时URL
        URL.revokeObjectURL(this.videoUrl);
    }
};
</script>

<style lang="scss" scoped>
@import "../fabu/fabu.scss"
</style>

<style>
.biaoti_right .el-upload--picture-card {
    width: auto;
    height: 40px;
    line-height: 40px;
    border: none;
}

.ce-toolbar__actions {
    left: 20px;
}


.ce-toolbar__content {
    max-width: 100%;
}

.codex-editor__redactor {
    padding: 30px 80px;
    padding-bottom: 80px !important;

}

.ce-block__content {
    max-width: 100%;
    height: 100%;
}

.tutu .upload-demo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.el-time-spinner__item {
    text-align: center;
}

.el-date-table td span {
    text-align: center;
}

.custom-button {
    width: 100%;
    height: 100%;
}

.el-upload--picture-card {
    background: none;
    width: 130px;
    height: 130px;
    line-height: 130px;
}

/***
.el-upload-list--picture-card {
    position: absolute;
    left: 0;
    top: 0;
}-->* */

.el-upload-list--picture-card .el-upload-list__item {
    width: 130px;
    height: 130px;
}




@media(max-width:1301px) {
    .index .index_nei {
        width: 100% !important;
        box-sizing: border-box;
        padding: 35px;
    }
}

@media(max-width:768px) {
    .index .index_nei .neirong .biaoti .biaoti_left .detail {
        flex-direction: column;
        height: auto !important;
        align-items: baseline !important;
    }

    .index .index_nei .neirong .biaoti .biaoti_left .detail .detail1 {
        margin-bottom: 15px;
        width: 100% !important;
    }

    .index .index_nei .neirong .biaoti_right {
        margin-top: 60px !important;
    }

    .index .index_nei .neirong .shuru {
        height: 600px !important;
    }

    .index .index_nei .neirong .shuru .quill-editor {
        height: 450px !important;
    }
}
</style>

