<template>
    <div class="index">
        <div class="jianli">
            <div class="jianli_left">
                <!-- <div class="left1">
                    <div class="touxiang">
                        <img src="../../img/morentx.png" alt="">
                        <div class="bianji">
                            <p>编辑头像</p>
                        </div>
                    </div>
                    <div class="tx_des">
                        <div class="des1">
                            <h2>{{ detail.name }}</h2>
                            <p>(6天后可编辑)</p>
                            <div class="renzheng">
                                <img src="../../img/renzheng.png" alt="">
                                <p>未认证</p>
                            </div>
                        </div>
                        <div class="des2">
                            <p>技术/制作</p>
                            <p>山东潍坊</p>
                        </div>
                        <div class="des3">
                            <div class="anniu">
                                <p>编辑昵称</p>
                            </div>
                            <div class="anniu2">
                                <img src="../../img/duihao1.png" alt="">
                                <p>编辑完成</p>
                            </div>
                        </div>
                    </div>
                </div> -->
                <div class="left2">
                    <div class="left2_1">
                        <div class="left2_head">
                            <div class="left2_head1">
                                <img src="../../img/mioashu.png" alt="">
                                <p>自我描述</p>
                            </div>
                            <!-- <div class="left2_head2" @click="tobianjiAnniu" v-if="msShow == false">
                                <img src="../../img/bianji.png" alt="">
                                <p>编辑</p>
                            </div> v-if="msShow"-->
                        </div>
                        <div class="left2_con_tian">
                            <textarea placeholder="请输入..." v-model="intro"></textarea>
                            <!-- <div class="dibu">
                                <div class="anniu1" @click="baocun">
                                    <p>保存</p>
                                </div>
                                <div class="anniu2" @click="quxiao">
                                    <p>取消</p>
                                </div>
                            </div> -->
                        </div>
                        <!-- <div class="left2_con" v-else>
                            <p>您还没填写</p>
                        </div> -->
                    </div>
                    <div class="left2_1">
                        <div class="left2_head">
                            <div class="left2_head1">
                                <img src="../../img/jingli.png" alt="">
                                <p>工作经验</p>
                            </div>
                            <!-- <div class="left2_head2" @click="addJingli" v-if="JLshow == false">
                                <img src="../../img/jiahao.png" alt="">
                                <p>添加</p>
                            </div> -->
                        </div>
                        <!-- <div class="left2_con" v-if="JLshow == false">
                            <p>您还没填写</p>
                        </div> -->
                        <div class="left2_jingli_shuru">
                            <el-form :model="dynamicValidateForm" ref="dynamicValidateForm" label-width="100px"
                                class="demo-dynamic">
                                <el-form-item prop="email" label="工作经验">
                                    <el-input v-model="dynamicValidateForm.email"></el-input>
                                </el-form-item>
                                <el-form-item v-for="(domain, index) in dynamicValidateForm.domains" :label="'工作经验'"
                                    :key="domain.key" :prop="'domains.' + index + '.value'" :rules="{
                                        required: true, message: '不能为空', trigger: 'blur'
                                    }">
                                    <el-input v-model="domain.value"></el-input><el-button
                                        @click.prevent="removeDomain(domain)">删除</el-button>
                                </el-form-item>
                                <el-form-item>
                                    <!-- <el-button type="primary" @click="submitForm('dynamicValidateForm')">提交</el-button> -->
                                    <el-button @click="addDomain">新增工作经验</el-button>
                                    <el-button @click="resetForm('dynamicValidateForm')">重置</el-button>
                                </el-form-item>
                            </el-form>
                           

                        </div>
                    </div>
                    <div class="left2_1">
                        <div class="left2_head">
                            <div class="left2_head1">
                                <img src="../../img/jingli.png" alt="">
                                <p>教育经历</p>
                            </div>
                            <!-- <div class="left2_head2" @click="addjiaoyu" v-if="JYshow == false">
                                <img src="../../img/jiahao.png" alt="">
                                <p>添加</p>
                            </div> -->
                        </div>
                        <div class="left2_jingli_shuru">
                            <el-form :model="dynamicValidateForm1" ref="dynamicValidateForm1" label-width="100px"
                                class="demo-dynamic">
                                <el-form-item prop="email" label="教育经历">
                                    <el-input v-model="dynamicValidateForm1.email"></el-input>
                                </el-form-item>
                                <el-form-item v-for="(domain, index) in dynamicValidateForm1.domains" :label="'教育经历'"
                                    :key="domain.key" :prop="'domains.' + index + '.value'" :rules="{
                                        required: true, message: '不能为空', trigger: 'blur'
                                    }">
                                    <el-input v-model="domain.value"></el-input><el-button
                                        @click.prevent="removeDomain(domain)">删除</el-button>
                                </el-form-item>
                                <el-form-item>
                                    <!-- <el-button type="primary" @click="submitForm('dynamicValidateForm')">提交</el-button> -->
                                    <el-button @click="addDomain1">新增教育经历</el-button>
                                    <el-button @click="resetForm1('dynamicValidateForm1')">重置</el-button>
                                </el-form-item>
                            </el-form>

                        </div>
                    </div>
                    <!-- <div class="left2_2">
                        <div class="left2_head">
                            <div class="left2_head1">
                                <img src="../../img/jiangbei.png" alt="">
                                <p>获奖荣誉</p>
                            </div>
                            <div class="left2_head2" @click="tianjiarongyu">
                                <img src="../../img/jiahao.png" alt="">
                                <p>添加</p>
                            </div>

                        </div>
                        <div class="left2_2_con">
                            <div class="list1">
                                <img src="../../img/rongyu.png" alt="">
                                <p>2022</p>
                                <p>The Webby Awards</p>
                                <span>Artist of the Year</span>
                                <div class="xuanze">
                                    <div class="xxx1">
                                        <img src="../../img/bianji1.png" alt="">
                                    </div>
                                    <div class="xxx1">
                                        <img src="../../img/chahao.png" alt="">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <div class="left2_3">
                        <div class="left2_head">
                            <div class="left2_head1">
                                <img src="../../img/ziliao.png" alt="">
                                <p>基本资料</p>
                            </div>
                            <div class="left2_head2" @click="tianjiarongyu">
                                <img src="../../img/jiahao.png" alt="">
                                <p>添加</p>
                            </div>


                        </div>
                        <div class="left2_3_1">
                            <div class="list1">
                                <p>我的姓名:</p>
                                <input class="shuru" type="text" v-model="name" placeholder="输入姓名">
                            </div>
                            <div class="list1">
                                <p>我的性别:</p>
                                <el-radio-group v-model="radio" @input="tochange">
                                    <el-radio :label="'男'">男</el-radio>
                                    <el-radio :label="'女'">女</el-radio>
                                </el-radio-group>
                            </div>
                            <div class="list1">
                                <p>我的手机:</p>
                                <input class="shuru" type="text" v-model="phone" placeholder="输入电话">
                            </div>
                            <div class="list1">
                                <p>我的年龄:</p>
                                <input class="shuru" type="text" v-model="age" placeholder="输入年龄">
                            </div>
                            <div class="list1">
                                <p>我的籍贯:</p>
                                <el-form label-width="auto">
                                    <el-form-item>
                                        <el-select size="small" style="" v-model="selectProv" placeholder="请选择省份"
                                            v-on:change="getProv($event)">
                                            <el-option v-for="item in provs" :label="item.name" :value="item.name"
                                                :key="item.index">
                                            </el-option>
                                        </el-select>
                                        <el-select size="small" style="padding-right:19px;width: auto" v-model="selectCity"
                                            placeholder="请选择城市" v-on:change="getCity($event)">
                                            <el-option v-for="item in citys" :label="item.name" :value="item.name"
                                                :key="item.index">
                                            </el-option>
                                        </el-select>

                                        <el-select size="small" style="padding-right:19px;width: auto"
                                            v-model="selectDistrict" placeholder="请选择区域" v-on:change="getDistrict($event)">
                                            <el-option v-for="item in districts" :label="item.name" :value="item.name"
                                                :key="item.index">
                                            </el-option>
                                        </el-select>


                                    </el-form-item>
                                </el-form>
                            </div>
                            <div class="list1">
                                <p>是否企业可见:</p>
                                <input class="check" type="checkbox" :checked="checked" v-model="is_visibled"
                                    @change="changeVisibled" name="" id="">
                            </div>

                        </div>
                        <div class="tijiao" @click="tijiao">
                            <p>修改完成</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="jianli_right">
                <!-- <div class="right1">
                    <div class="right_top">
                        <div class="yuan">
                            <div class="yuan_nei" :style="{ height: dynamicHeight }"></div>
                        </div>
                        <div class="yuan_des">
                            <p>我的简历完成60%</p>
                            <span>编辑简历</span>
                        </div>
                    </div>
                </div> -->
            </div>
            <div style="clear: both;"></div>
        </div>
    </div>
</template>

<script>
import { eventBus } from '../../App.vue';
export default {
    name: 'PageswangzhanMyJianli',

    data() {
        return {
            pageTitle: '我的简历',
            contentHeight: 70, // 用于计算 height 的数据属性
            msShow: false,
            JLshow: false,
            detail: '',
            JYshow: false,
            name: '',
            phone: '',
            age: '',
            intro: '',  //个人简介
            province: '',
            provinceCode: '',
            dynamicValidateForm: {
                domains: [{
                    value: ''
                }],
                email: ''
            },
            dynamicValidateForm1: {
                domains: [{
                    value: ''
                }],
                email: ''
            },
            districts: '',
            checked: false,
            is_visibled: 0,
            radio: "男",
            provs: [],
            citys: [],
            nianxian: [
                '2023',
                '2022',
                '2021',
                '2020',
                '2019',
                '2018',
                '2017',
                '2016',
                '2015',
                '2014',
                '2013',
            ],

            nianxian1: [
                '2023',
                '2022',
                '2021',
                '2020',
                '2019',
                '2018',
                '2017',
                '2016',
                '2015',
                '2014',
                '2013',
            ],
            month: [
                "1",
                "2",
                "3",
                "4",
                "5",
                "6",
                "7",
                "8",
                "9",
                "10",
                "11",
                "12",
            ],

            month1: [
                "1",
                "2",
                "3",
                "4",
                "5",
                "6",
                "7",
                "8",
                "9",
                "10",
                "11",
                "12",
            ],
            selectProv: '',
            selectCity: '',
            selectDistrict: '',
        };
    },
    created() {
        document.title = this.pageTitle;
    },
    computed: {
        dynamicHeight() {
            // 根据需要的逻辑计算动态的 height 值
            return this.contentHeight + '%';
        }
    },
    mounted() {
        this.getjianli();
        this.getChengshi()
    },

    methods: {


        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                console.log(this.dynamicValidateForm)
                if (valid) {
                    alert('submit!');
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        },
        removeDomain(item) {
            var index = this.dynamicValidateForm.domains.indexOf(item)
            if (index !== -1) {
                this.dynamicValidateForm.domains.splice(index, 1)
            }
        },
        addDomain() {
            this.dynamicValidateForm.domains.push({
                value: '',
                key: Date.now()
            });
        },



        submitForm1(formName) {
            this.$refs[formName].validate((valid) => {
                console.log(this.dynamicValidateForm)
                if (valid) {
                    alert('submit!');
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        resetForm1(formName) {
            this.$refs[formName].resetFields();
        },
        removeDomain1(item) {
            var index = this.dynamicValidateForm1.domains.indexOf(item)
            if (index !== -1) {
                this.dynamicValidateForm1.domains.splice(index, 1)
            }
        },
        addDomain1() {
            this.dynamicValidateForm1.domains.push({
                value: '',
                key: Date.now()
            });
        },



        tochange(e) {
            console.log(e)
        },
        //获取省市区
        getChengshi() {
            this.$axios.get('/api/pc/area', {}).then((result) => {
                console.log(result)
                if (result.data.code == 200) {
                    this.provs = result.data.data
                }
            }).catch((err) => {
                console.log(err)
            });
        },

        /*二级联动选择地区*/
        getProv: function (prov) {
            let tempCity = '';
            this.citys = [];
            this.districts = [];
            this.selectDistrict = '';
            this.selectCity = '';
            let allCity = this.provs;
            for (var val of allCity) {
                if (prov == val.name) {
                    console.log(val);
                    tempCity = val.children
                    this.provinceCode = val.code
                }
            }
            console.log(prov)
            this.selectProv = prov;
            this.citys = tempCity;
        },
        getCity: function (city) {
            let tempDitricts = '';
            this.districts = [];
            this.selectDistrict = '';
            let allCity = this.citys;
            for (var val of allCity) {
                if (city == val.name) {
                    console.log(val);
                    tempDitricts = val.children
                }
            }
            console.log(city);
            console.log(this.selectCity)
            console.log(tempDitricts)
            // this.selectDistrict = city;
            this.districts = tempDitricts;
        },
        getDistrict(district) {
            console.log(district)
            console.log(this.selectDistrict)
        },

        changeVisibled() {
            this.checked = !this.checked;
            if (this.checked == false) {
                this.is_visibled = 0
            } else {
                this.is_visibled = 1
            }
            console.log(this.is_visibled)
        },

        tijiao() {
            let reg = /^1[3456789]\d{9}$/;
            if (!reg.test(this.phone)) {
                this.$message.error('手机号格式不正确！');
                return
            }
            var exp = this.dynamicValidateForm.email +',' + this.dynamicValidateForm.domains.map(obj => obj.value).join(',');
            var edu = this.dynamicValidateForm1.email +',' + this.dynamicValidateForm1.domains.map(obj => obj.value).join(',');
            this.$axios.post('/api/user/resume/save', {
                name: this.name,
                gender: this.radio,
                age: this.age,
                province: this.provinceCode,
                mobile: this.phone,
                intro: this.intro,
                exp: exp,
                edu: edu,
                is_visibled: this.is_visibled
            }, {
                headers: {
                    'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
                }
            }).then((result) => {
                console.log(result)
                if (result.data.code == 200) {
                    this.$message({
                        message: result.data.message,
                        type: 'success'
                    });
                    setTimeout(() => {
                        this.$router.push({
                            name: "myJianli",
                        })
                    }, 1500);
                } else {
                    this.$message.error(result.data.message);
                }
            }).catch((err) => {
                console.log(err)
                this.$message.error(err.response.data.message);
            });
        },

        getjianli() {
            this.$axios.get('/api/user/resume', {
                headers: {
                    'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
                }
            }).then((result) => {
                console.log(result)
                if (result.data.code == 200) {
                    this.detail = result.data.data
                }
            }).catch((err) => {
                console.log(err)
                this.$message.error(err.response.data.message);
                setTimeout(() => {
                    eventBus.$emit('clearToken', '');
                    this.$router.push({
                        name: 'login'
                    })
                }, 1500);
            });
        },
        tianjiarongyu() {

        },
        tobianjiAnniu() {
            this.msShow = true
        },
        quxiao() {
            this.msShow = false
        },
        quxiao1() {
            this.JLshow = false
        },
        quxiao2() {
            this.JYshow = false
        },
        addJingli() {
            this.JLshow = true
        },
        addjiaoyu() {
            this.JYshow = true
        },
        baocun() {

        },
    },
};
</script>

<style lang="scss" scoped>
.index {
    margin-top: 20px;
    width: 100%;
    height: auto;
    background-color: #f2f2f2;

    .jianli {
        width: 100%;
        height: auto;
        box-sizing: border-box;
        padding: 10px;

        .jianli_left {
            float: left;
            width: 75%;
            height: auto;

            .left1 {
                width: 100%;
                height: 160px;
                display: flex;
                background-color: #fff;
                margin-bottom: 15px;

                .touxiang {
                    margin-right: 20px;
                    width: 160px;
                    height: 160px;
                    position: relative;

                    &:hover {
                        .bianji {
                            display: block;
                        }
                    }

                    img {
                        width: 100%;
                        height: 100%;
                    }

                    .bianji {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        z-index: 99;
                        width: auto;
                        height: auto;
                        padding: 5px 10px;
                        background-color: #212121;
                        display: block;
                        cursor: pointer;

                        p {
                            font-size: 14px;
                            color: #fff;
                        }
                    }
                }

                .tx_des {
                    width: 80%;
                    height: auto;
                    display: flex;
                    flex-direction: column;
                    box-sizing: border-box;
                    justify-content: space-between;
                    padding: 15px;
                    padding-left: 0;

                    .des1 {
                        margin-bottom: 0px;
                        width: 100%;
                        height: auto;
                        display: flex;
                        align-items: center;

                        h2 {
                            margin-right: 10px;
                            font-size: 18px;
                            color: #212121;
                            font-weight: bold;
                        }

                        p {
                            margin-right: 20px;
                            font-size: 15px;
                            color: #ff6702;
                        }

                        .renzheng {
                            width: auto;
                            display: flex;
                            align-items: center;

                            img {
                                margin-right: 8px;
                                width: 15px;
                                height: 15px;
                            }
                        }
                    }

                    .des2 {
                        width: auto;
                        height: auto;
                        display: flex;
                        flex-direction: column;

                        p {
                            margin-bottom: 5px;
                            font-size: 15px;
                            color: #999;
                        }
                    }

                    .des3 {
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        width: 100%;

                        .anniu {
                            margin-right: 15px;
                            width: 100px;
                            background-color: #ff6702;
                            height: 35px;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            cursor: pointer;

                            p {
                                font-size: 15px;
                                color: #fff;
                                line-height: 35px;
                            }
                        }

                        .anniu2 {
                            width: 180px;
                            height: 35px;
                            background-color: #212121;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            cursor: pointer;

                            img {
                                width: 15px;
                                height: 15px;
                                margin-right: 10px;
                            }

                            p {
                                font-size: 15px;
                                color: #fff;
                            }
                        }
                    }
                }
            }

            .left2 {
                width: 100%;
                height: auto;
                box-sizing: border-box;
                padding: 15px;
                background-color: #fff;

                .left2_head {
                    margin-bottom: 25px;
                    width: 100%;
                    height: auto;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .left2_head1 {
                        display: flex;
                        align-items: center;
                    }

                    .left2_head2 {
                        display: flex;
                        align-items: center;
                        padding: 3px 15px;
                        background-color: #ff6702;
                        cursor: pointer;

                        img {
                            margin-right: 5px;
                            width: 20px;
                        }

                        p {
                            color: #fff;
                            font-size: 14px;
                        }
                    }

                    img {
                        margin-right: 15px;
                        width: 25px;
                        height: auto;
                    }

                    p {
                        font-size: 16px;
                    }
                }


                .left2_1 {
                    margin: 15px 0;
                    width: 100%;
                    height: auto;

                    &:first-child {
                        margin-top: 0;
                    }

                    .left2_con {
                        width: 100%;
                        height: auto;
                        padding-bottom: 30px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        border-bottom: 1px solid #e0e0e0;

                        p {
                            font-size: 15px;
                            color: #999;
                        }

                        .left2_con_lishi {
                            width: 100%;
                            height: auto;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;

                            .jingli_left {
                                width: auto;
                                height: auto;
                                display: flex;
                                flex-direction: column;
                                align-items: baseline;

                                .jingli1 {
                                    display: flex;
                                    align-items: center;

                                    p {
                                        margin-right: 15px;
                                        font-size: 15px;
                                        color: #212121;
                                        font-weight: bold;
                                    }

                                    span {
                                        font-size: 14px;
                                        color: #ff6702;
                                        cursor: pointer;
                                    }
                                }

                                .jingli2 {
                                    margin: 10px 0;
                                    display: flex;
                                    align-items: center;

                                    p {
                                        margin-right: 15px;
                                        font-size: 14px;
                                        color: #212121;
                                    }

                                    span {
                                        font-size: 14px;
                                        color: #999;
                                    }

                                }

                                .jingli3 {
                                    p {
                                        font-size: 14px;
                                        color: #999;
                                    }

                                }
                            }
                        }
                    }

                    .left2_jingli_shuru {
                        width: 100%;
                        height: auto;

                        .shuru1 {
                            width: 530px;
                            height: 30px;
                            display: flex;
                            align-items: center;

                            p {
                                margin-right: 15px;
                                font-size: 15px;
                                color: #212121;
                                width: 100px;
                            }

                            .inp {
                                width: 460px;
                                height: 30px;
                                display: flex;
                                align-items: center;
                                background: #fff;
                                box-sizing: border-box;
                                padding: 0 10px;
                                border: 1px solid #ccc;

                                input {
                                    width: 95%;
                                    height: 30px;
                                    background: none;
                                    border: none;
                                    outline: none;
                                }

                                .add {
                                    width: 16px;
                                    height: 16px;
                                    background: #212121;
                                    border-radius: 50%;
                                    display: flex;
                                    flex-direction: column;
                                    align-items: center;
                                    justify-content: center;

                                    img {
                                        width: 14px;
                                        height: 14px;
                                    }
                                }
                            }

                        }

                        .shuru2 {
                            margin: 15px 0;
                            width: 530px;
                            height: 30px;
                            display: flex;
                            align-items: center;

                            p {
                                margin-right: 15px;
                                font-size: 15px;
                                color: #212121;
                                width: 100px;
                            }

                            input {
                                width: 460px;
                                height: 30px;
                                background: #fff;
                                outline: none;
                                padding: 0 10px;
                                border: 1px solid #ccc;
                            }
                        }

                        .shuru3 {
                            display: flex;
                            align-items: baseline;

                            p {
                                margin-right: 15px;
                                font-size: 15px;
                                color: #212121;
                                width: 100px;
                            }

                            .shijian {
                                width: 480px;
                                height: auto;
                                display: flex;
                                flex-direction: column;

                                .shijian1 {
                                    display: flex;
                                    align-items: center;
                                    margin-bottom: 10px;

                                    input {
                                        width: 13px;
                                        height: 13px;
                                        margin-right: 15px;
                                    }

                                    p {
                                        font-size: 15px;
                                        color: #212121;
                                    }
                                }

                                .shijian2 {
                                    display: flex;
                                    align-items: center;

                                    .shijian2_1 {
                                        display: flex;
                                        align-items: center;

                                        select {
                                            width: 60px;
                                            height: 30px;
                                        }

                                        span {
                                            margin: 0 15px;
                                            font-size: 16px;
                                            color: #212121;
                                        }
                                    }
                                }
                            }
                        }

                        .dibu {
                            margin-top: 15px;
                            width: 100%;
                            height: 35px;
                            display: flex;
                            align-items: center;

                            .anniu1 {
                                margin-right: 20px;
                                width: 120px;
                                height: 35px;
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                justify-content: center;
                                background: #212121;
                                cursor: pointer;

                                p {
                                    font-size: 15px;
                                    color: #ffffff;
                                }
                            }

                            .anniu2 {
                                margin-right: 20px;
                                width: 120px;
                                height: 35px;
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                justify-content: center;
                                background: #999;
                                cursor: pointer;

                                p {
                                    font-size: 15px;
                                    color: #212121;
                                }
                            }
                        }
                    }

                    .left2_con_tian {
                        width: 100%;
                        height: 300px;

                        textarea {
                            width: 100%;
                            height: 250px;
                            background: #fff;
                            resize: none;
                            outline: none;
                            box-sizing: border-box;
                            padding: 12px;
                            border-radius: 5px;
                            border: 1px solid #ccc;
                            font-size: 15px;
                        }

                        .dibu {
                            margin-top: 15px;
                            width: 100%;
                            height: 35px;
                            display: flex;
                            align-items: center;

                            .anniu1 {
                                margin-right: 20px;
                                width: 120px;
                                height: 35px;
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                justify-content: center;
                                background: #212121;
                                cursor: pointer;

                                p {
                                    font-size: 15px;
                                    color: #ffffff;
                                }
                            }

                            .anniu2 {
                                margin-right: 20px;
                                width: 120px;
                                height: 35px;
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                justify-content: center;
                                background: #999;
                                cursor: pointer;

                                p {
                                    font-size: 15px;
                                    color: #212121;
                                }
                            }
                        }
                    }

                }

                .left2_2 {
                    margin: 15px 0;
                    width: 100%;
                    height: auto;

                    .left2_2_con {
                        padding-bottom: 15px;
                        width: 100%;
                        height: auto;
                        border-bottom: 1px #e0e0e0 solid;

                        .list1 {
                            width: 100%;
                            height: auto;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;

                            img {
                                margin-right: 20px;
                                width: 50px;
                                height: 50px;
                            }

                            p {
                                display: block;
                                margin-right: 20px;
                                width: 150px;
                                height: auto;
                                color: #212121;
                                font-size: 15px;
                            }

                            span {
                                width: 150px;
                                height: auto;
                                font-size: 16px;
                                color: #333;
                                font-weight: bold;
                            }

                            .xuanze {
                                width: 200px;
                                height: auto;
                                display: flex;
                                align-items: center;
                                justify-content: center;

                                .xxx1 {
                                    margin-right: 20px;
                                    width: 18px;
                                    height: 18px;

                                    img {
                                        width: 100%;
                                        height: 100%;
                                        object-fit: contain;
                                    }
                                }
                            }
                        }
                    }
                }

                .left2_3 {

                    width: 100%;
                    height: auto;

                    .left2_3_1 {
                        width: 100%;
                        height: auto;
                        display: flex;
                        flex-direction: column;

                        .list1 {
                            width: auto;
                            height: auto;
                            display: flex;
                            align-items: center;
                            margin-bottom: 15px;

                            p {
                                width: 150px;
                                font-size: 15px;
                                color: #212121;
                            }

                            span {
                                width: 150px;
                                font-size: 15px;
                                color: #212121;
                            }

                            .shuru {
                                width: 290px;
                                height: 40px;
                                box-sizing: border-box;
                                padding: 5px 10px;
                                border: 1px solid #ccc;
                                border-radius: 5px;

                                &::placeholder {
                                    color: #ccc;
                                }
                            }

                            .el-form {
                                width: 420px;
                                height: 40px;

                                .el-form-item {
                                    margin-bottom: 0;
                                    height: 40px;

                                    .el-select {
                                        padding-right: 0 !important;
                                        width: 190px;
                                        height: 40px;

                                        .el-input {
                                            height: 40px;

                                            input {
                                                height: 40px;
                                            }
                                        }
                                    }
                                }
                            }

                            .check {
                                width: 15px;
                                height: 15px;
                                border: 1px solid #ccc;
                            }
                        }
                    }

                    .tijiao {
                        margin: auto;
                        margin-top: 50px;
                        width: 180px;
                        height: 45px;
                        background: #ff6702;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        border-radius: 5px;
                        cursor: pointer;

                        p {
                            font-size: 15px;
                            color: #fff;
                        }
                    }
                }

            }
        }

        .jianli_right {
            float: right;
            width: 24%;
            height: auto;

            .right1 {
                padding: 15rpx;
                box-sizing: border-box;
                width: 100%;
                height: auto;
                background-color: #fff;

                .right_top {
                    width: 100%;
                    height: auto;
                    display: flex;
                    align-items: center;
                    justify-content: space-around;

                    .yuan {
                        position: relative;
                        width: 120px;
                        height: 120px;
                        border-radius: 50%;
                        background-color: #fff;
                        border: 8px solid #eeeeee;
                        overflow: hidden;

                        .yuan_nei {
                            position: absolute;
                            left: 0;
                            bottom: 0;
                            width: 100%;
                            background-color: #ff6702;
                        }
                    }

                    .yuan_des {
                        width: auto;
                        height: auto;
                        display: flex;
                        flex-direction: column;

                        p {
                            padding: 10px 0;
                            font-size: 16px;
                            color: #212121;
                        }

                        span {
                            color: #ff6702;
                            cursor: pointer;
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
}
</style>

<style>
.list1 .el-input input {
    height: 40px;
    line-height: 40px;
    border: 1px solid #ccc;
}

.list1 .el-form-item__content {
    display: flex;
    align-items: center;
}

.list1 .el-select {
    margin-right: 10px;
    /* width: 190px; */
}
</style>