<template>
    <div class="index">
        <!-- <headerbar :selected-nav="selectedNav"></headerbar> -->
        <div class="index_nei">
            <div class="content">
                <div class="title">
                    <div class="detail">
                        <img v-if="detail.user.avatar.length > 0" :src="detail.user.avatar[0]" alt="">
                        <img v-if="detail.company.logo != null" :src="detail.company.logo" alt="">
                        <div class="detail1">
                            <p>{{ detail.author }}</p>
                            <span>上线时间：{{ detail.published_at | datefmt('YYYY-MM-DD HH:mm') }}</span>
                        </div>
                    </div>
                    <h1>{{ detail.title }}</h1>
                </div>
                <div class="des">
                    <!-- <div v-if="detail.content != null">
                        <div v-for="(item, index) in detail.content" :key="index">
                            <p v-html="item.data.text"></p>
                        </div>
                    </div> -->
                    <img v-for="(item, index) in detail.cover" :src="item" :key="index" alt="">
                </div>
                <div class="xiangqing">
                    <div class="xiangqing_top">
                        <div class="xq_img">
                            <!-- <img src="../../img/job1.png" alt="">
                            <img src="../../img/job2.png" alt=""> -->
                        </div>
                        <div class="button" @click="canjia(detail.id)">
                            <p>参与活动</p>
                        </div>
                    </div>
                    <div class="xiangqing_bot">
                        <h3>活动详情</h3>
                        <div class="bot_c">
                            <div class="bot1" v-if="detail.content != null">
                                <div class="list" v-for="(item, index) in detail.content" :key="index">
                                    <p v-html="item.data.text"></p>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="zuopin">
                <div class="title">
                    <h2>更多作品</h2>
                    <p>查看更多</p>
                </div>
                <div class="list">
                    <div class="list1" v-for="(item, index) in tuijian" :key="index">
                        <img :src="item.pic" alt="">
                    </div>
                </div>
            </div> -->
            <div class="allDibu">
                <div class="dibu_left">
                    <div class="pinlun">
                        <div class="pinglun_shuru">
                            <h2>发表评论</h2>
                            <div class="shuru">
                                <!-- <div class="tx">
                                    <img :src="myXinxi.pic" alt="">
                                </div> -->
                                <div class="kuang" style="width:100%">
                                    <el-input type="textarea" placeholder="说两句吧" v-model="textarea" maxlength="2000"
                                        show-word-limit resize="none">
                                    </el-input>
                                    <div class="anniu" @click="pinglunBtn(detail.id)">
                                        <p>发布</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="pl_list" v-if="pinglunList.length > 0">
                            <h3>全部评论({{ pinglunList.length }})</h3>
                            <div class="allList">
                                <div class="list1" v-for="(item, index) in pinglunList" :key="index">
                                    <div class="list1_img">
                                        <img :src="item.user.avatar" alt="">
                                    </div>
                                    <div class="list_xq">
                                        <div class="xq_left">
                                            <div class="left_top">
                                                <p>{{ item.user.name }}</p>
                                                <!-- <span>{{ item.time }}</span> -->
                                            </div>
                                            <div class="left_bot">
                                                <p>{{ item.content }}</p>
                                                <div class="button" @click="huifu()">回复</div>
                                            </div>
                                        </div>
                                        <div class="xq_right" :class="{ active: item.isdian === true }">
                                            <img src="../../img/tuijian_a.png" alt="" v-if="item.isdian">
                                            <img src="../../img/tuijian.png" alt="" v-else>
                                            <p>{{ item.support_count }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="dibu_right">
                    <div class="right1">
                        <h3>发布者</h3>
                        <div class="right1_con">
                            <div class="con1" @click="todetail(detail.company.id)">
                                <div class="con1_left">
                                    <img v-if="detail.company.logo.length > 0" :src="detail.company.logo[0]" alt="">
                                    <div class="detail">
                                        <p>{{ detail.company.name }} </p>
                                        <!-- <span>{{ detail.user.position }}</span> -->
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="con2">
                                <div class="con2_1">
                                    <p>{{ detail.user.fans }}</p>
                                    <span>粉丝</span>
                                </div>
                                <div class="con2_1">
                                    <p>{{ detail.user.article_count }}</p>
                                    <span>文章</span>
                                </div>
                                <div class="con2_1">
                                    <p>{{ detail.user.case_count }}</p>
                                    <span>案例</span>
                                </div>
                                <div class="con2_1">
                                    <p>{{ detail.user.project_count }}</p>
                                    <span>项目</span>
                                </div>
                            </div> -->
                            <!-- <div class="con2">
                                <div class="con2_1" v-if="!zuozhe.isguanzhu">
                                    <img src="../../img/duihao.png" alt="">
                                    <p>已关注</p>
                                </div>
                                <div class="con2_2" v-else>
                                    <span>+</span>
                                    <p>关注</p>
                                </div>
                                <div class="con2_3">
                                    <img src="../../img/email_1.png" alt="">
                                    <p>发信息</p>
                                </div>
                            </div> -->
                        </div>
                    </div>
                    <!-- <div class="right1">
                        <h3>发布者</h3>
                        <div class="right1_con">
                            <div class="list">
                                <img src="../../img/jiang.png" alt="">
                            </div>
                            <div class="list">
                                <img src="../../img/jiang1.png" alt="">
                            </div>
                            <div class="list">
                                <img src="../../img/jiang2.png" alt="">
                            </div>
                            <div class="list">
                                <img src="../../img/jiang3.png" alt="">
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
        <div class="fudong">
            <div class="fu1">
                <img v-if="detail.user.avatar.length > 0" :src="detail.user.avatar[0]" alt="">
                <div class="anniu">
                    <p>+</p>
                </div>
            </div>
            <div class="fu2">
                <div class="img">
                    <img src="../../img/xinxi.png" alt="">
                </div>
                <div class="anniu">
                    <p>{{ detail.comments.length }}</p>
                </div>
            </div>
            <div class="fu2" :class="{ active: detail.is_support == true }">
                <div class="img" @click="dzBtn(detail.id)">
                    <img src="../../img/tuijian.png" alt="" v-if="!detail.is_support">
                    <img src="../../img/tuijian_a.png" alt="" v-else>
                </div>
                <div class="anniu">
                    <p>{{ detail.support_count }}</p>
                </div>
            </div>
            <div class="fu2">
                <div class="img" @click="scBtn(detail.id)">
                    <img src="../../img/sc.png" alt="">
                </div>
                <div class="anniu">
                    <p>{{ detail.collect_count }}</p>
                </div>
            </div>
            <div class="fu2">
                <div class="img" @click="zfBtn(detail.id)">
                    <img src="../../img/zhuanfa.png" alt="">
                </div>
                <div class="anniu">
                    <p>{{ detail.transfer_count }}</p>
                </div>
            </div>
        </div>
        <footerbar></footerbar>
    </div>
</template>

<script>
// import headerbar from '../../components/headerbar.vue';
import { eventBus } from '../../App.vue';
import footerbar from '../../components/footerbar.vue';
export default {
    name: 'projectDetail',
    data() {
        return {
            pageTitle: '活动详情',
            selectedNav: '活动',
            textarea: '',
            id: '',
            detail: {
                title: 'DGEE是享誉国际的设计顾问公司，由埃迪·洛巴诺夫斯基及大卫·科瓦列夫合伙经营',
                isdianzan: true,
            },
            tuijian: [
                {
                    pic: require('../../img/tuijian1.jpg')
                },
                {
                    pic: require('../../img/tuijian2.jpg')
                },
                {
                    pic: require('../../img/tuijian3.jpg')
                },
                {
                    pic: require('../../img/tuijian4.jpg')
                },
                {
                    pic: require('../../img/tuijian5.jpg')
                },
                {
                    pic: require('../../img/tuijian6.jpg')
                },
                {
                    pic: require('../../img/tuijian1.jpg')
                },
                {
                    pic: require('../../img/tuijian2.jpg')
                },
            ],
            zuozhe: {
                name: 'Mearry',
                tx: require('../../img/shejishi.jpg'),
                isguanzhu: false,
                tag: '创意/设计',
                shenfen: '前时趣CMO,UDEE执行主编',
                fensi: '45893',
                wenzang: '74',
                anli: '88',
                xiangmu: '56'
            },
            myXinxi: {
                name: 'LGG',
                pic: require('../../img/shejishi.jpg'),
            },
            pinglunList: [
                {
                    author: {
                        name: '年少有为',
                        tx: require('../../img/shejishi1.jpg'),
                    },
                    time: '5小时前',
                    dianzan: '892',
                    isdian: true,
                    des: '结合文化与商业，引领着中国的品牌设计。2013年正式更名为靳刘高设计，印证三代人的同心协力。',
                },
                {
                    author: {
                        name: '年少有为',
                        tx: require('../../img/shejishi2.jpg'),
                    },
                    time: '5小时前',
                    dianzan: '892',
                    isdian: false,
                    des: '结合文化与商业，引领着中国的品牌设计。2013年正式更名为靳刘高设计，印证三代人的同心协力。',
                },
                {
                    author: {
                        name: '年少有为',
                        tx: require('../../img/shejishi1.jpg'),
                    },
                    time: '5小时前',
                    dianzan: '892',
                    isdian: true,
                    des: '结合文化与商业，引领着中国的品牌设计。2013年正式更名为靳刘高设计，印证三代人的同心协力。',
                },
                {
                    author: {
                        name: '年少有为',
                        tx: require('../../img/shejishi3.jpg'),
                    },
                    time: '5小时前',
                    dianzan: '892',
                    isdian: true,
                    des: '结合文化与商业，引领着中国的品牌设计。2013年正式更名为靳刘高设计，印证三代人的同心协力。',
                },
            ],
        }
    },
    created() {
        document.title = this.pageTitle;
    },
    mounted() {
        console.log(this.$route.query.id) // 输出 123
        this.id = this.$route.query.id
        this.getdetail()
        this.setActivePage()
    },
    components: {
        // headerbar,
        footerbar,
    },
    methods: {

        setActivePage() {
            // 触发事件，传递当前页面的名称
            eventBus.$emit('updateActivePage', '活动');
        },

        todetail(e) {
            this.$router.push({
                name: 'chuangzao',
                query: {
                    id: e
                }
            })
        },

        pinglunBtn(e) {
            this.$axios.post('/api/user/comment/save', {
                type: 1,
                ext_id: e,
                content: this.textarea,
                comment_id: '',
            }, {
                headers: {
                    'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
                }
            }).then((result) => {
                console.log(result)
                if (result.data.code == 200) {
                    this.$message({
                        message: result.data.message,
                        type: 'success'
                    });
                    this.textarea = ""
                } else {
                    this.$message.error(result.data.message);
                }
            }).catch((err) => {
                console.log(err)
                this.$message.error(err.response.data.message);
            });
        },
        //获取详情
        getdetail() {
            this.$axios.get('/api/pc/activity/detail/' + this.id, {}).then((result) => {
                console.log(result)
                if (result.data.code == 200) {
                    this.detail = result.data.data;
                    this.pinglunList = result.data.data.comments
                }
            }).catch((err) => {
                console.log(err)
            });
        },

        //参加活动
        canjia(e) {
            this.$axios.get('/api/user/activity/join/' + e, {
                headers: {
                    'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
                }
            }).then((result) => {
                console.log(result)
                if (result.data.code == 200) {
                    this.$message({
                        message: '参与成功！',
                        type: 'success'
                    });
                    setTimeout(() => {
                        this.getdetail()
                    }, 1500);
                } else {
                    this.$message.error(result.data.message);
                }
            }).catch((err) => {
                console.log(err)
                this.$message.error(err.response.data.message);
                if (err.response.data.code == 403) {
                    setTimeout(() => {
                        this.$router.push({
                            name: 'login'
                        })
                    }, 1500);
                }
            });
        },



        //收藏
        scBtn(e) {
            this.$axios.post('/api/user/collect/article', {
                id: e,
                type: 1,
            }, {
                headers: {
                    'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
                }
            }).then((result) => {
                console.log(result)
                if (result.data.code == 200) {
                    this.$message({
                        message: '收藏成功！',
                        type: 'success'
                    });
                    setTimeout(() => {
                        this.getdetail()
                    }, 1500);
                } else {
                    this.$message.error(result.data.message);
                }
            }).catch((err) => {
                console.log(err)
                this.$message.error(err.response.data.message);
                if (err.response.data.code == 403) {
                    setTimeout(() => {
                        this.$router.push({
                            name: 'login'
                        })
                    }, 1500);
                }
            });
        },


        //点赞
        dzBtn(e) {
            this.$axios.post('/api/user/support/content', {
                id: e,
                type: 2,
            }, {
                headers: {
                    'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
                }
            }).then((result) => {
                console.log(result)
                if (result.data.code == 200) {
                    this.$message({
                        message: '点赞成功！',
                        type: 'success'
                    });
                    setTimeout(() => {
                        this.getdetail()
                    }, 1500);
                } else {
                    this.$message.error(result.data.message);
                }
            }).catch((err) => {
                console.log(err)
                this.$message.error(err.response.data.message);
                if (err.response.data.code == 403) {
                    setTimeout(() => {
                        this.$router.push({
                            name: 'login'
                        })
                    }, 1500);
                }
            });
        },

        //转发
        zfBtn(e) {
            this.$axios.post('/api/user/transfer/article', {
                id: e
            }, {
                headers: {
                    'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
                }
            }).then((result) => {
                console.log(result)
                if (result.data.code == 200) {
                    this.$message({
                        message: '转发成功！',
                        type: 'success'
                    });
                    setTimeout(() => {
                        this.getDetail()
                    }, 1500);
                } else {
                    this.$message.error(result.data.message);
                }
            }).catch((err) => {
                console.log(err)
                this.$message.error(err.response.data.message);
                if (err.response.data.code == 403) {
                    setTimeout(() => {
                        this.$router.push({
                            name: 'login'
                        })
                    }, 1500);
                }
            });
        },




    },
}
</script>

<style scoped lang="scss">
@import '../huodongDetail/huodongDetail.scss'
</style>