<template>
  <div id="app">
    
    <headerbar :selected-nav="selectedNav"></headerbar>

    <router-view></router-view>
  </div>
</template>

<script>
import headerbar from './components/headerbar.vue';
import Vue from 'vue'
export const eventBus = new Vue();
export default {
  name: 'App',
  props: {
        selectedNav: String,
    },
  components:{
    headerbar
  },
  
 
}
</script>





<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

</style>
