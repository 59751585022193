<template>
    <div class="index">
        <!-- <headerbar :selected-nav="selectedNav"></headerbar> -->
        <div class="tuijian">
            <!-- <div class="faxian_top">
                 <div class="top_left">
                    <p>Creative City Guide创意城市指南</p>
                </div>
                <div class="top_right">
                    <p>查看更多</p>
                    <img src="../../img/arrow1.png" alt="">
                </div> 
            </div> -->
            <div class="tj_list">
                <div class="list1" v-for="(item, index) in tuijianList" :key="index" @click="todetail(item.id)">
                    <div class="img">
                        <img :src="item.cover[0]" alt="">
                        <div class="tag">
                            <p>文章</p>
                        </div>
                    </div>
                    <div class="des">
                        <div class="title">
                            <p>{{ item.title }}</p>
                        </div>
                        <div class="xq">
                            <div class="xq1">
                                <!-- <img :src="item.author" alt=""> -->
                                <p>{{ item.author }}</p>
                            </div>
                            <div class="xq2">
                                <div class="sc">
                                    <img src="../../img/shoucang.png" alt="">
                                    <p>{{ item.collect_count }}</p>
                                </div>
                                <div class="sc">
                                    <img src="../../img/dianzan.png" alt="">
                                    <p>{{ item.support_count }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="liebiao">
            <div class="liebiao_top">
                <div class="top_left">
                    <div class="choose" :class="{ active: choose === 'wenzhang' }" @click="chooseBtn('wenzhang')">
                        <p>海报</p>
                    </div>
                    <!-- <div class="choose" :class="{ active: choose === 'sheji' }" @click="chooseBtn('sheji')">
                        <p>设计</p>
                    </div>
                    <div class="choose" :class="{ active: choose === 'shipin' }" @click="chooseBtn('shipin')">
                        <p>视频</p>
                    </div> -->
                </div>
                <div class="top_right">
                    <div class="right1">
                        <el-select v-model="value" placeholder="请选择" @change="shaixuan">
                            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="right1">
                        <el-select v-model="value1" placeholder="请选择" @change="shaixuan1">
                            <el-option v-for="item in options1" :key="item.id" :label="item.name" :value="item.name">
                            </el-option>
                        </el-select>
                    </div>
                    <!-- <div class="right1">
                        <el-select v-model="value2" placeholder="请选择">
                            <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </div> -->
                    <div class="right1" v-if="choose == 'sheji' || choose == 'shipin'">
                        <el-select v-model="value3" placeholder="请选择">
                            <el-option v-for="item in options3" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                </div>
            </div>
            <!-- <div class="liebiao_con" v-if="choose == 'wenzhang'">
               
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :page-size="fanye.pagesize" layout="prev, pager, next" :total="fanye.pagecnt">
                </el-pagination>
            </div> -->
            <div class="liebiao_con" v-if="choose == 'wenzhang'">
                <div class="zpList">
                    <div class="zp" v-for="(item, index) in shejiList" :key="index" @click="todetail1(item.id)">
                        <div class="img">
                            <img :src="item.cover[0]" alt="">
                        </div>
                        <div class="xq">
                            <div class="xq1">
                                <!-- <img :src="item.author.tx" alt=""> -->
                                <p>{{ item.author }}</p>
                            </div>
                            <div class="xq2">
                                <div class="sc">
                                    <img src="../../img/shoucang.png" alt="">
                                    <!-- <p>{{ item.collect_count }}</p> -->
                                </div>
                                <div class="sc">
                                    <img src="../../img/dianzan.png" alt="">
                                    <p>{{ item.support_count }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
            <div class="liebiao_con" v-if="choose == 'shipin'">
                <div class="shipinList">
                    <div class="spList">
                        <div class="sp" v-for="(item, index) in shipinList" :key="index">
                            <div class="img">
                                <img class="tu" :src="item.pic" alt="">
                                <img class="biaozhi" src="../../img/shipin.png" alt="">
                                <div class="time">
                                    <p>03:20</p>
                                </div>
                            </div>
                            <div class="des">
                                <div class="title">
                                    <p>{{ item.title }}</p>
                                </div>
                                <div class="xq">
                                    <div class="xq1">
                                        <img :src="item.author.tx" alt="">
                                        <p>{{ item.author.name }}</p>
                                    </div>
                                    <div class="xq2">
                                        <div class="sc">
                                            <img src="../../img/shoucang.png" alt="">
                                            <p>{{ item.shoucang }}</p>
                                        </div>
                                        <div class="sc">
                                            <img src="../../img/dianzan.png" alt="">
                                            <p>{{ item.dianzan }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="ad">
                    <img :src="guanggao" alt="">
                </div>
                <div class="shipinList">
                    <div class="spList">
                        <div class="sp" v-for="(item, index) in shipinList" :key="index">
                            <div class="img">
                                <img class="tu" :src="item.pic" alt="">
                                <img class="biaozhi" src="../../img/shipin.png" alt="">
                                <div class="time">
                                    <p>03:20</p>
                                </div>
                            </div>
                            <div class="des">
                                <div class="title">
                                    <p>{{ item.title }}</p>
                                </div>
                                <div class="xq">
                                    <div class="xq1">
                                        <img :src="item.author.tx" alt="">
                                        <p>{{ item.author.name }}</p>
                                    </div>
                                    <div class="xq2">
                                        <div class="sc">
                                            <img src="../../img/shoucang.png" alt="">
                                            <p>{{ item.shoucang }}</p>
                                        </div>
                                        <div class="sc">
                                            <img src="../../img/dianzan.png" alt="">
                                            <p>{{ item.dianzan }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="dibu">
                <div class="dibu1" v-if="!islogin">
                    <p>登录后查看更多内容</p>
                    <div class="button">
                        <span>登录/注册</span>
                    </div>
                </div>
            </div> -->
        </div>
        <footerbar></footerbar>
    </div>
</template>

<script>
// import headerbar from '../../components/headerbar.vue';
import { eventBus } from '../../App.vue';
import footerbar from '../../components/footerbar.vue';
export default {
    name: 'faxian',
    data() {
        return {
            pageTitle: '海报',
            choose: 'wenzhang',
            selectedNav: '发现',
            guanggao: '',
            islogin: false,
            fanye:'',
            options: [{
                value: '1',
                label: '精选'
            }, {
                value: '2',
                label: '热门'
            }, {
                value: '3',
                label: '热点筛选'
            }],
            value: '',
            options1: [{
                value: '选项1',
                label: '标签1'
            }, {
                value: '选项2',
                label: '标签2'
            }, {
                value: '选项3',
                label: '标签3'
            }, {
                value: '选项4',
                label: '标签4'
            }, {
                value: '选项5',
                label: '标签5'
            }],
            value1: '',
            options2: [{
                value: '选项1',
                label: '行业1'
            }, {
                value: '选项2',
                label: '行业2'
            }, {
                value: '选项3',
                label: '行业3'
            }, {
                value: '选项44',
                label: '行业'
            }, {
                value: '选项5',
                label: '行业5'
            }],
            value2: '',
            options3: [{
                value: '选项1',
                label: '颜色1'
            }, {
                value: '选项2',
                label: '颜色2'
            }, {
                value: '选项3',
                label: '颜色3'
            }, {
                value: '选项44',
                label: '颜色4'
            }, {
                value: '选项5',
                label: '颜色5'
            }],
            value3: '',
            tuijianList: [
                {
                    img: require('@/img/tuijian1.jpg'),
                    tag: '文章',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
                    date: '2023-02-16',
                    dianzan: '125',
                    shoucang: '7895',
                    isdianzan: false,
                    author: {
                        name: '用户123',
                        tx: require('@/img/tx.jpg'),
                    },
                },


                {
                    img: require('@/img/tuijian2.jpg'),
                    tag: '文章',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
                    date: '2023-02-16',
                    dianzan: '125',
                    shoucang: '7895',
                    isdianzan: false,
                    author: {
                        name: '用户123',
                        tx: require('@/img/tx.jpg'),
                    },
                },

                {
                    img: require('@/img/tuijian3.jpg'),
                    tag: '文章',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
                    date: '2023-02-16',
                    dianzan: '125',
                    isdianzan: false,
                    shoucang: '7895',
                    author: {
                        name: '用户123',
                        tx: require('@/img/tx.jpg'),
                    },
                },

            ],
            allList: [
                {
                    img: require('@/img/tuijian1.jpg'),
                    tag: '文章',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
                    date: '2023-02-16',
                    dianzan: '125',
                    shoucang: '7895',
                    isdianzan: false,
                    author: {
                        name: '用户123',
                        tx: require('@/img/tx.jpg'),
                    },
                },


                {
                    img: require('@/img/tuijian2.jpg'),
                    tag: '文章',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
                    date: '2023-02-16',
                    dianzan: '125',
                    shoucang: '7895',
                    isdianzan: false,
                    author: {
                        name: '用户123',
                        tx: require('@/img/tx.jpg'),
                    },
                },

                {
                    img: require('@/img/tuijian3.jpg'),
                    tag: '文章',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
                    date: '2023-02-16',
                    dianzan: '125',
                    isdianzan: false,
                    shoucang: '7895',
                    author: {
                        name: '用户123',
                        tx: require('@/img/tx.jpg'),
                    },
                },

                {
                    img: require('@/img/tuijian4.jpg'),
                    tag: '文章',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
                    date: '2023-02-16',
                    dianzan: '125',
                    shoucang: '7895',
                    isdianzan: false,
                    author: {
                        name: '用户123',
                        tx: require('@/img/tx.jpg'),
                    },
                },

                {
                    img: require('@/img/tuijian5.jpg'),
                    tag: '文章',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
                    date: '2023-02-16',
                    dianzan: '125',
                    shoucang: '7895',
                    isdianzan: false,
                    author: {
                        name: '用户123',
                        tx: require('@/img/tx.jpg'),
                    },
                },


                {
                    img: require('@/img/tuijian6.jpg'),
                    tag: '文章',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
                    date: '2023-02-16',
                    dianzan: '125',
                    shoucang: '7895',
                    isdianzan: false,
                    author: {
                        name: '用户123',
                        tx: require('@/img/tx.jpg'),
                    },
                },
                {
                    img: require('@/img/tuijian1.jpg'),
                    tag: '文章',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
                    date: '2023-02-16',
                    dianzan: '125',
                    shoucang: '7895',
                    isdianzan: false,
                    author: {
                        name: '用户123',
                        tx: require('@/img/tx.jpg'),
                    },
                },


                {
                    img: require('@/img/tuijian2.jpg'),
                    tag: '文章',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
                    date: '2023-02-16',
                    dianzan: '125',
                    shoucang: '7895',
                    isdianzan: false,
                    author: {
                        name: '用户123',
                        tx: require('@/img/tx.jpg'),
                    },
                },

                {
                    img: require('@/img/tuijian3.jpg'),
                    tag: '文章',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
                    date: '2023-02-16',
                    dianzan: '125',
                    isdianzan: false,
                    shoucang: '7895',
                    author: {
                        name: '用户123',
                        tx: require('@/img/tx.jpg'),
                    },
                },

                {
                    img: require('@/img/tuijian4.jpg'),
                    tag: '文章',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
                    date: '2023-02-16',
                    dianzan: '125',
                    shoucang: '7895',
                    isdianzan: false,
                    author: {
                        name: '用户123',
                        tx: require('@/img/tx.jpg'),
                    },
                },

                {
                    img: require('@/img/tuijian5.jpg'),
                    tag: '文章',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
                    date: '2023-02-16',
                    dianzan: '125',
                    shoucang: '7895',
                    isdianzan: false,
                    author: {
                        name: '用户123',
                        tx: require('@/img/tx.jpg'),
                    },
                },


                {
                    img: require('@/img/tuijian6.jpg'),
                    tag: '文章',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
                    date: '2023-02-16',
                    dianzan: '125',
                    shoucang: '7895',
                    isdianzan: false,
                    author: {
                        name: '用户123',
                        tx: require('@/img/tx.jpg'),
                    },
                },
                {
                    img: require('@/img/tuijian1.jpg'),
                    tag: '文章',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
                    date: '2023-02-16',
                    dianzan: '125',
                    shoucang: '7895',
                    isdianzan: false,
                    author: {
                        name: '用户123',
                        tx: require('@/img/tx.jpg'),
                    },
                },


                {
                    img: require('@/img/tuijian2.jpg'),
                    tag: '文章',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
                    date: '2023-02-16',
                    dianzan: '125',
                    shoucang: '7895',
                    isdianzan: false,
                    author: {
                        name: '用户123',
                        tx: require('@/img/tx.jpg'),
                    },
                },

                {
                    img: require('@/img/tuijian3.jpg'),
                    tag: '文章',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
                    date: '2023-02-16',
                    dianzan: '125',
                    isdianzan: false,
                    shoucang: '7895',
                    author: {
                        name: '用户123',
                        tx: require('@/img/tx.jpg'),
                    },
                },

                {
                    img: require('@/img/tuijian4.jpg'),
                    tag: '文章',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
                    date: '2023-02-16',
                    dianzan: '125',
                    shoucang: '7895',
                    isdianzan: false,
                    author: {
                        name: '用户123',
                        tx: require('@/img/tx.jpg'),
                    },
                },

                {
                    img: require('@/img/tuijian5.jpg'),
                    tag: '文章',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
                    date: '2023-02-16',
                    dianzan: '125',
                    shoucang: '7895',
                    isdianzan: false,
                    author: {
                        name: '用户123',
                        tx: require('@/img/tx.jpg'),
                    },
                },


                {
                    img: require('@/img/tuijian6.jpg'),
                    tag: '文章',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
                    date: '2023-02-16',
                    dianzan: '125',
                    shoucang: '7895',
                    isdianzan: false,
                    author: {
                        name: '用户123',
                        tx: require('@/img/tx.jpg'),
                    },
                },


            ],
            shejiList: [
                {
                    pic: require('../../img/sheji1.jpg'),
                    author: {
                        name: 'Kolesp',
                        tx: require('@/img/tx.jpg'),
                    },
                    dianzan: '125',
                    shoucang: '7895',

                },
                {
                    pic: require('../../img/sheji2.jpg'),
                    author: {
                        name: 'Kolesp',
                        tx: require('@/img/tx.jpg'),
                    },
                    dianzan: '125',
                    shoucang: '7895',

                },
                {
                    pic: require('../../img/sheji3.jpg'),
                    author: {
                        name: 'Kolesp',
                        tx: require('@/img/tx.jpg'),
                    },
                    dianzan: '125',
                    shoucang: '7895',

                },
                {
                    pic: require('../../img/sheji4.jpg'),
                    author: {
                        name: 'Kolesp',
                        tx: require('@/img/tx.jpg'),
                    },
                    dianzan: '125',
                    shoucang: '7895',

                },
                {
                    pic: require('../../img/sheji5.jpg'),
                    author: {
                        name: 'Kolesp',
                        tx: require('@/img/tx.jpg'),
                    },
                    dianzan: '125',
                    shoucang: '7895',

                },
            ],
            shipinList: [
                {
                    pic: require('@/img/shipin1.jpg'),
                    time: '03:20',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    dianzan: '125',
                    shoucang: '7895',
                    author: {
                        name: 'Kolesp',
                        tx: require('@/img/tx.jpg'),
                    },
                },
                {
                    pic: require('@/img/shipin2.jpg'),
                    time: '03:20',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    dianzan: '125',
                    shoucang: '7895',
                    author: {
                        name: 'Kolesp',
                        tx: require('@/img/tx.jpg'),
                    },
                },
                {
                    pic: require('@/img/shipin3.jpg'),
                    time: '03:20',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    dianzan: '125',
                    shoucang: '7895',
                    author: {
                        name: 'Kolesp',
                        tx: require('@/img/tx.jpg'),
                    },
                },
                {
                    pic: require('@/img/shipin4.jpg'),
                    time: '03:20',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    dianzan: '125',
                    shoucang: '7895',
                    author: {
                        name: 'Kolesp',
                        tx: require('@/img/tx.jpg'),
                    },
                },
                {
                    pic: require('@/img/shipin5.jpg'),
                    time: '03:20',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    dianzan: '125',
                    shoucang: '7895',
                    author: {
                        name: 'Kolesp',
                        tx: require('@/img/tx.jpg'),
                    },
                },
                {
                    pic: require('@/img/shipin1.jpg'),
                    time: '03:20',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    dianzan: '125',
                    shoucang: '7895',
                    author: {
                        name: 'Kolesp',
                        tx: require('@/img/tx.jpg'),
                    },
                },
                {
                    pic: require('@/img/shipin2.jpg'),
                    time: '03:20',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    dianzan: '125',
                    shoucang: '7895',
                    author: {
                        name: 'Kolesp',
                        tx: require('@/img/tx.jpg'),
                    },
                },
                {
                    pic: require('@/img/shipin3.jpg'),
                    time: '03:20',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    dianzan: '125',
                    shoucang: '7895',
                    author: {
                        name: 'Kolesp',
                        tx: require('@/img/tx.jpg'),
                    },
                },
                {
                    pic: require('@/img/shipin4.jpg'),
                    time: '03:20',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    dianzan: '125',
                    shoucang: '7895',
                    author: {
                        name: 'Kolesp',
                        tx: require('@/img/tx.jpg'),
                    },
                },
                {
                    pic: require('@/img/shipin5.jpg'),
                    time: '03:20',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    dianzan: '125',
                    shoucang: '7895',
                    author: {
                        name: 'Kolesp',
                        tx: require('@/img/tx.jpg'),
                    },
                },
            ],
            tiaojian: '',
            tiaojian1: '',
        }
    },
    mounted() {
        this.guanggao = require('../../img/guanggao.jpg')
        this.getList();
        this.gethot()
        this.getListPoster();
        this.getVideoList();
        this.gethangye()
        this.setActivePage()
    },
    created() {
        document.title = this.pageTitle;
    },
    components: {
        // headerbar,
        footerbar
    },
    methods: {
        setActivePage() {
            // 触发事件，传递当前页面的名称
            eventBus.$emit('updateActivePage', '发现');
        },
        //进入文章详情
        todetail(e) {
            this.$router.push({
                name: 'articleDetail',
                query: {
                    id: e
                }
            })
        },
        todetail1(e) {
            this.$router.push({
                name: 'posterDetail',
                query: {
                    id: e
                }
            })
        },
        todetail2() {

        },
        chooseBtn(e) {
            this.choose = e;
            if (e == 'wenzhang') {
                this.guanggao = require('../../img/guanggao.jpg')
            }
            if (e == 'sheji') {
                this.guanggao = require('../../img/guanggao1.jpg')
            }
            if (e == 'shipin') {
                this.guanggao = require('../../img/guanggao1.jpg')
            }
        },

        handleSizeChange(e){
            console.log(e)
        },

        handleCurrentChange(e){
            console.log(e)
        },

        //获取行列列表
        gethangye() {
            this.$axios.get('/api/pc/industry', {}).then((result) => {
                console.log(result)
                if (result.data.code == 200) {
                    this.options1 = result.data.data
                } else {
                    this.$message.error(result.data.message);
                }
            }).catch((err) => {
                console.log(err)
                this.$message.error(err.response.data.message);
            });
        },

        shaixuan(e) {
            console.log(e)
            this.tiaojian = e;
            this.getList()
        },

        shaixuan1(e) {
            console.log(e)
            this.tiaojian1 = e;
            this.getList()
        },

        // 获取热门文章
        gethot() {
            this.$axios.get('/api/pc/article/hot', {}).then((result) => {
                console.log(result)
                if (result.data.code == 200) {
                    this.tuijianList = result.data.data.hot_article
                } else {
                    this.$message.error(result.data.message);
                }
            }).catch((err) => {
                console.log(err)
                this.$message.error(err.response.data.message);
            });
        },

        //获取文章列表
        getList() {
            this.$axios.get('/api/__yao/table/pc.poster/search', {
               params:{
                "where.tags.match": this.tiaojian,
                "where.industry.match": this.tiaojian1,
               }
            }).then((result) => {
                console.log(result)
                if (result.data.code == 200) {
                    this.shejiList = result.data.data;
                    // this.fanye = result.data
                } else {
                    this.$message.error(result.data.message);
                }
            }).catch((err) => {
                console.log(err)
                this.$message.error(err.response.data.message);
            });
        },

        //获取海报列表
        getListPoster() {
            this.$axios.get('/api/__yao/table/pc.poster/search', {
                "where.tags.match": "",
                "where.industry.match": "",
            }).then((result) => {
                console.log(result)
            }).catch((err) => {
                console.log(err)
            });
        },

        //获取视频列表
        getVideoList() {
            this.$axios.get('/api/__yao/table/pc.video/search', {
                "where.tags.match": "",
                "where.industry.match": "",
            }).then((result) => {
                console.log(result)
            }).catch((err) => {
                console.log(err)
            });
        },


        //收藏
        scBtn(e) {
            this.$axios.post('/api/user/collect/article', {
                id: e,
                type: 1,
            }, {
                headers: {
                    'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
                }
            }).then((result) => {
                console.log(result)
                if (result.data.code == 200) {
                    this.$message({
                        message: '收藏成功！',
                        type: 'success'
                    });
                } else {
                    this.$message.error(result.data.message);
                }
            }).catch((err) => {
                console.log(err)
                this.$message.error(err.response.data.message);
                if (err.response.data.code == 403) {
                    setTimeout(() => {
                        this.$router.push({
                            name: 'login'
                        })
                    }, 1500);
                }
            });
        },


        //点赞
        dzBtn(e) {
            this.$axios.post('/api/user/support/content', {
                id: e,
                type: 1,
            }, {
                headers: {
                    'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
                }
            }).then((result) => {
                console.log(result)
                if (result.data.code == 200) {
                    this.$message({
                        message: '点赞成功！',
                        type: 'success'
                    });
                } else {
                    this.$message.error(result.data.message);
                }
            }).catch((err) => {
                console.log(err)
                this.$message.error(err.response.data.message);
                if (err.response.data.code == 403) {
                    setTimeout(() => {
                        this.$router.push({
                            name: 'login'
                        })
                    }, 1500);
                }
            });
        },




    },
}
</script>

<style lang="scss" scoped>
@import "../poster/faxian.scss";
</style>

<style>
.liebiao_con .el-pagination{
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.liebiao .el-input__inner {
    border: 1px solid #212121;
    height: 35px;
}
</style>