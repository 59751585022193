<template>
    <div class="index">
        <!-- <headerbar :selected-nav="selectedNav"></headerbar> -->
        <div class="tuijian">
            <!-- <div class="faxian_top">
                 <div class="top_left">
                    <p>Creative City Guide创意城市指南</p>
                </div>
                <div class="top_right">
                    <p>查看更多</p>
                    <img src="../../img/arrow1.png" alt="">
                </div> 
            </div> -->
            <div class="tj_list">
                <div class="list1" v-for="(item, index) in tuijianList.slice(0, 3)" :key="index" @click="todetail(item.id)">
                    <div class="img">
                        <img :src="item.cover" alt="">
                        <div class="tag">
                            <p>文章</p>
                        </div>
                    </div>
                    <div class="des">
                        <div class="title">
                            <p>{{ item.title }}</p>
                        </div>
                        <div class="xq">
                            <div class="xq1">
                                <img v-if="item.user.avatar != null" :src="item.user.avatar" alt="">
                                <img src="@/img/tx.jpg" alt="" v-else>
                                <p>{{ item.author }}</p>
                            </div>
                            <div class="xq2">
                                <div class="sc">
                                    <img src="../../img/shoucang.png" alt="">
                                    <p>{{ item.collect_count }}</p>
                                </div>
                                <div class="sc">
                                    <img src="../../img/dianzan.png" alt="">
                                    <p>{{ item.support_count }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="liebiao">
            <div class="liebiao_top">
                <div class="top_left">
                    <div class="choose" :class="{ active: choose === 'wenzhang' }" @click="chooseBtn('wenzhang')">
                        <p>作品</p>
                    </div>
                    <!-- <div class="choose" :class="{ active: choose === 'sheji' }" @click="chooseBtn('sheji')">
                        <p>设计</p>
                    </div>
                    <div class="choose" :class="{ active: choose === 'shipin' }" @click="chooseBtn('shipin')">
                        <p>视频</p>
                    </div> -->
                </div>
                <div class="top_right">
                    <div class="search">
                        <img src="../../img/search.png" alt="">
                        <el-input placeholder="搜索..." v-model="keywords" clearable @change="clickBtn">
                        </el-input>
                    </div>
                    <div class="right1">
                        <el-select v-model="value2" clearable placeholder="请选择" @change="shaixuan2">
                            <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="right1">
                        <el-select v-model="value" clearable placeholder="请选择" @change="shaixuan">
                            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="right1">
                        <el-select v-model="value1" clearable placeholder="请选择" @change="shaixuan1">
                            <el-option v-for="item in options1" :key="item.id" :label="item.name" :value="item.name">
                            </el-option>
                        </el-select>
                    </div>

                    <div class="right1" v-if="choose == 'sheji' || choose == 'shipin'">
                        <el-select v-model="value3" placeholder="请选择">
                            <el-option v-for="item in options3" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                </div>
            </div>
            <div class="liebiao_con" v-if="choose == 'wenzhang'">
                <div class="allList">
                    <div class="list1" v-for="(item, index) in allList" :key="index" @click="todetail1(item.id)">
                        <div class="img">
                            <img v-if="item.cover.length > 0" :src="item.cover[0]" alt="">
                            <div class="tag">
                                <p>项目</p>
                            </div>
                            <div class="xuanze">
                                <div class="ms">
                                    <p>{{ item.title }}</p>
                                </div>
                                <div class="choose">
                                    <div class="choose1" @click.stop="todetail1(item.id)">
                                        <img src="../../img/shoucang.png" alt="">
                                    </div>
                                    <div class="choose1" @click.stop="todetail1(item.id)">
                                        <img src="../../img/dianzan.png" alt="" v-if="item.isdianzan == true">
                                        <img src="../../img/dianzan.png" alt="" v-else>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="des">
                            <div class="title">
                                <p>{{ item.title }}</p>
                            </div>
                            <div class="xq">
                                <div class="xq1">
                                    <img v-if="item.user.avatar != null" :src="item.user.avatar" alt="">
                                    <img src="@/img/tx.jpg" alt="" v-else>
                                    <p>{{ item.author }}</p>
                                </div>
                                <div class="xq2">
                                    <div class="sc">
                                        <img src="../../img/shoucang.png" alt="">
                                        <p>{{ item.collect_count }}</p>
                                    </div>
                                    <div class="sc">
                                        <img src="../../img/dianzan.png" alt="">
                                        <p>{{ item.support_count }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :page-size="fanye.pagesize" layout="prev, pager, next" :total="fanye.pagecnt">
                </el-pagination>
            </div>
            <div class="liebiao_con" v-if="choose == 'sheji'">
                <div class="zpList">
                    <div class="zp" v-for="(item, index) in shejiList" :key="index">
                        <div class="img">
                            <img :src="item.pic" alt="">
                        </div>
                        <div class="xq">
                            <div class="xq1">
                                <img :src="item.author.tx" alt="">
                                <p>{{ item.author.name }}</p>
                            </div>
                            <div class="xq2">
                                <div class="sc">
                                    <img src="../../img/shoucang.png" alt="">
                                    <p>{{ item.shoucang }}</p>
                                </div>
                                <div class="sc">
                                    <img src="../../img/dianzan.png" alt="">
                                    <p>{{ item.dianzan }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="ad">
                    <img :src="guanggao" alt="">
                </div>
                <div class="zpList">
                    <div class="zp" v-for="(item, index) in shejiList" :key="index">
                        <div class="img">
                            <img :src="item.pic" alt="">
                        </div>
                        <div class="xq">
                            <div class="xq1">
                                <img v-if="item.user.avatar != null" :src="item.user.avatar" alt="">
                                <img src="@/img/tx.jpg" alt="" v-else>
                                <p>{{ item.author.name }}</p>
                            </div>
                            <div class="xq2">
                                <div class="sc">
                                    <img src="../../img/shoucang.png" alt="">
                                    <p>{{ item.shoucang }}</p>
                                </div>
                                <div class="sc">
                                    <img src="../../img/dianzan.png" alt="">
                                    <p>{{ item.dianzan }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="liebiao_con" v-if="choose == 'shipin'">
                <div class="shipinList">
                    <div class="spList">
                        <div class="sp" v-for="(item, index) in shipinList" :key="index">
                            <div class="img">
                                <img class="tu" :src="item.pic" alt="">
                                <img class="biaozhi" src="../../img/shipin.png" alt="">
                                <div class="time">
                                    <p>03:20</p>
                                </div>
                            </div>
                            <div class="des">
                                <div class="title">
                                    <p>{{ item.title }}</p>
                                </div>
                                <div class="xq">
                                    <div class="xq1">
                                        <img :src="item.author.tx" alt="">
                                        <p>{{ item.author.name }}</p>
                                    </div>
                                    <div class="xq2">
                                        <div class="sc">
                                            <img src="../../img/shoucang.png" alt="">
                                            <p>{{ item.shoucang }}</p>
                                        </div>
                                        <div class="sc">
                                            <img src="../../img/dianzan.png" alt="">
                                            <p>{{ item.dianzan }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="ad">
                    <img :src="guanggao" alt="">
                </div>
                <div class="shipinList">
                    <div class="spList">
                        <div class="sp" v-for="(item, index) in shipinList" :key="index">
                            <div class="img">
                                <img class="tu" :src="item.pic" alt="">
                                <img class="biaozhi" src="../../img/shipin.png" alt="">
                                <div class="time">
                                    <p>03:20</p>
                                </div>
                            </div>
                            <div class="des">
                                <div class="title">
                                    <p>{{ item.title }}</p>
                                </div>
                                <div class="xq">
                                    <div class="xq1">
                                        <img v-if="item.user.avatar != null" :src="item.user.avatar" alt="">
                                        <img src="@/img/tx.jpg" alt="" v-else>
                                        <p>{{ item.author.name }}</p>
                                    </div>
                                    <div class="xq2">
                                        <div class="sc">
                                            <img src="../../img/shoucang.png" alt="">
                                            <p>{{ item.shoucang }}</p>
                                        </div>
                                        <div class="sc">
                                            <img src="../../img/dianzan.png" alt="">
                                            <p>{{ item.dianzan }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="dibu">
                <div class="dibu1" v-if="!islogin">
                    <p>登录后查看更多内容</p>
                    <div class="button">
                        <span>登录/注册</span>
                    </div>
                </div>
            </div> -->
        </div>
        <footerbar></footerbar>

        <div class="shoucangjia" v-if="toshowSS">
            <div class="scList">
                <div class="scList_top">
                    <p>我的收藏夹</p>
                    <span @click="cjscj" v-if="!isChuang">创建</span>
                    <span @click="cjscj" v-else>取消</span>
                </div>
                <div class="cccc" v-if="isChuang">
                    <input type="text" v-model="scName">
                    <p @click="cjBtn">确定</p>
                </div>
                <ul v-if="CjList.length > 0">
                    <li v-for="(item, index) in CjList" :key="index" @click="scBtn1(item.id)">
                        <p>{{ item.name }}</p>
                        <span @click.stop="deleteSC(item.id)">删除</span>
                    </li>
                </ul>
                <div class="scKong" v-if="CjList.length == 0">
                    <p>暂时没有收藏夹</p>
                </div>
                <div class="qxsc">
                    <p @click="quxiaoSc">取消</p>
                </div>
            </div>
        </div>

        <div class="xqTanchuang" v-if="xqzhanshi">
            <div class="closeanniu" @click="closeBtn" @keyup="handleKeyUp">
                <img src="../../img/close.png" alt="">
            </div>
            <div class="index_nei">
                <div class="wz_left">
                    <div class="content">
                        <div class="title">
                            <h2>{{ detail.title }}</h2>
                            <div class="des">
                                <div class="des1">
                                    <p>{{ detail.published_at | datefmt('YYYY-MM-DD HH:mm') }}</p>
                                    <i>·</i>
                                    <p>{{ detail.origin }}</p>
                                </div>
                                <div class="des1">
                                    <p>{{ detail.support_count }}点赞</p>
                                    <i>·</i>
                                    <p>{{ detail.read_count }}阅读</p>
                                    <i>·</i>
                                    <p>{{ detail.collect_count }}收藏</p>
                                    <i>·</i>
                                    <p>{{ detail.transfer_count }}转发</p>
                                </div>
                            </div>
                        </div>
                        <div class="con">

                            <img v-if="detail.cover.length > 0" :src="detail.cover[0]" alt="">
                            <video v-if="detail.video != null && detail.cover != null" :src="detail.video[0]" controls
                                :poster="detail.cover[0]"></video>
                            <div v-if="detail.content != null">
                                <div v-for="(item, index) in detail.content" :key="index">
                                    <p v-html="item.data.text"></p>
                                    <ul>
                                        <li>
                                            <p v-for="(dd, i) in item.data.items" :key="i" v-html="dd.content"></p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="dibu">
                            <div class="dibu1">
                                <p>未经授权严禁转载，授权事宜请联系作者本人，侵权必究。</p>
                            </div>
                            <div class="dibu2">
                                <div class="dibu2_1" @click="dzBtn(detail.id)" v-if="!detail.is_support">
                                    <div class="img">
                                        <img src="../../img/tuijian.png">
                                        <!-- <img src="../../img/tuijian_a.png" alt="" v-else> -->
                                    </div>
                                    <p>{{ detail.support_count }}</p>
                                </div>
                                <div class="dibu2_1 active" @click="qxdzBtn(detail.id)" v-if="detail.is_support">
                                    <div class="img">
                                        <!-- <img src="../../img/tuijian.png" > -->
                                        <img src="../../img/tuijian_a.png" alt="">
                                    </div>
                                    <p>{{ detail.support_count }}</p>
                                </div>
                                <div class="dibu2_1" @click="scBtn(detail.id)">
                                    <div class="img">
                                        <img src="../../img/sc.png" alt="">
                                    </div>
                                    <p>{{ detail.collect_count }}</p>
                                </div>
                                <div class="dibu2_1" @click="zfBtn(detail.id)">
                                    <div class="img">
                                        <img src="../../img/zhuanfa.png" alt="">
                                    </div>
                                    <p>{{ detail.transfer_count }}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="wz_right">


                    <div class="pinlun">
                        <div class="pinglun_shuru">
                            <h2>发表评论</h2>
                            <div class="shuru">
                                <!-- <div class="tx">
                                <img :src="myXinxi.pic" alt="">
                            </div> -->
                                <div class="kuang" style="width:100%">
                                    <el-input type="textarea" placeholder="说两句吧" v-model="textarea" maxlength="2000"
                                        show-word-limit resize="none">
                                    </el-input>
                                    <div class="anniu" @click="pinglunBtn(detail.id)">
                                        <p>发布</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="pl_list" v-if="pinglunList.length > 0">
                            <h3>全部评论({{ pinglunList.length }})</h3>
                            <div class="allList">
                                <div class="list1" v-for="(item, index) in pinglunList" :key="index">
                                    <div class="list1_img">
                                        <img :src="item.user.avatar" alt="">
                                    </div>
                                    <div class="list_xq">
                                        <div class="xq_left">
                                            <div class="left_top">
                                                <p>{{ item.user.name }}</p>
                                                <span>{{ item.created_at | datefmt('YYYY-MM-DD HH:mm') }}</span>
                                            </div>
                                            <div class="left_bot">
                                                <p>{{ item.content }}</p>
                                                <div class="button" @click="huifuShowBtn(index,item.id,item.user.name)" v-if="activeIndex === ''">
                                                    回复</div>
                                                <div class="button" @click="huifuShowBtn1" v-if="index === activeIndex">取消回复
                                                </div>
                                            </div>
                                            <div class="zichildren" v-for="(dd, ii) in item.children" :key="ii">
                                                <div class="tx">
                                                    <img :src="dd.user.avatar" alt="">
                                                </div>
                                                <div class="zi_detail">
                                                    <div class="zi_top">
                                                        <div class="zi_1">
                                                            <p>{{ dd.user.name }}</p>
                                                            <span>{{ dd.created_at | datefmt('YYYY-MM-DD HH:mm') }}</span>
                                                        </div>
                                                        <div class="zi_2">
                                                            <img src="../../img/tuijian_a.png" alt="" v-if="dd.isdian">
                                                            <img src="../../img/tuijian.png" alt="" v-else>
                                                            <p>{{ dd.support_count }}</p>
                                                        </div>
                                                    </div>
                                                    <div class="zi_right">
                                                        <p>{{ dd.content }}</p>
                                                        <div class="button" @click="huifuShowBtn(index,dd.id,dd.name)">回复</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="huifu_shu" v-if="index === activeIndex">
                                                <div class="shu1">
                                                    <el-input type="text" :placeholder="'回复' + shuruttt" v-model="textppp"
                                                        maxlength="300" show-word-limit>
                                                    </el-input>
                                                    <div class="button" @click="huifu(detail.id)">回复</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="xq_right" :class="{ active: item.isdian === true }">
                                            <img src="../../img/tuijian_a.png" alt="" v-if="item.isdian">
                                            <img src="../../img/tuijian.png" alt="" v-else>
                                            <p>{{ item.support_count }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="right1">
                        <h3>发布者</h3>
                        <div class="right1_con">
                            <div class="con1" @click="niurentodetail(detail.user.id)">
                                <div class="con1_left">
                                    <img v-if="detail.user.avatar.length > 0" :src="detail.user.avatar[0]" alt="">
                                    <div class="detail">
                                        <p>{{ detail.user.name }} </p>
                                        <span>{{ detail.user.intro }}</span>
                                        <span>{{ detail.user.position }}</span>
                                    </div>
                                </div>
                                <!--  <div class="con1_right">
                                <div class="anniu" v-if="zuozhe.isguanzhu">
                                    <span>+</span>
                                    <p>关注</p>
                                </div> -->
                                <!-- <div class="anniuyi" v-else>
                                    <img src="../../img/duihao.png" alt="">
                                    <p>已关注</p>
                                </div> 
                            </div>-->
                            </div>
                            <div class="con2">
                                <div class="con2_1">
                                    <p>{{ detail.user.fans }}</p>
                                    <span>粉丝</span>
                                </div>
                                <div class="con2_1">
                                    <p>{{ detail.user.article_count }}</p>
                                    <span>文章</span>
                                </div>
                                <div class="con2_1">
                                    <p>{{ detail.user.case_count }}</p>
                                    <span>案例</span>
                                </div>
                                <div class="con2_1">
                                    <p>{{ detail.user.project_count }}</p>
                                    <span>项目</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- <div class="right1">
                    <h3>相关企业</h3>
                    <div class="right1_con">
                        <div class="con1">
                            <div class="con1_left">
                                <img :src="xgqy.pic" alt="">
                                <div class="detail">
                                    <p>{{ xgqy.name }} </p>
                                    <span>{{ xgqy.address }}</span>
                                    <span>{{ xgqy.gsname }}</span>
                                </div>
                            </div>
                            <div class="con1_right">
                                <div class="anniu" v-if="xgqy.isguanzhu">
                                    <span>+</span>
                                    <p>关注</p>
                                </div>
                                <div class="anniuyi" v-else>
                                    <img src="../../img/duihao.png" alt="">
                                    <p>已关注</p>
                                </div>
                            </div>
                        </div>
                        <div class="con2">
                            <div class="con2_1">
                                <p>{{ xgqy.fensi }}</p>
                                <span>粉丝</span>
                            </div>
                            <div class="con2_1">
                                <p>{{ xgqy.wenzang }}</p>
                                <span>文章</span>
                            </div>
                            <div class="con2_1">
                                <p>{{ xgqy.anli }}</p>
                                <span>案例</span>
                            </div>
                            <div class="con2_1">
                                <p>{{ xgqy.xiangmu }}</p>
                                <span>项目</span>
                            </div>
                        </div>
                    </div>
                </div> -->

                    <!-- <div class="right2">
            <h3>热门文章</h3>
            <div class="list1" v-for="(item, index) in xgList" :key="index" @click="todetail(item.id)">
              <img :src="item.cover[0]" alt="">
              <p>{{ item.title }}</p>
            </div>
          </div> -->


                </div>
            </div>
        </div>
        <div class="xqTanchuang1" v-if="huodongShow">
            <div class="closeanniu" @click="closeBtn">
                <img src="../../img/close.png" alt="">
            </div>
            <div class="fudong">
                <div class="fu1">
                    <img v-if="detail.user.avatar != null" :src="detail.user.avatar[0]" alt="">
                    <!-- <div class="anniu">
                    <p>+</p>
                </div>-->
                </div>
                <div class="fu2">
                    <div class="img">
                        <img src="../../img/xinxi1.png" alt="">
                    </div>
                    <div class="anniu">
                        <p>{{ detail.comments.length }}</p>
                    </div>
                </div>
                <div class="fu2" :class="{ active: detail.is_support == true }">
                    <div class="img" @click="dzBtn(detail.id)">
                        <img src="../../img/tuijian1.png" alt="" v-if="!detail.is_support">
                        <img src="../../img/tuijian_a.png" alt="" v-else>
                    </div>
                    <div class="anniu">
                        <p>{{ detail.support_count }}</p>
                    </div>
                </div>
                <div class="fu2">
                    <div class="img" @click="scBtn(detail.id)">
                        <img src="../../img/sc1.png" alt="">
                    </div>
                    <div class="anniu">
                        <p>{{ detail.collect_count }}</p>
                    </div>
                </div>
                <div class="fu2">
                    <div class="img" @click="zfBtn(detail.id)">
                        <img src="../../img/zhuanfa1.png" alt="">
                    </div>
                    <div class="anniu">
                        <p>{{ detail.transfer_count }}</p>
                    </div>
                </div>
            </div>
            <div class="index_nei">
                <div class="content">
                    <div class="title">
                        <div class="detail">
                            <!-- <img src="../../img/job1.png" alt="">
                        <img src="../../img/job2.png" alt=""> -->
                            <img v-if="detail.brand.logo != null" :src="detail.brand.logo[0]" alt="">
                            <img v-if="detail.agent.logo != null" :src="detail.agent.logo[0]" alt="">
                            <div class="detail1">
                                <p>{{ detail.agent.name }}</p>
                                <span>上线时间：{{ detail.published_at | datefmt('YYYY-MM-DD HH:mm') }}</span>
                            </div>
                        </div>
                        <h1>{{ detail.title }}</h1>
                    </div>
                    <div class="des">
                        <!-- <div v-if="detail.content != null">
                        <div v-for="(item, index) in detail.content" :key="index">
                            <p v-html="item.data.text"></p>
                        </div>
                    </div> -->
                        <img v-for="(item, index) in detail.cover" :src="item" :key="index" alt="">
                    </div>
                    <div class="xiangqing">
                        <!-- <div class="xiangqing_top">
              <div class="xq_img">
                <img v-if="detail.user.avatar != null" :src="detail.user.avatar[0]" alt="">
              </div>
              <div class="button" @click="shenqing(detail.id)">
                <p>项目申请</p>
              </div>
            </div> -->
                        <div class="xiangqing_bot">
                            <!-- <h3>项目详情</h3> -->
                            <div class="bot_c">
                                <div class="bot1" v-if="detail.content != null">
                                    <div class="list" v-for="(item, index) in detail.content" :key="index">
                                        <p v-if="item.type == 'paragraph'" v-html="item.data.text"></p>
                                        <div class="tu" v-if="item.type == 'images'">
                                            <img v-for="(ii, ee) in item.data.images" :key="ee" :src="ii.src" alt="" />
                                        </div>

                                        <div class="tu" v-if="item.type == 'video'">
                                            <video v-for="(ii, ee) in item.data.video" :key="ee" :src="ii.src" alt=""
                                                controls></video>
                                        </div>


                                        <table class="your-table-class" v-if="item.type == 'table'">
                                            <thead>
                                                <tr>
                                                    <!-- 动态渲染表头 -->
                                                    <th v-for="(header, index) in item.data.content[0]" :key="index">{{
                                                        header }}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <!-- 动态渲染表格内容 -->
                                                <tr v-for="(row, rowIndex) in item.data.content.slice(1)" :key="rowIndex">
                                                    <td v-for="(column, colIndex) in row" :key="colIndex">
                                                        <!-- 使用 v-html 渲染包含 HTML 标签的内容 -->
                                                        <span v-if="colIndex === 2 || colIndex === 4 || colIndex === 7"
                                                            v-html="column"></span>
                                                        <span v-else>{{ column }}</span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>


                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
                <div class="zuopin">
                    <div class="title">
                        <h2>更多作品</h2>
                        <p>查看更多</p>
                    </div>
                    <div class="list">
                        <div class="list1" v-for="(item, index) in allList.slice(1, 5)" :key="index"
                            @click="toxiangmu1(item.id)">
                            <img :src="item.cover[0]" alt="">
                        </div>
                    </div>
                </div>
                <div class="allDibu">
                    <div class="dibu_left">
                        <div class="pinlun">
                            <div class="pinglun_shuru">
                                <h2>发表评论</h2>
                                <div class="shuru">
                                    <!-- <div class="tx">
                                    <img :src="myXinxi.pic" alt="">
                                </div> -->
                                    <div class="kuang" style="width:100%">
                                        <el-input type="textarea" placeholder="说两句吧" v-model="textarea" maxlength="2000"
                                            show-word-limit resize="none">
                                        </el-input>
                                        <div class="anniu" @click="pinglunBtn(detail.id)">
                                            <p>发布</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="pl_list" v-if="pinglunList.length > 0">
                                <h3>全部评论({{ pinglunList.length }})</h3>
                                <div class="allList">
                                    <div class="list1" v-for="(item, index) in pinglunList" :key="index">
                                        <div class="list1_img">
                                            <img :src="item.user.avatar" alt="">
                                        </div>
                                        <div class="list_xq">
                                            <div class="xq_left">
                                                <div class="left_top">
                                                    <p>{{ item.user.name }}</p>
                                                    <span>{{ item.created_at | datefmt('YYYY-MM-DD HH:mm') }}</span>
                                                </div>


                                                <div class="left_bot">
                                                    <p>{{ item.content }}</p>
                                                    <div class="button" @click="huifuShowBtn(index,item.id,item.user.name)"
                                                        v-if="activeIndex === ''">
                                                        回复</div>
                                                    <div class="button" @click="huifuShowBtn1" v-if="index === activeIndex">
                                                        取消回复
                                                    </div>
                                                </div>
                                                <div class="zichildren" v-for="(dd, ii) in item.children" :key="ii">
                                                <div class="tx">
                                                    <img :src="dd.user.avatar" alt="">
                                                </div>
                                                <div class="zi_detail">
                                                    <div class="zi_top">
                                                        <div class="zi_1">
                                                            <p>{{ dd.user.name }}</p>
                                                            <span>{{ dd.created_at | datefmt('YYYY-MM-DD HH:mm') }}</span>
                                                        </div>
                                                        <div class="zi_2">
                                                            <img src="../../img/tuijian_a.png" alt="" v-if="dd.isdian">
                                                            <img src="../../img/tuijian.png" alt="" v-else>
                                                            <p>{{ dd.support_count }}</p>
                                                        </div>
                                                    </div>
                                                    <div class="zi_right">
                                                        <p>{{ dd.content }}</p>
                                                        <div class="button" @click="huifuShowBtn(index,dd.id,dd.name)">回复</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="huifu_shu" v-if="index === activeIndex">
                                                <div class="shu1">
                                                    <el-input type="text" :placeholder="'回复' + shuruttt" v-model="textppp"
                                                        maxlength="300" show-word-limit>
                                                    </el-input>
                                                    <div class="button" @click="huifu(detail.id)">回复</div>
                                                </div>
                                            </div>




                                            </div>
                                            <div class="xq_right" :class="{ active: item.isdian === true }">
                                                <img src="../../img/tuijian_a.png" alt="" v-if="item.isdian">
                                                <img src="../../img/tuijian.png" alt="" v-else>
                                                <p>{{ item.support_count }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="dibu_right">
                        <div class="right1">
                            <h3>发布者</h3>
                            <div class="right1_con">
                                <div class="con1" @click="gstodetail(detail.user.id)">
                                    <div class="con1_left">
                                        <img v-if="detail.user.avatar != null" :src="detail.user.avatar[0]" alt="">
                                        <div class="detail">
                                            <p>{{ detail.user.name }} </p>
                                            <span>{{ detail.user.intro }}</span>
                                            <span>{{ detail.user.position }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="con2">
                                    <div class="con2_1">
                                        <p>{{ detail.user.fans }}</p>
                                        <span>粉丝</span>
                                    </div>
                                    <div class="con2_1">
                                        <p>{{ detail.user.article_count }}</p>
                                        <span>文章</span>
                                    </div>
                                    <div class="con2_1">
                                        <p>{{ detail.user.case_count }}</p>
                                        <span>案例</span>
                                    </div>
                                    <div class="con2_1">
                                        <p>{{ detail.user.project_count }}</p>
                                        <span>项目</span>
                                    </div>
                                </div>
                                <!-- <div class="con2">
                                <div class="con2_1">
                                    <p>{{ detail.user.fans }}</p>
                                    <span>粉丝</span>
                                </div>
                                <div class="con2_1">
                                    <p>{{ detail.user.article_count }}</p>
                                    <span>文章</span>
                                </div>
                                <div class="con2_1">
                                    <p>{{ detail.user.case_count }}</p>
                                    <span>案例</span>
                                </div>
                                <div class="con2_1">
                                    <p>{{ detail.user.project_count }}</p>
                                    <span>项目</span>
                                </div>
                            </div> -->
                                <!-- <div class="con2">
                                <div class="con2_1" v-if="!zuozhe.isguanzhu">
                                    <img src="../../img/duihao.png" alt="">
                                    <p>已关注</p>
                                </div>
                                <div class="con2_2" v-else>
                                    <span>+</span>
                                    <p>关注</p>
                                </div>
                                <div class="con2_3">
                                    <img src="../../img/email_1.png" alt="">
                                    <p>发信息</p>
                                </div>
                            </div> -->
                            </div>
                        </div>
                        <div class="bot_renling">
                            <div class="role">
                                <h2 v-if="detail.operator_user.length > 0">创作人员</h2>
                                <ul>
                                    <li v-for="(item, index) in detail.operator_user" :key="index">
                                        <div class="info">
                                            <img v-if="item.user.avatar != null" :src="item.user.avatar[0]" alt="">
                                            <p>{{ item.user.name }} <span>{{ item.role }}</span></p>
                                        </div>
                                        <div class="button" v-if="item.is_following == false"
                                            @click="addguanzhu(item.user)">
                                            <p>关注</p>
                                        </div>
                                        <div class="button wei" v-else @click="qxguanzhu(item.user)">
                                            <p>已关注</p>
                                        </div>
                                    </li>
                                </ul>
                                <div class="button" @click="shenqing(detail.id)">
                                    <p>项目申请</p>
                                </div>
                            </div>
                        </div>

                        <!-- <div class="right1">
                        <h3>发布者</h3>
                        <div class="right1_con">
                            <div class="list">
                                <img src="../../img/jiang.png" alt="">
                            </div>
                            <div class="list">
                                <img src="../../img/jiang1.png" alt="">
                            </div>
                            <div class="list">
                                <img src="../../img/jiang2.png" alt="">
                            </div>
                            <div class="list">
                                <img src="../../img/jiang3.png" alt="">
                            </div>
                        </div>
                    </div> -->
                    </div>
                </div>
            </div>

        </div>


        <div class="sqtanchuang" v-if="sqtan">
            <div class="sq_ccc">
                <div class="sq_ccc_t">
                    <h3>创作者申请</h3>
                    <img src="../../img/close_b.png" alt="" @click="closesq()">
                </div>

                <div class="sq_ccc_b">
                    <div class="search">
                        <div class="shuru">
                            <input type="text" placeholder="品牌" v-model="role" />
                            <img src="../../img/search.png" alt="">
                        </div>
                        <div class="button" @click="renlingBtn(detail.id)">
                            <p>立即申请</p>
                        </div>
                    </div>
                    <div class="bbb">
                        <ul class="bbb_list">
                            <li v-for="(item, index) in roleList1" :class="ssss == index ? 'active' : ''" :key="index"
                                @click="shaixuan1_new(item.label, index)">
                                <p>{{ item.label }}</p>
                                <span v-if="item.is_apply">
                                    <img src="../../img/duihao.png" alt="">
                                    已申请
                                </span>
                            </li>
                        </ul>
                        <div class="ms">
                            <p>没有匹配的职位，</p>
                            <p>填写后直接申请。</p>
                        </div>
                    </div>
                </div>
                <ul>


                    <!-- <el-select v-model="role" placeholder="请选择角色" @change="shaixuan1">
            <el-option v-for="item in roleList" :key="item.value" :label="item.label" :value="item.label">
            </el-option>
          </el-select> -->

                    <!-- <span @click="renlingBtn(detail.id)">认领</span> -->

                </ul>
            </div>
        </div>




    </div>
</template>

<script>
// import headerbar from '../../components/headerbar.vue';
import { eventBus } from '../../App.vue';
import footerbar from '../../components/footerbar.vue';
export default {
    name: 'faxian',
    data() {
        return {
            pageTitle: '作品',
            choose: 'wenzhang',
            selectedNav: '作品',
            guanggao: '',
            islogin: false,
            fanye: '',
            keywords: '',
            role: '',
            options: [{
                value: '1',
                label: '精选'
            }, {
                value: '2',
                label: '热门'
            }, {
                value: '3',
                label: '热点筛选'
            }],
            value: '',
            options1: [{
                value: '选项1',
                label: '标签1'
            }, {
                value: '选项2',
                label: '标签2'
            }, {
                value: '选项3',
                label: '标签3'
            }, {
                value: '选项4',
                label: '标签4'
            }, {
                value: '选项5',
                label: '标签5'
            }],
            value1: '',
            options2: [{
                value: 'created_at.desc',
                label: '最新收录'
            }, {
                value: 'comment_count.desc',
                label: '最多评论'
            }, {
                value: 'collect_count.desc',
                label: '最多收藏'
            }],
            value2: '',
            options3: [{
                value: '选项1',
                label: '颜色1'
            }, {
                value: '选项2',
                label: '颜色2'
            }, {
                value: '选项3',
                label: '颜色3'
            }, {
                value: '选项44',
                label: '颜色4'
            }, {
                value: '选项5',
                label: '颜色5'
            }],
            value3: '',
            tuijianList: [
                {
                    img: require('@/img/tuijian1.jpg'),
                    tag: '文章',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
                    date: '2023-02-16',
                    dianzan: '125',
                    shoucang: '7895',
                    isdianzan: false,
                    author: {
                        name: '用户123',
                        tx: require('@/img/tx.jpg'),
                    },
                },


                {
                    img: require('@/img/tuijian2.jpg'),
                    tag: '文章',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
                    date: '2023-02-16',
                    dianzan: '125',
                    shoucang: '7895',
                    isdianzan: false,
                    author: {
                        name: '用户123',
                        tx: require('@/img/tx.jpg'),
                    },
                },

                {
                    img: require('@/img/tuijian3.jpg'),
                    tag: '文章',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
                    date: '2023-02-16',
                    dianzan: '125',
                    isdianzan: false,
                    shoucang: '7895',
                    author: {
                        name: '用户123',
                        tx: require('@/img/tx.jpg'),
                    },
                },

            ],
            allList: [
                {
                    img: require('@/img/tuijian1.jpg'),
                    tag: '文章',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
                    date: '2023-02-16',
                    dianzan: '125',
                    shoucang: '7895',
                    isdianzan: false,
                    author: {
                        name: '用户123',
                        tx: require('@/img/tx.jpg'),
                    },
                },


                {
                    img: require('@/img/tuijian2.jpg'),
                    tag: '文章',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
                    date: '2023-02-16',
                    dianzan: '125',
                    shoucang: '7895',
                    isdianzan: false,
                    author: {
                        name: '用户123',
                        tx: require('@/img/tx.jpg'),
                    },
                },

                {
                    img: require('@/img/tuijian3.jpg'),
                    tag: '文章',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
                    date: '2023-02-16',
                    dianzan: '125',
                    isdianzan: false,
                    shoucang: '7895',
                    author: {
                        name: '用户123',
                        tx: require('@/img/tx.jpg'),
                    },
                },

                {
                    img: require('@/img/tuijian4.jpg'),
                    tag: '文章',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
                    date: '2023-02-16',
                    dianzan: '125',
                    shoucang: '7895',
                    isdianzan: false,
                    author: {
                        name: '用户123',
                        tx: require('@/img/tx.jpg'),
                    },
                },

                {
                    img: require('@/img/tuijian5.jpg'),
                    tag: '文章',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
                    date: '2023-02-16',
                    dianzan: '125',
                    shoucang: '7895',
                    isdianzan: false,
                    author: {
                        name: '用户123',
                        tx: require('@/img/tx.jpg'),
                    },
                },


                {
                    img: require('@/img/tuijian6.jpg'),
                    tag: '文章',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
                    date: '2023-02-16',
                    dianzan: '125',
                    shoucang: '7895',
                    isdianzan: false,
                    author: {
                        name: '用户123',
                        tx: require('@/img/tx.jpg'),
                    },
                },
                {
                    img: require('@/img/tuijian1.jpg'),
                    tag: '文章',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
                    date: '2023-02-16',
                    dianzan: '125',
                    shoucang: '7895',
                    isdianzan: false,
                    author: {
                        name: '用户123',
                        tx: require('@/img/tx.jpg'),
                    },
                },


                {
                    img: require('@/img/tuijian2.jpg'),
                    tag: '文章',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
                    date: '2023-02-16',
                    dianzan: '125',
                    shoucang: '7895',
                    isdianzan: false,
                    author: {
                        name: '用户123',
                        tx: require('@/img/tx.jpg'),
                    },
                },

                {
                    img: require('@/img/tuijian3.jpg'),
                    tag: '文章',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
                    date: '2023-02-16',
                    dianzan: '125',
                    isdianzan: false,
                    shoucang: '7895',
                    author: {
                        name: '用户123',
                        tx: require('@/img/tx.jpg'),
                    },
                },

                {
                    img: require('@/img/tuijian4.jpg'),
                    tag: '文章',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
                    date: '2023-02-16',
                    dianzan: '125',
                    shoucang: '7895',
                    isdianzan: false,
                    author: {
                        name: '用户123',
                        tx: require('@/img/tx.jpg'),
                    },
                },

                {
                    img: require('@/img/tuijian5.jpg'),
                    tag: '文章',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
                    date: '2023-02-16',
                    dianzan: '125',
                    shoucang: '7895',
                    isdianzan: false,
                    author: {
                        name: '用户123',
                        tx: require('@/img/tx.jpg'),
                    },
                },


                {
                    img: require('@/img/tuijian6.jpg'),
                    tag: '文章',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
                    date: '2023-02-16',
                    dianzan: '125',
                    shoucang: '7895',
                    isdianzan: false,
                    author: {
                        name: '用户123',
                        tx: require('@/img/tx.jpg'),
                    },
                },
                {
                    img: require('@/img/tuijian1.jpg'),
                    tag: '文章',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
                    date: '2023-02-16',
                    dianzan: '125',
                    shoucang: '7895',
                    isdianzan: false,
                    author: {
                        name: '用户123',
                        tx: require('@/img/tx.jpg'),
                    },
                },


                {
                    img: require('@/img/tuijian2.jpg'),
                    tag: '文章',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
                    date: '2023-02-16',
                    dianzan: '125',
                    shoucang: '7895',
                    isdianzan: false,
                    author: {
                        name: '用户123',
                        tx: require('@/img/tx.jpg'),
                    },
                },

                {
                    img: require('@/img/tuijian3.jpg'),
                    tag: '文章',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
                    date: '2023-02-16',
                    dianzan: '125',
                    isdianzan: false,
                    shoucang: '7895',
                    author: {
                        name: '用户123',
                        tx: require('@/img/tx.jpg'),
                    },
                },

                {
                    img: require('@/img/tuijian4.jpg'),
                    tag: '文章',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
                    date: '2023-02-16',
                    dianzan: '125',
                    shoucang: '7895',
                    isdianzan: false,
                    author: {
                        name: '用户123',
                        tx: require('@/img/tx.jpg'),
                    },
                },

                {
                    img: require('@/img/tuijian5.jpg'),
                    tag: '文章',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
                    date: '2023-02-16',
                    dianzan: '125',
                    shoucang: '7895',
                    isdianzan: false,
                    author: {
                        name: '用户123',
                        tx: require('@/img/tx.jpg'),
                    },
                },


                {
                    img: require('@/img/tuijian6.jpg'),
                    tag: '文章',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
                    date: '2023-02-16',
                    dianzan: '125',
                    shoucang: '7895',
                    isdianzan: false,
                    author: {
                        name: '用户123',
                        tx: require('@/img/tx.jpg'),
                    },
                },


            ],
            shejiList: [
                {
                    pic: require('../../img/sheji1.jpg'),
                    author: {
                        name: 'Kolesp',
                        tx: require('@/img/tx.jpg'),
                    },
                    dianzan: '125',
                    shoucang: '7895',

                },
                {
                    pic: require('../../img/sheji2.jpg'),
                    author: {
                        name: 'Kolesp',
                        tx: require('@/img/tx.jpg'),
                    },
                    dianzan: '125',
                    shoucang: '7895',

                },
                {
                    pic: require('../../img/sheji3.jpg'),
                    author: {
                        name: 'Kolesp',
                        tx: require('@/img/tx.jpg'),
                    },
                    dianzan: '125',
                    shoucang: '7895',

                },
                {
                    pic: require('../../img/sheji4.jpg'),
                    author: {
                        name: 'Kolesp',
                        tx: require('@/img/tx.jpg'),
                    },
                    dianzan: '125',
                    shoucang: '7895',

                },
                {
                    pic: require('../../img/sheji5.jpg'),
                    author: {
                        name: 'Kolesp',
                        tx: require('@/img/tx.jpg'),
                    },
                    dianzan: '125',
                    shoucang: '7895',

                },
            ],
            shipinList: [
                {
                    pic: require('@/img/shipin1.jpg'),
                    time: '03:20',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    dianzan: '125',
                    shoucang: '7895',
                    author: {
                        name: 'Kolesp',
                        tx: require('@/img/tx.jpg'),
                    },
                },
                {
                    pic: require('@/img/shipin2.jpg'),
                    time: '03:20',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    dianzan: '125',
                    shoucang: '7895',
                    author: {
                        name: 'Kolesp',
                        tx: require('@/img/tx.jpg'),
                    },
                },
                {
                    pic: require('@/img/shipin3.jpg'),
                    time: '03:20',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    dianzan: '125',
                    shoucang: '7895',
                    author: {
                        name: 'Kolesp',
                        tx: require('@/img/tx.jpg'),
                    },
                },
                {
                    pic: require('@/img/shipin4.jpg'),
                    time: '03:20',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    dianzan: '125',
                    shoucang: '7895',
                    author: {
                        name: 'Kolesp',
                        tx: require('@/img/tx.jpg'),
                    },
                },
                {
                    pic: require('@/img/shipin5.jpg'),
                    time: '03:20',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    dianzan: '125',
                    shoucang: '7895',
                    author: {
                        name: 'Kolesp',
                        tx: require('@/img/tx.jpg'),
                    },
                },
                {
                    pic: require('@/img/shipin1.jpg'),
                    time: '03:20',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    dianzan: '125',
                    shoucang: '7895',
                    author: {
                        name: 'Kolesp',
                        tx: require('@/img/tx.jpg'),
                    },
                },
                {
                    pic: require('@/img/shipin2.jpg'),
                    time: '03:20',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    dianzan: '125',
                    shoucang: '7895',
                    author: {
                        name: 'Kolesp',
                        tx: require('@/img/tx.jpg'),
                    },
                },
                {
                    pic: require('@/img/shipin3.jpg'),
                    time: '03:20',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    dianzan: '125',
                    shoucang: '7895',
                    author: {
                        name: 'Kolesp',
                        tx: require('@/img/tx.jpg'),
                    },
                },
                {
                    pic: require('@/img/shipin4.jpg'),
                    time: '03:20',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    dianzan: '125',
                    shoucang: '7895',
                    author: {
                        name: 'Kolesp',
                        tx: require('@/img/tx.jpg'),
                    },
                },
                {
                    pic: require('@/img/shipin5.jpg'),
                    time: '03:20',
                    title: '现代汽车邀请凯文培根参加超级碗前的比赛',
                    dianzan: '125',
                    shoucang: '7895',
                    author: {
                        name: 'Kolesp',
                        tx: require('@/img/tx.jpg'),
                    },
                },
            ],
            tiaojian: '1',
            tiaojian1: '',
            tiaojian2: 'created_at.desc',

            toshowSS: false,
            detail: "",
            pinglunList: [],
            xqzhanshi: false,
            huodongShow: false,
            isScrollDisabled: false,
            textarea: '',
            roleList: '',
            isChuang: false,
            scName: '',
            CjList: '',

            sqtan: false,
            mingdanList: '',
            ssss: -1,
            activeIndex: '',
            textppp: '',
            plid:'',
            shuruttt:'',
        }
    },
    beforeRouteLeave(to, from, next) {
        document.body.classList.remove('disable-body-scroll');
        next();
    },
    computed: {
        roleList1: {
            get() {
                console.log(this.roleList)
                if (this.roleList.length > 0) {
                    return this.roleList.filter(item => item.label.includes(this.role));
                } else {
                    return null;
                }

            },
            set(value) {
                // 如果需要，你可以在这里添加设置计算属性时的逻辑
                console.log(value)
            }

        }
    },
    mounted() {
        this.guanggao = require('../../img/guanggao.jpg')
        this.getList();
        this.gethot()
        this.gethangye()
        this.setActivePage()
        this.showMobileNav()
        this.getRole()
        this.getSCList()
        document.addEventListener("keyup", this.handleKeyUp);
    },
    created() {
        document.title = this.pageTitle;
    },
    beforeUnmount() {
        // Remove the keyup event listener before the component is unmounted to prevent memory leaks
        document.removeEventListener("keyup", this.handleKeyUp);
    },
    components: {
        // headerbar,
        footerbar
    },
    methods: {

        huifu(e) {
            this.$axios.post('/api/user/comment/save', {
                type: 1,
                ext_id: e,
                content: this.textppp,
                comment_id: this.plid,
            }, {
                headers: {
                    'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
                }
            }).then((result) => {
                console.log(result)
                if (result.data.code == 200) {
                    this.$message({
                        message: result.data.message,
                        type: 'success'
                    });
                    this.textppp = "";
                    this.activeIndex = ''
                } else {
                    this.$message.error(result.data.message);
                }
            }).catch((err) => {
                console.log(err)
                // this.$message.error(err.response.data.message);
                if (err.response.data.code == 403 || err.response.data.code == 500) {
                    this.$message.error("请重新登录!");
                    setTimeout(() => {
                        eventBus.$emit('clearToken', '');
                        this.$router.push({
                            name: 'login'
                        })
                    }, 1500);
                } else {
                    this.$message.error(err.response.data.message)
                }
            });
        },

        huifuShowBtn(e,d,f) {
            console.log(e,d,f)
            this.activeIndex = e;
            this.plid = d;
            this.shuruttt = f
        },

        huifuShowBtn1() {
            this.activeIndex = ''
            this.plid = '';
            this.shuruttt = ''
        },

        searchBb() {
            console.log(this.role)
            setTimeout(() => {
                if (this.role != '') {
                    let newobject = {
                        label: '',
                    }
                    for (var i = 0; i < this.roleList.length; i++) {
                        if (this.role == this.roleList[i].label) {
                            this.roleList.splice(0, this.roleList.length)
                            newobject.label = this.role
                            this.roleList.push(newobject)
                        }
                    }
                    console.log(this.roleList)
                } else {
                    this.getRole()
                    console.log(456456)
                }
            }, 2000);

        },


        addguanzhu(e) {
            console.log("关注")
            this.$axios.get('/api/user/user_following/' + e.id, {
                headers: {
                    'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
                }
            }).then((result) => {
                console.log(result)
                if (result.data.code == 200) {
                    this.$message({
                        message: result.data.message,
                        type: 'success'
                    });
                    this.todetail1(this.detail.id)
                } else {
                    this.$message.error(result.data.message);
                }
            }).catch((err) => {
                console.log(err)
                if (err.response.data.code == 403 || err.response.data.code == 500) {
                    this.$message.error('请先登录！');
                    setTimeout(() => {
                        eventBus.$emit('clearToken', '');
                        this.$router.push({
                            name: 'login'
                        })
                    }, 1500);
                }
            });
        },
        qxguanzhu(e) {
            console.log("quxiao")
            this.$axios.get('/api/user/user_following/' + e.id, {
                headers: {
                    'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
                }
            }).then((result) => {
                console.log(result)
                if (result.data.code == 200) {
                    this.$message({
                        message: result.data.message,
                        type: 'success'
                    });
                    this.todetail1(this.detail.id)
                } else {
                    this.$message.error(result.data.message);
                }
            }).catch((err) => {
                console.log(err)
                if (err.response.data.code == 403 || err.response.data.code == 500) {
                    this.$message.error('请先登录！');
                    setTimeout(() => {
                        eventBus.$emit('clearToken', '');
                        this.$router.push({
                            name: 'login'
                        })
                    }, 1500);
                }
            });
        },



        // shaixuan1(e, d) {
        //   console.log(e)
        //   console.log(d)
        //   this.role = e;
        //   this.ssss = d
        // },

        shaixuan1_new(e, d) {
            console.log(e)
            this.role = e;
            this.ssss = d
        },

        anniuBtn(e) {
            this.$axios.get('/api/user/user_following/' + e, {
                headers: {
                    'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
                }
            }).then((result) => {
                console.log(result)
                if (result.data.code == 200) {
                    this.$message({
                        message: '关注成功!',
                        type: 'success'
                    });
                    // setTimeout(() => {
                    //   this.getDetail()
                    // }, 1500);
                }
            }).catch((err) => {
                console.log(err)
            });
        },
        //进入文章详情
        // todetail(e) {
        //     this.$router.push({
        //         name: 'articleDetail',
        //         query: {
        //             id: e
        //         }
        //     })
        // },
        // clear(){
        //     this.tiaojian = "";
        //     this.tiaojian1 = "";
        //     this.tiaojian2 = "";
        //     this.getList()
        // },
        cjscj() {
            this.isChuang = !this.isChuang
        },
        //创建文件夹
        cjBtn() {
            if (this.scName == '') {
                this.$message.error('请输入文件夹名!');
                return
            }
            this.$axios.post('api/collection/create', {
                name: this.scName
            }, {
                headers: {
                    'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
                }
            }).then((result) => {
                console.log(result)
                if (result.data.code == 200) {
                    this.$message({
                        message: '创建成功!',
                        type: 'success'
                    });
                    this.getSCList();
                    this.isChuang = false;
                } else {
                    this.$message.error(result.data.message);
                }
            }).catch((err) => {
                console.log(err)
                if (err.response.data.code == 403 || err.response.data.code == 500) {
                    this.$message.error("请重新登录！");
                    setTimeout(() => {
                        eventBus.$emit('clearToken', '');
                        this.$router.push({
                            name: 'login'
                        })
                    }, 1500);
                } else {
                    this.$message.error(err.response.data.message);
                }
            });
        },

        //删除收藏夹
        deleteSC(e) {
            this.$axios.get('api/collection/delete/' + e, {
                headers: {
                    'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
                }
            }).then((result) => {
                if (result.data.code == 200) {
                    this.$message({
                        message: '删除成功!',
                        type: 'success'
                    });
                    this.getSCList();
                } else {
                    this.$message.error(result.data.message);
                }
            }).catch((err) => {
                console.log(err)
                if (err.response.data.code == 403 || err.response.data.code == 500) {
                    this.$message.error("请重新登录！");
                    setTimeout(() => {
                        eventBus.$emit('clearToken', '');
                        this.$router.push({
                            name: 'login'
                        })
                    }, 1500);
                } else {
                    this.$message.error(err.response.data.message);
                }
            });
        },

        //获取收藏列表
        getSCList() {
            this.$axios.get('api/collection/my_collect', {
                headers: {
                    'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
                }
            }).then((result) => {
                console.log(result)
                if (result.data.code == 200) {
                    this.CjList = result.data.data
                } else {
                    this.$message.error(result.data.message);
                }
            }).catch((err) => {
                console.log(err)
                if (err.response.data.code == 500 || err.response.data.code == 403) {
                    // this.$message.error("请重新登录!");
                    setTimeout(() => {
                        eventBus.$emit('clearToken', '');
                        // this.$router.push({
                        //   name: 'login'
                        // })
                    }, 1500);
                } else {
                    this.$message.error(err.response.data.message)
                }
            });
        },

        //获取项目认领角色
        getRole() {
            this.$axios.get('api/select/project_role', {}).then((result) => {
                console.log(result)
                if (result.data.code == 200) {
                    this.roleList = result.data.data;
                    this.roleList1 = result.data.data
                } else {
                    this.$message.error(result.data.message);
                }
            }).catch((err) => {
                console.log(err)
                this.$message.error(err.response.data.message);
            });
        },
        // mingdanList
        // getmingdan(){
        //     this.$axios.get('api/user/project_apply_list',{})
        // },

        shenqing() {
            this.sqtan = true;
        },
        closesq() {
            this.sqtan = false;
        },


        //项目认领
        renlingBtn(d) {
            this.$axios.post('api/user/project_apply', {
                article_id: d,
                role: this.role,
            }, {
                headers: {
                    'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
                }
            }).then((result) => {
                console.log(result)
                if (result.data.code == 200) {
                    this.$message({
                        message: result.data.message,
                        type: 'success'
                    });
                    setTimeout(() => {
                        this.sqtan = false;
                        this.role = '';
                    }, 1000);
                } else {
                    this.$message.error(result.data.message);
                    setTimeout(() => {
                        this.sqtan = false;
                        this.role = '';
                    }, 1000);
                }
            }).catch((err) => {
                console.log(err)

                if (err.response.data.code == 403 || err.response.data.code == 500) {
                    this.$message.error("请重新登录！");
                    setTimeout(() => {
                        eventBus.$emit('clearToken', '');
                        this.$router.push({
                            name: 'login'
                        })
                    }, 1500);
                } else {
                    this.$message.error(err.response.data.message);
                    setTimeout(() => {
                        this.sqtan = false;
                        this.role = '';
                    }, 1000);
                }
            });
        },


        handleKeyUp(event) {
            // console.log(event)
            if (event.key === 'Escape') {
                // 处理按下 Enter 键的逻辑
                console.log('esc 键被按下');
                this.closeBtn()
            }
        },




        setActivePage() {
            // 触发事件，传递当前页面的名称
            eventBus.$emit('updateActivePage', '作品');
        },

        showMobileNav() {
            eventBus.$emit('shownav', '');
        },



        quxiaoSc() {
            this.toshowSS = false;
        },
        closeBtn() {
            this.xqzhanshi = false;
            this.huodongShow = false;
            this.toshowSS = false;
            this.sqtan = false;

            this.activeIndex = ''
            this.plid = '';
            this.shuruttt = ''

            document.body.classList.remove('disable-body-scroll');
        },


        //海报和文章详情弹窗
        todetail(e) {
            // this.$router.push('/articleDetail')
            // this.$router.push({
            //   name: 'articleDetail',
            //   query: {
            //     id: e
            //   }
            // })
            document.body.classList.add('disable-body-scroll');
            this.xqzhanshi = true;

            if (window.localStorage.getItem('token')) {
                this.$axios.get('api/pc/article/detail/' + e, {
                    headers: {
                        'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
                    }
                }).then((result) => {
                    console.log(result)
                    if (result.data.code == 200) {
                        this.detail = result.data.data;
                        this.pinglunList = result.data.data.comments
                    } else {
                        this.$message.error(result.data.message);
                    }
                }).catch((err) => {
                    console.log(err)

                    if (err.response.data.code == 403 || err.response.data.code == 500) {
                        this.$message.error("请重新登录！");
                        setTimeout(() => {
                            eventBus.$emit('clearToken', '');
                            this.$router.push({
                                name: 'login'
                            })
                        }, 1500);
                    } else {
                        this.$message.error(err.response.data.message);
                    }
                });
            } else {
                this.$axios.get('api/pc/article/detail/' + e, {}).then((result) => {
                    console.log(result)
                    if (result.data.code == 200) {
                        this.detail = result.data.data;
                        this.pinglunList = result.data.data.comments
                    } else {
                        this.$message.error(result.data.message);
                    }
                }).catch((err) => {
                    console.log(err)

                    if (err.response.data.code == 403 || err.response.data.code == 500) {
                        this.$message.error("请重新登录！");
                        setTimeout(() => {
                            eventBus.$emit('clearToken', '');
                            this.$router.push({
                                name: 'login'
                            })
                        }, 1500);
                    } else {
                        this.$message.error(err.response.data.message);
                    }
                });
            }






        },


        // todetail1(e) {
        //     this.$router.push({
        //         name: 'projectDetail',
        //         query: {
        //             id: e
        //         }
        //     })
        // },

        //获取详情
        getDetail(e) {
            this.$axios.get('/api/pc/article/detail/' + e, {
                headers: {
                    'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
                }
            }).then((result) => {
                console.log(result)
                if (result.data.code == 200) {
                    this.detail = result.data.data;
                    this.pinglunList = result.data.data.comments
                } else {
                    this.$message.error(result.data.message);
                }
            }).catch((err) => {
                console.log(err)
                this.$message.error(err.response.data.message);
            });
        },

        todetail1(e) {
            // this.$router.push({
            //   name: 'huodongDetail',
            //   query: {
            //     id: e
            //   }
            // })

            document.body.classList.add('disable-body-scroll');
            this.huodongShow = true;
            console.log(e)
            console.log(this.huodongShow)
            if (window.localStorage.getItem('token')) {
                this.$axios.get('/api/pc/article/detail/' + e, {
                    headers: {
                        'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
                    }
                }).then((result) => {
                    console.log(result)
                    if (result.data.code == 200) {
                        this.detail = result.data.data;
                        this.pinglunList = result.data.data.comments
                    }
                }).catch((err) => {
                    console.log(err)
                });
            } else {
                this.$axios.get('/api/pc/article/detail/' + e, {}).then((result) => {
                    console.log(result)
                    if (result.data.code == 200) {
                        this.detail = result.data.data;
                        this.pinglunList = result.data.data.comments
                    }
                }).catch((err) => {
                    console.log(err)
                });
            }


        },


        niurentodetail(e) {
            this.$router.push({
                name: 'niurenDetail',
                query: {
                    id: e
                }
            })
        },

        gstodetail(e) {
            this.$router.push({
                name: 'chuangzao',
                query: {
                    id: e
                }
            })
        },


        todetail2() {

        },
        chooseBtn(e) {
            this.choose = e;
            if (e == 'wenzhang') {
                this.guanggao = require('../../img/guanggao.jpg')
            }
            if (e == 'sheji') {
                this.guanggao = require('../../img/guanggao1.jpg')
            }
            if (e == 'shipin') {
                this.guanggao = require('../../img/guanggao1.jpg')
            }
        },

        clickBtn() {
            this.$axios.get('/api/__yao/table/pc.project/search', {
                params: {
                    "order": this.tiaojian2,
                    "where.categories.match": this.tiaojian,
                    "where.industry.match": this.tiaojian1,
                    "where.title.match": this.keywords,
                }
            }).then((result) => {
                console.log(result)
                if (result.data.code == 200) {
                    this.allList = result.data.data
                } else {
                    this.$message.error(result.data.message);
                }
            }).catch((err) => {
                console.log(err)
            });
        },

        handleSizeChange(e) {
            console.log(e)
        },

        handleCurrentChange(e) {
            console.log(e)
        },

        //获取行业列表
        gethangye() {
            this.$axios.get('/api/pc/industry', {}).then((result) => {
                console.log(result)
                if (result.data.code == 200) {
                    this.options1 = result.data.data
                } else {
                    this.$message.error(result.data.message);
                }
            }).catch((err) => {
                console.log(err)
                this.$message.error(err.response.data.message);
            });
        },

        shaixuan(e) {
            console.log(e)
            if (e == '') {
                this.tiaojian = 1;
            } else {
                this.tiaojian = e;
            }

            this.getList()
        },

        shaixuan1(e) {
            console.log(e)
            // if(e == ''){

            // }
            this.tiaojian1 = e;
            this.getList()
        },
        shaixuan2(e) {
            console.log(e)
            if (e == '') {
                this.tiaojian2 = 'created_at.desc';
            } else {
                this.tiaojian2 = e;
            }
            this.getList()
        },

        // 获取热门文章
        gethot() {
            this.$axios.get('/api/pc/article/hot', {}).then((result) => {
                console.log(result)
                if (result.data.code == 200) {
                    this.tuijianList = result.data.data.hot_article
                } else {
                    this.$message.error(result.data.message);
                }
            }).catch((err) => {
                console.log(err)
                this.$message.error(err.response.data.message);
            });
        },

        //获取文章列表
        getList() {
            this.$axios.get('/api/__yao/table/pc.project/search', {
                params: {
                    "order": this.tiaojian2,
                    "where.categories.match": this.tiaojian,
                    "where.industry.match": this.tiaojian1,
                }
            }).then((result) => {
                console.log(result)
                console.log(123123)
                if (result.data.code == 200) {
                    this.allList = result.data.data;
                    this.fanye = result.data
                } else {
                    this.$message.error(result.data.message);
                }
            }).catch((err) => {
                console.log(err)
                this.$message.error(err.response.data.message);
            });
        },

        //收藏
        scBtn() {
            console.log(1312312)
            this.toshowSS = !this.toshowSS
            // this.$axios.post('/api/user/collect/article', {
            //     id: e,
            //     type: 1,
            // }, {
            //     headers: {
            //         'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
            //     }
            // }).then((result) => {
            //     console.log(result)
            //     if (result.data.code == 200) {
            //         this.$message({
            //             message: '收藏成功！',
            //             type: 'success'
            //         });
            //         setTimeout(() => {
            //             this.getDetail(e)
            //         }, 500);
            //     } else {
            //         this.$message.error(result.data.message);
            //     }
            // }).catch((err) => {
            //     console.log(err)
            //     this.$message.error(err.response.data.message);
            //     if (err.response.data.code == 403) {
            //         setTimeout(() => {
            //             this.$router.push({
            //                 name: 'login'
            //             })
            //         }, 1500);
            //     }
            // });
        },
        scBtn1() {
            this.$axios.post('/api/user/collect/article', {
                id: this.detail.id,
                type: 1,
            }, {
                headers: {
                    'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
                }
            }).then((result) => {
                console.log(result)
                if (result.data.code == 200) {
                    this.$message({
                        message: '收藏成功！',
                        type: 'success'
                    });
                    setTimeout(() => {
                        this.getDetail(this.detail.id)
                        this.toshowSS = !this.toshowSS
                    }, 500);
                } else {
                    this.$message.error(result.data.message);
                    this.toshowSS = !this.toshowSS
                }
            }).catch((err) => {
                console.log(err)

                if (err.response.data.code == 403 || err.response.data.code == 500) {
                    this.$message.error("请重新登录！");
                    setTimeout(() => {
                        eventBus.$emit('clearToken', '');
                        this.$router.push({
                            name: 'login'
                        })
                    }, 1500);
                } else {
                    this.$message.error(err.response.data.message);
                }
            });
        },


        //点赞
        dzBtn(e) {
            this.$axios.post('/api/user/support/content', {
                id: e,
                type: 1,
            }, {
                headers: {
                    'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
                }
            }).then((result) => {
                console.log(result)
                if (result.data.code == 200) {
                    this.$message({
                        message: '点赞成功！',
                        type: 'success'
                    });
                    setTimeout(() => {
                        this.getDetail(e)
                    }, 500);
                } else {
                    this.$message.error(result.data.message);
                }
            }).catch((err) => {
                console.log(err)

                if (err.response.data.code == 403 || err.response.data.code == 500) {
                    this.$message.error("请重新登录！");
                    setTimeout(() => {
                        eventBus.$emit('clearToken', '');
                        this.$router.push({
                            name: 'login'
                        })
                    }, 1500);
                } else {
                    this.$message.error(err.response.data.message);
                }
            });
        },

        //取消点赞
        qxdzBtn(e) {
            this.$axios.post('/api/user/unsupport/content', {
                id: e,
                type: 1,
            }, {
                headers: {
                    'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
                }
            }).then((result) => {
                console.log(result)
                if (result.data.code == 200) {
                    this.$message({
                        message: '取消点赞！',
                        type: 'success'
                    });

                    //重新获取详情接口
                    setTimeout(() => {
                        this.getDetail(e)
                    }, 500);

                } else {
                    this.$message.error(result.data.message);
                }

            }).catch((err) => {
                console.log(err)

                if (err.response.data.code == 403 || err.response.data.code == 500) {
                    this.$message.error("请重新登录！");
                    setTimeout(() => {
                        eventBus.$emit('clearToken', '');
                        this.$router.push({
                            name: 'login'
                        })
                    }, 1500);
                } else {
                    this.$message.error(err.response.data.message);
                }
            });
        },


        //转发
        zfBtn(e) {
            this.$axios.post('/api/user/transfer/article', {
                id: e
            }, {
                headers: {
                    'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
                }
            }).then((result) => {
                console.log(result)
                if (result.data.code == 200) {
                    this.$message({
                        message: '转发成功！',
                        type: 'success'
                    });
                    setTimeout(() => {
                        this.getDetail(e)
                    }, 500);
                } else {
                    this.$message.error(result.data.message);
                }
            }).catch((err) => {
                console.log(err)

                if (err.response.data.code == 403 || err.response.data.code == 500) {
                    this.$message.error("请重新登录！");
                    setTimeout(() => {
                        eventBus.$emit('clearToken', '');
                        this.$router.push({
                            name: 'login'
                        })
                    }, 1500);
                } else {
                    this.$message.error(err.response.data.message);
                }
            });
        },


        pinglunBtn(e) {
            this.$axios.post('/api/user/comment/save', {
                type: 1,
                ext_id: e,
                content: this.textarea,
                comment_id: '',
            }, {
                headers: {
                    'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
                }
            }).then((result) => {
                console.log(result)
                if (result.data.code == 200) {
                    this.$message({
                        message: result.data.message,
                        type: 'success'
                    });
                    this.textarea = ""
                } else {
                    this.$message.error(result.data.message);
                }
            }).catch((err) => {
                console.log(err)
                // this.$message.error(err.response.data.message);
                if (err.response.data.code == 403 || err.response.data.code == 500) {
                    this.$message.error("请重新登录!");
                    setTimeout(() => {
                        eventBus.$emit('clearToken', '');
                        this.$router.push({
                            name: 'login'
                        })
                    }, 1500);
                } else {
                    this.$message.error(err.response.data.message)
                }
            });
        },



    },
}
</script>

<style lang="scss" scoped>
@import "../project/faxian.scss";
</style>

<style>
.liebiao_con .el-pagination {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.liebiao .el-input__inner {
    border: 1px solid #212121;
    height: 35px;
}


.search .el-input__inner {
    background: none;
    border: none !important;
}
</style>