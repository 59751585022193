<template>
    <div class="index">
        
    </div>
</template>

<script>
export default {
    name: 'PageswangzhanMySetting',

    data() {
        return {
            pageTitle: '账号设置',
        };
    },
    created() {
        document.title = this.pageTitle;
    },

    mounted() {
        
    },

    methods: {
        
    },
};
</script>

<style lang="scss" scoped>

</style>