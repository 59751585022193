<template>
    <div class="index">
        <!-- <headerbar :selected-nav="selectedNav"></headerbar> -->
        <div class="banner">
            <div class="swiper-visualization wow fadeInUp">
                <div class="swiper-visualization-box">
                    <swiper ref="visualizationSwiper" :options="swiperOptions">
                        <swiper-slide v-for="(item, index) in visualizationList" :key="index">
                            <div class="pic">
                                <img :src="item.pic" alt="">
                            </div>
                        </swiper-slide>
                    </swiper>
                    <div class="swiper-arrow swiper-prev"></div>
                    <div class="swiper-arrow swiper-next"></div>
                </div>
            </div>
        </div>
        <div class="huodong">
            <div class="liebiao_top">
                <div class="top_left">
                    <div class="choose" :class="{ active: choose === 'wenzhang' }" @click="chooseBtn('wenzhang')">
                        <p>热门推荐</p>
                    </div>
                    <!-- <div class="choose" :class="{ active: choose === 'sheji' }" @click="chooseBtn('sheji')">
                        <p>最新发布</p>
                    </div> -->
                </div>
                <div class="top_right">
                    <div class="right1">
                        <!-- <el-select v-model="value" placeholder="今天">
                            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select> -->

                        <el-date-picker v-model="value" type="date" placeholder="选择日期" @change="shaixuan2"></el-date-picker>


                    </div>
                    <div class="right1">
                        <el-select v-model="value1" placeholder="行业" @change="shaixuan">
                            <el-option v-for="item in options1" :key="item.id" :label="item.name" :value="item.name">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="right1">
                        <el-select v-model="value2" placeholder="全国" @change="shaixuan1">
                            <el-option v-for="item in options2" :key="item.code" :label="item.name" :value="item.code">
                            </el-option>
                        </el-select>
                    </div>
                </div>
            </div>
            <div class="liebiao_con">
                <div class="allList">
                    <div class="list1" v-for="(item, index) in allList" :key="index">
                        <div class="img">
                            <img class="tu" v-if="item.cover.length > 0" :src="item.cover[0]" alt="">
                            <!-- <div class="tag">
                                <p>{{ item.tag }}</p>
                            </div> -->
                            <div class="xuanze">
                                <div class="ms">
                                    <img v-if="item.company.logo != null" :src="item.company.logo" alt="">
                                    <p>{{ item.company.name }}</p>
                                </div>
                                <div class="choose">
                                    <div class="choose1" v-for="(tag, index) in item.tags" :key="index">
                                        <p>{{ tag.name }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="des">
                            <div class="title">
                                <p>{{ item.title }}</p>
                                <!-- <span>￥{{ item.money }}</span> -->
                            </div>
                            <div class="xq">
                                <div class="xq1">
                                    <div class="xq1_1">
                                        <img src="../../img/time.png" alt="">
                                        <p>{{ item.published_at | datefmt('YYYY-MM-DD HH:mm') }}</p>
                                    </div>
                                    <div class="xq1_1">
                                        <img src="../../img/location.png" alt="">
                                        <p>{{ item.area[0] }}{{ item.area[1] }}{{ item.area[2] }}{{ item.address }}</p>
                                    </div>
                                </div>
                                <div class="xq2" @click="todetail(item.id)">
                                    <p>查看详情</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <footerbar></footerbar>
        <div class="xqTanchuang1" v-if="huodongShow">
            <div class="closeanniu" @click="closeBtn" @keyup="handleKeyUp">
                <img src="../../img/close.png" alt="">
            </div>
            <div class="fudong">
                <div class="fu1">
                    <img v-if="detail.company.logo != null" :src="detail.company.logo[0]" alt="">
                    <!-- <div class="anniu">
                    <p>+</p>
                </div> -->
                </div>
                <div class="fu2">
                    <div class="img">
                        <img src="../../img/xinxi1.png" alt="">
                    </div>
                    <div class="anniu">
                        <p>{{ detail.comments.length }}</p>
                    </div>
                </div>
                <div class="fu2" :class="{ active: detail.is_support == true }">
                    <div class="img" @click="dzBtn(detail.id)">
                        <img src="../../img/tuijian1.png" alt="" v-if="!detail.is_support">
                        <img src="../../img/tuijian_a.png" alt="" v-else>
                    </div>
                    <div class="anniu">
                        <p>{{ detail.support_count }}</p>
                    </div>
                </div>
                <div class="fu2">
                    <div class="img" @click="scBtn(detail.id)">
                        <img src="../../img/sc1.png" alt="">
                    </div>
                    <div class="anniu">
                        <p>{{ detail.collect_count }}</p>
                    </div>
                </div>
                <div class="fu2">
                    <div class="img" @click="zfBtn(detail.id)">
                        <img src="../../img/zhuanfa1.png" alt="">
                    </div>
                    <div class="anniu">
                        <p>{{ detail.transfer_count }}</p>
                    </div>
                </div>
            </div>
            <div class="index_nei">
                <div class="content">
                    <div class="title">
                        <div class="detail">
                            <!-- <img src="../../img/job1.png" alt="">
                        <img src="../../img/job2.png" alt=""> -->
                            <img v-if="detail.company.logo != null" :src="detail.company.logo[0]" alt="">
                            <div class="detail1">
                                <p>{{ detail.author }}</p>
                                <span>上线时间：{{ detail.published_at | datefmt('YYYY-MM-DD HH:mm') }}</span>
                            </div>
                        </div>
                        <h1>{{ detail.title }}</h1>
                    </div>
                    <div class="des">
                        <!-- <div v-if="detail.content != null">
                        <div v-for="(item, index) in detail.content" :key="index">
                            <p v-html="item.data.text"></p>
                        </div>
                    </div> -->
                        <img v-for="(item, index) in detail.cover" :src="item" :key="index" alt="">
                    </div>
                    <div class="xiangqing">
                        <div class="xiangqing_top">
                            <div class="xq_img">
                                <!-- <img src="../../img/job1.png" alt="">
                            <img src="../../img/job2.png" alt=""> -->
                            </div>
                            <div class="button" @click="canjia(detail.id)">
                                <div class="buttonN">
                                    <p>参与活动</p>
                                </div>
                            </div>
                        </div>
                        <div class="xiangqing_bot">
                            <h3>活动详情</h3>
                            <div class="bot_c">
                                <div class="bot1" v-if="detail.content != null">
                                    <div class="list" v-for="(item, index) in detail.content" :key="index">
                                        <p v-html="item.data.text"></p>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="zuopin">
                <div class="title">
                    <h2>更多作品</h2>
                    <p>查看更多</p>
                </div>
                <div class="list">
                    <div class="list1" v-for="(item, index) in tuijian" :key="index">
                        <img :src="item.pic" alt="">
                    </div>
                </div>
            </div> -->
                <div class="allDibu">
                    <div class="dibu_left">
                        <div class="pinlun">
                            <div class="pinglun_shuru">
                                <h2>发表评论</h2>
                                <div class="shuru">
                                    <!-- <div class="tx">
                                    <img :src="myXinxi.pic" alt="">
                                </div> -->
                                    <div class="kuang" style="width:100%">
                                        <el-input type="textarea" placeholder="说两句吧" v-model="textarea" maxlength="2000"
                                            show-word-limit resize="none">
                                        </el-input>
                                        <div class="anniu" @click="pinglunBtn(detail.id)">
                                            <p>发布</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="pl_list" v-if="pinglunList.length > 0">
                                <h3>全部评论({{ pinglunList.length }})</h3>
                                <div class="allList">
                                    <div class="list1" v-for="(item, index) in pinglunList" :key="index">
                                        <div class="list1_img">
                                            <img :src="item.user.avatar" alt="">
                                        </div>
                                        <div class="list_xq">
                                            <div class="xq_left">
                                                <div class="left_top">
                                                    <p>{{ item.user.name }}</p>
                                                    <span>{{ item.created_at | datefmt('YYYY-MM-DD HH:mm') }}</span>
                                                </div>
                                                <div class="left_bot">
                                                    <p>{{ item.content }}</p>
                                                    <div class="button" @click="huifuShowBtn(index)"
                                                        v-if="activeIndex === ''">回复</div>
                                                    <div class="button" @click="huifuShowBtn1" v-if="index === activeIndex">
                                                        取消回复</div>
                                                </div>
                                                <div class="huifu_shu" v-if="index === activeIndex">
                                                    <div class="shu1">
                                                        <el-input type="text" placeholder="请输入内容" v-model="textppp"
                                                            maxlength="300" show-word-limit>
                                                        </el-input>
                                                        <div class="button" @click="huifu(detail.id, item.id)">回复</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="xq_right" :class="{ active: item.isdian === true }">
                                                <img src="../../img/tuijian_a.png" alt="" v-if="item.isdian">
                                                <img src="../../img/tuijian.png" alt="" v-else>
                                                <p>{{ item.support_count }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="dibu_right">
                        <div class="right1">
                            <h3>发布者</h3>
                            <div class="right1_con">
                                <div class="con1" @click="gstodetail(detail.company.id)">
                                    <div class="con1_left">
                                        <img v-if="detail.company.logo != null" :src="detail.company.logo[0]" alt="">
                                        <div class="detail">
                                            <p>{{ detail.company.name }} </p>
                                            <!-- <span>{{ detail.user.position }}</span> -->
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="con2">
                                <div class="con2_1">
                                    <p>{{ detail.user.fans }}</p>
                                    <span>粉丝</span>
                                </div>
                                <div class="con2_1">
                                    <p>{{ detail.user.article_count }}</p>
                                    <span>文章</span>
                                </div>
                                <div class="con2_1">
                                    <p>{{ detail.user.case_count }}</p>
                                    <span>案例</span>
                                </div>
                                <div class="con2_1">
                                    <p>{{ detail.user.project_count }}</p>
                                    <span>项目</span>
                                </div>
                            </div> -->
                                <!-- <div class="con2">
                                <div class="con2_1" v-if="!zuozhe.isguanzhu">
                                    <img src="../../img/duihao.png" alt="">
                                    <p>已关注</p>
                                </div>
                                <div class="con2_2" v-else>
                                    <span>+</span>
                                    <p>关注</p>
                                </div>
                                <div class="con2_3">
                                    <img src="../../img/email_1.png" alt="">
                                    <p>发信息</p>
                                </div>
                            </div> -->
                            </div>
                        </div>
                        <!-- <div class="right1">
                        <h3>发布者</h3>
                        <div class="right1_con">
                            <div class="list">
                                <img src="../../img/jiang.png" alt="">
                            </div>
                            <div class="list">
                                <img src="../../img/jiang1.png" alt="">
                            </div>
                            <div class="list">
                                <img src="../../img/jiang2.png" alt="">
                            </div>
                            <div class="list">
                                <img src="../../img/jiang3.png" alt="">
                            </div>
                        </div>
                    </div> -->
                    </div>
                </div>
            </div>

        </div>
        <div class="shoucangjia" v-if="toshowSS">
            <div class="scList">
                <div class="scList_top">
                    <p>我的收藏夹</p>
                    <span @click="cjscj" v-if="!isChuang">创建</span>
                    <span @click="cjscj" v-else>取消</span>
                </div>
                <div class="cccc" v-if="isChuang">
                    <input type="text" v-model="scName">
                    <p @click="cjBtn">确定</p>
                </div>
                <ul v-if="CjList.length > 0">
                    <li v-for="(item, index) in CjList" :key="index" @click="scBtn1(item.id)">
                        <p>{{ item.name }}</p>
                        <span @click.stop="deleteSC(item.id)">删除</span>
                    </li>
                </ul>
                <div class="scKong" v-if="CjList.length == 0">
                    <p>暂时没有收藏夹</p>
                </div>
                <div class="qxsc">
                    <p @click="quxiaoSc">取消</p>
                </div>
            </div>
        </div>

        <div class="bmtanchuang" v-if="baoming">
            <div class="baoming_top">
                <h3>填写报名单</h3>
                <img src="../../img/chahao.png" alt="" @click="closebm()">
            </div>
            <div class="baoming_con">
                <div class="con1">
                    <p>姓名<i>*</i></p>
                    <input type="text" placeholder="请填写联系人姓名" v-model="contactName">
                </div>

                <div class="con1">
                    <p>昵称<i>*</i></p>
                    <input type="text" placeholder="请填写联系人昵称" v-model="contactNickName">
                </div>

                <div class="con1">
                    <p>电话<i>*</i></p>
                    <input type="text" placeholder="请填写联系电话" v-model="contactPhone">
                </div>

                <div class="con1">
                    <p>电子邮箱<i>*</i></p>
                    <input type="text" placeholder="请填写电子邮箱" v-model="contactEmail">
                </div>
                <div class="tijiao" @click="tijiao((detail.id))">
                    <p>提交</p>
                </div>
            </div>
        </div>


    </div>
</template>

<script>
import moment from 'moment';
// import headerbar from '../../components/headerbar.vue';
import { eventBus } from '../../App.vue';
import footerbar from '../../components/footerbar.vue';
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'
const phoneRegex = /^1[3456789]\d{9}$/;
const emailRegex = /^[A-Za-z0-9+_.-]+@(.+)$/;
export default {
    name: 'huodong',
    data() {
        return {
            pageTitle: '活动展示',
            choose: 'wenzhang',
            selectedNav: '活动',
            visualizationList: [
                {
                    pic: require('@/img/huodong_banner.jpg'),
                }, {
                    pic: require('@/img/huodong_banner.jpg'),
                }, {
                    pic: require('@/img/huodong_banner.jpg'),
                }
            ],
            swiperOptions: {
                autoplay: true,
                loop: true,
                loopAdditionalSlides: 3,
                initialSlide: 1,
                spaceBetween: 35,
                navigation: {
                    nextEl: '.swiper-visualization .swiper-next',
                    prevEl: '.swiper-visualization .swiper-prev',
                },
                breakpoints: {
                    768: {
                        spaceBetween: 10,
                    }
                }
            },
            options: [{
                value: '选项1',
                label: '推荐1'
            }, {
                value: '选项2',
                label: '推荐2'
            }, {
                value: '选项3',
                label: '推荐3'
            }, {
                value: '选项4',
                label: '推荐4'
            }, {
                value: '选项5',
                label: '推荐5'
            }],
            value: '',
            options1: [{
                value: '选项1',
                label: '标签1'
            }, {
                value: '选项2',
                label: '标签2'
            }, {
                value: '选项3',
                label: '标签3'
            }, {
                value: '选项4',
                label: '标签4'
            }, {
                value: '选项5',
                label: '标签5'
            }],
            value1: '',
            options2: [{
                value: '选项1',
                label: '行业1'
            }, {
                value: '选项2',
                label: '行业2'
            }, {
                value: '选项3',
                label: '行业3'
            }, {
                value: '选项44',
                label: '行业'
            }, {
                value: '选项5',
                label: '行业5'
            }],
            value2: '',
            options3: [{
                value: '选项1',
                label: '颜色1'
            }, {
                value: '选项2',
                label: '颜色2'
            }, {
                value: '选项3',
                label: '颜色3'
            }, {
                value: '选项44',
                label: '颜色4'
            }, {
                value: '选项5',
                label: '颜色5'
            }],
            value3: '',
            allList: [],
            tiaojian1: '',
            tiaojian2: '',
            tiaojian3: '',

            detail: "",
            pinglunList: [],
            xqzhanshi: false,
            huodongShow: false,
            isScrollDisabled: false,
            textarea: '',

            toshowSS: false,
            roleList: '',
            isChuang: false,
            scName: '',
            CjList: '',

            baoming: false,
            contactName: '',
            contactNickName: '',
            contactPhone: '',
            contactEmail: '',

        }
    },
    components: {
        // headerbar,
        footerbar,
        swiper,
        swiperSlide,
    },
    computed: {
        swiper() {
            return this.$refs.visualizationSwiper.swiper
        }
    },
    beforeRouteLeave(to, from, next) {
        document.body.classList.remove('disable-body-scroll');
        next();
    },
    created() {
        document.title = this.pageTitle;
    },
    mounted() {
        this.getList();
        this.gethangye();
        this.getprovince()
        this.setActivePage()
        this.showMobileNav()
        this.getRole()
        this.getSCList()
        document.addEventListener("keyup", this.handleKeyUp);
    },
    beforeUnmount() {
        // Remove the keyup event listener before the component is unmounted to prevent memory leaks
        document.removeEventListener("keyup", this.handleKeyUp);
    },
    methods: {

        //活动详情二级评论
        huifu(e, d) {
            this.$axios.post('/api/user/comment/save', {
                type: 2,
                ext_id: e,
                content: this.textppp,
                comment_id: d,
            }, {
                headers: {
                    'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
                }
            }).then((result) => {
                console.log(result)
                if (result.data.code == 200) {
                    this.$message({
                        message: result.data.message,
                        type: 'success'
                    });
                    this.textppp = "";
                    this.activeIndex = ''
                } else {
                    this.$message.error(result.data.message);
                }
            }).catch((err) => {
                console.log(err)
                // this.$message.error(err.response.data.message);
                if (err.response.data.code == 403 || err.response.data.code == 500) {
                    this.$message.error("请重新登录!");
                    setTimeout(() => {
                        eventBus.$emit('clearToken', '');
                        this.$router.push({
                            name: 'login'
                        })
                    }, 1500);
                } else {
                    this.$message.error(err.response.data.message)
                }
            });
        },

        huifuShowBtn(e) {
            this.activeIndex = e
        },

        huifuShowBtn1() {
            this.activeIndex = ''
        },

        cjscj() {
            this.isChuang = !this.isChuang
        },
        //创建文件夹
        cjBtn() {
            if (this.scName == '') {
                this.$message.error('请输入文件夹名!');
                return
            }
            this.$axios.post('api/collection/create', {
                name: this.scName
            }, {
                headers: {
                    'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
                }
            }).then((result) => {
                console.log(result)
                if (result.data.code == 200) {
                    this.$message({
                        message: '创建成功!',
                        type: 'success'
                    });
                    this.getSCList();
                    this.isChuang = false;
                } else {
                    this.$message.error(result.data.message);
                }
            }).catch((err) => {
                console.log(err)
                if (err.response.data.code == 403) {
                    this.$message.error("请重新登录！");
                    setTimeout(() => {
                        eventBus.$emit('clearToken', '');
                        this.$router.push({
                            name: 'login'
                        })
                    }, 1500);
                } else {
                    this.$message.error(err.response.data.message);
                }
            });
        },

        //删除收藏夹
        deleteSC(e) {
            this.$axios.get('api/collection/delete/' + e, {
                headers: {
                    'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
                }
            }).then((result) => {
                if (result.data.code == 200) {
                    this.$message({
                        message: '删除成功!',
                        type: 'success'
                    });
                    this.getSCList();
                } else {
                    this.$message.error(result.data.message);
                }
            }).catch((err) => {
                console.log(err)
                if (err.response.data.code == 403) {
                    this.$message.error("请重新登录！");
                    setTimeout(() => {
                        eventBus.$emit('clearToken', '');
                        this.$router.push({
                            name: 'login'
                        })
                    }, 1500);
                } else {
                    this.$message.error(err.response.data.message);
                }
            });
        },

        //获取收藏列表
        getSCList() {
            this.$axios.get('api/collection/my_collect', {
                headers: {
                    'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
                }
            }).then((result) => {
                console.log(result)
                if (result.data.code == 200) {
                    this.CjList = result.data.data
                } else {
                    this.$message.error(result.data.message);
                }
            }).catch((err) => {
                console.log(err)
            });
        },

        //获取项目认领角色
        getRole() {
            this.$axios.get('api/select/project_role', {}).then((result) => {
                console.log(result)
                if (result.data.code == 200) {
                    this.roleList = result.data.data
                } else {
                    this.$message.error(result.data.message);
                }
            }).catch((err) => {
                console.log(err)
                this.$message.error(err.response.data.message);
            });
        },



        setActivePage() {
            // 触发事件，传递当前页面的名称
            eventBus.$emit('updateActivePage', '活动');
        },

        showMobileNav() {
            eventBus.$emit('shownav', '');
        },

        quxiaoSc() {
            this.toshowSS = false;
        },

        handleKeyUp(event) {
            // console.log(event)
            if (event.key === 'Escape') {
                // 处理按下 Enter 键的逻辑
                console.log('esc 键被按下');
                this.closeBtn()
            }
        },

        closeBtn() {
            this.xqzhanshi = false;
            this.huodongShow = false;
            this.toshowSS = false;
            this.baoming = false;
            document.body.classList.remove('disable-body-scroll');
        },

        //活动详情弹窗
        todetail(e) {

            document.body.classList.add('disable-body-scroll');
            this.huodongShow = true;
            if (window.localStorage.getItem('token')) {

                this.$axios.get('/api/pc/activity/detail/' + e, {
                    headers: {
                        'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
                    }
                }).then((result) => {
                    console.log(result)
                    if (result.data.code == 200) {
                        this.detail = result.data.data;
                        this.pinglunList = result.data.data.comments
                    }
                }).catch((err) => {
                    console.log(err)
                });

            } else {
                this.$axios.get('/api/pc/activity/detail/' + e, {}).then((result) => {
                    console.log(result)
                    if (result.data.code == 200) {
                        this.detail = result.data.data;
                        this.pinglunList = result.data.data.comments
                    }
                }).catch((err) => {
                    console.log(err)
                });
            }


        },
        chooseBtn() { },

        //评论
        pinglunBtn(e) {
            this.$axios.post('/api/user/comment/save', {
                type: 2,
                ext_id: e,
                content: this.textarea,
                comment_id: '',
            }, {
                headers: {
                    'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
                }
            }).then((result) => {
                console.log(result)
                if (result.data.code == 200) {
                    this.$message({
                        message: result.data.message,
                        type: 'success'
                    });
                    this.textarea = ""
                } else {
                    this.$message.error(result.data.message);
                }
            }).catch((err) => {
                console.log(err)
                // this.$message.error(err.response.data.message);
                if (err.response.data.code == 403 || err.response.data.code == 500) {
                    this.$message.error("请重新登录!");
                    setTimeout(() => {
                        eventBus.$emit('clearToken', '');
                        this.$router.push({
                            name: 'login'
                        })
                    }, 1500);
                } else {
                    this.$message.error(err.response.data.message)
                }
            });
        },

        getDetail(e) {
            this.$axios.get('/api/pc/activity/detail/' + e, {
                headers: {
                    'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
                }
            }).then((result) => {
                console.log(result)
                if (result.data.code == 200) {
                    this.detail = result.data.data;
                    this.pinglunList = result.data.data.comments
                } else {
                    this.$message.error(result.data.message);
                }
            }).catch((err) => {
                console.log(err)
                this.$message.error(err.response.data.message);
            });
        },


        //获取地区
        getprovince() {
            this.$axios.get('/api/pc/area', {}).then((result) => {
                console.log(result)
                if (result.data.code == 200) {
                    this.options2 = result.data.data
                }
            }).catch((err) => {
                console.log(err)
            })
        },



        //获取行业列表
        gethangye() {
            this.$axios.get('/api/pc/industry', {}).then((result) => {
                console.log(result)
                if (result.data.code == 200) {
                    this.options1 = result.data.data
                } else {
                    this.$message.error(result.data.message);
                }
            }).catch((err) => {
                console.log(err)
                this.$message.error(err.response.data.message);
            });
        },
        shaixuan(e) {
            this.tiaojian2 = e;
            this.getList()
        },
        shaixuan1(e) {
            console.log(e)
            this.tiaojian3 = e;
            this.getList()
        },
        shaixuan2(e) {
            console.log(moment(e).format('YYYY-MM-DD'))
            this.tiaojian1 = moment(e).format('YYYY-MM-DD')
            this.getList()
        },
        //获取列表
        getList() {
            this.$axios.get('/api/__yao/table/pc.activity/search', {
                params: {
                    "where.published_at.match": this.tiaojian1,  // 活动日期搜索
                    "where.industry.match": this.tiaojian2,  //活动行业搜索
                    "where.area.match": this.tiaojian3,  //活动地区搜索:传省市区的编码
                }
            }).then((result) => {
                console.log(result)
                if (result.data.code == 200) {
                    this.allList = result.data.data
                } else {
                    this.$message.error(result.data.message);
                }
            }).catch((err) => {
                console.log(err)
                this.$message.error(err.response.data.message);
            });
        },


        //收藏
        scBtn() {
            console.log(1312312)
            this.toshowSS = !this.toshowSS
            // this.$axios.post('/api/user/collect/article', {
            //     id: e,
            //     type: 1,
            // }, {
            //     headers: {
            //         'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
            //     }
            // }).then((result) => {
            //     console.log(result)
            //     if (result.data.code == 200) {
            //         this.$message({
            //             message: '收藏成功！',
            //             type: 'success'
            //         });
            //         setTimeout(() => {
            //             this.getDetail(e)
            //         }, 500);
            //     } else {
            //         this.$message.error(result.data.message);
            //     }
            // }).catch((err) => {
            //     console.log(err)
            //     this.$message.error(err.response.data.message);
            //     if (err.response.data.code == 403) {
            //         setTimeout(() => {
            //             this.$router.push({
            //                 name: 'login'
            //             })
            //         }, 1500);
            //     }
            // });
        },
        scBtn1() {
            this.$axios.post('/api/user/collect/article', {
                id: this.detail.id,
                type: 1,
            }, {
                headers: {
                    'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
                }
            }).then((result) => {
                console.log(result)
                if (result.data.code == 200) {
                    this.$message({
                        message: '收藏成功！',
                        type: 'success'
                    });
                    setTimeout(() => {
                        this.getDetail(this.detail.id)
                        this.toshowSS = !this.toshowSS
                    }, 500);
                } else {
                    this.$message.error(result.data.message);
                    this.toshowSS = !this.toshowSS
                }
            }).catch((err) => {
                console.log(err)

                if (err.response.data.code == 403) {
                    this.$message.error("请重新登录！");
                    setTimeout(() => {
                        eventBus.$emit('clearToken', '');
                        this.$router.push({
                            name: 'login'
                        })
                    }, 1500);
                } else {
                    this.$message.error(err.response.data.message);
                }
            });
        },


        //点赞
        dzBtn(e) {
            this.$axios.post('/api/user/support/content', {
                id: e,
                type: 2,
            }, {
                headers: {
                    'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
                }
            }).then((result) => {
                console.log(result)
                if (result.data.code == 200) {
                    this.$message({
                        message: '点赞成功！',
                        type: 'success'
                    });
                    setTimeout(() => {
                        this.getDetail(e)
                    }, 500);
                } else {
                    this.$message.error(result.data.message);
                }
            }).catch((err) => {
                console.log(err)

                if (err.response.data.code == 403) {
                    this.$message.error("请重新登录！");
                    setTimeout(() => {
                        eventBus.$emit('clearToken', '');
                        this.$router.push({
                            name: 'login'
                        })
                    }, 1500);
                } else {
                    this.$message.error(err.response.data.message);
                }
            });
        },

        //取消点赞
        qxdzBtn(e) {
            this.$axios.post('/api/user/unsupport/content', {
                id: e,
                type: 2,
            }, {
                headers: {
                    'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
                }
            }).then((result) => {
                console.log(result)
                if (result.data.code == 200) {
                    this.$message({
                        message: '取消点赞！',
                        type: 'success'
                    });

                    //重新获取详情接口
                    setTimeout(() => {
                        this.getDetail(e)
                    }, 500);

                } else {
                    this.$message.error(result.data.message);
                }

            }).catch((err) => {
                console.log(err)

                if (err.response.data.code == 403) {
                    this.$message.error("请重新登录！");
                    setTimeout(() => {
                        eventBus.$emit('clearToken', '');
                        this.$router.push({
                            name: 'login'
                        })
                    }, 1500);
                } else {
                    this.$message.error(err.response.data.message);
                }
            });
        },


        //转发
        zfBtn(e) {
            this.$axios.post('/api/pc/transfer/activity', {
                id: e,
            }, {
                headers: {
                    'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
                }
            }).then((result) => {
                console.log(result)
                if (result.data.code == 200) {
                    this.$message({
                        message: '转发成功！',
                        type: 'success'
                    });
                    setTimeout(() => {
                        this.getDetail(e)
                    }, 500);
                } else {
                    this.$message.error(result.data.message);
                }
            }).catch((err) => {
                console.log(err)

                if (err.response.data.code == 403) {
                    this.$message.error("请重新登录！");
                    setTimeout(() => {
                        eventBus.$emit('clearToken', '');
                        this.$router.push({
                            name: 'login'
                        })
                    }, 1500);
                } else {
                    this.$message.error(err.response.data.message);
                }
            });
        },


        //参加活动
        canjia() {
            this.baoming = true;
        },

        closebm() {
            this.baoming = false;
        },

        //参加活动
        tijiao(e) {
            if (this.contactName == "") {
                this.$message.error('联系人姓名不能为空！');
                return
            }
            if (this.contactPhone == "") {
                this.$message.error('联系人手机不能为空！');
                return
            }
            if (!phoneRegex.test(this.contactPhone)) {
                this.$message.error('手机号格式不正确！');
                return
            }
            if (this.contactEmail == "") {
                this.$message.error('电子邮箱不能为空！');
                return
            }
            if (!emailRegex.test(this.contactEmail)) {
                this.$message.error('电子邮箱格式不正确！');
                return
            }
            this.$axios.post('/api/user/activity/join/', {
                id: e,
                name: this.contactName,
                mobile: this.contactPhone,
                nickname: this.contactNickName,
                email: this.contactEmail,
            }, {
                headers: {
                    'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
                }
            }).then((result) => {
                console.log(result)
                if (result.data.code == 200) {
                    this.$message({
                        message: '参与成功！',
                        type: 'success'
                    });
                    setTimeout(() => {
                        this.baoming = false;
                        this.contactName = "";
                        this.contactPhone = "";
                        this.contactEmail = "";
                        this.contactNickName = "";
                    }, 1500);
                } else {
                    this.$message.error(result.data.message);
                }
            }).catch((err) => {
                console.log(err)

                if (err.response.data.code == 403) {
                    this.$message.error("请重新登录！");
                    setTimeout(() => {
                        eventBus.$emit('clearToken', '');
                        this.$router.push({
                            name: 'login'
                        })
                    }, 1500);
                } else {
                    this.$message.error(err.response.data.message);
                }
            });
        },



    }
}
</script>

<style scoped lang="scss">
@import '../huodong/huodong.scss'
</style>

<style>
.right1 .el-input__inner {
    border: 1px solid #212121;
    height: 35px;
}

.right1 .el-date-editor.el-input,
.el-date-editor.el-input__inner {
    width: 100%;
}

.right1 .el-input__icon {
    line-height: 35px;
}
</style>