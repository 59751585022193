<template>
  <div class="index">
    <!-- <headerbar :selected-nav="selectedNav"></headerbar> -->
    <div class="index">
      <div class="jobTop">
        <div class="jobTop_left">
          <div class="left_nav">
            <img src="../../img/shejishi.jpg" alt="">
            <p>UGEE</p>
          </div>
          <div class="miaoshu">
            <p>品牌标识设计师/插画师</p>
          </div>

          <div class="anniu">
            <div class="anniu2">
              <img src="../../img/duihao.png" alt="">
              <span>已关注</span>
            </div>
            <div class="anniu1">
              <img src="../../img/email_1.png" alt="">
              <p>发送消息</p>
            </div>
          </div>

        </div>
        <div class="jobTop_right">
          <img src="../../img/ge_detail.png" alt="">
        </div>
      </div>
      <div class="huodong">
        <div class="liebiao_top">
          <div class="top_left">
            <div class="choose" :class="{ active: choose === 'zuopin' }" @click="chooseBtn('zuopin')">
              <p>作品560</p>
            </div>
            <div class="choose" :class="{ active: choose === 'neirong' }" @click="chooseBtn('neirong')">
              <p>内容236</p>
            </div>
            <div class="choose" :class="{ active: choose === 'guanyu' }" @click="chooseBtn('guanyu')">
              <p>关于</p>
            </div>
          </div>
        </div>
        <div class="liebiao_con">
          <div class="allList" v-if="choose == 'zuopin'">
            <div class="list1" v-for="(item, index) in allList" :key="index" @click="todetail">
              <div class="img">
                <img :src="item.img" alt="">
              </div>
              <div class="des">
                <div class="title">
                  <p>{{ item.title }}</p>
                </div>
                <div class="xq">
                  <div class="xq1">
                    <img :src="item.author.tx" alt="">
                    <p>{{ item.author.name }}</p>
                  </div>
                  <div class="xq2">
                    <div class="sc">
                      <img src="../../img/shoucang.png" alt="">
                      <p>{{ item.shoucang }}</p>
                    </div>
                    <div class="sc">
                      <img src="../../img/dianzan.png" alt="">
                      <p>{{ item.dianzan }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="guanyu" v-if="choose == 'guanyu'">
            <div class="guanyu_left">
              <div class="jianjie">
                <h2>简介</h2>
                <div class="neirong">
                  <p>{{ neirong }}</p>
                </div>
                
              </div>
              <div class="shanchang">
                  <h2>擅长</h2>
                  <div class="sc">
                    <div class="tags" v-for="(item, index) in shanchang" :key="index">
                    <p>{{ item }}</p>
                  </div>
                  </div>
                </div>
                <div class="pinpai">
                  <div class="pp" v-for="(item, index) in pinpaiList" :key="index">
                    <img :src="item.img" alt="">
                  </div>
                </div>
            </div>
            <div class="guanyu_right">
              <div class="xinxi">
                <div class="xx1">
                  <img src="../../img/location1.jpg" alt="">
                  <p>{{ address }}</p>
                </div>

                <div class="xx1">
                  <img src="../../img/chuangli.jpg" alt="">
                  <p>{{ dingzhi }}</p>
                </div>

                <div class="xx1">
                  <img src="../../img/huiyuan.jpg" alt="">
                  <p>{{ huiyuan }}</p>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
    <footerbar></footerbar>
  </div>
</template>

<script>
// import headerbar from '../../components/headerbar.vue';
import { eventBus } from '../../App.vue';
import footerbar from '../../components/footerbar.vue';
export default {
  name: 'chuangzao_p',
  data() {
    return {
      pageTitle: '个人详情',
      choose: 'zuopin',
      selectedNav: 'chuangzao',
      address: '上海市',
      chuangli: '2010年创立',
      dingzhi: '定制版企业',
      huiyuan: '2010年会员',
      allList: [
        {
          img: require('@/img/cz1.jpg'),
          tag: '文章',
          title: '现代汽车邀请凯文培根参加超级碗前的比赛',
          des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
          date: '2023-02-16',
          dianzan: '125',
          shoucang: '7895',
          isdianzan: false,
          author: {
            name: '用户123',
            tx: require('@/img/tx.jpg'),
          },
        },


        {
          img: require('@/img/cz2.jpg'),
          tag: '文章',
          title: '现代汽车邀请凯文培根参加超级碗前的比赛',
          des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
          date: '2023-02-16',
          dianzan: '125',
          shoucang: '7895',
          isdianzan: false,
          author: {
            name: '用户123',
            tx: require('@/img/tx.jpg'),
          },
        },

        {
          img: require('@/img/cz3.jpg'),
          tag: '文章',
          title: '现代汽车邀请凯文培根参加超级碗前的比赛',
          des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
          date: '2023-02-16',
          dianzan: '125',
          isdianzan: false,
          shoucang: '7895',
          author: {
            name: '用户123',
            tx: require('@/img/tx.jpg'),
          },
        },

        {
          img: require('@/img/cz4.jpg'),
          tag: '文章',
          title: '现代汽车邀请凯文培根参加超级碗前的比赛',
          des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
          date: '2023-02-16',
          dianzan: '125',
          shoucang: '7895',
          isdianzan: false,
          author: {
            name: '用户123',
            tx: require('@/img/tx.jpg'),
          },
        },

        {
          img: require('@/img/cz5.jpg'),
          tag: '文章',
          title: '现代汽车邀请凯文培根参加超级碗前的比赛',
          des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
          date: '2023-02-16',
          dianzan: '125',
          shoucang: '7895',
          isdianzan: false,
          author: {
            name: '用户123',
            tx: require('@/img/tx.jpg'),
          },
        },


        {
          img: require('@/img/cz6.jpg'),
          tag: '文章',
          title: '现代汽车邀请凯文培根参加超级碗前的比赛',
          des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
          date: '2023-02-16',
          dianzan: '125',
          shoucang: '7895',
          isdianzan: false,
          author: {
            name: '用户123',
            tx: require('@/img/tx.jpg'),
          },
        },
        {
          img: require('@/img/cz1.jpg'),
          tag: '文章',
          title: '现代汽车邀请凯文培根参加超级碗前的比赛',
          des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
          date: '2023-02-16',
          dianzan: '125',
          shoucang: '7895',
          isdianzan: false,
          author: {
            name: '用户123',
            tx: require('@/img/tx.jpg'),
          },
        },


        {
          img: require('@/img/cz2.jpg'),
          tag: '文章',
          title: '现代汽车邀请凯文培根参加超级碗前的比赛',
          des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
          date: '2023-02-16',
          dianzan: '125',
          shoucang: '7895',
          isdianzan: false,
          author: {
            name: '用户123',
            tx: require('@/img/tx.jpg'),
          },
        },

        {
          img: require('@/img/cz3.jpg'),
          tag: '文章',
          title: '现代汽车邀请凯文培根参加超级碗前的比赛',
          des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
          date: '2023-02-16',
          dianzan: '125',
          isdianzan: false,
          shoucang: '7895',
          author: {
            name: '用户123',
            tx: require('@/img/tx.jpg'),
          },
        },

        {
          img: require('@/img/cz4.jpg'),
          tag: '文章',
          title: '现代汽车邀请凯文培根参加超级碗前的比赛',
          des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
          date: '2023-02-16',
          dianzan: '125',
          shoucang: '7895',
          isdianzan: false,
          author: {
            name: '用户123',
            tx: require('@/img/tx.jpg'),
          },
        },

        {
          img: require('@/img/cz5.jpg'),
          tag: '文章',
          title: '现代汽车邀请凯文培根参加超级碗前的比赛',
          des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
          date: '2023-02-16',
          dianzan: '125',
          shoucang: '7895',
          isdianzan: false,
          author: {
            name: '用户123',
            tx: require('@/img/tx.jpg'),
          },
        },


        {
          img: require('@/img/cz6.jpg'),
          tag: '文章',
          title: '现代汽车邀请凯文培根参加超级碗前的比赛',
          des: '2月14日，《2022年中国游戏产业报告》在中国游戏产业年会上正式公布，报告显示，2022年中国游戏市场实际销售收入为2658.84亿元，同比下降10.33%，是自2008年统计以来出现的首次负增长。',
          date: '2023-02-16',
          dianzan: '125',
          shoucang: '7895',
          isdianzan: false,
          author: {
            name: '用户123',
            tx: require('@/img/tx.jpg'),
          },
        },



      ],
      chengyuanList: [
        {
          author: {
            name: '用户123',
            tx: require('@/img/tx.jpg'),
            address: '北京市',
          },
          isguanzhu: true,
          list: [
            {
              pic: require('@/img/cz1.jpg'),
            },
            {
              pic: require('@/img/cz2.jpg'),
            },
            {
              pic: require('@/img/cz3.jpg'),
            },
            {
              pic: require('@/img/cz4.jpg'),
            },
          ],
        },

        {
          author: {
            name: '用户123',
            tx: require('@/img/tx.jpg'),
            address: '北京市',
          },
          isguanzhu: false,
          list: [
            {
              pic: require('@/img/cz1.jpg'),
            },
            {
              pic: require('@/img/cz2.jpg'),
            },
            {
              pic: require('@/img/cz3.jpg'),
            },
            {
              pic: require('@/img/cz4.jpg'),
            },
          ],
        },

        {
          author: {
            name: '用户123',
            tx: require('@/img/tx.jpg'),
            address: '北京市',
          },
          isguanzhu: false,
          list: [
            {
              pic: require('@/img/cz1.jpg'),
            },
            {
              pic: require('@/img/cz2.jpg'),
            },
            {
              pic: require('@/img/cz3.jpg'),
            },
            {
              pic: require('@/img/cz4.jpg'),
            },
          ],
        },

        {
          author: {
            name: '用户123',
            tx: require('@/img/tx.jpg'),
            address: '北京市',
          },
          isguanzhu: false,
          list: [
            {
              pic: require('@/img/cz1.jpg'),
            },
            {
              pic: require('@/img/cz2.jpg'),
            },
            {
              pic: require('@/img/cz3.jpg'),
            },
            {
              pic: require('@/img/cz4.jpg'),
            },
          ],
        },

        {
          author: {
            name: '用户123',
            tx: require('@/img/tx.jpg'),
            address: '北京市',
          },
          isguanzhu: false,
          list: [
            {
              pic: require('@/img/cz1.jpg'),
            },
            {
              pic: require('@/img/cz2.jpg'),
            },
            {
              pic: require('@/img/cz3.jpg'),
            },
            {
              pic: require('@/img/cz4.jpg'),
            },
          ],
        },

        {
          author: {
            name: '用户123',
            tx: require('@/img/tx.jpg'),
            address: '北京市',
          },
          isguanzhu: true,
          list: [
            {
              pic: require('@/img/cz1.jpg'),
            },
            {
              pic: require('@/img/cz2.jpg'),
            },
            {
              pic: require('@/img/cz3.jpg'),
            },
            {
              pic: require('@/img/cz4.jpg'),
            },
          ],
        },
      ],
      neirong: 'DGEE是享誉国际的设计顾问公司，由埃迪·洛巴诺夫斯基及大卫·科瓦列夫合伙经营。前身为设计顾问，经过近40年的发展，现在于香港、深圳设有公司，业务遍布全国，服务超过200多个国内外的客户，在业界获奖无数。公司以国际的视野与本土的经验，结合文化与商业，引领着中国的品牌设计。2013年正式更名为靳刘高设计，印证三代人的同心协力，传承创意，与时俱进，致力创造国际水平的中国设计。公司创作领域广泛，部门专业化管理，能为客户提供从文化研究、策略定位、形象系统、包装与产品设计、推广宣传、导视系统，以及空间设计等一系列整合性的设计服务。合作过的知名企业有中国银行、屈臣氏、鄂尔多斯、李宁、八马茶业等行业翘楚及政府项目如启德发展，不断为品牌创造价值，缔造行业标杆，影响著现代中国的生活。公司未来将进一步专业化管理与资源整合，发展战略性的品牌设计体系，互补互进，致力于成为中国最高设计水平的代表。',
      shanchang: [
        '整合营销',
        '社会化运营',
        'TVC拍摄',
        '品牌推广',
        '标识',
        '用户体验',
        '多媒体',
      ],
      pinpaiList: [
        {
          img: require('../../img/pinpai.jpg'),
        },
        {
          img: require('../../img/pinpai1.jpg'),
        },
        {
          img: require('../../img/pinpai2.jpg'),
        },
        {
          img: require('../../img/pinpai3.jpg'),
        },
        {
          img: require('../../img/pinpai4.jpg'),
        },
        {
          img: require('../../img/pinpai5.jpg'),
        },
        {
          img: require('../../img/pinpai6.jpg'),
        },
        {
          img: require('../../img/pinpai7.jpg'),
        },
        {
          img: require('../../img/pinpai.jpg'),
        },
        {
          img: require('../../img/pinpai1.jpg'),
        },
        {
          img: require('../../img/pinpai2.jpg'),
        },
        {
          img: require('../../img/pinpai3.jpg'),
        },
        {
          img: require('../../img/pinpai4.jpg'),
        },
        {
          img: require('../../img/pinpai5.jpg'),
        },
        {
          img: require('../../img/pinpai6.jpg'),
        },
        {
          img: require('../../img/pinpai7.jpg'),
        },
      ],
      cyList: [
        {
          pic: require('../../img/shejishi.jpg'),
        },
        {
          pic: require('../../img/shejishi1.jpg'),
        },
        {
          pic: require('../../img/shejishi2.jpg'),
        },
        {
          pic: require('../../img/shejishi3.jpg'),
        },
        {
          pic: require('../../img/shejishi4.jpg'),
        },
        {
          pic: require('../../img/shejishi5.jpg'),
        },
        {
          pic: require('../../img/shejishi.jpg'),
        },
        {
          pic: require('../../img/shejishi1.jpg'),
        },
        {
          pic: require('../../img/shejishi2.jpg'),
        },
        {
          pic: require('../../img/shejishi3.jpg'),
        },
        {
          pic: require('../../img/shejishi4.jpg'),
        },
        {
          pic: require('../../img/shejishi5.jpg'),
        },
      ],
    }
  },
  created() {
        document.title = this.pageTitle;
    },
  components: {
    // headerbar,
    footerbar
  },
  mounted(){
    this.setActivePage()
  },
  methods: {
    setActivePage() {
            // 触发事件，传递当前页面的名称
            eventBus.$emit('updateActivePage', 'chuangzao');
        },
    chooseBtn(e) {
      this.choose = e
    },
    todetail() {

    },
  }
}
</script>

<style scoped lang="scss">
@import "../chuangzao_p/chuangzao.scss"
</style>