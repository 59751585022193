<template>
    <div class="index">
        <div class="liebiao_con" v-if="chengyuanList.length > 0">
            <div class="allList chengyuan">
                <div class="list1" v-for="(item, index) in chengyuanList" :key="index" @click="todetail(item.id)">
                    <div class="cy_top">
                        <div class="cy_1">
                            <img :src="item.user.avatar[0]" alt="">
                            <div class="detail">
                                <p>{{ item.user.name }}</p>
                                <span>粉丝数量:{{ item.fans }}</span>
                            </div>
                        </div>
                        <div class="cy_2">
                            <div class="guanzhu" v-if="item.easch_fans">
                                <img src="../../img/duihao.png" alt="">
                                <p>互相关注</p>
                            </div>

                            <div class="guanzhu" v-else>
                                <img src="../../img/duihao.png" alt="">
                                <p>已关注</p>
                            </div>

                            <!-- <div class="weiguanzhu" v-else>
                                <i> + </i>
                                <p>关注</p>
                            </div> -->
                        </div>
                    </div>
                    <!-- <div class="cy_bot">
                        <div class="bot1" v-for="(tt, index) in item.list" :key="index">
                            <img :src="tt.pic" alt="">
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
        <div class="kong" v-else>
            <div class="kong1">
                <i class="el-icon-warning-outline"></i>
                <p>您还没有关注的人</p>
            </div>
            <div class="anniu">
                <p>搜寻喜欢的公司</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PageswangzhanFollow',

    data() {
        return {
            pageTitle: '我的关注',
            chengyuanList: [
                {
                    author: {
                        name: '用户123',
                        tx: require('@/img/tx.jpg'),
                        address: '北京市',
                    },
                    isguanzhu: true,
                    list: [
                        {
                            pic: require('@/img/cz1.jpg'),
                        },
                        {
                            pic: require('@/img/cz2.jpg'),
                        },
                        {
                            pic: require('@/img/cz3.jpg'),
                        },
                        {
                            pic: require('@/img/cz4.jpg'),
                        },
                    ],
                },

                {
                    author: {
                        name: '用户123',
                        tx: require('@/img/tx.jpg'),
                        address: '北京市',
                    },
                    isguanzhu: false,
                    list: [
                        {
                            pic: require('@/img/cz1.jpg'),
                        },
                        {
                            pic: require('@/img/cz2.jpg'),
                        },
                        {
                            pic: require('@/img/cz3.jpg'),
                        },
                        {
                            pic: require('@/img/cz4.jpg'),
                        },
                    ],
                },

                {
                    author: {
                        name: '用户123',
                        tx: require('@/img/tx.jpg'),
                        address: '北京市',
                    },
                    isguanzhu: false,
                    list: [
                        {
                            pic: require('@/img/cz1.jpg'),
                        },
                        {
                            pic: require('@/img/cz2.jpg'),
                        },
                        {
                            pic: require('@/img/cz3.jpg'),
                        },
                        {
                            pic: require('@/img/cz4.jpg'),
                        },
                    ],
                },

                {
                    author: {
                        name: '用户123',
                        tx: require('@/img/tx.jpg'),
                        address: '北京市',
                    },
                    isguanzhu: false,
                    list: [
                        {
                            pic: require('@/img/cz1.jpg'),
                        },
                        {
                            pic: require('@/img/cz2.jpg'),
                        },
                        {
                            pic: require('@/img/cz3.jpg'),
                        },
                        {
                            pic: require('@/img/cz4.jpg'),
                        },
                    ],
                },

                {
                    author: {
                        name: '用户123',
                        tx: require('@/img/tx.jpg'),
                        address: '北京市',
                    },
                    isguanzhu: false,
                    list: [
                        {
                            pic: require('@/img/cz1.jpg'),
                        },
                        {
                            pic: require('@/img/cz2.jpg'),
                        },
                        {
                            pic: require('@/img/cz3.jpg'),
                        },
                        {
                            pic: require('@/img/cz4.jpg'),
                        },
                    ],
                },

                {
                    author: {
                        name: '用户123',
                        tx: require('@/img/tx.jpg'),
                        address: '北京市',
                    },
                    isguanzhu: true,
                    list: [
                        {
                            pic: require('@/img/cz1.jpg'),
                        },
                        {
                            pic: require('@/img/cz2.jpg'),
                        },
                        {
                            pic: require('@/img/cz3.jpg'),
                        },
                        {
                            pic: require('@/img/cz4.jpg'),
                        },
                    ],
                },
            ],
        };
    },
    created() {
        document.title = this.pageTitle;
    },
    mounted() {
        this.getList()
    },

    methods: {
        getList(){
            this.$axios.get('/api/__yao/table/personal.following/search',{
                headers: {
                    'Authorization': "Bearer" + " " + window.localStorage.getItem('token'),
                }
            }).then((result) => {
                console.log(result)
                if (result.data.code == 200) {
                    this.chengyuanList = result.data.data
                }else{
                    this.$message.error(result.data.message);
                }
            }).catch((err) => {
                console.log(err)
                this.$message.error(err.response.data.message);
                if (err.response.data.code == 403) {
                    setTimeout(() => {
                        this.$router.push({
                            name: 'login'
                        })
                    }, 1500);
                }
            });
        },
        todetail(){},
    },
};
</script>

<style lang="scss" scoped>
.index {
    margin-top: 30px;
    width: 100%;
    height: auto;

    .liebiao_con {
        width: 100%;
        height: auto;

        .allList {
            width: 100%;
            height: auto;
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            .list1 {
                margin-bottom: 30px;
                margin-right: 2%;
                width: 32%;
                height: auto;

                &:nth-child(3n) {
                    margin-right: 0;
                }

                &:hover {
                    .img {
                        .xuanze {
                            opacity: 1;
                        }
                    }
                }

                .img {
                    position: relative;
                    width: 100%;
                    height: 200px;
                    border-radius: 10px;
                    overflow: hidden;

                    img {
                        width: 100%;
                        height: 100%;
                    }

                    .tag {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 60px;
                        height: 30px;
                        border-radius: 10px 0 10px 0;
                        background: rgba($color: #000000, $alpha: 0.6);
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;

                        p {
                            color: #fff;
                            font-size: 14px;
                        }
                    }

                    .xuanze {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        z-index: 99;
                        opacity: 0;
                        width: 100%;
                        height: auto;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        box-sizing: border-box;
                        padding: 10px 20px;
                        background: linear-gradient(to bottom, transparent, #00000f);
                        transition: all 0.35s;

                        .ms {
                            width: 80%;
                            height: auto;

                            p {
                                font-size: 14px;
                                color: #fff;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                width: 100%;
                            }
                        }

                        .choose {
                            width: 20%;
                            height: 35px;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;

                            .choose1 {
                                width: 35px;
                                height: 35px;
                                cursor: pointer;

                                img {
                                    width: 100%;
                                    height: auto;
                                }
                            }
                        }
                    }
                }

                .des {
                    margin-top: 20px;
                    width: 100%;
                    height: auto;

                    .title {
                        margin-bottom: 10px;
                        width: 100%;
                        height: auto;

                        p {
                            font-size: 15px;
                            color: #212121;
                        }
                    }

                    .xq {
                        width: 100%;
                        height: auto;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        .xq1 {
                            display: flex;
                            align-items: center;

                            img {
                                margin-right: 10px;
                                width: 26px;
                                height: 26px;
                                border-radius: 7px;
                            }

                            p {
                                font-size: 14px;
                                color: #666;
                            }
                        }

                        .xq2 {
                            display: flex;
                            align-items: center;

                            .sc {
                                display: flex;
                                align-items: center;
                                margin-right: 15px;

                                img {
                                    margin-right: 5px;
                                    width: 26px;
                                    height: 26px;
                                }

                                p {
                                    font-size: 15px;
                                    color: #666;
                                }
                            }
                        }
                    }
                }
            }

        }

        .chengyuan {
            .list1 {
                height: auto;
                border-radius: 10px;
                box-sizing: border-box;
                padding: 20px;
                border: 1px solid #ccc;
                border-radius: 10px;

                &:hover {
                    background-color: #fafafa;
                }

                .cy_top {
                    margin-bottom: 20px;
                    width: 100%;
                    height: auto;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .cy_1 {
                        width: auto;
                        display: flex;
                        align-items: center;

                        img {
                            margin-right: 10px;
                            width: 50px;
                            height: 50px;
                        }

                        .detail {
                            display: flex;
                            flex-direction: column;

                            p {
                                font-size: 16px;
                                color: #212121;
                            }

                            span {
                                font-size: 14px;
                                color: #999;
                            }
                        }
                    }

                    .cy_2 {
                        width: 100px;
                        height: 35px;

                        .guanzhu {
                            width: 100%;
                            height: 100%;
                            border: 1px solid #212121;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 8px;

                            img {
                                margin-right: 10px;
                                width: auto;
                            }

                            p {
                                font-size: 14px;
                                color: #212121;
                            }
                        }

                        .weiguanzhu {
                            border: 1px solid #ff6600;
                            width: 100%;
                            height: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 8px;

                            i {
                                margin-right: 10px;
                                font-size: 20px;
                                color: #ff6600;
                            }

                            p {
                                font-size: 14px;
                                color: #ff6600;
                            }
                        }
                    }
                }

                .cy_bot {
                    width: 100%;
                    height: auto;
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;

                    .bot1 {
                        margin-bottom: 20px;
                        margin-right: 4%;
                        width: 48%;
                        height: 95px;

                        &:nth-child(2n) {
                            margin-right: 0;
                        }

                        img {
                            width: 100%;
                            border-radius: 10px;
                        }
                    }
                }
            }
        }
    }

    .kong {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .kong1 {
            width: 100%;
            height: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 20px;

            i {
                margin-right: 15px;
                font-size: 22px;
                color: #ff6600;
            }

            p {
                font-size: 16px;
                color: #ff6600;
                font-weight: bold;
            }
        }

        .anniu {
            width: 200px;
            height: 35px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background-color: #ff6600;
            border-radius: 5px;
            cursor: pointer;

            p {
                color: #fff;
                font-size: 15px;
            }
        }
    }

}</style>