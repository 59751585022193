import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from './router' 
import store from './store' 
import axios from 'axios'
import '@/assets/base.scss'
import '@/assets/media.css'
import "swiper/swiper.min.css"
import moment from 'moment'
import VueSocialSharing from 'vue-social-sharing';
Vue.use(VueSocialSharing);

// 全局配置axios的请求根路径
// axios.defaults.baseURL = 'https://hw-cms.iqka.com/api/'
Vue.prototype.$website= 'https://hw-cms.iqka.com/api/';

// 将axios挂载到Vue上
Vue.prototype.$axios = axios
// Vue.prototype.$socialSharing = SocialSharing;
// 今后在每个.vue组件中要发起请求,直接调用 this.$axios.xxx
Vue.filter('datefmt',function (input,fmtstring) {//当input为时间戳时，需转为Number类型
  // 使用momentjs这个日期格式化类库实现日期的格式化功能
  return moment(input).format(fmtstring);
});

Vue.filter('integer',function(value){
  return Math.floor(value);
})
Vue.config.productionTip = false
Vue.use(ElementUI);
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
